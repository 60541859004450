import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'UseOfCreditDisclosurePage' })((theme) => ({
  root: {},
  container: {
    maxWidth: 639,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      padding: 15,
    },
  },
  pageTitle: {
    ...theme.typography.h1,
    paddingTop: 60,
    paddingBottom: 10,
    [theme.breakpoints.down('md')]: {
      paddingTop: 33,
    },
  },
  linkContainer: {
    paddingTop: 20,
    borderTop: `1px solid ${theme.palette.other.divider}`,
    wordBreak: 'break-all',
  },
  link: theme.mixins.underlinedLink,
  accordionSection: {
    marginBottom: 16,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.other.border}`,
    borderRadius: 4,
  },
  introText: {
    marginBottom: 20,
  },
  accordionTitle: {
    ...theme.typography.body3Bold,
    minHeight: 64,
    '&.MuiAccordionSummary-root.Mui-expanded': {
      borderBottom: `1px solid ${theme.palette.other.border}`,
    },
  },
  accordionDetails: {
    ...theme.typography.body1,
    display: 'block',
  },
  divider: {
    margin: '40px 0px 20px',
  },
  cdLink: {
    width: '80%',
    wordWrap: 'break-word',
    ...theme.mixins.underlinedLink,
  },
}));
