import { memo, useCallback, useRef, useState } from 'react';

import { Grid } from '@mui/material';
import dayjs from 'dayjs';

import { useInteractionId } from '@ecp/utils/analytics/interaction-id';
import { datadogRum } from '@ecp/utils/logger';

import { Button, Snackbar } from '@ecp/components';
import {
  getAllValues,
  getCurrentPage,
  getCustomerId,
  getDalSessionId,
  getGlobalError,
  getInquiryId,
  getLineOfBusiness,
  getOfferProductsSelected,
  getOfferSetId,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { useStyles } from './DebugTopbarDrawer.styles';

export const DebugTopbarDrawer: React.FC = memo(() => {
  const { classes } = useStyles();
  const answers = useSelector(getAllValues);
  const offerProductsSelected = useSelector(getOfferProductsSelected);
  const { requestId, text } = useSelector(getGlobalError);
  const interactionId = useInteractionId().get();
  const debugInfo = [
    {
      label: 'Current date/time',
      value: dayjs().toDate().toString(),
    },
    {
      label: 'Customer ID',
      value: useSelector(getCustomerId),
    },
    {
      label: 'auto.prefill.flow',
      value: answers['auto.prefill.flow'],
    },
    {
      label: 'home.prefill.flow',
      value: answers['home.prefill.flow'],
    },
    {
      label: 'Line of business',
      value: useSelector(getLineOfBusiness),
    },
    {
      label: 'Current page',
      value: useSelector(getCurrentPage),
    },
    {
      label: 'dalSessionId',
      value: useSelector(getDalSessionId),
    },
    {
      label: 'inquiryId',
      value: useSelector(getInquiryId),
    },
    {
      label: 'offerSetId',
      value: useSelector(getOfferSetId),
    },
    {
      label: 'products',
      value: offerProductsSelected.join(','),
    },
    {
      label: 'Interaction Id',
      value: interactionId,
    },
    {
      label: 'Datadog session_id',
      value: datadogRum.getInternalContext()?.session_id,
    },
    {
      label: 'Error X-Request-Id',
      value: requestId,
    },
    {
      label: 'Error text',
      value: text,
    },
  ];

  const debugListElement = useRef<HTMLUListElement>(null);

  const [textCopied, setTextCopied] = useState(false);

  // !TODO Replace with an API (which would create something like ServiceNow ticket) or simply copy to clipboard a debugInfo object
  const copyToClipboard = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      const textArea = document.createElement('textarea');
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      textArea.value = debugListElement
        .current!.innerHTML.replace(/<\/li>/g, '\n')
        .replace(/<.+?>/g, '');
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      textArea.remove();
      event.currentTarget.focus();
      setTextCopied(true);
    },
    [],
  );

  const handleClose = useCallback(() => setTimeout(() => setTextCopied(false), 3000), []);

  return (
    <Grid container direction='column' alignItems='center' className={classes.root}>
      <ul ref={debugListElement}>
        {debugInfo.map((debugItem) => {
          return (
            debugItem.value && (
              <li key={debugItem.label}>
                <strong>{debugItem.label}:</strong> {debugItem.value}
              </li>
            )
          );
        })}
      </ul>
      <Button variant='primary' onClick={copyToClipboard}>
        Copy info to clipboard
      </Button>
      <Snackbar
        open={textCopied}
        message='&#10003; Information Copied to Clipboard'
        vertical='top'
        horizontal='center'
        onClose={handleClose}
      />
    </Grid>
  );
});
