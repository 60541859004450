import { useCallback, useState } from 'react';

import { Collapse } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';

import { IconUIExpandLess, IconUIExpandMore } from '@ecp/themes/base';

import { Button } from '../Button';
import { useStyles } from './Topbar.styles';

interface Props {
  classes?: Partial<ReturnType<typeof useStyles>['classes']>;
  title: string;
  drawer?: React.ReactElement;
  trackingName?: string;
  infoBanner?: React.ReactElement;
}

export const Topbar: React.FC<Props> = (props) => {
  const { title, drawer, trackingName, infoBanner } = props;
  const { classes } = useStyles(undefined, { props });
  const [expand, setExpand] = useState(false);

  const ExpandIcon = expand ? IconUIExpandLess : IconUIExpandMore;

  const toggleExpand: React.MouseEventHandler<HTMLButtonElement> = useCallback(() => {
    trackClick({ action: trackingName, label: expand ? 'Close' : 'Open' });
    setExpand(!expand);
  }, [expand, trackingName]);

  return (
    <div className={classes.root}>
      {infoBanner}
      <div className={classes.titlePanel}>
        <Button
          variant='iconText'
          className={classes.expandButton}
          onClick={toggleExpand}
          aria-label={`${title} Toggle topbar`}
          aria-expanded={expand}
        >
          {title}
          <ExpandIcon className={classes.expandIcon} />
        </Button>
      </div>
      <Collapse in={expand} timeout='auto' unmountOnExit>
        <div className={classes.collapseContent}>{drawer}</div>
      </Collapse>
    </div>
  );
};
