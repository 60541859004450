import { useAddFields } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { OptionProps } from '@ecp/features/sales/shared/types';

interface Props extends QuestionProps {
  driverRef: string;
}

export const LicenseCountryQuestion: React.FC<Props> = (props) => {
  const {
    driverRef,
    groupLabel,
    id = 'LicenseStatus',
    label = '',
    trackingName = 'LicenseCountry',
  } = props;

  const useDriverField = useFieldWithPrefix(driverRef, 'driver.<id>');
  const dlCountry = useDriverField('license.country');
  useAddFields({ dlCountry });

  if (!dlCountry.exists) return null;

  return (
    <Select
      {...(dlCountry.props as OptionProps)}
      fullWidth={false}
      groupLabel={groupLabel}
      label={label}
      id={id}
      data-testid={id}
      inputButtonAriaLabel='Country'
      trackingName={trackingName}
    />
  );
};
