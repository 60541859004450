import type { StateOption } from '@ecp/types';

interface BaseMetadata {
  subHeader?: string;
  subTitle?: string;
}

interface StateMetadata extends StateOption<BaseMetadata>, BaseMetadata {}

const sharedHeaderMetadata = {
  subHeader:
    'You may be eligible for vehicle, driver and auto policy level discounts depending on your location and qualification.',
  subTitle: 'Additional auto policy discounts',
};

export const autoHeaderMetadata: {
  [productKey: string]: StateMetadata;
} = {
  'amfam.auto': {
    ...sharedHeaderMetadata,
    stateOptions: {
      MA: {
        subHeader:
          'You may be eligible for vehicle, driver and auto policy level savings depending on your location and qualifications.',
        subTitle: 'Additional auto policy savings',
      },
      HI: {
        subHeader:
          'You may be eligible for vehicle, driver and auto policy level savings depending on your location and qualifications.',
        subTitle: 'Additional auto policy savings',
      },
    },
  },
  'connect.auto': sharedHeaderMetadata,
};
