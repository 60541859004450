import { env } from '@ecp/env';

import type { Metadata } from './metadata.js';

const metadata: Metadata = {
  shouldRenderTitle: !env.static.isAgent,
  shouldRenderTopSpacer: env.static.isAgent,
  variant: 'bannerHeading',
};

export default metadata;
