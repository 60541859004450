// NOTE: this file is only used in devMode,
export const partner = {
  shared: {
    partnerDisplayName: '[partnerName]',
    salesEmailQuotesAddress: '[salesEmailQuotesAddress]',
    salesPhoneHours: '[partnerSalesPhoneHours]',
    servicePhoneHours: '[partnerServicePhoneHours]',
    chatHours: '[partnerchatHours]',
    qnbPhoneNumber: '[ChoiceQNBPN]',
    dnqPhoneNumber: '[ChoiceDNQN]',
    salesPhoneNumber: '[ChoiceMSPN]',
    servicePhoneNumber: '[servicePhoneNumber]',
    agentFromEmailAddress: '[agentFromEmailAddress]',
    agentFromEmailName: '[agentFromEmailName]',
    membershipPhoneNumber: '[membershipPhoneNumber]',
    dnqEmail: '[dnqEmail]', // adding this new field, which is required for amfam-excl-agent partner.
  },

  lobDetail: {
    AUTO: {
      partnerDisplayNameShort: '[partnerName]',
      salesPhoneNumber: '[salesPN]',
      softdnqPhoneNumber: '[softdnqPN]',
      servicePhoneNumber: '[servicePN]',
      dnqNumbers: {
        default: '[defaultPN]',
      },
      discount: {
        AutoPolicyPartner: '[discount.AutoPolicyPartner]',
      },
    },
  },
} as const;
