import { memo, useCallback } from 'react';

import { sessionStorage } from '@ecp/utils/storage';

import { Button } from '@ecp/components';
import { PhoneLink } from '@ecp/features/sales/shared/components';
import { getDalSessionId } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { partner } from '@ecp/partners';

import { HO2_SESSION_STORAGE_KEY } from '../constants';
import { useStyles } from './QuotesHO2Prompt.styles';

export const QuotesHO2Prompt: React.FC = memo(() => {
  const { classes } = useStyles();

  const dalSessionId = useSelector(getDalSessionId);
  const handleContinue = useCallback((): void => {
    sessionStorage.setItem(`${dalSessionId} ${HO2_SESSION_STORAGE_KEY}`, true);
  }, [dalSessionId]);

  return (
    <div className={classes.container}>
      <h1 className={classes.pageTitle}>Continue with auto online</h1>
      <h2 className={classes.pageSubTitle}>Call for home insurance</h2>
      <p className={classes.guidance}>
        Based on the information you’ve provided, we’re unable to insure your home with a standard
        Replacement Cost Policy.
      </p>
      <p className={classes.guidance}>
        The good news — we offer a Market Value Policy that should sufficiently insure your home.
      </p>
      <p className={classes.lastGuidance}>
        Call one of our licensed advisors for your home insurance quote at
        <PhoneLink withUnderlinedLinkStyle withLinkStyle number={partner.shared.dnqPhoneNumber} />.
      </p>
      <Button variant='primary' className={classes.next} onClick={handleContinue} type='submit'>
        Continue with auto
      </Button>
    </div>
  );
});
