import baseMetadata from './metadata.js';
import type { Metadata } from './metadata.js';

export const metadata: Metadata = {
  ...baseMetadata,
  subTextContent:
    'If anyone on the policy is legally married and there are two or more drivers, their significant other must also be listed on the policy.',
  subTextContentError:
    'Your quote must have an even number of married drivers if there are two or more drivers. Change the married status of a driver to continue.',
  allowOneMarriedDriver: true,
  showSpecialCircumstancesLink: false,
  ignoreLicenseStatus: true,
  ignoreLapseQuestion: true,
  defaultNewDriverSNI: false,
};

export default metadata;
