import type { EnhancedStore } from '@reduxjs/toolkit';

import { datadogLog } from '@ecp/utils/logger';

import type { RootActions, RootStore } from '@ecp/features/sales/shared/store/types';

declare global {
  interface Window {
    store: EnhancedStore<RootStore, RootActions>;
  }
}

export const CALLED_GET_STATE_IN_REDUCER =
  'Called store.getState() while the reducer is executing.';
export const NO_GLOBAL_STORE_FOUND = 'No global store found';

/**
 * Allows to run any selector on the current store state outside of the store context (in any random place).
 * We use it in `*.lazy.ts` modules to conditionally load partner modules in agent experience.
 */
export const globalSelector = <T>(
  selector: (state: ReturnType<typeof window.store.getState>) => T,
): T => {
  try {
    const state = getState();

    return selector(state);
  } catch (error) {
    const e = error as Error;

    datadogLog({
      logType: e?.message === CALLED_GET_STATE_IN_REDUCER ? 'warn' : 'error',
      message: e?.message,
      context: {
        logOrigin: 'libs/features/sales/shared/store/utils/src/globalSelector.ts',
        functionOrigin: 'globalSelector',
        message: e?.message,
      },
      error: e,
    });

    throw error;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getState = (): any => {
  if (!window.store) {
    datadogLog({
      logType: 'error',
      message: NO_GLOBAL_STORE_FOUND,
      context: {
        logOrigin: 'libs/features/sales/shared/store/utils/src/globalSelector.ts',
        functionOrigin: 'getState',
      },
    });

    throw new Error(NO_GLOBAL_STORE_FOUND);
  }

  try {
    return window.store.getState();
  } catch (error) {
    const e = error as Error;

    datadogLog({
      logType: 'error',
      message: e?.message,
      context: {
        logOrigin: 'libs/features/sales/shared/store/utils/src/globalSelector.ts',
        functionOrigin: 'getState',
        message: e?.message,
      },
      error: e,
    });

    throw new Error(CALLED_GET_STATE_IN_REDUCER);
  }
};
