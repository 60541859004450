import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles<
  void,
  'cardImage' | 'cardImageContainer' | 'cardImageContainerAdd'
>({ name: 'AutoProfileForm' })((...[theme, , classes]) => ({
  ...theme.mixins.formAll,
  root: {
    ...theme.mixins.form,
    marginTop: 0,
  },
  alert: {
    marginTop: 15,
  },
  alertMessage: {
    color: theme.palette.error.darkText,
  },
  container: {
    '& > .MuiGrid-item': {
      paddingTop: 0,
    },
  },
  inlineImage: {
    verticalAlign: 'middle',
    ...theme.mixins.setColorSvg(theme.palette.warning.main),
  },
  noMargin: {
    margin: 0,
  },
  cardImageContainer: {
    justifyContent: 'center',
  },
  cardImageContainerVehicle: {
    backgroundColor: theme.palette.primary.main,
  },
  cardImageContainerAdd: {},
  cardImage: {
    height: 47,
    width: 47,
    borderRadius: '100%',
    objectFit: 'contain',
  },
  cardImageAdd: {
    color: theme.palette.grey[500],
    backgroundColor: theme.palette.grey[50],
    padding: 5,
  },
  message: {
    marginBottom: 15,
    marginTop: 0,
    color: theme.palette.text.tertiary,
  },
  nextPageInstructions: {
    '& > hr': {
      marginTop: 3,
    },
  },
  withAddButton: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    [`& .${classes.cardImageContainer}`]: {
      width: 76,
      height: '100%',
      borderRadius: 0,
    },
    [`& .${classes.cardImageContainerAdd}`]: {
      backgroundColor: theme.palette.primary.light,
    },
  },
  subTextWrapper: {
    ...theme.typography.body2,
    color: theme.palette.text.tertiary,
    marginTop: 5,
  },
  errorSubTextWrapper: {
    color: theme.palette.error.darkText,
  },
  warning: {
    '& legend, & p': {
      color: theme.palette.error.main,
    },
  },

  maritalWarning: {
    border: `2px solid ${theme.palette.error.main}`,
    background: theme.palette.error.light,
    borderRadius: 3,
    padding: 12,
    marginTop: 15,
  },
  driverWarning: {
    marginTop: 8,
  },
}));
