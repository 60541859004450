import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AgentLoginForm' })((theme) => ({
  root: {},
  logoPanel: {
    marginBottom: 30,
    [theme.breakpoints.down('md')]: {
      marginBottom: 14,
    },
  },
  [theme.breakpoints.down('lg')]: {
    logoItem: {
      height: 57,
      flexGrow: 1,
      width: 105,
      '& img': {
        height: 44.5,
        width: 89,
        margin: '3px 8px',
      },
      marginRight: 0,
      marginTop: 10,
      marginLeft: 10,
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  loginButton: {
    width: '100%',
  },
  forgotPasswordLink: {
    ...theme.typography.body1,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'none',
  },
  errorMessage: {
    ...theme.typography.body1,
    textAlign: 'center',
    marginBottom: 40,
    padding: '16px 20px',
    border: `2px solid ${theme.palette.error.main}`,
    backgroundColor: `${theme.palette.error.main}1a`,
    color: theme.palette.error.main,
  },
}));
