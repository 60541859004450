import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AgentDashboardForm' })((theme) => ({
  root: {
    padding: '30px 30px 60px',
  },
  changeDateLink: {
    ...theme.mixins.underlinedLink,
    lineHeight: '20px',
    height: 'auto',
    fontSize: 'inherit',
    fontWeight: 'normal',
    paddingBottom: 2,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  policyStartDateChangeBody: {
    ...theme.typography.body1,
    display: 'inline',
    marginRight: 10,
  },
  expiredQuotesWarning: {
    marginTop: 3,
  },
  policyStartDateCard: {
    padding: 20,
    backgroundColor: theme.palette.grey[100],
  },
  calendarIcon: {
    ...theme.mixins.setColorSvg(theme.palette.primary.main),
    marginTop: '-2px',
    marginRight: 10,
  },
  policyStartDateCardDivider: {
    marginLeft: 20,
    marginRight: 20,
  },
  errorAlert: {
    width: 1084,
    minHeight: 44,
    margin: '12px 0',
  },
  alertIcon: {
    ...theme.mixins.setColorSvg(theme.palette.error.main),
  },
}));
