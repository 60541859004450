import { useCallback, useRef } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem, TooltipWithIcon } from '@ecp/components';
import { useGetConditionValues, useGetFields } from '@ecp/features/sales/form';
import { Button, Form, Link, NextPageInstructions } from '@ecp/features/sales/shared/components';
import { telematicsConsent } from '@ecp/features/sales/shared/metadata';
import { useForm } from '@ecp/features/sales/shared/store';

import { VehicleOwnershipDateFormQuestions } from '../../formBody';
import { useGetVehicleItemsWithImage } from '../../state';
import metadata from './metadata';
import { useStyles } from './VehicleOwnershipDateForm.styles';

interface Props {
  onNext: () => Promise<void>;
  nextPageInstructions: string;
}

export const VehicleOwnershipDateForm: React.FC<Props> = (props) => {
  const { onNext, nextPageInstructions } = props;
  const { classes } = useStyles();
  const initValues = useRef({});
  const getFields = useGetFields();
  const getConditions = useGetConditionValues();

  const { isPatchFormInProgress, patchFormValues, validateForm } = useForm({
    initValues,
    fields: getFields(),
    conditions: getConditions(),
  });

  const vehicleItemList = useGetVehicleItemsWithImage();

  const handleSubmit = useCallback(async () => {
    if (validateForm().isValid) {
      await patchFormValues();
      await onNext();
    }
  }, [onNext, patchFormValues, validateForm]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container>
          <Grid item xs={12} className={classes.subTitle}>
            When did you purchase, finance or lease your vehicle(s)?
            <TooltipWithIcon title="This will help us confirm we're using the right vehicle information to determine your quote." />
          </Grid>
          <GridItem topSpacing='sm' xs={12} className={classes.form}>
            <VehicleOwnershipDateFormQuestions vehicleItemList={vehicleItemList} />
          </GridItem>
        </Grid>
        <Grid item xs={12}>
          <NextPageInstructions className={classes.nextText} divider>
            {nextPageInstructions}
          </NextPageInstructions>
        </Grid>
        <Grid item xs={12}>
          <p className={classes.text}>
            {metadata.authorizationText}
            <Link
              href={telematicsConsent}
              underline='always'
              target='_blank'
              rel='noopener noreferrer'
            >
              Learn more
            </Link>
          </p>
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.next}
            variant='primary'
            onClick={handleSubmit}
            isProcessing={isPatchFormInProgress}
            trackingName='VehicleOwnershipDateContinue'
            trackingLabel={GoogleAnalyticsLabels.CONTINUE}
            analyticsElement='choice.vehicleOwnershipDatePage.continueButton'
          >
            Accept & continue
          </Button>
        </Grid>
      </Form>
    </div>
  );
};
