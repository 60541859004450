import { memo, useRef } from 'react';

import { AppBar } from '@mui/material';

import { LogoMoonshotAbbrvImageUrl } from '@ecp/themes/base';

import { Actions } from './Actions';
import { useStyles } from './AppHeader.styles';
import metadata from './metadata';
import { PartnerLogo } from './PartnerLogo';

export const AppHeader: React.FC = memo(() => {
  const { classes } = useStyles();
  const headerRef = useRef<HTMLDivElement>(null);

  return (
    <AppBar className={classes.root} ref={headerRef} position='sticky'>
      <div className={classes.logos}>
        <img src={LogoMoonshotAbbrvImageUrl} className={classes.appLogo} alt='app logo' />
        <PartnerLogo className={classes.partnerLogo} />
      </div>
      {!metadata.hideActions && <Actions className={classes.actions} />}
    </AppBar>
  );
});
