import { env } from '@ecp/env';

import type { Metadata } from './metadata';

const helperText = env.static.isAgent ? (
  'This discount is only available when you pay through a bank account. It does not apply to credit card payments.'
) : (
  <>
    Sign up to pay your auto premium through automatic withdrawal from a checking or savings
    account. Discount <strong>does not</strong> apply to credit card payments.
  </>
);

const metadata: Metadata = {
  helperText,
  label: 'Automated recurring payments?',
};

export default metadata;
