import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutoSummaryCard' })((theme) => ({
  root: {
    border: `1px solid ${theme.palette.other.border}`,
    height: '100%',
    backgroundColor: theme.palette.grey[50],
  },
  cardHeader: {
    padding: '15px 20px',
    backgroundColor: theme.palette.primary.light,
  },
  cardAction: {
    margin: 0,
  },
  cardTitle: {
    ...theme.typography.h4,
    borderBottom: `1px solid ${theme.palette.other.divider}`,
    marginBottom: 10,
    textTransform: 'none',
  },
  cardContent: {
    display: 'flex',
    padding: 0,
    margin: 20,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
    },
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  address: {
    marginTop: 25,
  },
  quoteItem: {
    marginBottom: 15,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  quoteImage: {
    height: 18,
    width: 32,
  },
  quoteDriverImage: {
    height: 18,
    width: 18,
  },
  description: {
    paddingLeft: 6,
  },
  imageTitle: {
    display: 'flex',
    '& > div': {
      width: 20,
      height: 20,
    },
  },
  editLink: {
    ...theme.mixins.linkButton,
    fontWeight: 'bold',
  },
  body2: theme.typography.body2,
}));
