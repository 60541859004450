import type { Metadata } from './DriverLicenseStatusQuestion.metadata.js';
import baseMetadata from './DriverLicenseStatusQuestion.metadata.js';

const metadata: Metadata = {
  ...baseMetadata,
  getLicenseStatusGroupLabel: (possessiveName, ifNameOrYou) =>
    `What's ${possessiveName} driver's license status? ${ifNameOrYou} will not be driving any vehicles included on this policy, select "Non operator".`,
};

export default metadata;
