/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Events } from '@ecp/utils/common';
import { registerEvents } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';
import type { PersistentStorage } from '@ecp/utils/storage';

import type { ExperienceId, PartnerName } from '@ecp/partners';

import { parseJwt, type Token } from '../token';
import { AuthError } from '../util';

type AgentAuthUtilEvent = 'login' | 'logout';

export interface BaseItem {
  id: string;
  name: string;
}
export interface PartnerAccount extends BaseItem {
  experienceId: ExperienceId;
}

export interface PartnerSegment extends BaseItem {
  accounts: PartnerAccount[];
}

export interface AuthorizedLevel extends BaseItem {
  segments: PartnerSegment[];
}
export interface AgentAccessToken {
  authToken: string;
  expAt: number;
  authorizedLevels: AuthorizedLevel[];
}

export interface DecodedAgentAuthToken {
  email: string;
  exp: number;
  iat: number;
  iss: string;
  producerId: string;
  user: string;
}

export interface PartnerAccount {
  id: string;
  name: string;
  experienceId: ExperienceId;
}

export interface PartnerSegment {
  id: string;
  name: string;
  accounts: PartnerAccount[];
}

export interface AuthorizedLevel {
  id: string;
  name: string;
  segments: PartnerSegment[];
}

export interface FetchAgentTokensResponseBody extends AgentAccessToken {
  partnerName: PartnerName;
  experienceId: ExperienceId;
  partnerCode: number;
  partnerProducerId: string;
  agencyId: number;
  changePassword: boolean;
  producerId: number;
  producerHorisonId: number;
  locked: boolean;
  siteType: string;
}

export type FetchAgentTokensFromRefreshTokenResponseBody = AgentAccessToken;

export interface FetchAgentTokensResponse extends Response {
  json(): Promise<FetchAgentTokensResponseBody>;
}

export interface FetchAgentTokensFromRefreshTokenResponse extends Response {
  json(): Promise<FetchAgentTokensFromRefreshTokenResponseBody>;
}

interface AuthUtil extends Events<AgentAuthUtilEvent, () => any> {
  /**
   * Whether the current session is authenticated or not.
   *
   * @readonly
   * @default false
   */
  isAuth: boolean;
  /**
   * Whether the current authentication state should persist across browser tabs/windows or not.
   *
   * @readonly
   * @default false
   */
  isRememberMe: boolean;
  /**
   * Returns agent token value if it's not expired, else fetches the new token and returns it.
   */
  token: Promise<string | null>;
  /**
   * Logs in the agent. Emits `login` event.
   *
   * @readonly
   * @param username - Agent's username.
   * @param password - Agent's password.
   * @throws {AuthError}
   */
  login: (username: string, password: string) => Promise<void>;
  /**
   * Logs out the agent. Emits `logout` event.
   */
  logout: () => void;
}

/**
 * Agent authentication utility.
 * Note: `Remember me` flag is not functional yet, since we don't have the requirements to keep the agents authenticated across browser tabs/windows.
 * Most of its logic is implemented though.
 */
export class AgentAuthUtil implements AuthUtil {
  /**
   * Whether the current session is authenticated or not.
   *
   * @default false
   */
  private _isAuth = false;

  /**
   * Save the agent authorized levels for managing products.
   *
   * @default empty array
   */
  private _authorizedLevels: AuthorizedLevel[] | null = null;

  get authorizedLevels(): AuthorizedLevel[] | null {
    return this._isAuth ? this._authorizedLevels : null;
  }

  get isAuth(): boolean {
    return this._isAuth;
  }

  /**
   * Whether the current authentication state should persist across browser tabs/windows or not.
   *
   * @default false
   */
  private _isRememberMe = false;

  get isRememberMe(): boolean {
    return this._isRememberMe;
  }

  readonly addEventListener;
  readonly removeEventListener;
  readonly notifyEventListeners;

  constructor(
    /**
     * Agent token.
     */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    private _token: Token,
    /**
     * Fetch API.
     * Cookie with refresh token is expected to be set in the response.
     *
     * @returns Response JSON object with the body of {FetchAgentTokensResponse} type.
     */
    private fetchAgentTokens: (
      username: string,
      password: string,
    ) => Promise<FetchAgentTokensResponse>,
    /**
     * Fetch API.
     * Cookie with refresh token should be passed in the request.
     * Cookie with refresh token is expected to be set in the response.
     *
     * @returns Response JSON object with the body of {FetchAgentTokensFromRefreshTokenResponse} type.
     */
    private fetchAgentTokensFromRefreshToken: () => Promise<FetchAgentTokensFromRefreshTokenResponse>,
    /**
     * Reference to `session` PersistentStorage instance.
     */
    private sessionStorage: PersistentStorage, // !TODO add localStorage impl to have an option for token to persist between the browser tabs // private localStorage?: PersistentStorage,
  ) /**
   * Reference to `local` PersistentStorage instance.
   */ {
    const events = registerEvents('login', 'logout');
    this.addEventListener = events.addEventListener;
    this.removeEventListener = events.removeEventListener;
    this.notifyEventListeners = events.notifyEventListeners;
    // this._isRememberMe = Boolean(this.localStorage.getItem('agentAuth.isRememberMe'));
    // if (this.isRememberMe) {
    const agentAccessToken = this.sessionStorage.getItem('agentAuth.token') as unknown;
    if (agentAccessToken) {
      const { authToken, expAt, authorizedLevels } = agentAccessToken as AgentAccessToken;
      this.loginSuccess(authToken, expAt, authorizedLevels);
      if (this._token.isExpired) this.refreshTokens();
    }
    //   } else {
    //     this.refreshTokens();
    //   }
    // }
  }

  /**
   * returns agent email for agent screen recording
   */
  readonly getAgentEmail = (): string | null => {
    const agentAccessToken = this.sessionStorage.getItem('agentAuth.token') as unknown;
    let agentEmail = null;
    if (agentAccessToken) {
      const { authToken } = agentAccessToken as AgentAccessToken;

      const decodedToken = parseJwt(authToken) as unknown;
      const { email } = decodedToken as DecodedAgentAuthToken;

      if (email) {
        agentEmail = email.toLowerCase();
      }
    }

    return agentEmail;
  };

  /**
   * logs agent information for datadog on login
   */
  readonly logAgentInformation = (username?: string): void => {
    const agentAccessToken = this.sessionStorage.getItem('agentAuth.token') as unknown;
    if (agentAccessToken && username) {
      const { authToken } = agentAccessToken as AgentAccessToken;

      const decodedToken = parseJwt(authToken) as unknown;
      if (decodedToken) {
        const { user, producerId } = decodedToken as DecodedAgentAuthToken;

        datadogLog({
          logType: 'info',
          message: 'Successful agent login',
          context: {
            logOrigin: 'libs/utils/auth/src/agentAuth/util.ts',
            functionOrigin: 'logAgentLogInformation',
            username,
            agentId: user,
            agentProducerId: producerId,
          },
        });
      }
    }
  };

  /**
   * Handles fetch errors.
   *
   * @param res - Response object.
   * @returns Promise of Response JSON object.
   * @throws {AuthError}
   */
  private handleErrors = async (
    res: Response,
  ): Promise<FetchAgentTokensResponseBody | FetchAgentTokensFromRefreshTokenResponseBody> => {
    if (!res.ok) {
      let text = '';
      if (res.body) text = await res.text();
      throw new AuthError(text, res.status);
    }

    return res.json();
  };

  /**
   * Sets new token value and expiration time. Sets authenticated flag to `true`. Notifies all listeners of successful `login` event.
   *
   * @param authToken - New token value.
   * @param expAt - New expiration time in seconds.
   */
  private loginSuccess = (
    authToken: string,
    expAt: number,
    authorizedLevels: AuthorizedLevel[],
    username?: string,
  ): void => {
    this._token.set(authToken, expAt);
    this.sessionStorage.setItem('agentAuth.token', { authToken, expAt, authorizedLevels });
    this._isAuth = true;
    this._authorizedLevels = authorizedLevels;
    this.notifyEventListeners('login');

    this.logAgentInformation(username);
  };

  /**
   * Resets the token. Sets authenticated flag to `false`. Removes token from `sessionStorage`. Removes remember me flag from `localStorage`.
   *
   * @param authToken - New token value.
   * @param expAt - New expiration time in seconds.
   */
  private clear = (): void => {
    this._token.reset();
    this._isAuth = false;
    this._authorizedLevels = [];
    this.sessionStorage.removeItem('agentAuth.token');
    // this.localStorage.removeItem('agentAuth.isRememberMe');
  };

  readonly login = (username: string, password: string): Promise<void> => {
    if (!username || !password) throw new AuthError('Username and password are required');

    return (
      this.fetchAgentTokens(username, password)
        .then(this.handleErrors)
        // set expAt to the highest value in case if expAt is not provided in the response
        .then(({ authToken, expAt = 9999999999, authorizedLevels }) => {
          this.loginSuccess(authToken, expAt, authorizedLevels, username);
        })
        .catch((error) => {
          datadogLog({
            logType: 'error',
            message: `Login failure - ${error?.message}`,
            context: {
              logOrigin: 'libs/utils/auth/src/agentAuth/util.ts',
              functionOrigin: 'login',
            },
            error,
          });

          this.clear();
          throw error;
        })
    );
  };

  readonly logout = (): void => {
    this.clear();
    this.notifyEventListeners('logout');
  };

  /**
   * Fetches new access and refresh tokens by passing the refresh token in the cookies.
   */
  private refreshTokens = (): Promise<void> => {
    return (
      this.fetchAgentTokensFromRefreshToken()
        .then(this.handleErrors)
        // TODO:  Work with the agent auth service team to have authorized levels added to the token so that we ensure they are always current rather than relying on just the initial authorize call
        .then(({ authToken, expAt }) => {
          this.loginSuccess(authToken, expAt, this._authorizedLevels ?? []);
        })
        .catch((error) => {
          datadogLog({
            logType: 'error',
            message: `RefreshTokens failure - ${error?.message}`,
            context: {
              logOrigin: 'libs/utils/auth/src/agentAuth/util.ts',
              functionOrigin: 'refreshTokens',
            },
            error,
          });

          this.clear();
          throw error;
        })
    );
  };

  get token(): Promise<string | null> {
    if (!this.isAuth) throw new AuthError('No agent token is present');
    if (this._token.isExpired) {
      return this.refreshTokens().then(() => this._token.value);
    }

    return Promise.resolve(this._token.value);
  }
}

/**
 * Get authorized partner list for the current agent
 *
 * @param authorizedLevels - the array of agent authorized levels
 * @returns an array of BaseItem for populating the parnter list on landing page.
 */
export const getAuthorizedPartnerList = (authorizedLevels: AuthorizedLevel[]): BaseItem[] => {
  return authorizedLevels.reduce((list: BaseItem[], level: AuthorizedLevel) => {
    const { segments, ...item } = level;
    list.push(item);

    return list;
  }, []);
};

/**
 * Get authorized segment list for the specific partner
 *
 * @param authorizedLevels - the array of agent authorized levels
 * @param partnerId - partner id
 * @returns an array of BaseItem for populating the segment list for selected partner on landing page.
 */
export const getAuthorizedPartnerSegmentList = (
  authorizedLevels: AuthorizedLevel[],
  partnerId: string,
): BaseItem[] => {
  const partner = authorizedLevels.find((option) => option.id === partnerId);
  if (partner)
    return partner.segments.reduce((list: BaseItem[], segment: PartnerSegment) => {
      const { accounts, ...item } = segment;
      list.push(item);

      return list;
    }, []);

  return [];
};

/**
 * Get authorized account list for the specific partner and specific segment
 *
 * @param authorizedLevels - the array of agent authorized levels
 * @param partnerId - partner id
 * @param segmentyId - segment id
 * @returns an array of PartnerAccount for populating the account list for selected partner and selected segment on landing page.
 */
export const getAuthorizedPartnerSegmentAccountList = (
  authorizedLevels: AuthorizedLevel[],
  partnerId: string,
  segmentId: string,
): PartnerAccount[] => {
  const partner = authorizedLevels.find((option) => option.id === partnerId);
  if (partner) {
    const segment = partner.segments.find((option) => option.id === segmentId);
    if (segment) return segment.accounts;
  }

  return [];
};
