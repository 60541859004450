export interface Metadata {
  disclosureGroups?: Array<{
    groupTitle?: string;
    disclosures?: JSX.Element;
  }>;
  disclosureLink?: string;
}

const metadata: Metadata = {};

export default metadata;
