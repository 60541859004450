import { memo, useEffect, useState } from 'react';

import { agentAuth } from '@ecp/utils/auth';
import { upperFirst } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';

import { PARTNER_EXPERIENCE_ID } from '@ecp/features/sales/shared/constants';
import { useField } from '@ecp/features/sales/shared/store';
import type { PartnerName } from '@ecp/partners';
import type { ExperienceId } from '@ecp/partners';
import { mapping } from '@ecp/partners';

import { partnerOverride } from './partnerLogoOverride';

interface Props {
  className?: string;
}

export const PartnerLogo: React.FC<Props> = memo((props) => {
  const { className } = props;
  const expId = useField(PARTNER_EXPERIENCE_ID).value as ExperienceId;
  const [imgSrc, setImgSrc] = useState<ExperienceId | undefined>(expId);

  useEffect(() => {
    if (!expId) {
      setImgSrc(undefined);

      return;
    }

    let partnerName: PartnerName = mapping[expId];
    if (partnerName.includes('-')) {
      partnerName = partnerName.split('-')[0] as PartnerName;
    }

    // Get the logo name from the exceptions list or fall back to dynamically generating the name
    const logoName = partnerOverride[partnerName]
      ? partnerOverride[partnerName] // Use custom logo name for partners (cobranded) in the exception list
      : `logo${upperFirst(partnerName)}`; // Otherwise, generate dynamically

    import(
      /* webpackMode: "eager" */
      `../../../../../../libs/themes/base/src/assets/generated/logos/${logoName}.svg`
    )
      .then((module) => {
        setImgSrc(module.default);
      })
      .catch((error) => {
        setImgSrc(undefined);

        datadogLog({
          logType: 'warn',
          message: `Partner logo not loaded properly - ${error?.message}`,
          context: {
            logOrigin: 'apps/sales/agent/src/components/AppHeader/PartnerLogo.tsx',
            functionOrigin: `libs/themes/base/src/assets/generated/logos/logo${upperFirst(
              partnerName,
            )}.svg`,
          },
          error,
        });
      });
  }, [expId]);

  if (!imgSrc || !agentAuth.isAuth) return null;

  return <img src={imgSrc} className={className} alt='partner logo' />;
});
