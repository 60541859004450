export const partner = {
  shared: {
    partnerDisplayName: 'CONNECT, powered by American Family Insurance',
    salesEmailQuotesAddress: 'AmFamService@connectbyamfam.com',
    servicePhoneHours: '8:00am-9:00pm EST M-F, 9:00am-6:00pm EST Sat',
    qnbPhoneNumber: '1-833-456-0898',
    dnqPhoneNumber: '1-866-875-5575 ext 78478',
    salesPhoneNumber: '1-833-456-0898',
    servicePhoneNumber: '1-833-456-0899',
    agentFromEmailAddress: 'CONNECT@email.connectbyamfam.com',
    agentFromEmailName: 'CONNECT, powered by American Family Insurance',
    dnqEmail: 'BA_PL@amfam.com',
  },
  lobDetail: { AUTO: { dnqNumbers: {}, discount: {} } },
} as const;
