import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { TextField } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';
import type { OptionProps } from '@ecp/features/sales/shared/types';

import { useStyles } from './AwayAtSchoolSubQuestions.styles';

interface Props extends QuestionProps {
  driverRef: string;
}

export const AwayAtSchoolSubQuestions: React.FC<Props> = (props) => {
  const { driverRef } = props;
  const { classes } = useStyles();

  const awayAtSchoolCountry = useField(`${driverRef}.discount.awayAtSchoolCountry`);
  const awayAtSchoolZipCode = useField(`${driverRef}.discount.awayAtSchoolZip`);
  const driverId = driverRef.split('.')[1];

  useAddFields({ awayAtSchoolCountry, awayAtSchoolZipCode });

  if (!awayAtSchoolCountry.exists || !awayAtSchoolZipCode.exists) return null;

  return (
    <Grid item xs={12} md={6} key={driverRef} className={classes.root}>
      <Select
        {...(awayAtSchoolCountry.props as OptionProps)}
        inputButtonAriaLabel='AwayAtSchoolCountry'
        id={`AwayAtSchoolCountry-${driverId}`}
        label='Away at school country'
        trackingName='AwayAtSchoolCountry'
        trackingLabel={GoogleAnalyticsLabels.REDACTED}
      />
      {awayAtSchoolCountry.value === 'US' && (
        <TextField
          {...awayAtSchoolZipCode.props}
          className={classes.zipCode}
          id={`AwayAtSchoolZipCode-${driverId}`}
          label='Away at school zip code'
          maxLength={5}
          trackingName='AwayAtSchoolZipCode'
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
        />
      )}
    </Grid>
  );
};
