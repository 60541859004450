import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AgentScriptBox' })((theme) => ({
  header: {
    ...theme.typography.header1,
    paddingTop: 6,
  },
  translationText: {
    margin: '10px 0px',
    ...theme.mixins.setColorSvg(theme.palette.text.link),
  },
  scriptContent: {
    '& p': theme.typography.body3,
    '& li': theme.typography.body3,
    '& ul': {
      paddingInlineStart: 20,
      marginBlockStart: 0,
      listStyleType: 'disc',
      marginBlockEnd: 0,
    },
  },
  scriptContentHeader: {
    ...theme.typography.body3Bold,
    margin: '15px 0px 10px',
  },
  checkboxText: {
    ...theme.typography.body3Italics,
    margin: '-22px 0px 10px 40px',
    paddingRight: 20,
  },
  scriptBoxAccordion: {
    backgroundColor: theme.palette.grey[200],
    boxShadow: 'none',
  },
  accordionTitle: {
    marginLeft: 10,
  },
  accordionExpand: {
    ...theme.mixins.setColorSvg(theme.palette.text.link),
  },
  accordionContent: {
    marginLeft: 32,
  },
  scriptBoxAccordionError: {
    border: `2px solid ${theme.palette.error.main}`,
  },
  hideHeader: {
    marginTop: '-32px',
  },
  dialogScriptBox: {
    backgroundColor: theme.palette.grey[50],
  },
  icon: {
    fontSize: 46,
  },
  dialogRoot: {
    '& .MuiDialogActions-root': {
      margin: '28px 0 10px 58px',
    },
  },
  yesOrNoQuestion: {
    '& > div': {
      width: 135,
    },
  },
}));
