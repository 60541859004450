import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'Actions' })((theme) => ({
  root: {
    display: 'flex',
    '& > *:not(:last-child)': {
      marginRight: 20,
      [theme.breakpoints.down('md')]: {
        marginRight: 10,
      },
    },
  },
}));
