import { useCallback, useMemo } from 'react';

import { GridItem } from '@ecp/components';
import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { RadioGroupWithOptions, Select } from '@ecp/features/sales/shared/components';
import {
  getPrimaryInsuredStateCode,
  getStateSpecificVehicleDiscountMetadata,
  useField,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { OptionProps } from '@ecp/features/sales/shared/types';

import { PrimaryUseOptions } from '../../../../metadata';

interface Props {
  vehicleRef: string;
}

export const VehicleUseQuestion: React.FC<Props> = (props) => {
  const { vehicleRef } = props;
  const primaryUse = useField(`${vehicleRef}.primaryUse`);
  const businessUse = useField(`${vehicleRef}.businessUse`);
  const model = useField(`${vehicleRef}.model`);
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  useAddFields({ primaryUse, businessUse });
  useAddConditionValues({
    conditionalFields: [businessUse],
    isExcluded: () => primaryUse.value !== 'VEHICLE_USE.PRIMARY.BUSINESS',
  });
  useAddConditionValues({
    conditionalFields: [primaryUse],
    isRequiredOverride: () => true,
  });

  // CSUI-650 & CPUI-5959 Quick fix, adding to tech debt for a better solution later.
  const primaryUseOptions = useMemo(
    () =>
      getStateSpecificVehicleDiscountMetadata(
        PrimaryUseOptions.filter(
          (option) => !!primaryUse.question.options?.find((x) => x.value === option.value),
        ),
        stateCode,
      ),
    [primaryUse.question.options, stateCode],
  );

  const vehicle = model.props.value || 'vehicle';

  const handleVehicleUseClick = useCallback(
    async (value: string) => {
      await primaryUse.props.actionOnComplete(value);
    },
    [primaryUse],
  );

  return (
    <>
      {primaryUse.exists && (
        <GridItem xs={12}>
          <RadioGroupWithOptions
            cardSize='medium'
            {...(primaryUse.props as OptionProps)}
            id='vehiclePrimaryUse'
            options={primaryUseOptions}
            label='What’s this vehicle primarily used for?'
            trackingName='primary_use_selection'
            actionOnComplete={handleVehicleUseClick}
          />
        </GridItem>
      )}
      {businessUse.exists && primaryUse.value === 'VEHICLE_USE.PRIMARY.BUSINESS' && (
        <GridItem topSpacing='lg' xs={12}>
          <Select
            {...(businessUse.props as OptionProps)}
            fullWidth={false}
            id='BusinessUse'
            groupLabel={`We need a few more details on how you use your ${vehicle}`}
            helperText='Select the business purpose that best fits your situation'
          />
        </GridItem>
      )}
    </>
  );
};
