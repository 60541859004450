import { useCallback, useMemo, useRef } from 'react';

import { FormHelperText, FormLabel, Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { formatMonth, getYearMonth } from '@ecp/utils/date';
import { useEvent } from '@ecp/utils/react';

import { GridItem, NumberFormat } from '@ecp/components';
import { Button, Form, Select } from '@ecp/features/sales/shared/components';
import {
  getPrimaryInsuredStateCode,
  setFormErrorsChangedByField,
  useForm,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, Incident, OptionProps } from '@ecp/features/sales/shared/types';
import type { Field } from '@ecp/types';

import {
  INCIDENT_TYPE_CLAIM_OR_ACCIDENT,
  INCIDENT_TYPE_VIOLATION,
  isFutureIncidentDate,
  useAttachIncidentToDriver,
  useGetIncidentFields,
  useIncident,
} from '../../../../state';
import { GetSavedIncidentDescription } from '../../../../state/modelUtil';
import { useStyles } from './IncidentQuestions.styles';
import metadata from './metadata';
import { hasIncidentClaimAmountQuestion } from './util';

interface Props {
  incidentCancel(): void;
  driverRef: string;
  currentIncidentRef: string;
  addOrEditIncident: string;
  removeCurrentIncident: (incident: Incident | undefined) => void;
}

export const IncidentQuestionsSection: React.FC<Props> = (props) => {
  const {
    driverRef,
    incidentCancel,
    currentIncidentRef,
    addOrEditIncident,
    removeCurrentIncident,
  } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const initValues = useRef({});

  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const currentIncident = useIncident(currentIncidentRef || '');

  const addIncidentToDriver = useAttachIncidentToDriver(driverRef || '');

  const accidentHelperText = metadata.accidentHelpText[stateCode];
  const incidentHelperText = metadata.helpText(stateCode);

  const incidentFields = useGetIncidentFields(currentIncidentRef || '');

  const {
    incident: {
      type: incidentType,
      date: incidentDate,
      year: incidentYear,
      month: incidentMonth,
      violationDescription,
      claimDescription,
      lossAmount,
      claimAmount,
    },
  } = incidentFields;
  if (
    incidentFields.incident.year?.value &&
    incidentFields.incident.month?.value &&
    !incidentFields.incident.date?.value
  ) {
    incidentDate.validateAndUpdate(
      `${formatMonth(incidentFields.incident.month.value as string)}/${
        incidentFields.incident.year.value
      }`,
    );
  }
  const incidentClaimAmountQuestion = hasIncidentClaimAmountQuestion(stateCode);
  const descriptionField =
    incidentType.value === INCIDENT_TYPE_VIOLATION ? violationDescription : claimDescription;
  const savedDescriptionField = GetSavedIncidentDescription(currentIncidentRef);

  const {
    validateForm: validateIncidentForm,
    patchFormValues: patchIncidentFormValues,
    isPatchFormInProgress: isIncidentFormPatchInProgress,
  } = useForm({
    fields: incidentFields.incident,
    initValues,
    conditions: [
      {
        conditionalFields: incidentClaimAmountQuestion ? [lossAmount] : [claimAmount],
        isExcluded: () => true,
      },
    ],
  });

  const handleIncidentTypeChange = useCallback(
    (newValue: AnswerValue): void => {
      if (newValue === INCIDENT_TYPE_VIOLATION) {
        claimDescription.validateAndUpdate('');
        lossAmount.validateAndUpdate('');
      } else if (newValue === INCIDENT_TYPE_CLAIM_OR_ACCIDENT) {
        violationDescription.validateAndUpdate('');
      }
      incidentType.validateAndUpdate(newValue);
    },
    [claimDescription, incidentType, lossAmount, violationDescription],
  );

  const handleIncidentDateChange = useCallback(
    (date: AnswerValue): void => {
      const { month, year } = getYearMonth(date as string);
      incidentMonth.validateAndUpdate(month);
      incidentYear.validateAndUpdate(year);
      incidentDate.validateAndUpdate(date);
    },
    [incidentDate, incidentMonth, incidentYear],
  );

  const handleClaimAmountChange = useCallback(
    (value: AnswerValue): void => {
      claimAmount.validateAndUpdate(value);
    },
    [claimAmount],
  );

  const handleLossAmountChange = useCallback(
    (value: AnswerValue): void => {
      lossAmount.validateAndUpdate(value);
    },
    [lossAmount],
  );

  const handleIncidentDescriptionChange = useCallback(
    (value: AnswerValue): void => {
      descriptionField.validateAndUpdate(value);
    },
    [descriptionField],
  );

  const onValidateIncidentDate = useCallback(
    (date: Field, month: Field, year: Field) => {
      const dateErrors = date.validate(date.value);

      if (dateErrors.length) {
        dispatch(setFormErrorsChangedByField({ key: date.key, errors: dateErrors }));

        return false;
      }

      const monthErrors = month.validate(month.value);

      if (monthErrors.length) {
        dispatch(setFormErrorsChangedByField({ key: date.key, errors: monthErrors }));

        return false;
      }

      if (
        year.value &&
        isFutureIncidentDate({
          month: month.value as number,
          year: year.value as number,
        })
      ) {
        dispatch(
          setFormErrorsChangedByField({
            key: date.key,
            errors: ['date is in the future.'],
          }),
        );

        return false;
      }

      const yearErrors = year.validate(year.value);

      if (yearErrors.length) {
        dispatch(setFormErrorsChangedByField({ key: date.key, errors: yearErrors }));

        return false;
      }

      return true;
    },
    [dispatch],
  );

  const incidentDateValue = useMemo((): string => {
    if (!incidentDate.value) {
      if (incidentMonth.value && incidentYear.value) {
        return `${formatMonth(incidentMonth.value as string)}/${incidentYear.value}`;
      }
      if (incidentMonth.value && !incidentYear.value) {
        return `${formatMonth(incidentMonth.value as string)}/`;
      }

      return '';
    }

    return incidentDate.value as string;
  }, [incidentDate, incidentMonth.value, incidentYear.value]);

  const handleAddIncident = useEvent(async () => {
    const incidentFormValid = validateIncidentForm().isValid;
    // add any errors in incidentMonth and incidentYearErrors to incidentDate.errors for display
    const incidentDateValid = onValidateIncidentDate(incidentDate, incidentMonth, incidentYear);
    if (incidentFormValid && incidentDateValid) {
      // patch incident only.
      await patchIncidentFormValues();
      addIncidentToDriver(currentIncidentRef);
      incidentDate.reset();
      incidentDate.patch('');
      incidentCancel();
    }
  });

  const handleCancelIncidentSection = useCallback(() => {
    if (addOrEditIncident === 'addIncident') {
      removeCurrentIncident(currentIncident);
    } else {
      descriptionField.validateAndUpdate(savedDescriptionField);
      violationDescription.validateAndUpdate(savedDescriptionField);
      claimDescription.validateAndUpdate(savedDescriptionField);
    }
    incidentCancel();
  }, [
    addOrEditIncident,
    incidentCancel,
    removeCurrentIncident,
    currentIncident,
    descriptionField,
    savedDescriptionField,
    violationDescription,
    claimDescription,
  ]);

  return (
    <Form>
      <Grid container item xs={12} className={classes.newIncident}>
        <GridItem xs={12}>
          <FormLabel component='legend' focused={false}>
            Tell us about your accidents, tickets or claims
            {incidentHelperText && (
              <FormHelperText error={false}>{incidentHelperText}</FormHelperText>
            )}
            {accidentHelperText && (
              <FormHelperText error={false}>{accidentHelperText}</FormHelperText>
            )}
          </FormLabel>
        </GridItem>
        <Grid container xs={12}>
          {incidentType.exists && (
            <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
              <Select
                {...(incidentType.props as OptionProps)}
                placeholder='Select one'
                id='IncidentType'
                inputButtonAriaLabel='Incident Type'
                label='Incident type'
                actionOnComplete={handleIncidentTypeChange}
                trackingName='incident_type_selection'
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
              />
            </GridItem>
          )}
          {incidentDate.exists && (
            <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
              <NumberFormat
                {...incidentDate.props}
                value={incidentDateValue}
                id='IncidentDate'
                format='##/####'
                ariaLabel='Incident Date'
                label='Incident date'
                placeholder='MM/YYYY'
                actionOnComplete={handleIncidentDateChange}
                trackingName='incident_date'
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
              />
            </GridItem>
          )}
          {descriptionField.exists && (
            <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
              <Select
                {...(descriptionField.props as OptionProps)}
                id='IncidentDescription'
                inputButtonAriaLabel='Incident Description'
                placeholder='Select one'
                label='Incident description'
                actionOnComplete={handleIncidentDescriptionChange}
                trackingName='incident_description_selection'
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
              />
            </GridItem>
          )}
          {(claimAmount.exists || lossAmount.exists) &&
            incidentType.value === INCIDENT_TYPE_CLAIM_OR_ACCIDENT && (
              <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
                {incidentClaimAmountQuestion ? (
                  <NumberFormat
                    {...claimAmount.props}
                    ariaLabel='Total claim amount'
                    prefix='$'
                    thousandSeparator
                    label={metadata.getIncidentAmountLabel}
                    actionOnComplete={handleClaimAmountChange}
                    trackingName='DamageAmount'
                    trackingLabel={GoogleAnalyticsLabels.REDACTED}
                  />
                ) : (
                  <Select
                    {...(lossAmount.props as OptionProps)}
                    id='IncidentDamageAmount'
                    inputButtonAriaLabel='Total Damage Amount'
                    placeholder='Select one'
                    label='Total damage amount'
                    actionOnComplete={handleLossAmountChange}
                    trackingName='DamageAmount'
                    trackingLabel={GoogleAnalyticsLabels.REDACTED}
                  />
                )}
              </GridItem>
            )}
        </Grid>
        <GridItem topSpacing='md' bottomSpacing='sm' xs={8} className={classes.buttonGrid}>
          <Button
            className={classes.saveButton}
            type='button'
            color='primary'
            variant='outlinePrimary'
            onClick={handleAddIncident}
            isProcessing={isIncidentFormPatchInProgress}
            trackingName='add_incident_button'
            trackingLabel='add_incident'
          >
            Save incident
          </Button>
          <Button
            className={classes.cancelButton}
            variant='iconTextMedium'
            trackingName='cancel_incident_button'
            trackingLabel='cancel_incident'
            onClick={handleCancelIncidentSection}
          >
            Cancel incident
          </Button>
        </GridItem>
      </Grid>
    </Form>
  );
};
