import { useAddFields } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

import metadata from './metadata';
import { useStyles } from './RecurringPaymentQuestion.styles';

export const RecurringPaymentQuestion: React.FC<QuestionProps> = (props) => {
  const {
    label = metadata.label,
    trackingName = 'auto_pay_selection',
    helperText = metadata.helperText,
  } = props;
  const { classes } = useStyles();
  const autoPay = useField('discount.auto.autoPay');
  useAddFields({ autoPay });

  if (!autoPay.exists) return null;

  return (
    <div className={classes.helperText}>
      <RadioGroupWithOptions
        {...autoPay.props}
        label={label}
        helperText={helperText}
        id='AutoPay'
        variant='yesNoButton'
        trackingName={trackingName}
      />
    </div>
  );
};
