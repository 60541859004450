import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'CollegeDegreeDiscountsQuestion' })(() => ({
  root: {
    '& .MuiFormControl-root': {
      marginBottom: 0,
      paddingBottom: 20,
    },
  },
}));
