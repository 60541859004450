import { useCallback, useEffect, useMemo } from 'react';

import { Grid } from '@mui/material';

import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { collegeDegreeOptions } from '@ecp/features/sales/quotes/auto';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import { useField } from '@ecp/features/sales/shared/store';

import type { QuestionProps } from '../../questionProps.types';
import { useStyles } from './CollegeDegreeDiscountsQuestion.styles';

interface Props extends QuestionProps {
  driverRef: string;
  isContinueClicked?: boolean;
}

export const CollegeDegreeDiscountsQuestion: React.FC<Props> = (props) => {
  const { driverRef, trackingName = 'DegreeCompleted', isContinueClicked } = props;
  const { classes } = useStyles();

  const collegeDegreeDiscount = useField(`${driverRef}.discount.collegeDegree`);
  const twoYearDegree = useField(`${driverRef}.discount.collegeDegree.twoYearDegree`);
  const fourYearDegree = useField(`${driverRef}.discount.collegeDegree.fourYearDegree`);
  const graduateDegree = useField(`${driverRef}.discount.collegeDegree.graduateDegree`);

  useEffect(() => {
    if (!collegeDegreeDiscount.props.value) {
      twoYearDegree.props.actionOnComplete(null);
      fourYearDegree.props.actionOnComplete(null);
      graduateDegree.props.actionOnComplete(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collegeDegreeDiscount.props.value]);

  const selectedCollegeDegreeValue = useMemo((): string | null => {
    const discountFields = [twoYearDegree, fourYearDegree, graduateDegree];
    const selectedValues = discountFields.filter((field) => {
      return field.value;
    });

    if (selectedValues.length === 0) return null;

    const selectedValue = selectedValues[0].key;

    return collegeDegreeOptions.filter((cardOption) => {
      return selectedValue.includes(cardOption.value);
    })[0].value;
  }, [fourYearDegree, graduateDegree, twoYearDegree]);

  const errorText = !selectedCollegeDegreeValue ? 'Select a degree' : undefined;

  const handleCollegeDiscountChange = useCallback(
    (value: string) => {
      const discountsFields = [twoYearDegree, fourYearDegree, graduateDegree];
      discountsFields.forEach((field) => {
        if (field.key.includes(value)) {
          field.props.actionOnComplete(true);
        } else {
          field.props.actionOnComplete(null);
        }
      });
    },
    [fourYearDegree, graduateDegree, twoYearDegree],
  );

  useAddFields({
    [`${twoYearDegree.key}`]: twoYearDegree,
    [`${fourYearDegree.key}`]: fourYearDegree,
    [`${graduateDegree.key}`]: graduateDegree,
    [`${collegeDegreeDiscount.key}`]: collegeDegreeDiscount,
  });
  useAddConditionValues({
    conditionalFields: [twoYearDegree],
    isExcluded: () => fourYearDegree.value === true || graduateDegree.value === true,
    isRequiredOverride: () => collegeDegreeDiscount.value === true,
  });

  if (!collegeDegreeDiscount.exists || !collegeDegreeDiscount.value) return null;

  return (
    <Grid item xs={12} md={6} key={driverRef} className={classes.root}>
      <RadioGroupWithOptions
        name={twoYearDegree.key}
        value={selectedCollegeDegreeValue}
        id={`${driverRef}-collegeDegreeOptions`}
        options={collegeDegreeOptions}
        actionOnComplete={handleCollegeDiscountChange}
        variant='classic'
        label='What is your highest level of education completed?'
        error={!isContinueClicked ? undefined : errorText}
        trackingName={trackingName}
      />
    </Grid>
  );
};
