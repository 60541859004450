import { env } from '@ecp/env';

import type { Metadata } from './metadata';

const helperText = env.static.isAgent
  ? 'You will be able to enroll after you purchase your policy.'
  : 'Sign up for paperless billing and receive a discount.';

const metadata: Metadata = {
  helperText,
  label: 'Paperless billing?',
};

export default metadata;
