import { NumberFormat } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

interface VehicleAnnualMileageQuestionProps extends QuestionProps {
  vehicleRef: string;
}
export const VehicleAnnualMileageQuestion: React.FC<VehicleAnnualMileageQuestionProps> = (
  props,
) => {
  const {
    label = 'Annual Miles Driven',
    trackingName = 'AnnualMiles',
    dataTestId = 'annualMiles',
    vehicleRef,
  } = props;

  const annualMiles = useField(`${vehicleRef}.annualMiles`);
  useAddFields({ annualMiles });
  useInitValues({ [annualMiles.key]: 12000 });

  if (!annualMiles.exists) return null;

  return (
    <NumberFormat
      {...annualMiles.props}
      id='AnnualMiles'
      fullWidth={false}
      label={label}
      thousandSeparator
      data-testid={dataTestId}
      trackingName={trackingName}
    />
  );
};
