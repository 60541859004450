import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PersonLivingForm' })((theme) => ({
  ...theme.mixins.formAll,
  helpBoxContent: {
    margin: 0,
    padding: 0,
  },
  subheader: theme.typography.body4,
  container: {
    marginTop: 40,
  },
}));
