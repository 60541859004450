import { satisfies } from '@ecp/features/sales/shared/misc';
import type { FieldCardOption } from '@ecp/features/sales/shared/types';
import { IconCardCollision, IconCardComprehensive } from '@ecp/themes/base';

export const IncidentTypeQuestionMetadata = satisfies({
  key: 'incidentType',
  title: 'Driver(s) Incident Type (Violation)',
  options: {
    VIOLATION: {
      value: 'INCIDENT_TYPE.VIOLATION',
      label: 'Violation',
    },
    CLAIM: {
      value: 'INCIDENT_TYPE.CLAIM_OR_ACCIDENT',
      label: 'Claim',
    },
  },
} as const);

export const PriorPolicyCoverageOptions: FieldCardOption[] = [
  {
    fieldName: 'comprehensive',
    label: 'Comprehensive',
    value: 'COVERAGE.COMPREHENSIVE',
    icon: <IconCardComprehensive />,
    helpText:
      'When your vehicle is damaged from something other than a collision, comprehensive coverage may help pay for your losses (minus the deductible)',
  },
  {
    fieldName: 'collision',
    label: 'Collision',
    value: 'COVERAGE.COLLISION',
    icon: <IconCardCollision />,
    helpText:
      'If your vehicle collides with another vehicle or object, collision coverage would help pay (minus your deductible) for damage to your vehicle',
  },
];
