import { agentAuth } from '@ecp/utils/auth';
import type { RouteProps } from '@ecp/utils/routing';
import { Redirect, Route } from '@ecp/utils/routing';

import { PagePath } from '@ecp/features/sales/shared/routing';

export const ProtectedRoute: React.FC<RouteProps> = (props) => {
  const { children, component, ...rest } = props;

  return (
    <Route {...rest} component={agentAuth.isAuth ? props.component : undefined}>
      {agentAuth.isAuth ? children : <Redirect to={PagePath.AGENT_LOGIN} />}
    </Route>
  );
};
