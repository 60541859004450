import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'RecurringPaymentQuestion' })(() => ({
  helperText: {
    '& .MuiFormHelperText-root': {
      fontStyle: 'normal',
      fontSize: 12,
      fontWeight: 400,
      marginTop: 3,
      lineHeight: '20px',
    },
  },
}));
