import { useCallback, useEffect, useState } from 'react';

import { Events, trackEvent } from '@ecp/utils/flags';
import { useBackAndForwardNav, useLocation } from '@ecp/utils/routing';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import { navigateToVehicle, useField } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';

import { VehicleForm, VehicleProfileForm } from '../../forms';
import { useAddVehicle, useRemoveUnusedRefsForVehicle, useVehicleRef } from '../../state';

export const VehiclePage: React.FC = () => {
  const dispatch = useDispatch();
  const {
    parameters: { id: vehicleId },
    search: { referencePage = '' },
  } = useLocation(PagePath.VEHICLE_PROFILE);
  const isAddMode = Boolean(referencePage?.includes('profileAdd'));
  const isEditMode = Boolean(referencePage?.includes('profileEdit'));
  const addVehicle = useAddVehicle();
  const currentVehicleRef = useVehicleRef(vehicleId) || '';
  const keptAtRiskAddress = useField(`${currentVehicleRef}.keptAtRiskAddress`);
  const fromPrefill = useField(`${currentVehicleRef}.fromPrefill`).value;
  const { value: vehicle = 'vehicle' } = useField(`${currentVehicleRef}.model`);

  const [maskRequiredFieldError, setMaskRequiredFieldError] = useState(true);

  useEffect(() => {
    if (!vehicleId && currentVehicleRef) {
      dispatch(navigateToVehicle({ ref: currentVehicleRef, replace: true }));
    }
  });

  // TODO: We might need a better way to handle this. A/B test metric tracking function for vehicle page view
  useEffect(() => {
    trackEvent(Events.VEHICLE_PAGE);
  }, []);

  const navigateToProfilePage = useNavigateToPage(PagePath.EDIT_AUTO_PROFILE, {
    replace: true,
    removeQuery: false,
    replaceProfileAdd: true,
  });
  // TODO Here we make an assumption that referencePage is always defined which is bad
  // TODO Look into replacing magic referencePage param with separate add/edit/view routes
  // TODO and utilize previous page value from the routing state or history API (goBack, replace, etc.)
  const navigateToReferencePage = useNavigateToPage(referencePage, { replace: true });
  const handleNext =
    referencePage?.includes('profile') || !referencePage
      ? navigateToProfilePage
      : navigateToReferencePage;

  const [vehicleToAddRef, setVehicleToAddRef] = useState('');
  const vehicleRef = vehicleToAddRef || currentVehicleRef;

  const handleAddVehicle = useCallback(async () => {
    const { vehicleRef: newVehicleRef } = addVehicle();
    setVehicleToAddRef(newVehicleRef);
    keptAtRiskAddress.reset();
    await dispatch(navigateToVehicle({ ref: newVehicleRef, replace: false }));
  }, [addVehicle, dispatch, keptAtRiskAddress]);

  useEffect(() => {
    if (isAddMode) {
      setVehicleToAddRef(currentVehicleRef);
    }
  }, [currentVehicleRef, isAddMode]);

  const removeUnusedVehicleRef = useRemoveUnusedRefsForVehicle();

  // On back navigation remove ref for vehicle and delete all answers related to the new ref.
  // Since for every new vehicle we initialize the page with new vehicle ref and patch it as answers.
  // We will lose all data for that particular vehicle (as per the requirements)
  useBackAndForwardNav(async () => {
    if (!isEditMode && vehicleToAddRef) await removeUnusedVehicleRef(vehicleRef);
  });

  const nextPageInstructions = referencePage?.includes('profile')
    ? `Thanks! Let’s return to your profile!`
    : 'Next we will gather a few more driver details';

  return (
    <div>
      <Page
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.EDIT_AUTO_PROFILE} />,
        }}
        title={
          fromPrefill || vehicle
            ? `Let's get the details on your ${vehicle}`
            : "First, let's get some vehicle details"
        }
        analyticsElement='choice.vehicleDetailsPage.page'
      >
        {referencePage?.includes('Short') ? (
          <div>
            <VehicleProfileForm
              vehicleRef={vehicleRef}
              maskRequiredFieldError={maskRequiredFieldError}
              setMaskRequiredFieldError={setMaskRequiredFieldError}
              onNext={handleNext}
              onAddVehicle={handleAddVehicle}
              nextPageInstructions={nextPageInstructions}
              isEdit={isEditMode}
            />
          </div>
        ) : (
          <VehicleForm
            vehicleRef={vehicleRef}
            needActions
            maskRequiredFieldError={maskRequiredFieldError}
            setMaskRequiredFieldError={setMaskRequiredFieldError}
            onNext={handleNext}
            onAddVehicle={handleAddVehicle}
            nextPageInstructions={nextPageInstructions}
            isEdit={isEditMode}
          />
        )}
      </Page>
    </div>
  );
};
