import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PersonForm' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    ...theme.mixins.form,
    marginTop: 40,
    [theme.breakpoints.down('md')]: {
      padding: '0 15px',
    },
  },
  next: {
    ...theme.mixins.formAll.next,
    marginTop: 0,
  },
  agreementContent: {
    ...theme.typography.body1,
    whiteSpace: 'pre-line',
    marginTop: 30,
  },
  link: {
    ...theme.mixins.linkButton,
    color: theme.palette.primary.main,
  },
  radioGroup: {
    marginTop: -28,
  },
}));
