import { useEffect, useState } from 'react';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { useEvent } from '@ecp/utils/react';

export const useReviewPeriodDialogProps = (): {
  handleClose: () => void;
  show: boolean;
} => {
  const [show, setShow] = useState(false);
  const enableCapiReviewPeriod = flagValues[FeatureFlags.CAPI_10_DAY_REVIEW];

  useEffect(() => {
    if (enableCapiReviewPeriod) {
      setShow(true);
    }
  }, [enableCapiReviewPeriod]);

  const handleClose = useEvent(() => {
    setShow(false);
  });

  return { handleClose, show };
};
