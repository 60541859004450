import type { Metadata } from './metadata';
import { AThroughK, LThroughZ } from './metadata.connect-opco';

const metadata: Metadata = {
  disclosureGroups: [
    {
      groupTitle: 'United States of America: States A through K',
      disclosures: <AThroughK />,
    },
    {
      groupTitle: 'United States of America: States L through Z',
      disclosures: <LThroughZ customerfirstEmail='customerservice@homesite.com' />,
    },
  ],
};

export default metadata;
