import { memo } from 'react';

import { GeneralPaymentDisclaimer } from './GeneralCheckoutDisclaimers';

interface Props {
  state: string;
  agreement?: React.ReactElement;
}

const RecurringPaymentMetadataForProperty: React.FC<React.PropsWithChildren<Props>> = memo(
  ({ state, children }) => {
    let fraudText = '';
    if (state === 'VA') {
      fraudText =
        'Your premium and eligibility are based on information provided. It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.';
    }

    return (
      <>
        {children}
        <GeneralPaymentDisclaimer fraudText={fraudText} />
      </>
    );
  },
);

export const RecurringPaymentMetadataForPropertyEFT: React.FC<Props> = ({
  state,
  agreement,
}): React.ReactElement => (
  <RecurringPaymentMetadataForProperty state={state}>
    {agreement}
  </RecurringPaymentMetadataForProperty>
);

export const getRecurringPaymentMetadataForPropertyCC = (
  name: string,
  state: string,
): React.ReactElement => <RecurringPaymentMetadataForProperty state={state} />;
