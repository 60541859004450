import { env } from '@ecp/env';
import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutoDeltaPage' })((theme) => ({
  root: {
    '& #content': {
      maxWidth: !env.static.isAgent ? 'initial' : undefined,
    },
    '& [role=main]': {
      [theme.breakpoints.only('md')]: {
        maxWidth: 'initial',
      },
    },
  },
}));
