import { useCallback, useEffect, useState } from 'react';

import { Events, trackEvent } from '@ecp/utils/flags';
import { useSearchParams } from '@ecp/utils/routing';

import { LogoSpinner } from '@ecp/components';
import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { PrefillFlow } from '@ecp/features/sales/shared/constants';
import { PagePath, useNavigateToNextPage } from '@ecp/features/sales/shared/routing';
import {
  getCurrentFlows,
  getDrivers,
  getOperatorsInDrivers,
  questionExists,
  submitProofDraft,
  updateAnswers,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

import { useCheckHasGen10Vehicle, useSetupDriverForm, useVehiclesForSidebar } from '../../state';
import { AutoProfileForm } from './AutoProfileForm';
import { useStyles } from './AutoProfilePage.styles';

export const AutoProfilePage: React.FC = () => {
  const { classes } = useStyles();
  const drivers = useSelector(getDrivers);

  const operators = useSelector(getOperatorsInDrivers);
  const vehicles = useVehiclesForSidebar();
  const { referencePage } = useSearchParams();
  const dispatch = useDispatch();
  const navigateToNextPage = useNavigateToNextPage();

  const [nextPageInstructions, setNextPageInstructions] = useState(
    referencePage === 'quotes'
      ? 'Once we have the right vehicles and drivers shown, you can move on to quotes.'
      : 'Once we have the right vehicles and drivers shown, you can move on to discounts.',
  );

  const autoAssign = useCallback(async () => {
    await dispatch(
      updateAnswers({
        answers: {
          [`${vehicles[0].ref}.primary.driver.ref`]: operators[0].ref,
          [`${vehicles[0].ref}.secondary.driver.ref`]: null,
        },
      }),
    );
    // remove seconday driver if it exists as its no longer needed for 1 driver 1 vehicle scenario.
  }, [dispatch, operators, vehicles]);

  const driverAssignmentQuestionExists = useSelector((state: RootStore) =>
    questionExists('vehicle.<id>.primary.driver.ref')(state),
  );

  // TODO: We might need a better way to handle this. A/B test metric tracking function for auto summary page view
  useEffect(() => {
    trackEvent(Events.AUTO_SUMMARY_PAGE);
  }, []);

  useEffect(() => {
    if (!(operators.length === 1 && vehicles.length === 1)) {
      if (driverAssignmentQuestionExists) {
        setNextPageInstructions(
          'Once we have the right vehicles and drivers, you can move on to driver assignment.',
        );
      }
    } else {
      setNextPageInstructions(
        referencePage === 'quotes'
          ? 'Once we have the right vehicles and drivers shown, you can move on to quotes.'
          : 'Once we have the right vehicles and drivers shown, you can move on to discounts.',
      );
    }
  }, [dispatch, operators.length, vehicles.length, driverAssignmentQuestionExists, referencePage]);

  const flow = useSelector(getCurrentFlows);
  const isFlowShort = flow.auto === PrefillFlow.SHORT;
  const needVehicleOwnershipDate = useCheckHasGen10Vehicle() && isFlowShort;

  useEffect(() => {
    if (needVehicleOwnershipDate) {
      setNextPageInstructions(
        'Once we have the right vehicles and drivers, you can move on to vehicle ownership date.',
      );
    } else if (
      !(operators.length === 1 && vehicles.length === 1) &&
      driverAssignmentQuestionExists
    ) {
      setNextPageInstructions(
        'Once we have the right vehicles and drivers, you can move on to driver assignment.',
      );
    } else {
      setNextPageInstructions(
        referencePage === 'quotes'
          ? 'Once we have the right vehicles and drivers shown, you can move on to quotes.'
          : 'Once we have the right vehicles and drivers shown, you can move on to discounts.',
      );
    }
  }, [
    dispatch,
    operators.length,
    vehicles.length,
    driverAssignmentQuestionExists,
    needVehicleOwnershipDate,
    referencePage,
  ]);

  const navigateToNext = useCallback(async () => {
    if (driverAssignmentQuestionExists && operators.length === 1 && vehicles.length === 1) {
      await autoAssign();
    }
    await dispatch(submitProofDraft({ policyTypes: ['auto'] }));
    await navigateToNextPage();
  }, [
    operators,
    driverAssignmentQuestionExists,
    vehicles.length,
    navigateToNextPage,
    autoAssign,
    dispatch,
  ]);

  useSetupDriverForm();

  const title = <>Your vehicles and drivers</>;

  return (
    <div className={classes.root}>
      <Page
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.EDIT_AUTO_PROFILE} />,
        }}
        title={title}
        analyticsElement='choice.reviewVehicleAndDriverPage.page'
      >
        <div>
          <p className={classes.subTitle}>
            Enter all the drivers and vehicles you want on this policy
          </p>
        </div>
        {drivers.length > 0 ? (
          <AutoProfileForm
            drivers={drivers}
            vehicles={vehicles}
            onNext={navigateToNext}
            nextPageInstructions={nextPageInstructions}
          />
        ) : (
          <LogoSpinner />
        )}
      </Page>
    </div>
  );
};
