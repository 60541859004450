import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Grid } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { ensureStringArray, isMasked, upperFirst } from '@ecp/utils/common';
import { useSearchParams } from '@ecp/utils/routing';

import { Alert, GridItem, TooltipWithIcon } from '@ecp/components';
import {
  Button,
  CheckboxGroup,
  DriverIcon,
  Form,
  Link,
  NextPageInstructions,
} from '@ecp/features/sales/shared/components';
import { NavStatus, PrefillFlow } from '@ecp/features/sales/shared/constants';
import { PagePath } from '@ecp/features/sales/shared/routing';
import {
  deleteAnswers,
  fetchInquiry,
  getCurrentFlows,
  getDriverRefs,
  getOperatorsInDrivers,
  getPrimaryInsuredPersonRef,
  getVehicleRefs,
  isAnyApiInProgress,
  navigateToDriver,
  navigateToVehicle,
  updatePageStatus,
  updatePartialPageStatus,
  useField,
  useForm,
  usePniRef,
  useSniRef,
  validateQuestion,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Driver, VehicleBasic } from '@ecp/features/sales/shared/types';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import { goToFirstError, useLoading } from '@ecp/features/sales/shared/utils/web';
import {
  IconUICheckCircleSolid as CompleteIcon,
  IconCardAuto,
  IconUIExclaimTriangle as IncompleteIcon,
} from '@ecp/themes/base';
import type { CardOption, Field, Fields } from '@ecp/types';

import { DRIVER_INTL, DRIVER_UNLICENSED, DRIVER_VALID } from '../../constants';
import {
  getInvalidDlRefs,
  useAddDriver,
  useAddVehicle,
  useGetDriverFieldsForValidations,
  useGetVehicleFieldsForValidation,
  useGetVehicleItemsWithImage,
  useRemoveUnusedRefsForVehicle,
  useUpdateVehiclesAndDrivers,
} from '../../state';
import {
  checkDuplicateVIN,
  useDeepRemoveDriver,
  useUpdateSecondaryPolicyHolderRef,
  useVehiclesFields,
} from '../../state/modelUtil';
import { useStyles } from './AutoProfileForm.styles';
import metadata from './metadata';
import { SpecialCircumstancesModal } from './SpecialCircumstancesModal';

export interface AutoProfileFields extends Fields {
  vehicleRefs: Field;
  driverRefs: Field;
}

const useGetFields = (): AutoProfileFields => {
  return {
    vehicleRefs: useField('static.vehicleRefs'),
    driverRefs: useField('static.driverRefs'),
  };
};

export interface AutoProfileFormProps {
  vehicles: VehicleBasic[];
  drivers: Driver[];
  onNext: () => Promise<void>;
  nextPageInstructions: string;
}

export interface VehicleCardOptions extends CardOption {
  vin?: string;
  year?: string;
}

export const AutoProfileForm: React.FC<AutoProfileFormProps> = (props) => {
  const { vehicles, drivers, onNext, nextPageInstructions } = props;
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const fields = useGetFields();
  const { vehicleRefs: vehicleRefsField, driverRefs: driverRefsField } = fields;
  const primaryInsuredPersonRef = useSelector(getPrimaryInsuredPersonRef);
  const addVehicle = useAddVehicle();
  const vehicleRefs = useSelector(getVehicleRefs);
  const getVehicles = useVehiclesFields(vehicleRefs);
  const addDriver = useAddDriver();
  const driverRefs = useSelector(getDriverRefs);
  const operators = useSelector(getOperatorsInDrivers);
  const pniRef = usePniRef();
  const sniRef = useSniRef();
  const { referencePage } = useSearchParams();
  const fromQuotes = Boolean(referencePage?.includes('quotes'));

  const [showSpecialModal, setShowSpecialModal] = useState(false);
  const deepRemoveDriver = useDeepRemoveDriver();
  const removeUnusedVehicleRef = useRemoveUnusedRefsForVehicle();

  const flow = useSelector(getCurrentFlows);
  const isFlowShortAuto = flow.auto === PrefillFlow.SHORT;

  // TODO: Implement these in check validation
  const flowAuto: PrefillFlow = flow.auto || PrefillFlow.LONG;
  const driverQuestions = useGetDriverFieldsForValidations(drivers, flowAuto);
  const vehicleQuestions = useGetVehicleFieldsForValidation(vehicles, flowAuto);

  const [incompleteDrivers, setIncompleteDrivers] = useState(false);
  const [incompleteVehicles, setIncompleteVehicles] = useState(false);

  const [fetchingInquiry, setFetchingInquiry] = useState(true);

  const [isProcessing, setIsProcessing] = useState(false);

  const initValues = useRef({});
  useForm({ initValues, fields });
  const { isAutoProfileApiInProgress, updateVehiclesAndDrivers } = useUpdateVehiclesAndDrivers();

  const invalidDlRefs = useSelector(getInvalidDlRefs);

  const openModal = useCallback(() => {
    setShowSpecialModal(true);
    trackClick({ action: 'SpecialCircumstancesLink', label: 'SpecialCircumstances' });
  }, []);

  const closeModal = useCallback(() => {
    setShowSpecialModal(false);
  }, []);

  // Make sure not to run any action while previous one is still in flight, like multiple clicks on delete
  const shouldWait = useSelector(isAnyApiInProgress);
  const { isLoading, withLoading } = useLoading(shouldWait);

  const getUncheckedItems = (allItems: string[], checkItems: string[]): string[] => {
    if (checkItems.length > 0) {
      return allItems.reduce<string[]>((preItems, item) => {
        if (!checkItems.includes(item)) {
          return [...preItems, item];
        }

        return preItems;
      }, []);
    }

    return [...allItems];
  };

  const [uncheckedVehicles, setUncheckedVehicles] = useState<string[]>([]);
  const vehiclesValues = useMemo((): string[] => {
    return vehicleRefs.reduce<string[]>((preValues, ref) => {
      if (!uncheckedVehicles.includes(ref)) {
        return [...preValues, ref];
      }

      return preValues;
    }, []);
  }, [vehicleRefs, uncheckedVehicles]);

  const vehicleItemList = useGetVehicleItemsWithImage();

  // TODO: eliminate this logic and move somewhere else
  if (!isFlowShortAuto) {
    vehicleRefs.forEach((ref) => {
      const vehicleQuestionClone = JSON.parse(JSON.stringify(vehicleQuestions[ref]));
      vehicleQuestionClone.primaryUse.question.required = true;
      vehicleQuestionClone.keptAtRiskAddress.question.required = true;

      vehicleQuestions[ref] = vehicleQuestionClone;
    });
  }

  driverRefs.forEach((ref) => {
    // This undefined check for `driverQuestions[ref]` is needed
    // to avoid any issues while back navigation from driver page.
    if (driverQuestions[ref]) {
      const driverQuestionClone = JSON.parse(JSON.stringify(driverQuestions[ref]));

      if (!isFlowShortAuto) {
        const carrierName = driverQuestionClone['carrierName']
          ? driverQuestionClone['carrierName'].value
          : '';
        const hasPriorInsurance = driverQuestionClone['hasPriorInsurance'];
        // make prior insurance fields as optional if hasPriorInsurance field exists
        // and is selected as false
        if (hasPriorInsurance.exists && !hasPriorInsurance.value) {
          driverQuestionClone['carrierName'].question.required = false;
          driverQuestionClone['years'].question.required = false;
          driverQuestionClone['policyEndDate'].question.required = false;
          driverQuestionClone['coverages.policy.bodilyInjury'].question.required = false;
        }
        if (metadata.ignoreLicenseStatus) {
          driverQuestionClone['license.ageFirstLicensed'].question.required = false;
        }
        if (
          driverQuestionClone['person.ref'] &&
          (driverQuestionClone['person.ref'].value === pniRef ||
            driverQuestionClone['person.ref'].value === sniRef)
        ) {
          if (carrierName && carrierName.toString() !== 'CARRIER.NONE') {
            driverQuestionClone['coverages.policy.bodilyInjury'].question.required = true;
          } else if (carrierName && carrierName.toString() === 'CARRIER.NONE') {
            driverQuestionClone['lapse'].question.required = false;
          }
        } else {
          driverQuestionClone['lapse'].question.required = false;
        }
      } else if (driverQuestionClone['license.status'].value === DRIVER_UNLICENSED) {
        driverQuestionClone['license.ageFirstLicensed'].question.required = false;
        driverQuestionClone['license.number'].question.required = false;
        driverQuestionClone['license.state'].question.required = false;
      } else if (driverQuestionClone['license.status'].value === DRIVER_INTL) {
        driverQuestionClone['license.state'].question.required = false;
      }

      driverQuestions[ref] = driverQuestionClone;
    }
  });

  const vehiclesOptions: VehicleCardOptions[] = useMemo(() => {
    setIncompleteVehicles(false);
    const vehicles = getVehicles;

    return vehicleItemList.map((v) => {
      const { vehicle } = v;
      const { ref = '', description } = vehicle;

      let completeIcon = <CompleteIcon />;
      let completeValue = true;

      const hasDuplicateVin = checkDuplicateVIN(vehicles, vehicle.vin, ref);
      if (hasDuplicateVin) {
        setIncompleteVehicles(true);
        completeIcon = <IncompleteIcon />;
        completeValue = false;
      }
      const filteredQuestions = Object.keys(vehicleQuestions)
        .filter((key) => key.includes(ref) && !key.includes('ref'))
        .reduce((obj, key) => {
          obj[key] = vehicleQuestions[key];

          return obj;
        }, {} as typeof vehicleQuestions);

      Object.keys(filteredQuestions[ref] || {}).forEach((filtered) => {
        const { question, value, key } = filteredQuestions[ref][filtered] as Field;
        const isValid = dispatch(validateQuestion({ question, value, questionKey: key }));
        if (!isValid) {
          setIncompleteVehicles(true);
          completeIcon = <IncompleteIcon />;
          completeValue = false;
        }
      });
      // This fixes upon refresh or backnav for short flow, if the vin is empty, we keep the vehicle box incomplete
      if (isFlowShortAuto && !vehicle.vin) {
        setIncompleteVehicles(true);
        completeIcon = <IncompleteIcon />;
        completeValue = false;
      }
      // We need to stop a user from continuing forward if the vin is masked but isnt equal to 17 chars
      if (isMasked(vehicle.vin) && vehicle.vin?.length !== 17) {
        setIncompleteVehicles(true);
        completeIcon = <IncompleteIcon />;
        completeValue = false;
      }

      return {
        value: ref,
        label: description,
        image: (
          <div className={cx(classes.cardImageContainer, classes.cardImageContainerVehicle)}>
            <IconCardAuto className={classes.cardImage} />
          </div>
        ),
        completeIcon,
        completeValue,
        vin: vehicle.vin,
        year: vehicle.year,
      };
    });
    // we don't want to re-render for vehicleQuestions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleItemList, classes, dispatch, isFlowShortAuto]);

  const handleVehiclesChange = useCallback(
    (checkedRefs: string[]) => {
      const uncheckedRefs = getUncheckedItems(vehicleRefs, checkedRefs);
      setUncheckedVehicles(uncheckedRefs);
      vehicleRefsField.validateAndUpdate(checkedRefs.join(','));
    },
    [vehicleRefs, vehicleRefsField],
  );

  let refPageAdd: 'profileAddShortQuotes' | 'profileAddShort' | 'profileAddQuotes' | 'profileAdd';
  let refPageEdit:
    | 'profileEditShortQuotes'
    | 'profileEditShort'
    | 'profileEditQuotes'
    | 'profileEdit';

  if (isFlowShortAuto) {
    refPageAdd = fromQuotes ? 'profileAddShortQuotes' : 'profileAddShort';
    refPageEdit = fromQuotes ? 'profileEditShortQuotes' : 'profileEditShort';
  } else {
    refPageAdd = fromQuotes ? 'profileAddQuotes' : 'profileAdd';
    refPageEdit = fromQuotes ? 'profileEditQuotes' : 'profileEdit';
  }

  const handleAddVehicle = withLoading(async () => {
    trackSapiAnalyticsEvent({
      element: 'choice.reviewVehicleAndDriverPage.AddAnotherVehicleButton',
      event: 'click',
      eventDetail: 'true',
    });

    // This avoids showing previous vehicle info in dialog
    const newVehicle = addVehicle();
    const { vehicleRef } = newVehicle;
    await newVehicle.done;

    trackClick({ action: 'add_vehicle_link', label: vehicleRef });
    await dispatch(
      navigateToVehicle({
        ref: vehicleRef,
        replace: false,
        referencePage: refPageAdd,
      }),
    );
  });

  const handleEditVehicle = withLoading<string>(async (vehicleRef) => {
    trackClick({ action: 'edit_vehicle_link', label: vehicleRef });

    trackSapiAnalyticsEvent({
      element: 'choice.reviewVehicleAndDriverPage.EditVehicleButton',
      event: 'click',
      eventDetail: vehicleRef,
    });

    await dispatch(
      navigateToVehicle({
        ref: vehicleRef,
        replace: false,
        referencePage: refPageEdit,
      }),
    );
  });

  const handleDeleteVehicle = withLoading<string>(async (vehicleRef) => {
    trackClick({ action: 'delete_vehicle_button', label: vehicleRef });

    trackSapiAnalyticsEvent({
      element: 'choice.reviewVehicleAndDriverPage.DeleteVehicleButton',
      event: 'click',
      eventDetail: vehicleRef,
    });

    await removeUnusedVehicleRef(vehicleRef);
  });

  const [uncheckedDrivers, setUncheckedDrivers] = useState<string[]>([]);
  const driversValues = useMemo((): string[] => {
    return driverRefs.reduce<string[]>((preValues, ref) => {
      if (!uncheckedDrivers.includes(ref)) {
        return [...preValues, ref];
      }

      return preValues;
    }, []);
  }, [driverRefs, uncheckedDrivers]);

  const [unevenMarried, setUnevenMarried] = useState(false);
  const [unevenDPCU, setUnevenDPCU] = useState(false);
  const [hasValidDriver, setHasValidDriver] = useState(false);

  const driversOptions: CardOption[] = useMemo(() => {
    let numMarriedTotal = 0;
    let numDPCUTotal = 0;

    setIncompleteDrivers(false);

    return drivers.map((driver, index) => {
      const {
        ref = '',
        firstName,
        lastName,
        middleName,
        suffix,
        maritalStatus,
        personRef,
        driversLicense,
      } = driver;

      const maritalStatusString = maritalStatus
        ? upperFirst(maritalStatus.substring(maritalStatus.indexOf('.') + 1).toLowerCase()).replace(
            /_/g,
            ' ',
          )
        : '';
      // Avoid showing undefined when adding a driver
      const label =
        firstName && lastName
          ? `${firstName} ${middleName?.slice(0, 1)?.toUpperCase() || ''} ${lastName} ${
              upperFirst(suffix) || ''
            }`
          : '';

      const subLabel = maritalStatusString ? `(${maritalStatusString})` : '';

      if (maritalStatus === 'MARITAL_STATUS.MARRIED') {
        numMarriedTotal += 1;
        if (numMarriedTotal === 1 && metadata.allowOneMarriedDriver && drivers.length === 1) {
          setUnevenMarried(false);
        } else if (numMarriedTotal % 2 !== 0) {
          setUnevenMarried(true);
        } else {
          setUnevenMarried(false);
        }
      }

      if (
        maritalStatus === 'MARITAL_STATUS.DOMESTIC_PARTNER_CIVIL_UNION' ||
        maritalStatus === 'MARITAL_STATUS.DOMESTIC_PARTNER'
      ) {
        numDPCUTotal += 1;
        if (numDPCUTotal % 2 !== 0) {
          setUnevenDPCU(true);
        } else {
          setUnevenDPCU(false);
        }
      }

      if (numMarriedTotal === 0) {
        setUnevenMarried(false);
      }

      if (numDPCUTotal === 0) {
        setUnevenDPCU(false);
      }

      if (driversLicense.status === DRIVER_VALID) {
        setHasValidDriver(true);
      }

      const image = (
        <DriverIcon
          classes={{ root: classes.cardImageContainer, icon: classes.cardImage }}
          index={index}
        />
      );

      const disabled = driver.personRef === primaryInsuredPersonRef;
      let completeIcon = <CompleteIcon />;
      let completeValue = true;

      const filteredQuestions = Object.keys(driverQuestions || {}).reduce((obj, key) => {
        if ((key.includes(ref) || key.includes(personRef)) && !key.includes('ref')) {
          obj[key] = driverQuestions[key];
        }

        return obj;
      }, {} as typeof driverQuestions);

      const filteredQuestion = filteredQuestions[ref] as Fields;

      Object.keys(filteredQuestion).forEach((filtered) => {
        const { question, value, key } = filteredQuestion[filtered] as Field;
        // add dl number validation result here as part of the field completion validator
        // to ensure that incomplete driver will be located properly
        let isDlValid = true;
        if (question.description === 'License number') {
          isDlValid = !invalidDlRefs.includes(ref);
        }
        const isValid =
          key.includes('.lapse') && metadata.ignoreLapseQuestion
            ? true
            : dispatch(validateQuestion({ question, value, questionKey: key }));
        if (!isValid || !isDlValid) {
          setIncompleteDrivers(true);
          completeIcon = <IncompleteIcon />;
          completeValue = false;
        }
      });

      return { value: ref, label, subLabel, image, disabled, completeIcon, completeValue };
    });
    // we don't want to re-render for driverQuestions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drivers, classes, primaryInsuredPersonRef, dispatch, isFlowShortAuto, invalidDlRefs]);

  const handleDriversChange = useCallback(
    (checkedRefs: string[]) => {
      const uncheckedRefs = getUncheckedItems(driverRefs, checkedRefs);
      setUncheckedDrivers(uncheckedRefs);
      driverRefsField.validateAndUpdate(checkedRefs.join(','));
    },
    [driverRefs, driverRefsField],
  );

  const updateSNI = useUpdateSecondaryPolicyHolderRef();

  const handleAddDriver = withLoading(async () => {
    trackSapiAnalyticsEvent({
      element: 'choice.reviewVehicleAndDriverPage.AddAnotherDriverButton',
      event: 'click',
      eventDetail: 'true',
    });

    // This avoids showing previous driver info in dialog
    const newDriver = addDriver();
    const { driverRef } = newDriver;
    await newDriver.done;

    if (metadata.defaultNewDriverSNI) {
      // ADVECP-1339: amfam-adv defaults to not auto-selecting new drivers to SNI
      await updateSNI(driverRef);
    }

    trackClick({ action: 'add_driver_link', label: driverRef });

    await dispatch(
      navigateToDriver({
        ref: driverRef,
        replace: false,
        referencePage: refPageAdd,
      }),
    );
  });

  const handleEditDriver = withLoading<string>(async (driverRef) => {
    trackClick({ action: 'edit_driver_link', label: driverRef });

    trackSapiAnalyticsEvent({
      element: 'choice.reviewVehicleAndDriverPage.EditDriverButton',
      event: 'click',
      eventDetail: driverRef,
    });

    await dispatch(
      navigateToDriver({
        ref: driverRef,
        replace: false,
        referencePage: refPageEdit,
      }),
    );
  });

  const handleDeleteDriver = withLoading<string>(async (driverRef) => {
    trackClick({ action: 'delete_driver_button', label: driverRef });

    trackSapiAnalyticsEvent({
      element: 'choice.reviewVehicleAndDriverPage.DeleteDriverButton',
      event: 'click',
      eventDetail: driverRef,
    });

    await deepRemoveDriver(driverRef);
  });

  const saveVehiclesAndDrivers = useCallback(async () => {
    setIsProcessing(true);
    if (vehiclesValues.length > 0 && driversValues.length > 0) {
      // We need to remove any prior insurance prefill data if driver is not primary or secondary
      const keysToDelete = driverRefs.reduce((acc, ref) => {
        const driverQuestionsRef = driverQuestions[ref] as Fields;
        const priorInsuranceRef = driverQuestionsRef[`${ref}.priorInsurance.ref`]
          ? ensureStringArray(driverQuestionsRef[`${ref}.priorInsurance.ref`]?.value)[0]
          : '';
        const carrierName = driverQuestionsRef['carrierName']
          ? driverQuestionsRef['carrierName'].value
          : '';

        if (
          driverQuestionsRef['person.ref'] &&
          driverQuestionsRef['person.ref'].value !== pniRef &&
          driverQuestionsRef['person.ref'].value !== sniRef &&
          carrierName === 'CARRIER.PREFILL'
        ) {
          const keys = [
            `${priorInsuranceRef}.carrierName`,
            `${priorInsuranceRef}.AMBestNumber`,
            `${priorInsuranceRef}.carrierNameText`,
            `${priorInsuranceRef}.coverages.policy.bodilyInjury`,
            `${priorInsuranceRef}.coverages.vehicle.collision`,
            `${priorInsuranceRef}.coverages.vehicle.comprehensive`,
            `${priorInsuranceRef}.inceptionDate`,
            `${priorInsuranceRef}.lapse`,
            `${priorInsuranceRef}.policyEndDate`,
            `${priorInsuranceRef}.riskType`,
            `${priorInsuranceRef}.years`,
            `${priorInsuranceRef}.naicNumber`,
          ];
          acc.push(...keys);
        }

        return acc;
      }, [] as string[]);

      if (keysToDelete.length > 0) await dispatch(deleteAnswers({ keys: keysToDelete }));

      await updateVehiclesAndDrivers(
        vehiclesValues,
        driversValues,
        uncheckedVehicles,
        uncheckedDrivers,
      );

      dispatch(updatePageStatus(NavStatus.VALID));
      // Update subpaths to valid since profile page can only be valid if these pages are valid. Just a safety net.
      dispatch(updatePartialPageStatus(NavStatus.VALID, PagePath.ADD_VEHICLE_PROFILE));
      dispatch(updatePartialPageStatus(NavStatus.VALID, PagePath.ADD_DRIVER_PROFILE));

      await onNext();
    } else {
      dispatch(updatePageStatus(NavStatus.INVALID));

      if (vehiclesValues.length === 0) {
        vehicleRefsField.validateAndUpdate('');
      }
      if (driversValues.length === 0) {
        driverRefsField.validateAndUpdate('');
      }
      goToFirstError();
    }
    setIsProcessing(false);
    // we don't want to re-render for driverQuestions
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    vehiclesValues,
    driversValues,
    updateVehiclesAndDrivers,
    uncheckedVehicles,
    uncheckedDrivers,
    onNext,
    vehicleRefsField,
    driverRefsField,
    driverRefs,
    pniRef,
    sniRef,
  ]);

  const showError = unevenMarried || unevenDPCU;
  const subTextContent = showError ? metadata.subTextContentError : metadata.subTextContent;

  const SubContent = (): React.ReactElement => {
    const tooltipText = metadata.subTextContent;

    return (
      <>
        <p className={showError ? classes.errorSubTextWrapper : classes.subTextWrapper}>
          {showError && <strong>Edit marital status: </strong>}
          {subTextContent}
          {metadata.showSpecialCircumstancesLink && (
            <span>
              {' '}
              Call us for{' '}
              <Link component='button' onClick={openModal}>
                special circumstances
              </Link>
              .
            </span>
          )}
          {showError && <TooltipWithIcon title={tooltipText} />}
        </p>
        {!hasValidDriver && !incompleteDrivers && (
          <p className={showError ? classes.driverWarning : classes.maritalWarning}>
            <strong>Please review your driver information. </strong>
            <span>At least one driver on the quote must have a valid driver's license.</span>
          </p>
        )}
      </>
    );
  };

  useEffect(() => {
    dispatch(fetchInquiry({})).then(() => {
      setFetchingInquiry(false);
    });
  }, [dispatch]);

  return (
    <>
      <div className={classes.root}>
        <Form>
          {(incompleteDrivers || incompleteVehicles) && (
            <Alert className={classes.alert} type='warning'>
              <p>
                <IncompleteIcon className={classes.inlineImage} />
                Complete your driver and/or vehicle details below.
              </p>
            </Alert>
          )}
          {vehicles.length > 0 && operators.length === 0 && (
            <Alert className={classes.alert} type='error'>
              <p>
                <IncompleteIcon className={classes.inlineImage} />
                At least one person must have the Role of Operator.
              </p>
            </Alert>
          )}
          <Grid container spacing={1} className={classes.container}>
            <GridItem topSpacing='lg' xs={12} className={classes.withAddButton}>
              <CheckboxGroup
                addMoreProps={{
                  showDefaultAddMore: true,
                  buttonTitle: 'Add a vehicle',
                  dataTestId: 'addVehicle',
                  handleClick: handleAddVehicle,
                  cardSize: 'large',
                  image: (
                    <div className={cx(classes.cardImageContainer, classes.cardImageContainerAdd)}>
                      <IconCardAuto className={cx(classes.cardImage, classes.cardImageAdd)} />
                    </div>
                  ),
                }}
                {...vehicleRefsField.props}
                cardSize='large'
                options={vehiclesOptions}
                label='Vehicles'
                values={vehiclesValues}
                actionOnComplete={handleVehiclesChange}
                actionOnEdit={handleEditVehicle}
                actionOnDelete={handleDeleteVehicle}
                variant='editCard'
                customVariant='h2'
                trackingName='SelectVehicles'
              />
            </GridItem>
            <GridItem topSpacing='lg' xs={12} className={classes.withAddButton}>
              <CheckboxGroup
                addMoreProps={{
                  showDefaultAddMore: true,
                  buttonTitle: 'Add a driver',
                  dataTestId: 'addDriver',
                  handleClick: handleAddDriver,
                  cardSize: 'large',
                  image: (
                    <DriverIcon
                      classes={{
                        root: cx(classes.cardImageContainer, classes.cardImageContainerAdd),
                        icon: cx(classes.cardImage, classes.cardImageAdd),
                      }}
                    />
                  ),
                }}
                {...driverRefsField.props}
                cardSize='large'
                options={driversOptions}
                label='Drivers'
                message={<SubContent />}
                messageClassName={unevenMarried || unevenDPCU ? classes.maritalWarning : undefined}
                values={driversValues}
                actionOnComplete={handleDriversChange}
                actionOnEdit={handleEditDriver}
                actionOnDelete={handleDeleteDriver}
                variant='editCard'
                customVariant='h2'
                trackingName='SelectDrivers'
              />
            </GridItem>
            <GridItem topSpacing='lg' xs={12} className={classes.nextPageInstructions}>
              {!fetchingInquiry && (
                <NextPageInstructions divider>{nextPageInstructions}</NextPageInstructions>
              )}
            </GridItem>
            <Grid item xs={12}>
              <Button
                className={classes.next}
                data-testid='saveAndContinue'
                variant='primary'
                onClick={saveVehiclesAndDrivers}
                isProcessing={isProcessing}
                disabled={
                  isProcessing ||
                  isAutoProfileApiInProgress ||
                  vehiclesOptions.length === 0 ||
                  driversOptions.length === 0 ||
                  operators.length === 0 ||
                  unevenMarried ||
                  unevenDPCU ||
                  incompleteDrivers ||
                  incompleteVehicles ||
                  isLoading ||
                  fetchingInquiry ||
                  !hasValidDriver
                }
                analyticsElement='choice.reviewVehicleAndDriverPage.saveAndContinueButton'
                trackingLabel='auto_profile_continue'
                trackingName='continue'
              >
                Save & continue
              </Button>
            </Grid>
          </Grid>
        </Form>
      </div>
      <SpecialCircumstancesModal open={showSpecialModal} onClose={closeModal} />
    </>
  );
};
