import { uuid } from '@ecp/utils/common';

export const fetchAgentTokens = (agentAuthBaseUrl: string, expId: string) => {
  return (username: string, password: string): Promise<Response> => {
    const userCredentials = window.btoa(`${username}:${password}`);
    const requestId = uuid();

    return fetch(`${agentAuthBaseUrl}/authorize`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${userCredentials}`,
        'x-trace-id': requestId,
      },
      body: JSON.stringify({
        experienceId: expId,
      }),
      credentials: 'include',
      mode: 'cors',
      method: 'POST',
    });
  };
};

export const fetchAgentTokensFromRefreshToken = (agentAuthBaseUrl: string) => {
  return (): Promise<Response> => {
    return fetch(`${agentAuthBaseUrl}/refresh-token`, {
      credentials: 'include',
      mode: 'cors',
      method: 'POST',
    });
  };
};
