import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutoProfilePage' })((theme) => ({
  root: {},
  subTitle: {
    ...theme.typography.subtitle,
    marginTop: 5,
    marginBottom: 0,
    padding: '0 30px',
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      lineHeight: '24px',
      padding: '0 15px',
    },
  },
}));
