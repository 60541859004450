import { Grid } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';

import { env } from '@ecp/env';
import { Button } from '@ecp/features/sales/shared/components';
import { isLineOfBusinessBundle } from '@ecp/features/shared/product';

import { useStyles as useStylesBase } from './QuotesTabs.styles';
import { useStyles as useStylesAgent } from './QuotesTabs.styles.agent';
import { useQuotesSelection } from './useQuotesSelection';
import { useSavedPremium } from './useSavedPremium';

const useStyles = env.static.isAgent ? useStylesAgent : useStylesBase;

export const QuotesTabs: React.FC = () => {
  const { classes, cx } = useStyles();
  const { handleChangeLineOfBusinessUserSelection, lineOfBusinessUserSelection, tabOptions } =
    useQuotesSelection();
  const savedPremium = useSavedPremium();

  if (tabOptions.length < 2) return null;

  // TODO Look into switching to MUI tabs: https://mui.com/material-ui/react-tabs/
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <table className={classes.tabs}>
          <tbody className={classes.tabsTable}>
            <tr>
              {tabOptions.map((option) => {
                const isSelected = lineOfBusinessUserSelection === option.value;

                return (
                  <td key={option.value}>
                    <Button
                      className={cx(classes.tab, isSelected && classes.tabSelected)}
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() => handleChangeLineOfBusinessUserSelection(option.value)}
                      variant='iconText'
                    >
                      <Grid container justifyContent='center'>
                        <Grid item>
                          <p className={isSelected ? classes.tabTitleSelected : classes.tabTitle}>
                            {option.label}
                          </p>
                        </Grid>
                        {env.static.isAgent &&
                          isLineOfBusinessBundle(option.value) &&
                          savedPremium && (
                            <Grid item>
                              <p className={classes.tabSubTitleDiscount}>
                                Save up to <strong>{parseDollar(savedPremium)}</strong>
                              </p>
                            </Grid>
                          )}
                      </Grid>
                    </Button>
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
