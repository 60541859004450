import { PaymentPlan, SELECTED_PAYMENT_PLAN } from '@ecp/features/sales/shared/constants';

import {
  questionsOverrides as questionsOverridesBase,
  STATIC_QUESTIONS as STATIC_QUESTIONS_BASE,
} from './structure.connect-opco.js';

export * from './structure.connect-opco.js';

export const STATIC_QUESTIONS: typeof STATIC_QUESTIONS_BASE = {
  ...STATIC_QUESTIONS_BASE,
  [SELECTED_PAYMENT_PLAN]: {
    ...STATIC_QUESTIONS_BASE[SELECTED_PAYMENT_PLAN],
    defaultValue: PaymentPlan.MONTHLY_PREMIUM,
  },
};

export const questionsOverrides: typeof questionsOverridesBase = {
  ...questionsOverridesBase,
  'membership.costco.number': {
    required: 'true',
    criteria: [
      {
        error: 'Please review your membership number',
        $regex: '^\\d{12}$',
      },
    ],
  },
};
