import type { Questions } from '@ecp/types';

import { STATIC_QUESTIONS as STATIC_QUESTIONS_BASE } from './structure.js';

export * from './structure.js';

/** Includes either agent or consumer questions, depending on the experience. */
export const STATIC_QUESTIONS: Questions = {
  ...STATIC_QUESTIONS_BASE,
  'static.advisorName': {
    description: 'Advisor Name',
    value: '',
    answerType: 'String',
    businessType: ['auto', 'home', 'renters'],
    required: 'false',
  },
};
