import { useCallback, useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { isMasked } from '@ecp/utils/common';

import type { TextFieldProps } from '@ecp/components';
import { TextField, TooltipWithIcon } from '@ecp/components';
import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { useField } from '@ecp/features/sales/shared/store';

import type { QuestionProps } from '../../questionProps.types';
import { useStyles } from './GMEmployeeQuestion.styles';

interface Props extends QuestionProps {
  driverRef: string;
}

export const GmEmployeeQuestion: React.FC<Props> = (props) => {
  const { classes } = useStyles();
  const { driverRef } = props;
  const gmEmployeeDiscount = useField(`${driverRef}.discount.gmEmployee`);
  const gmEmployeeId = useField(`${driverRef}.discount.gmEmployee.id`);
  const [isGMIN9Digits, setIsGMIN9Digits] = useState(true);

  useAddFields({ [`${gmEmployeeId.key}`]: gmEmployeeId });

  useAddConditionValues({
    conditionalFields: [gmEmployeeId],
    isRequiredOverride: () => gmEmployeeDiscount.value === true,
  });

  useEffect(() => {
    // unchecking gm employee checkbox should clear employee id field
    if (gmEmployeeDiscount.exists && !gmEmployeeDiscount.value && gmEmployeeId.value) {
      gmEmployeeId.props.actionOnComplete(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gmEmployeeDiscount.exists, gmEmployeeDiscount.value, gmEmployeeId.value]);

  const handleGMINChange: NonNullable<TextFieldProps['actionOnChange']> = useCallback(
    (value) => {
      // clear the field when user type over a masked value
      if (isMasked(gmEmployeeId.value)) {
        gmEmployeeId.props.actionOnChange(null);
        // asterisk is not allowed to be typed/pasted in by user here
      } else if (!isMasked(value)) {
        gmEmployeeId.props.actionOnChange(value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [gmEmployeeId.value],
  );

  const handleGMINComplete: NonNullable<TextFieldProps['actionOnComplete']> = useCallback(
    (value: string) => {
      // We only need to check if GMIN is 9 digits when blurring out
      if (value.toString().length !== 9) setIsGMIN9Digits(false);
      // only patch the value if it's 9 digits and no masked value (in case patching masked value to sapi)
      // or when the field is cleared
      if ((value.toString().length === 9 && !isMasked(value)) || !value) {
        gmEmployeeId.props.actionOnComplete(value);
        setIsGMIN9Digits(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (!gmEmployeeId.exists || !gmEmployeeDiscount.value) return null;

  const gmEmployeeIdError =
    gmEmployeeId.props.error || (!isGMIN9Digits ? 'Invalid GM employee id' : null);

  return (
    <Grid item xs={12} md={6} key={driverRef} className={classes.questionContainer}>
      <TextField
        {...gmEmployeeId.props}
        error={gmEmployeeIdError}
        actionOnChange={handleGMINChange}
        actionOnComplete={handleGMINComplete}
        id={`GMIN-${driverRef}`}
        label='GMIN number'
        groupLabel={
          <>
            What is your GMIN?
            <TooltipWithIcon title='Enter the GMIN from your employee badge.' />
          </>
        }
        maxLength={9}
        trackingName='GMIN'
        trackingLabel={GoogleAnalyticsLabels.REDACTED}
      />
    </Grid>
  );
};
