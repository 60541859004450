import type { FieldCardOption } from '@ecp/features/sales/shared/types';
import { IconCardAntiTheft, IconCardFrontAirBag, IconCardKeptInGarage } from '@ecp/themes/base';

import { AntiTheftHelpText } from '../../components/Question/Vehicle/VehicleAntiTheftQuestion/AntiTheftHelpText';

export const antiTheftDiscountsOptions: FieldCardOption[] = [
  {
    fieldName: 'alarm',
    value: 'features.antitheft.alarm',
    label: 'Alarm',
    helpText:
      'An alarm produces a sound that can be heard at least 300 feet away for a minimum of 3 minutes.',
  },
  {
    fieldName: 'active',
    value: 'features.antitheft.active',
    label: 'Active',
    helpText: 'An active anti-theft device is one that the driver has to manually activate.',
  },
  {
    fieldName: 'passive',
    value: 'features.antitheft.passive',
    label: 'Passive',
    helpText:
      'A passive anti-theft device is automatically activated when the vehicle key is removed and all doors are locked.',
  },
  {
    fieldName: 'vehicleRecoverySystem',
    value: 'features.antitheft.vehicleRecoverySystem',
    label: 'Vehicle recovery system',
    helpText:
      'A vehicle recovery system, when activated, provides information regarding the location of the vehicle.',
  },
  {
    fieldName: 'windowEtching',
    value: 'features.antitheft.windowEtching',
    label: 'Window etching',
    helpText:
      'A window etching is a permanent engraving of the vehicle’s VIN number onto the windshield and windows of the vehicle.',
  },
  {
    fieldName: 'category1',
    value: 'features.antitheft.category1',
    label: 'Category I',
  },
  {
    fieldName: 'category2',
    value: 'features.antitheft.category2',
    label: 'Category II',
  },
  {
    fieldName: 'category3',
    value: 'features.antitheft.category3',
    label: 'Category III',
  },
  {
    fieldName: 'category4',
    value: 'features.antitheft.category4',
    label: 'Category IV',
  },
];

export const safetyDiscountOptions: FieldCardOption[] = [
  {
    fieldName: 'dayTimeRunningLights',
    value: 'features.safety.dayTimeRunningLights',
    label: 'Daytime running lights',
    helpText:
      'Lights on the front of a motor vehicle, automatically switched on when the vehicle is in drive.',
  },
  {
    fieldName: 'antiLockBrakes',
    value: 'features.safety.antiLockBrakes',
    label: 'Anti-lock brakes',
    helpText:
      'ABS operates by preventing the wheels from locking up during braking, thereby maintaining traction with the road surface.',
  },
  {
    fieldName: 'electronicStabilityControl',
    value: 'features.safety.electronicStabilityControl',
    label: 'Electronic stability controls',
    helpText:
      "A computerized technology that improves a vehicle's stability by detecting and reducing loss of traction.",
  },
  {
    fieldName: 'driver',
    value: 'features.safety.airbag.driver',
    label: 'Driver airbag',
    helpText:
      'Airbags designed to inflate to prevent a driver’s head and chest from contacting hard structures in the vehicle.',
  },
  {
    fieldName: 'passenger',
    value: 'features.safety.airbag.passenger',
    label: 'Passenger airbags',
    helpText:
      'Airbags designed to inflate to prevent a passenger’s head and chest from contacting hard structures in the vehicle.',
  },
  {
    fieldName: 'side',
    value: 'features.safety.airbag.side',
    label: 'Side airbags',
    helpText:
      'Side-impact airbags are usually located in the seat or door panel, and inflate between the seat occupant and the door.',
  },
];

export const vehicleDiscountsOptions: FieldCardOption[] = [
  {
    fieldName: 'keptInGarage',
    value: 'discount.keptInGarage',
    label: 'Kept in garage',
    icon: <IconCardKeptInGarage />,
    helpText: 'Is vehicle regularly parked in a garage when not at work?',
  },
  {
    fieldName: 'antitheft',
    value: 'features.antitheft',
    label: 'Anti-theft devices',
    icon: <IconCardAntiTheft />,
    helpText: 'Examples of anti-theft devices are alarms or fuel cutoffs.',
    stateOptions: {
      NJ: {
        helpText: <AntiTheftHelpText />,
      },
      RI: {
        helpText: <AntiTheftHelpText />,
      },
      KY: {
        helpText: <AntiTheftHelpText />,
      },
    },
  },
  {
    fieldName: 'safety',
    value: 'features.safety',
    label: 'Safety features',
    icon: <IconCardFrontAirBag />,
    helpText:
      'You may qualify for the safety features discount if your vehicle(s) have airbags, daytime running lights or anti-lock brakes.',
  },
];
