import { useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

interface Props extends QuestionProps {
  vehicleRef: string;
}

export const VehicleComprehensiveQuestion: React.FC<Props> = (props) => {
  const {
    label = 'Do you want comprehensive coverage only to this vehicle?',
    trackingName = 'VehicleComprehensiveButton',
    vehicleRef,
  } = props;

  const vehicleComprehensive = useField(`${vehicleRef}.compOnlyInd`);
  useInitValues({ [vehicleComprehensive.key]: false });

  if (!vehicleComprehensive.exists) return null;

  return (
    <div>
      <RadioGroupWithOptions
        {...vehicleComprehensive.props}
        label={label}
        variant='yesNoButton'
        trackingName={trackingName}
      />
    </div>
  );
};
