import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'QuotesTabs' })((theme) => ({
  root: {},
  container: {},
  tabs: {
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    margin: '0 auto',
    maxWidth: 462,
    position: 'absolute',
    right: 0,
    top: 90,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      maxWidth: 345,
      top: 58,
    },
    '& td': {
      width: 160,
      height: 40,
      [theme.breakpoints.down('md')]: {
        width: 120,
      },
    },
  },
  tabsTable: {
    alignItems: 'center',
    borderSpacing: '5px 0',
  },
  tab: {
    backgroundColor: theme.palette.actions.disabledBg,
    border: `1px solid ${theme.palette.other.border}`,
    borderBottom: 0,
    borderRadius: '4px 4px 0 0',
    color: theme.palette.text.primary,
    lineHeight: '40px',
    marginBottom: -2,
    padding: 10,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      padding: 8,
    },
    '& span': {
      display: 'block',
      lineHeight: '20px',
      [theme.breakpoints.down('md')]: {
        lineHeight: '16px',
        margin: '0 auto',
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.grey[50],
      textDecoration: 'none',
    },
  },
  tabSelected: {
    '&.MuiButton-root': {
      backgroundColor: theme.palette.grey[50],
    },
  },
  tabTitle: theme.typography.body2,
  tabTitleSelected: theme.typography.body2Bold,
  tabSubTitleDiscount: {},
}));
