import { useCallback, useMemo, useState } from 'react';

import { Card, Divider, Grid, Typography } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { formatDate } from '@ecp/utils/date';

import { Alert, GridItem, LogoSpinner } from '@ecp/components';
import { Button, Form, QuotesPolicyStartDateDialog } from '@ecp/features/sales/shared/components';
import { PrefillFlow } from '@ecp/features/sales/shared/constants';
import { useNavigateToPage } from '@ecp/features/sales/shared/routing';
import type { AutoQuoteDetails } from '@ecp/features/sales/shared/store';
import {
  getCurrentFlows,
  getHasAnyQuoteExpired,
  getOffersForSelectedLob,
  getPolicyStartDates,
} from '@ecp/features/sales/shared/store';
import type { OfferInfo } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Driver } from '@ecp/features/sales/shared/types';
import type {
  AutoProduct,
  HomeProduct,
  Product,
  ProductName,
  RentersProduct,
} from '@ecp/features/shared/product';
import { LineOfBusiness } from '@ecp/features/shared/product';
import { IconUICalendar, IconUIExclaimTriangle } from '@ecp/themes/base';

import { useStyles } from './AgentDashboardForm.styles';
import { AgentDashboardTable } from './AgentDashboardTable';
import { AgentDashboardUnavailableTable } from './AgentDashboardUnavailableTable';
import { AutoSummaryCard } from './AutoSummaryCard';
import { PersonSummaryCard } from './PersonSummaryCard';

type Props = {
  drivers: Driver[];
  isLoadingOffers: boolean;
};

const handleDisabledFlags = (
  offersForSelectedLob: Partial<Record<Product, OfferInfo>>,
  key: Product,
): boolean | undefined => {
  if (offersForSelectedLob) {
    if (offersForSelectedLob[key]) return offersForSelectedLob[key]?.isPurchased;
  }

  return false;
};

const determineOfferLocked = (
  offersForSelectedLob: Partial<Record<Product, OfferInfo>>,
  key: Product,
): boolean | undefined => {
  if (offersForSelectedLob) {
    if (offersForSelectedLob[key]) return offersForSelectedLob[key]?.isLocked;
  }

  return false;
};
export const AgentDashboardForm: React.FC<Props> = (props) => {
  const { drivers, isLoadingOffers } = props;
  const { classes } = useStyles();

  const offersForSelectedLob = useSelector(getOffersForSelectedLob);

  const [dialogOpenAuto, setDialogOpenAuto] = useState(false);
  const [dialogOpenHome, setDialogOpenHome] = useState(false);
  const [dialogOpenRenters, setDialogOpenRenters] = useState(false);

  const recalledOfferProducts: ProductName[] = [];
  offersForSelectedLob?.auto && recalledOfferProducts.push('auto');
  offersForSelectedLob?.home && recalledOfferProducts.push('home');
  offersForSelectedLob?.renters && recalledOfferProducts.push('renters');
  const expiredQuotes = useSelector(getHasAnyQuoteExpired(recalledOfferProducts));
  const policyStartDates = useSelector(getPolicyStartDates);
  const flow = useSelector(getCurrentFlows);
  const isEditButtonHidden = flow.auto !== PrefillFlow.SHORT;
  const autoProductKey: Product = offersForSelectedLob?.auto
    ? Object.keys(offersForSelectedLob?.auto)[0]
    : ('' as AutoProduct);
  const homeProductKey: Product = offersForSelectedLob?.home
    ? Object.keys(offersForSelectedLob?.home)[0]
    : ('' as HomeProduct);
  const rentersProductKey: Product = offersForSelectedLob?.renters
    ? Object.keys(offersForSelectedLob?.renters)[0]
    : ('' as RentersProduct);

  const autoQuoteDetails: AutoQuoteDetails = useMemo(() => ({ drivers }), [drivers]);
  const isOfferLocked =
    offersForSelectedLob?.auto && determineOfferLocked(offersForSelectedLob?.auto, autoProductKey);

  const navigateToAutoProfilePage = useNavigateToPage('/quote/auto/profile');

  const autoSummaryEditClick = useCallback(async () => {
    trackClick({ action: 'AutoQuoteDetailsEditLink', label: 'AutoQuoteDetailsEdit' });
    await navigateToAutoProfilePage();
  }, [navigateToAutoProfilePage]);

  const handleChangeStartDateToggleAuto = useCallback(() => {
    setDialogOpenAuto(!dialogOpenAuto);
  }, [setDialogOpenAuto, dialogOpenAuto]);

  const handleChangeStartDateToggleHome = useCallback(() => {
    setDialogOpenHome(!dialogOpenHome);
  }, [setDialogOpenHome, dialogOpenHome]);

  const handleChangeStartDateToggleRenters = useCallback(() => {
    setDialogOpenRenters(!dialogOpenRenters);
  }, [setDialogOpenRenters, dialogOpenRenters]);

  const displayPolicyStartDate = (): React.ReactElement | null => {
    const policyStartDateInfo = (
      <>
        {offersForSelectedLob?.auto && (
          <div className={classes.policyStartDateChangeBody} data-testid='autoPolicyStartDateText'>
            Auto policy will start on{' '}
            <Button
              className={classes.changeDateLink}
              variant='iconText'
              onClick={handleChangeStartDateToggleAuto}
              data-testid='changeAutoStartDateLink'
              disabled={handleDisabledFlags(offersForSelectedLob?.auto, autoProductKey)}
            >
              {formatDate(policyStartDates.auto)}
            </Button>
            .
          </div>
        )}{' '}
        {offersForSelectedLob?.home && (
          <div className={classes.policyStartDateChangeBody} data-testid='homePolicyStartDateText'>
            Home policy will start on{' '}
            <Button
              className={classes.changeDateLink}
              variant='iconText'
              onClick={handleChangeStartDateToggleHome}
              data-testid='changeHomeStartDateLink'
              disabled={handleDisabledFlags(offersForSelectedLob?.home, homeProductKey)}
            >
              {formatDate(policyStartDates.property)}
            </Button>
            .
          </div>
        )}{' '}
        {offersForSelectedLob?.renters && (
          <div
            className={classes.policyStartDateChangeBody}
            data-testid='rentersPolicyStartDateText'
          >
            Renters policy will start on{' '}
            <Button
              className={classes.changeDateLink}
              variant='iconText'
              onClick={handleChangeStartDateToggleRenters}
              data-testid='changeRentersStartDateLink'
              disabled={handleDisabledFlags(offersForSelectedLob?.renters, rentersProductKey)}
            >
              {formatDate(policyStartDates.property)}
            </Button>
            .
          </div>
        )}
      </>
    );

    return (
      <div>
        <QuotesPolicyStartDateDialog
          open={dialogOpenAuto}
          selectedLob={LineOfBusiness.AUTO}
          toggleDialog={handleChangeStartDateToggleAuto}
        />
        <QuotesPolicyStartDateDialog
          open={dialogOpenHome}
          selectedLob={LineOfBusiness.HOME}
          toggleDialog={handleChangeStartDateToggleHome}
        />
        <QuotesPolicyStartDateDialog
          open={dialogOpenRenters}
          selectedLob={LineOfBusiness.RENTERS}
          toggleDialog={handleChangeStartDateToggleRenters}
        />
        {policyStartDateInfo}
      </div>
    );
  };

  if (isLoadingOffers) return <LogoSpinner />;

  return (
    <div className={classes.root}>
      <Form>
        <Grid container>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <PersonSummaryCard />
            </Grid>
            {drivers.length > 0 && (
              <Grid item xs={12} lg={6}>
                <AutoSummaryCard
                  {...autoQuoteDetails}
                  onEditClick={autoSummaryEditClick}
                  isEditButtonHidden={isEditButtonHidden}
                />
              </Grid>
            )}
          </Grid>
          {offersForSelectedLob && (
            <>
              <GridItem topSpacing='lg' xs={12}>
                <h2> Available quotes</h2>
              </GridItem>

              {isOfferLocked && (
                <Alert
                  withIcon
                  icon={<IconUIExclaimTriangle className={classes.alertIcon} />}
                  type='error'
                  className={classes.errorAlert}
                >
                  The customer's quote is being reviewed. The auto quote can not be retrieved for 14
                  days from the original quote date.
                </Alert>
              )}

              {expiredQuotes && (
                <GridItem topSpacing='sm' xs={12}>
                  <Alert withIcon type='warning'>
                    <p className={classes.expiredQuotesWarning}>
                      New policy start date. Premium may have changed.
                    </p>
                  </Alert>
                </GridItem>
              )}
              <GridItem topSpacing='sm' xs={12}>
                <Card className={classes.policyStartDateCard}>
                  <Grid container>
                    <span className={classes.calendarIcon}>
                      <IconUICalendar />
                    </span>
                    <Typography variant='body1Bold'> Start date</Typography>
                    <Divider
                      className={classes.policyStartDateCardDivider}
                      orientation='vertical'
                      flexItem
                    />
                    {displayPolicyStartDate()}
                  </Grid>
                </Card>
              </GridItem>
              <GridItem topSpacing='sm' xs={12}>
                <AgentDashboardTable
                  offers={offersForSelectedLob}
                  hasExpiredQuotes={expiredQuotes}
                />
              </GridItem>
            </>
          )}
          <AgentDashboardUnavailableTable />
        </Grid>
      </Form>
    </div>
  );
};
