import { TooltipWithIcon } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { PhoneLink, RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { getPrimaryInsuredStateCode, useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { OptionProps } from '@ecp/features/sales/shared/types';
import { partner } from '@ecp/partners';

interface Props extends QuestionProps {
  personRef: string;
}

export const GenderQuestion: React.FC<Props> = (props) => {
  const { personRef, label, trackingName = 'gender', trackingLabel } = props;
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const gender = usePersonField('gender');
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  useAddFields({ gender });

  const genderTooltipText = (
    <>
      {'To receive a quote without providing your gender, please call '}
      <PhoneLink number={partner.shared.salesPhoneNumber} />.
    </>
  );

  if (!gender.exists) return null;

  return (
    <RadioGroupWithOptions
      {...(gender.props as OptionProps)}
      label={
        <>
          {label}
          {stateCode === 'MI' && <TooltipWithIcon title={genderTooltipText} />}
        </>
      }
      trackingName={trackingName}
      trackingLabel={trackingLabel}
    />
  );
};
