import type { ProductName } from '@ecp/features/shared/product';

export type NachaProductName = ProductName | 'autoMidvale';

export type Language = 'English' | 'Spanish';

export type ContentByProductType = Record<NachaProductName, Record<Language, Content>>;

interface Content {
  insuranceType: string;
  multipleProductInsuranceType: string;
  underwriterOnStatement: string;
  underwritingGroupFull: string;
  underwritingGroupShort: string;
}

export type DataType =
  | 'insuranceType'
  | 'multipleProductInsuranceType'
  | 'underwriterOnStatement'
  | 'underwritingGroupFull'
  | 'underwritingGroupShort';

const metadata: ContentByProductType = {
  auto: {
    English: {
      insuranceType: '"ConnectByAmfam Prem"',
      multipleProductInsuranceType: '"ConnectByAmfam Prem" for your auto policy',
      underwriterOnStatement: 'CONNECT, for your Auto policy',
      underwritingGroupFull: 'CONNECT, powered by American Family Insurance, and its subsidiaries',
      underwritingGroupShort: 'CONNECT',
    },
    Spanish: {
      insuranceType: '"ConnectByAmfam Prem"',
      multipleProductInsuranceType: '"ConnectByAmfam Prem" para su póliza de automóvilo',
      underwriterOnStatement: 'CONNECT, para su póliza de automóvilo',
      underwritingGroupFull: 'CONNECT, powered by American Family Insurance, y sus subsidiarias',
      underwritingGroupShort: 'CONNECT',
    },
  },
  autoMidvale: {
    English: {
      insuranceType: '"Auto insurance"',
      multipleProductInsuranceType: '"Auto insurance" for your auto policy',
      underwriterOnStatement: 'Midvale Indemnity Company, for your Auto policy',
      underwritingGroupFull: 'Midvale Indemnity Company, and its subsidiaries',
      underwritingGroupShort: 'Midvale',
    },
    Spanish: {
      insuranceType: '"Seguro de automóvil"',
      multipleProductInsuranceType: '"Seguro de automóvil" para su póliza de automóvi',
      underwriterOnStatement: 'Midvale Indemnity Company, para su póliza de automóvilo',
      underwritingGroupFull: 'Midvale Indemnity Company, y sus subsidiarias',
      underwritingGroupShort: 'Midvale',
    },
  },
  home: {
    English: {
      insuranceType: '"Homeowners insurance"',
      multipleProductInsuranceType: '"Homeowners insurance" for your home policy',
      underwriterOnStatement: 'Homesite Group, for your Home policy',
      underwritingGroupFull: 'Homesite Group Incorporated, and its subsidiaries',
      underwritingGroupShort: 'Homesite Group',
    },
    Spanish: {
      insuranceType: '"Seguro de hogar"',
      multipleProductInsuranceType: '"Seguro de hogar" para su póliza de hogar',
      underwriterOnStatement: 'Homesite Group, para su póliza de hogar',
      underwritingGroupFull: 'Homesite Group Incorporated, y sus subsidiarias',
      underwritingGroupShort: 'Homesite Group',
    },
  },
  renters: {
    English: {
      insuranceType: '"HOMESITE IN PREM"',
      multipleProductInsuranceType: '"HOMESITE IN PREM" for your home policy',
      underwriterOnStatement: 'Homesite Group, for your Home policy',
      underwritingGroupFull: 'Homesite Group Incorporated, and its subsidiaries',
      underwritingGroupShort: 'Homesite Group',
    },
    Spanish: {
      insuranceType: '"HOMESITE IN PREM"',
      multipleProductInsuranceType: '"HOMESITE IN PREM" para su póliza de hogar',
      underwriterOnStatement: 'Homesite Group, para su póliza de hogar',
      underwritingGroupFull: 'Homesite Group Incorporated, y sus subsidiarias',
      underwritingGroupShort: 'Homesite Group',
    },
  },
};

export default metadata;
