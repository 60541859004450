import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'VehicleKeptAtQuestion' })((theme) => ({
  differentStateError: {
    border: `2px solid ${theme.palette.error.main}`,
    background: theme.palette.error.light,
    borderRadius: 3,
    padding: 12,
    marginTop: 15,
  },
}));
