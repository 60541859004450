import { useCallback } from 'react';

import { NumberFormat } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import {
  AGENT_NOT_LICENSED_FOR_STATE_MSG,
  PARTNER_NOT_ELIGIBLE_MSG,
} from '@ecp/features/sales/shared/constants';
import {
  checkPolicyType,
  getFullPrimaryInsuredAddressInfo,
  getPrimaryInsuredAddressLock,
  setFormErrorsResetByField,
  updateAnswers,
  useGetAddressFields,
  useGetProductFields,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Answers, AnswerValue } from '@ecp/features/sales/shared/types';

import { useStyles } from './ZipCodeQuestion.styles';

export interface ZipCodeQuestionProps {
  label?: string;
  groupLabel?: string;
  trackingName?: string;
  validateZipCodeAndGetStateCity: (value: AnswerValue) => Promise<Answers | null>;
  disabled?: boolean;
}

export const ZipCodeQuestion: React.FC<ZipCodeQuestionProps> = (props) => {
  const {
    label = 'ZIP code',
    trackingName = 'ZipCodeEntry',
    validateZipCodeAndGetStateCity,
    groupLabel,
    disabled,
  } = props;
  const { zipcode: existingZipCode } = useSelector(getFullPrimaryInsuredAddressInfo);
  const primaryInsuredAddressLock = useSelector(getPrimaryInsuredAddressLock);
  const { zipCode } = useGetProductFields();
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const addressFields = useGetAddressFields();

  const line1 = addressFields.address.line1;
  const line2 = addressFields.address.line2;
  useAddFields({ zipCode });

  const handleZipCodeComplete = useCallback(
    async (value: string) => {
      dispatch(setFormErrorsResetByField({ key: zipCode.key }));
      if (existingZipCode !== value) {
        line1.props.actionOnComplete('');
        line2.props.actionOnComplete('');
      }
      const errors = zipCode.validateAndUpdate(value);
      if (!errors.length) {
        const stateCity = await validateZipCodeAndGetStateCity(value);
        await dispatch(
          updateAnswers({
            answers: {
              ...stateCity,
              [zipCode.key]: value,
            },
          }),
        );
        await dispatch(checkPolicyType());
      }
    },
    [dispatch, existingZipCode, line1.props, line2.props, validateZipCodeAndGetStateCity, zipCode],
  );

  const zipAlertError =
    zipCode.errors[0] === AGENT_NOT_LICENSED_FOR_STATE_MSG ||
    zipCode.errors[0] === PARTNER_NOT_ELIGIBLE_MSG;

  if (!zipCode.exists && !disabled) return null;

  return (
    <NumberFormat
      {...zipCode.props}
      actionOnComplete={handleZipCodeComplete}
      className={zipAlertError ? classes.root : ''}
      formatType='zipcode'
      groupLabel={groupLabel}
      data-testid='zipCode'
      id='zipCode'
      name='zipCode'
      trackingName={trackingName}
      label={label}
      disabled={primaryInsuredAddressLock || disabled}
      ariaLabel='Zip code'
      autoComplete='postal-code'
    />
  );
};
