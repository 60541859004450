import { PagePath } from '@ecp/features/sales/shared/routing';

type Link = {
  url: string;
  text: string;
  action: string;
  label: string;
};

export interface Metadata {
  copyrightText: string;
  links: Link[];
}

export const metadata: Metadata = {
  copyrightText: 'Homesite Group, Inc. All Rights Reserved.',
  links: [
    {
      url: PagePath.PRIVACY_POLICY,
      text: 'Privacy policy',
      action: 'BodyTextPrivacyPolicyLink',
      label: 'PrivacyPolicy',
    },
    {
      url: PagePath.PARTICIPATING_INSURERS,
      text: 'Participating insurers',
      action: 'ParticipatingInsurersLink',
      label: 'ParticipatingInsurers',
    },
  ],
};

export default metadata;
