export interface Metadata {
  priorInsurancePrefix: string;
  validateGaragedState: boolean;
}

export const metadata: Metadata = {
  priorInsurancePrefix: 'insurance.prior',
  validateGaragedState: false,
};

export default metadata;
