import { describeOfferSummary, getOffersForSelectedLob } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { useQuotesSelection } from './useQuotesSelection';

export const useSavedPremium = (): number | undefined => {
  const offersForSelectedLob = useSelector(getOffersForSelectedLob);
  const { lineOfBusinessUserSelection } = useQuotesSelection();
  const { savedPremium } = describeOfferSummary(offersForSelectedLob, lineOfBusinessUserSelection);

  return savedPremium;
};
