import type { Metadata } from './metadata.js';
import baseMetadata from './metadata.js';

const metadata: Metadata = {
  ...baseMetadata,
  showEmailAndPhoneQuestions: false,
  showReviewSection: false,
};

export default metadata;
