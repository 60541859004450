import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutoDeltaTopBanner' })((theme) => ({
  root: {
    color: theme.palette.other.body,
    margin: '0 auto',
    [theme.breakpoints.only('xl')]: {
      maxWidth: 1084,
      padding: 0,
    },
    [theme.breakpoints.only('lg')]: {
      maxWidth: theme.breakpoints.values.lg - 60,
    },
    [theme.breakpoints.only('md')]: {
      maxWidth: theme.breakpoints.values.md - 60,
    },
    [theme.breakpoints.only('sm')]: {
      maxWidth: theme.breakpoints.values.md,
    },
  },
  heading: {
    color: theme.palette.secondary.contrastText,
    marginBottom: 10,
  },
  headingAgent: {
    marginBottom: 5,
    [theme.breakpoints.down('md')]: {
      marginBottom: 10,
    },
  },
}));
