import { useCallback } from 'react';

import { Select } from '@ecp/features/sales/shared/components';
import { PARTNER } from '@ecp/features/sales/shared/constants';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';
import type { AnswerValue, Option } from '@ecp/types';

interface Props extends QuestionProps {
  onPartnerSelect?: (value: AnswerValue) => void;
  selectedPartner: string;
  partnerOptions: Option[];
}

export const PartnerQuestion: React.FC<Props> = (props) => {
  const { partnerOptions, selectedPartner, onPartnerSelect } = props;

  const partnerField = useField(PARTNER);

  const actionOnChange = useCallback(
    (value: AnswerValue): void => {
      onPartnerSelect?.(value);
    },
    [onPartnerSelect],
  );

  return (
    <Select
      {...partnerField.props}
      id='partner'
      error={partnerField.props.error}
      value={selectedPartner}
      autoSelect
      label='Select a partner'
      options={partnerOptions}
      actionOnChange={actionOnChange}
      actionOnComplete={undefined}
    />
  );
};
