import { useCallback, useEffect, useState } from 'react';

import { Divider, Grid } from '@mui/material';

import type { AuthorizedLevel } from '@ecp/utils/auth';
import {
  agentAuth,
  getAuthorizedPartnerList,
  getAuthorizedPartnerSegmentAccountList,
  getAuthorizedPartnerSegmentList,
} from '@ecp/utils/auth';
import { emptyArray } from '@ecp/utils/common';
import { sessionStorage } from '@ecp/utils/storage';

import { GridItem } from '@ecp/components';
import {
  PARTNER,
  PARTNER_ACCOUNT,
  PARTNER_EXPERIENCE_ID,
  PARTNER_SEGMENT,
} from '@ecp/features/sales/shared/constants';
import { useField } from '@ecp/features/sales/shared/store';
import type { AnswerValue } from '@ecp/features/sales/shared/types';
import type { ExperienceId } from '@ecp/partners';
import { useIsMobile } from '@ecp/themes/base';
import type { Option } from '@ecp/types';

import { AccountQuestion, PartnerQuestion, SegmentQuestion } from '../../../components';
import { useStyles } from '../LandingPageForm.styles';
import metadata from './metadata';

interface AccountOption extends Option {
  experienceId: ExperienceId;
}

interface Props {
  onExperienceIdChange: (value: ExperienceId) => Promise<void>;
}

const retrieveSegmentOptions = (partner: string): Option[] => {
  return getAuthorizedPartnerSegmentList(
    agentAuth.authorizedLevels as AuthorizedLevel[],
    partner,
  ).map((segment) => {
    const option = {
      value: segment.id,
      label: segment.name,
    };

    return option;
  });
};

const retrieveAccountOptions = (partner: string, segment: string): AccountOption[] => {
  return getAuthorizedPartnerSegmentAccountList(
    agentAuth.authorizedLevels as AuthorizedLevel[],
    partner,
    segment,
  ).map((account) => {
    const option = {
      value: account.id,
      label: account.name,
      experienceId: account.experienceId,
    };

    return option;
  });
};

const getInitialSegmentOptions = (selectedPartner: string): Option[] => {
  if (!selectedPartner) return emptyArray as unknown as Option[];

  return retrieveSegmentOptions(selectedPartner);
};

const getInitialAccountOptions = (
  selectedPartner: string,
  selectedSegment: string,
): AccountOption[] => {
  if (!selectedPartner || !selectedSegment) return emptyArray as unknown as AccountOption[];

  return retrieveAccountOptions(selectedPartner, selectedSegment);
};

export const PartnerQuestions: React.FC<Props> = (props) => {
  const { onExperienceIdChange } = props;

  const { classes } = useStyles();
  const isMobile = useIsMobile();
  const partner = useField(PARTNER);
  const segment = useField(PARTNER_SEGMENT);
  const account = useField(PARTNER_ACCOUNT);
  const partnerExpId = useField(PARTNER_EXPERIENCE_ID);
  const selectedPartner = sessionStorage.getItem(PARTNER) as string;
  const selectedSegment = sessionStorage.getItem(PARTNER_SEGMENT) as string;
  const selectedAccount = sessionStorage.getItem(PARTNER_ACCOUNT) as string;

  const [segmentOptions, setSegmentOptions] = useState(getInitialSegmentOptions(selectedPartner));
  const [accountOptions, setAccountOptions] = useState(
    getInitialAccountOptions(selectedPartner, selectedSegment),
  );

  const partnerOptions = agentAuth.authorizedLevels
    ? getAuthorizedPartnerList(agentAuth.authorizedLevels as AuthorizedLevel[]).map((partner) => {
        const option = {
          value: partner.id,
          label: partner.name,
        };

        return option;
      })
    : (emptyArray as unknown as Array<{ value: string; label: string }>);

  const handlePartnerSelect = useCallback(
    (value: AnswerValue): void => {
      segment.validateUpdateAndPatch('');
      account.validateUpdateAndPatch('');
      sessionStorage.setItem(PARTNER_SEGMENT, '');
      sessionStorage.setItem(PARTNER_ACCOUNT, '');

      setSegmentOptions(retrieveSegmentOptions(value as string));

      sessionStorage.setItem(PARTNER, value);
      partner.props.actionOnChange(value);
    },
    [account, partner.props, segment],
  );

  const handleSegmentSelect = useCallback(
    (value: AnswerValue): void => {
      // clear out account values
      account.validateUpdateAndPatch('');
      sessionStorage.setItem(PARTNER_ACCOUNT, '');

      setAccountOptions(retrieveAccountOptions(selectedPartner as string, value as string));

      sessionStorage.setItem(PARTNER_SEGMENT, value);
      segment.props.actionOnChange(value);
    },
    [account, selectedPartner, segment.props],
  );

  const handleAccountSelect = useCallback(
    async (value: AnswerValue): Promise<void> => {
      const experienceId = accountOptions.find((option) => option.value === value)?.experienceId;
      if (experienceId) {
        partnerExpId.update(experienceId);
        sessionStorage.setItem(PARTNER_EXPERIENCE_ID, experienceId);
        sessionStorage.setItem(PARTNER_ACCOUNT, value);
        account.props.actionOnChange(value);

        // Call onExperienceIdChange last for any dependencies on values set above.
        await onExperienceIdChange(experienceId);
      }
    },
    [account.props, accountOptions, onExperienceIdChange, partnerExpId],
  );

  useEffect(() => {
    if (selectedPartner) {
      partner.update(selectedPartner);
    }

    if (selectedSegment) {
      segment.update(selectedSegment);
    }

    if (selectedAccount) {
      account.update(selectedAccount);
    }
  }, [selectedPartner, partner, selectedSegment, segment, selectedAccount, account]);

  return (
    <div className={metadata.hidePartnerQuestionsSection ? classes.hide : undefined}>
      <h2 className={classes.subtitle}>Partner information</h2>
      <Grid container>
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
          <PartnerQuestion
            onPartnerSelect={handlePartnerSelect}
            partnerOptions={partnerOptions}
            selectedPartner={selectedPartner}
          />
        </GridItem>
        <GridItem
          topSpacing={isMobile ? 'md' : 'sm'}
          xs={12}
          md={6}
          className={classes.columnRight}
        >
          <SegmentQuestion
            onSegmentSelect={handleSegmentSelect}
            segmentOptions={segmentOptions}
            selectedSegment={selectedSegment}
          />
        </GridItem>

        <GridItem topSpacing='md' xs={12} md={6} className={classes.columnLeft}>
          <AccountQuestion
            onAccountSelect={handleAccountSelect}
            accountOptions={accountOptions}
            selectedAccount={selectedAccount}
          />
        </GridItem>
      </Grid>
      <Divider className={classes.divider} />
    </div>
  );
};
