import { memo } from 'react';

import { Grid } from '@mui/material';

import { GridItem, Topbar } from '@ecp/components';
import { getCurrentPage } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { useStyles } from './AppFooter.styles';
import { AppFooterLinks } from './AppFooterLinks';
import { DebugTopbarDrawer } from './DebugTopbarDrawer';
import metadata from './metadata';

const copyrightTextElementDefault = (
  <>
    © {new Date().getFullYear()} {metadata.copyrightText}
  </>
);

interface Props {
  copyrightTextElement?: React.ReactElement;
}

export const AppFooter: React.FC<Props> = memo((props) => {
  const { copyrightTextElement = copyrightTextElementDefault } = props;
  const { links } = metadata;
  const { classes } = useStyles();
  const currentPage = useSelector(getCurrentPage);
  const hideFooterLinks = currentPage === undefined;

  return (
    <footer role='contentinfo' className={classes.root}>
      <Topbar
        title='Are you experiencing any sort of error? View debug information'
        drawer={<DebugTopbarDrawer />}
        trackingName='DebugTopbarArrow'
        classes={{
          root: classes.debugWrapperRoot,
          titlePanel: classes.debugWrapperTitlePanel,
          collapseContent: classes.debugWrapperCollapseContent,
        }}
      />
      <Grid container className={classes.footerContainer}>
        <GridItem className={classes.copyrightText}>{copyrightTextElement}</GridItem>
        {!hideFooterLinks && (
          <GridItem className={classes.siteLinks}>
            {links.map((link, index) => {
              const { action, label, url, text } = link;

              return (
                <AppFooterLinks
                  action={action}
                  label={label}
                  url={url}
                  text={text}
                  numberOfLinks={links.length}
                  index={index}
                  key={`${index}-${text}`}
                />
              );
            })}
          </GridItem>
        )}
      </Grid>
    </footer>
  );
});
