import { PostBindPage } from '@ecp/features/sales/checkout';

import { AgentScriptBox } from '../../components';

export const AgentPostBindPage: React.FC = () => {
  return (
    <PostBindPage
      disclosureScriptsDialog={
        <AgentScriptBox trackingName='PostBindPageDialog' variant='dialog' hideCheckBoxHeader />
      }
      disclosureScripts={
        <AgentScriptBox
          trackingName='PostBindPageAccordion'
          variant='accordion'
          hideRequiredCheckbox
        />
      }
    />
  );
};
