import { useCallback, useState } from 'react';

import { Grid } from '@mui/material';

import { TrackingDimensions } from '@ecp/utils/analytics/tracking';

import { useAddFields } from '@ecp/features/sales/form';
import { CheckboxGroup } from '@ecp/features/sales/shared/components';
import {
  getAntiTheftDevicesDialogToggled,
  getPrimaryInsuredStateCode,
  getStateSpecificVehicleDiscountMetadata,
  setAntiTheftDevicesDialogToggled,
  updateAnswers,
  useField,
  useMultiFieldsForCheckBoxGroup,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Answers, FieldCardOption, VehicleWithImage } from '@ecp/features/sales/shared/types';

import type { AutoDiscounts } from '../../../../forms';
import { AntiTheftAdditionalQuestion } from '../../Vehicle/VehicleAntiTheftQuestion/AntiTheftAdditionalQuestion';
import { AntiTheftDevicesDialog } from '../../Vehicle/VehicleAntiTheftQuestion/AntiTheftDevicesDialog';
import { SafetyFeaturesAdditionalQuestion } from '../../Vehicle/VehicleSafetyQuestion/SafetyFeaturesAdditionalQuestion';
import { useStyles } from './VehicleDiscounts.styles';

interface VehicleDiscountsProps {
  vehicleDiscountsOptions: FieldCardOption[];
  vehicleItem: VehicleWithImage;
  vehicleDiscounts: AutoDiscounts;
  isContinueClicked: boolean;
}

export const VehicleDiscounts: React.FC<VehicleDiscountsProps> = (props) => {
  const {
    vehicleDiscountsOptions: baseVehicleDiscountsOptions,
    vehicleItem,
    vehicleDiscounts,
    isContinueClicked,
  } = props;
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const vehicleDiscountsOptions = getStateSpecificVehicleDiscountMetadata(
    baseVehicleDiscountsOptions,
    stateCode,
  );
  let availableVehicleDiscountsOptions = [...vehicleDiscountsOptions];
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const vehicleDiscountsFields = Object.values(vehicleDiscounts[vehicleItem.vehicle.ref!]);

  vehicleDiscountsFields.forEach((field) => {
    if (!field.exists) {
      availableVehicleDiscountsOptions = availableVehicleDiscountsOptions.filter(
        (option) => option.fieldName !== field.key.substr(field.key.lastIndexOf('.') + 1),
      );
    }
  });
  const vehicleDiscountsFieldsFiltered = vehicleDiscountsFields.filter((field) => field.exists);
  const vehicleOptionsProps = useMultiFieldsForCheckBoxGroup(
    vehicleDiscountsFieldsFiltered,
    availableVehicleDiscountsOptions,
    TrackingDimensions.VEHICLE_REF,
    vehicleItem.vehicle.ref,
  );
  const antiTheft = useField(`${vehicleItem.vehicle.ref}.features.antitheft`);
  const safety = useField(`${vehicleItem.vehicle.ref}.features.safety`);
  useAddFields({ [`${antiTheft.key}`]: antiTheft, [`${safety.key}`]: safety });

  const cotainerClassName = antiTheft.value || safety.value ? classes.container : undefined;

  const antiTheftDevicesDialogToggled = useSelector(getAntiTheftDevicesDialogToggled);
  const handleClose = useCallback(() => {
    dispatch(setAntiTheftDevicesDialogToggled(false));
  }, [dispatch]);

  const initialCheckBoxGroupValue: Answers = {};
  const [checkboxGroupValuetoBePatchedForAntiTheft, setCheckboxGroupValuetoBePatchedForAntiTheft] =
    useState(initialCheckBoxGroupValue);
  const [checkboxGroupValuetoBePatchedForSafety, setCheckboxGroupValuetoBePatchedForSafety] =
    useState(initialCheckBoxGroupValue);

  const handleChangeForAntiTheft = useCallback(
    (value: Answers) => setCheckboxGroupValuetoBePatchedForAntiTheft(value),
    [],
  );
  const handleChangeForSafety = useCallback(
    (value: Answers) => setCheckboxGroupValuetoBePatchedForSafety(value),
    [],
  );

  const mouseMovingOutAndPatch = useCallback(() => {
    const checkboxGroupValuetoBePatched = {
      ...checkboxGroupValuetoBePatchedForAntiTheft,
      ...checkboxGroupValuetoBePatchedForSafety,
    };
    dispatch(updateAnswers({ answers: checkboxGroupValuetoBePatched }));
  }, [checkboxGroupValuetoBePatchedForAntiTheft, checkboxGroupValuetoBePatchedForSafety, dispatch]);

  return (
    <>
      <CheckboxGroup
        className={classes.vehicleDiscountCheckBoxGroup}
        dataTestIdPrefix={`${vehicleItem.vehicle.ref}-discounts`}
        cardSize='medium'
        options={vehicleDiscountsOptions}
        label={
          <div className={classes.label}>
            {vehicleItem.imageUrl && (
              <img className={classes.labelImage} src={vehicleItem.imageUrl} alt='Vehicle' />
            )}
            <h3>{vehicleItem.vehicle.description}</h3>
          </div>
        }
        trackingName='VehicleDiscountsButton'
        {...vehicleOptionsProps}
      />
      <AntiTheftDevicesDialog open={!!antiTheftDevicesDialogToggled} onClose={handleClose} />
      <Grid container className={cotainerClassName} onMouseLeave={mouseMovingOutAndPatch}>
        <AntiTheftAdditionalQuestion
          vehicleRef={vehicleItem.vehicle.ref || ''}
          antiTheft={antiTheft}
          isContinueClicked={isContinueClicked}
          variant='discount'
          setCheckboxGroupValuetoBePatched={handleChangeForAntiTheft}
          originalCheckboxGroupValue={checkboxGroupValuetoBePatchedForAntiTheft}
        />
        <SafetyFeaturesAdditionalQuestion
          isContinueClicked={isContinueClicked}
          vehicleRef={vehicleItem.vehicle.ref || ''}
          variant='discount'
          setCheckboxGroupValuetoBePatched={handleChangeForSafety}
          originalCheckboxGroupValue={checkboxGroupValuetoBePatchedForSafety}
        />
      </Grid>
    </>
  );
};
