import { useCallback, useMemo } from 'react';

import { setDimension, TrackingDimensions } from '@ecp/utils/analytics/tracking';
import { castToBoolean, ensureStringOrNull } from '@ecp/utils/common';

import { useAddFields } from '@ecp/features/sales/form';
import { CheckboxGroup, DriverIcon } from '@ecp/features/sales/shared/components';
import { DiscountsSubQuestions } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';
import type { DriverDiscountMetadata } from '@ecp/features/sales/shared/types';

import type { AutoDiscounts } from '../../../../forms';
import { discountsDefaultValue } from '../../../../metadata';
import { useStyles } from './DriverDiscounts.styles';

interface DriverDiscountsProps {
  driverDiscounts: AutoDiscounts;
  driver: DriverDiscountMetadata;
  index: number;
  isContinueClicked?: boolean;
}

interface DriverDiscountsValues {
  [key: string]: string[];
}

export const DriverDiscounts: React.FC<DriverDiscountsProps> = (props) => {
  const { classes } = useStyles();
  const {
    driver: { ref: driverRef, firstName, cardOptions },
    driverDiscounts,
    index,
    isContinueClicked,
  } = props;

  const collegeDegree = useField(`${driverRef}.discount.collegeDegree`);
  useAddFields({ [`${collegeDegree.key}`]: collegeDegree });

  const driverDiscountsValues: DriverDiscountsValues = useMemo(() => {
    return Object.keys(driverDiscounts).reduce((discountsValues, ref) => {
      const discountsFields = driverDiscounts[ref];
      const discountValues = Object.keys(discountsFields).reduce((values, key) => {
        const field = discountsFields[key];
        // !FIXME Look at key type which includes `value` string, so `field.value` doesn't exist but discountsFields.value - does
        // @ts-ignore FIXME ASAP
        if (castToBoolean(field.value) || ensureStringOrNull(field.value)) {
          values.push(key);
        }

        return values;
      }, [] as (keyof typeof discountsFields)[]);

      return { ...discountsValues, [ref]: discountValues };
    }, {} as DriverDiscountsValues);
  }, [driverDiscounts]);

  const handleDriverDiscountChange = useCallback(
    (values: string[]) => {
      // sets the cor associated with the discount options to GA dimension
      setDimension(TrackingDimensions.DRIVER_REF, driverRef);
      const discountsFields = driverDiscounts[driverRef];
      values.forEach((key) => {
        // @ts-ignore FIXME ASAP
        const field = discountsFields[key];
        if (!field.exists) return;
        const asnwerType = field.question.answerType;
        if (asnwerType === 'Boolean' && !castToBoolean(field.value))
          field.props.actionOnComplete(true);
        if (asnwerType === 'String' && !ensureStringOrNull(field.value))
          // @ts-ignore FIXME ASAP
          field.props.actionOnComplete(discountsDefaultValue[key]);
      });
      Object.keys(discountsFields).forEach((key) => {
        if (!values.includes(key)) {
          const field = discountsFields[key];
          // @ts-ignore FIXME ASAP
          if (!field.exists) return;
          // @ts-ignore FIXME ASAP
          const asnwerType = field.question.answerType;
          // @ts-ignore FIXME ASAP
          if (asnwerType === 'Boolean' && castToBoolean(field.value)) {
            // @ts-ignore FIXME ASAP
            field.props.actionOnComplete(false);
          }
          // @ts-ignore FIXME ASAP
          if (asnwerType === 'String' && ensureStringOrNull(field.value))
            // @ts-ignore FIXME ASAP
            field.props.actionOnComplete('');
        }
      });
    },
    [driverDiscounts, driverRef],
  );

  return (
    <>
      <CheckboxGroup
        className={classes.driverDiscountCheckBoxGroup}
        name={driverRef}
        cardSize='medium'
        options={cardOptions}
        data-testid={driverRef}
        labelClassName={classes.labelClassName}
        dataTestIdPrefix={`${driverRef}-discounts`}
        label={
          <div className={classes.label}>
            <DriverIcon index={index} classes={{ root: classes.driverIcon }} />
            <h3>{`${firstName}`}</h3>
          </div>
        }
        actionOnComplete={handleDriverDiscountChange}
        values={driverDiscountsValues[driverRef]}
        trackingName='driver_discounts'
      />
      <DiscountsSubQuestions driverRef={driverRef} isContinueClicked={isContinueClicked} />
    </>
  );
};
