import { useEffect, useState } from 'react';

import { Events, trackEvent } from '@ecp/utils/flags';
import { useBackAndForwardNav, useLocation } from '@ecp/utils/routing';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { PrefillFlow } from '@ecp/features/sales/shared/constants';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import {
  getCurrentFlows,
  getLineOfBusiness,
  navigateToDriver,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

import { DriverForm } from '../../forms';
import { useDriver, useRemoveDriver, useSetupDriverForm } from '../../state';
import { DriverProfileForm } from '../AutoProfile/Driver';

export const DriverPage: React.FC = () => {
  const dispatch = useDispatch();
  const {
    parameters: { id: driverId },
    search: { referencePage },
  } = useLocation(PagePath.DRIVER_PROFILE);
  const isAddMode = Boolean(referencePage?.includes('profileAdd'));
  const isEditMode = Boolean(referencePage?.includes('profileEdit'));
  const lineOfBusiness = useSelector(getLineOfBusiness);
  const {
    isPni,
    driverRef: currentDriverRef = '',
    driverFields,
    incidentFields,
    incidentList,
    validateFormAndIncidents,
    incidentRequired,
    setIncidentRequired,
  } = useSetupDriverForm(driverId);
  const flow = useSelector(getCurrentFlows);
  const isFlowShortAuto = flow.auto === PrefillFlow.SHORT;

  const {
    driver: {
      firstName,
      fromPrefill: { value: fromPrefill },
    },
  } = driverFields;

  const {
    props: { value: name = '' },
  } = firstName;

  useEffect(() => {
    if (!driverId && currentDriverRef) {
      // replace the route so the /driver url will on longer be in the history
      dispatch(navigateToDriver({ ref: currentDriverRef, replace: true }));
    }
  });

  // TODO: We might need a better way to handle this. A/B test metric tracking function for driver page view
  useEffect(() => {
    trackEvent(Events.DRIVER_PAGE);
  }, []);

  const handleNext = useNavigateToPage(PagePath.EDIT_AUTO_PROFILE, {
    replace: true,
    removeQuery: false,
    replaceProfileAdd: true,
  });

  const [driverToAddRef, setDriverToAddRef] = useState('');

  useEffect(() => {
    if (isAddMode) {
      setDriverToAddRef(currentDriverRef);
    }
  }, [currentDriverRef, isAddMode]);

  // While navigating back to Driver Page driverToAddRef comes with only reference number instead of driver.reference
  // incase of only reference number adding prefix 'driver.' to reference number which is exprected.
  const updatedUnusedDriverRef =
    driverToAddRef && !driverToAddRef.startsWith('driver')
      ? `driver.${driverToAddRef}`
      : driverToAddRef;

  const removeDriver = useRemoveDriver();
  const driver = useDriver(updatedUnusedDriverRef);

  // On back navigation remove refs for driver and person and delete all answers related to the new refs.
  // Since for every new driver we initialize the page with new driver and person refs and patch them as answers.
  // We will lose all data for that particular driver/person (as per the requirements)
  useBackAndForwardNav(async () => {
    if (updatedUnusedDriverRef)
      await removeDriver(updatedUnusedDriverRef, driver.personRef, driver.incidentRefs);
  });

  return (
    <div>
      <Page
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.EDIT_AUTO_PROFILE} />,
        }}
        title={
          fromPrefill || isEditMode
            ? `Let's finish ${name}'s profile`
            : 'Now for some driver details'
        }
        analyticsElement='choice.driverDetailsPage.page'
      >
        {isFlowShortAuto ? (
          <DriverProfileForm
            driverRef={currentDriverRef}
            isPni={isPni}
            lineOfBusiness={lineOfBusiness}
            onNext={handleNext}
            isEdit={isEditMode}
          />
        ) : (
          <DriverForm
            driverFields={driverFields}
            incidentFields={incidentFields}
            isPni={isPni}
            driverRef={currentDriverRef}
            incidentList={incidentList}
            incidentRequired={incidentRequired}
            setIncidentRequired={setIncidentRequired}
            onNext={handleNext}
            validateFormAndIncidents={validateFormAndIncidents}
            needActions
            isEdit={isEditMode}
          />
        )}
      </Page>
    </div>
  );
};
