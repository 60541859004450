import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import type { ContentByProductType } from './metadata.js';
import baseMetadata from './metadata.js';

const metadata: () => ContentByProductType = () => {
  const autoProductSwapPaymentDisclosure = flagValues[FeatureFlags.AUTO_PRODUCT_SWAP_STATE_ROLLOUT];

  return {
    ...baseMetadata(),
    auto: {
      insuranceType: '"Auto insurance."',
      underwritingGroupFull: autoProductSwapPaymentDisclosure
        ? 'Midvale Indemnity Company, and its subsidiaries'
        : 'CONNECT, powered by American Family Insurance, and its subsidiaries',
      underwritingGroupShort: autoProductSwapPaymentDisclosure ? 'Midvale' : 'CONNECT',
    },
  };
};

export default metadata;
