import { useCallback, useEffect, useMemo } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels, trackClick } from '@ecp/utils/analytics/tracking';
import type { GeoAddress } from '@ecp/utils/geo';
import { useGeoAddressOptions, validateAndCombineAddress } from '@ecp/utils/geo';

import { GridItem } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import {
  useUpdateVehicleKeptAddressRef,
  useVehicleKeptAddressRef,
} from '@ecp/features/sales/quotes/auto';
import {
  AutoComplete,
  RadioGroupWithOptions,
  Select,
  TextField,
} from '@ecp/features/sales/shared/components';
import { STATE_CODE_PREFIX } from '@ecp/features/sales/shared/constants';
import {
  createAddressForUpdate,
  getInferredValueForYesNoButton,
  getZipLookupBypassed,
  updateAnswers,
  useField,
  useFieldWithPrefix,
  usePrimaryAddressRef,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, OptionProps } from '@ecp/features/sales/shared/types';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

import { useStyles } from './VehicleKeptAddressQuestion.styles';

interface Props {
  setVehicleKeptAddressSuggestions(value: GeoAddress[]): void;
  vehicleKeptAddressSuggestions: GeoAddress[];
  disabled: boolean;
}

const gaTrackSuggestionClick = (): void => {
  trackClick({
    action: 'SmartyStreetSuggestedAddress',
    label: 'ClickedSuggestion',
  });
  trackSapiAnalyticsEvent({
    element: 'choice.personForm.smartyStreetSuggestion',
    event: 'click',
    eventDetail: 'true',
  });
};

export const VehicleKeptAddressQuestion: React.FC<Props> = (props) => {
  const { setVehicleKeptAddressSuggestions, vehicleKeptAddressSuggestions, disabled } = props;
  const dispatch = useDispatch();
  const updateVehicleKeptAddressRef = useUpdateVehicleKeptAddressRef();
  const primaryAddressRef = usePrimaryAddressRef();
  const vehicleKeptAddressReference = useVehicleKeptAddressRef();
  const useAddressField = useFieldWithPrefix(vehicleKeptAddressReference, 'address.<id>');
  const usePrimaryAddressField = useFieldWithPrefix(primaryAddressRef, 'address.<id>');
  const line1 = useAddressField('line1');
  const line2 = useAddressField('line2');
  const city = useAddressField('city');
  const primaryState = usePrimaryAddressField('state');
  const state = useAddressField('state');
  const zipcode = useAddressField('zipcode');
  const vehicleKeptAddressYesNoField = useField('static.vehicleKeptAddressYesNo');
  const vehicleKeptAddress = useField('static.vehicleKeptAddress');
  const zipcodeBypassed = useSelector(getZipLookupBypassed);
  const { classes } = useStyles();

  useEffect(() => {
    if (!vehicleKeptAddressReference) {
      updateVehicleKeptAddressRef(true);
    }
  }, [vehicleKeptAddressReference, updateVehicleKeptAddressRef]);

  useAddFields({
    vehicleKeptAddressYesNoField,
    vehicleKeptAddress,
    line1,
    line2,
    city,
    state,
    zipcode,
  });
  const {
    handleSuggestionsFetchRequested: handleVKASuggestionsFetchRequested,
    handleSuggestionsClearRequested,
    handleAptSuggestionFetchRequested,
  } = useGeoAddressOptions();

  useEffect(() => {
    // ensures that the state value for garage is saved else we don't store the value if a user selects to enter an address manually
    // instead of selecting from a dropdown.
    // if we haven't gotten vehicleKeptAddressReference, then we end up patching with key of `undefined.state`
    if (!state.value && primaryState.value && vehicleKeptAddressReference) {
      state.props.actionOnComplete(primaryState.value);
    }
  }, [state.value, primaryState.value, state.props, vehicleKeptAddressReference]);

  const autoCompleteVKASuggestions = useMemo(
    () =>
      vehicleKeptAddressSuggestions.map((s: GeoAddress, index, arr) => {
        if (arr.length - 1 === index) {
          return s.street_line;
        }

        return (
          s.street_line +
          (s.secondary !== ''
            ? ` ${s.secondary} (${s.entries} more entries) ${s.city}, ${s.state} ${s.zipcode}`
            : ` ${s.city}, ${s.state} ${s.zipcode}`)
        );
      }),
    [vehicleKeptAddressSuggestions],
  );

  const vehicleKeptAddressValue = getInferredValueForYesNoButton({
    yesNoField: vehicleKeptAddressYesNoField,
    getValue: () => vehicleKeptAddressReference === primaryAddressRef,
  });

  const handleVehicleKeptAddressChange = useCallback(
    (value: AnswerValue) => {
      vehicleKeptAddressYesNoField.props.actionOnComplete(value);
      if (value) {
        updateVehicleKeptAddressRef(true, vehicleKeptAddressReference);
      } else {
        updateVehicleKeptAddressRef(false);
      }
    },
    [vehicleKeptAddressReference, vehicleKeptAddressYesNoField.props, updateVehicleKeptAddressRef],
  );

  const handleVKASuggestionsFetch = useCallback(
    async (value: string): Promise<void> => {
      if (!value.includes('Apt') || !value.includes('Ste')) {
        // Need to pass state value as well to grab suggestions instead of just filtering on zipcode because garage address
        // may be different from primary address filled in.
        const output = await handleVKASuggestionsFetchRequested(
          value,
          zipcode.value as string,
          state.value as string,
        );
        setVehicleKeptAddressSuggestions(output);
      }
    },
    [
      handleVKASuggestionsFetchRequested,
      setVehicleKeptAddressSuggestions,
      state.value,
      zipcode.value,
    ],
  );

  const handleVehickeKeptAddressSelection = useCallback(
    async (value: string) => {
      const vehicleKeptAddressToBeValidated = vehicleKeptAddressSuggestions.find(
        (vehicleKeptaddressVal: GeoAddress) => {
          const fullAddress =
            vehicleKeptaddressVal.street_line +
            (vehicleKeptaddressVal.secondary !== ''
              ? ` ${vehicleKeptaddressVal.secondary} (${vehicleKeptaddressVal.entries} more entries) ${vehicleKeptaddressVal.city}, ${vehicleKeptaddressVal.state} ${vehicleKeptaddressVal.zipcode}`
              : ` ${vehicleKeptaddressVal.city}, ${vehicleKeptaddressVal.state} ${vehicleKeptaddressVal.zipcode}`);

          return fullAddress === value;
        },
      );
      // If selected address is just a house
      if (vehicleKeptAddressToBeValidated) {
        if (vehicleKeptAddressToBeValidated.entries <= 1) {
          line1.props.actionOnComplete(vehicleKeptAddressToBeValidated.street_line);
          const inputAddress = {
            street: vehicleKeptAddressToBeValidated.street_line,
            city: vehicleKeptAddressToBeValidated.city,
            street2:
              vehicleKeptAddressToBeValidated.secondary !== ''
                ? vehicleKeptAddressToBeValidated.secondary
                : '',
            state: vehicleKeptAddressToBeValidated.state,
            zipcode: vehicleKeptAddressToBeValidated.zipcode,
          };
          const newVehicleKeptAddress = await validateAndCombineAddress(
            inputAddress,
            vehicleKeptAddressReference,
          );
          if (newVehicleKeptAddress) {
            // we should manually update these field values to account for any prior existing error messages,
            // because updating the answers in redux will not handle that for us
            line1.props.actionOnComplete(newVehicleKeptAddress.line1);
            line2.props.actionOnComplete(newVehicleKeptAddress.line2);
            city.props.actionOnComplete(newVehicleKeptAddress.city);
            state.props.actionOnComplete(`${STATE_CODE_PREFIX}${newVehicleKeptAddress.state}`);
            zipcode.props.actionOnComplete(newVehicleKeptAddress.zipcode);
            const vehicleKeptAddressToBeSaved = createAddressForUpdate(newVehicleKeptAddress);
            await dispatch(updateAnswers({ answers: { ...vehicleKeptAddressToBeSaved } }));
          }
        } else {
          line1.props.actionOnComplete(vehicleKeptAddressToBeValidated.street_line);
          // need to pass selected address as well as the letter A to limit the results to number of valid entries recieved for address.
          // Will return # of entries whose secondary value starts with A.
          const selectedValue = `${vehicleKeptAddressToBeValidated.street_line} ${vehicleKeptAddressToBeValidated.secondary} A (${vehicleKeptAddressToBeValidated.entries}) ${vehicleKeptAddressToBeValidated.city} ${vehicleKeptAddressToBeValidated.state} ${vehicleKeptAddressToBeValidated.zipcode}`;
          // If selected address is a series of apartments
          const fetchNewAptSuggestions = await handleAptSuggestionFetchRequested(
            vehicleKeptAddressToBeValidated.street_line,
            selectedValue,
          );

          setVehicleKeptAddressSuggestions(fetchNewAptSuggestions);
        }
      }
    },
    [
      vehicleKeptAddressSuggestions,
      vehicleKeptAddressReference,
      line1.props,
      line2.props,
      city.props,
      state.props,
      zipcode.props,
      handleAptSuggestionFetchRequested,
      setVehicleKeptAddressSuggestions,
      dispatch,
    ],
  );

  return (
    <>
      <Grid item xs={12}>
        <RadioGroupWithOptions
          {...vehicleKeptAddressYesNoField.props}
          value={vehicleKeptAddressValue}
          id='VehicleKeptAddress'
          actionOnComplete={handleVehicleKeptAddressChange}
          label='Is this the address where your vehicles are kept?'
          trackingName='garaging_address'
          variant='yesNoButton'
          disabled={disabled}
        />
      </Grid>
      {!vehicleKeptAddressValue && (
        <GridItem topSpacing='sm' xs={12}>
          <Grid container columnSpacing={4}>
            <GridItem topSpacing='sm' xs={12} md={6}>
              <AutoComplete
                {...line1.props}
                label='Where are your vehicles kept?'
                suggestions={autoCompleteVKASuggestions}
                geoAddressFormattedSuggestions={vehicleKeptAddressSuggestions}
                onSuggestionsClearRequested={handleSuggestionsClearRequested}
                onSuggestionsFetchRequested={handleVKASuggestionsFetch}
                onSuggestionSelected={handleVehickeKeptAddressSelection}
                id='vehicleKeptAddressAutoComplete'
                trackingName='garaging_address'
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
                gaTrackSuggestionClick={gaTrackSuggestionClick}
                disabled={disabled}
              />
            </GridItem>
            <GridItem topSpacing='sm' xs={12} md={6}>
              <TextField
                id='vehicleKeptAddressLine2'
                label='Apt./Unit # (optional)'
                ariaLabel='Vehicle Kept Address Line 2'
                {...line2.props}
                trackingName='garaging_address_line_2'
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
                disabled={disabled}
              />
            </GridItem>
            <GridItem topSpacing='sm' xs={12} md={4} className={classes.columnLeft}>
              <TextField
                id='vehicleKeptAddressCity'
                label='City'
                {...city.props}
                trackingName='garaging_city'
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
                disabled={disabled}
              />
            </GridItem>
            <GridItem topSpacing='sm' xs={12} md={4} className={classes.columnMiddle}>
              <Select
                id='vehicleKeptAddressState'
                label='State'
                inputAriaLabel='Vehicle Kept Address State'
                trackingName='garaging_state'
                {...(state.props as OptionProps)}
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
                disabled={!zipcodeBypassed || disabled}
              />
            </GridItem>
            <GridItem topSpacing='sm' xs={12} md={4} className={classes.columnRight}>
              <TextField
                id='vehicleKeptAddressZipCode'
                label='ZIP code'
                ariaLabel='Vehicle Kept Address Zip Code'
                trackingName='garaging_zip_code'
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
                {...zipcode.props}
                disabled={disabled}
              />
            </GridItem>
          </Grid>
        </GridItem>
      )}
    </>
  );
};
