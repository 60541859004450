import type { ContentByProductType } from './metadata';
import { default as baseMetadata } from './metadata';

const metadata: ContentByProductType = {
  ...baseMetadata,
  auto: {
    English: {
      insuranceType: '"Auto insurance"',
      multipleProductInsuranceType: '"Auto insurance" for your auto policy',
      underwriterOnStatement: 'Midvale Indemnity Company, for your Auto policy',
      underwritingGroupFull: 'Midvale Indemnity Company, and its subsidiaries',
      underwritingGroupShort: 'Midvale',
    },
    Spanish: {
      insuranceType: '"Seguro de automóvil"',
      multipleProductInsuranceType: '"Seguro de automóvil" para su póliza de automóvi',
      underwriterOnStatement: 'Midvale Indemnity Company, para su póliza de automóvilo',
      underwritingGroupFull: 'Midvale Indemnity Company, y sus subsidiarias',
      underwritingGroupShort: 'Midvale',
    },
  },
};

export default metadata;
