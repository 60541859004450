import { getCurrentPage, getPrimaryInsuredStateCode } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { partner } from '@ecp/partners';

import { AgentScriptContent } from '../AgentScriptContent';
import { ProtectedPersonDisclaimerMetadata, ProtectedPersonDisclaimerQuestion } from '../metadata';

export const ProtectedPersonScripts: React.FC = (): React.ReactElement | null => {
  const currentPage = useSelector(getCurrentPage);
  const isCurrentPageCheckout = currentPage === '/app/checkout';
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const partnerName = partner.shared.partnerDisplayName;
  const showProtectedPersonScript = isCurrentPageCheckout && stateCode === 'NM';

  if (!showProtectedPersonScript) return null;

  return (
    <>
      <AgentScriptContent agentScript={ProtectedPersonDisclaimerQuestion()} />
      <AgentScriptContent
        agentScript={ProtectedPersonDisclaimerMetadata(partnerName)}
        includeDisclaimerButton
      />
    </>
  );
};
