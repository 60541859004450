import { useCallback } from 'react';

import { FormLabel, Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { isMasked } from '@ecp/utils/common';

import type { SelectProps, TextFieldProps } from '@ecp/components';
import { TextField } from '@ecp/components';
import { env } from '@ecp/env';
import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import { useField } from '@ecp/features/sales/shared/store';
// import { GraphicGenericCarImageUrl } from '@ecp/themes/base';
import type { Field } from '@ecp/types';

import { VIN_REGEX_NEGATED_SYMBOL } from '../../../../constants';
import { yearOptions } from '../../../../forms';
import {
  useValidateVinNumber,
  validateVinFormatRegex as validateVinFormat,
} from '../../../../state';
import { useStyles } from './VehicleYearVinMakeModelQuestion.styles';

interface Props {
  vehicleRef: string;
  setMaskRequiredFieldError: (value: boolean) => void;
}

export const VehicleYearVinMakeModelQuestion: React.FC<Props> = (props) => {
  const { vehicleRef, setMaskRequiredFieldError } = props;
  const { classes } = useStyles();

  const make = useField(`${vehicleRef}.make`);
  const model = useField(`${vehicleRef}.model`);
  const vin = useField(`${vehicleRef}.vin`);
  const year = useField(`${vehicleRef}.year`);

  useAddFields({ year, vin, make, model });

  useAddConditionValues({
    conditionalFields: [make, model],
    isRequiredOverride: () => true,
  });

  const validateVinNumber = useValidateVinNumber(vin);

  const checkAndClearFields = useCallback((fields: Field[]): void => {
    fields.forEach((field) => {
      if (field.value) {
        field.validateUpdateAndPatch('');
      }
    });
  }, []);

  const handleYearChange: NonNullable<SelectProps['actionOnChange']> = useCallback(
    (value) => {
      setMaskRequiredFieldError(true);
      checkAndClearFields([make, model]);
      year.props.actionOnChange(value);
      if (isMasked(vin.value)) {
        checkAndClearFields([vin]);
      }
    },
    [setMaskRequiredFieldError, checkAndClearFields, model, make, year.props, vin],
  );

  const handleYearComplete: NonNullable<SelectProps['actionOnComplete']> = useCallback(
    async (value) => {
      year.props.actionOnComplete(value);
      const vinValue = vin.value as string;
      if (vinValue && !validateVinFormat(vinValue) && !isMasked(vinValue)) {
        validateVinNumber();
      }
    },
    [year.props, vin.value, validateVinNumber],
  );

  const handleVinOnChange: NonNullable<TextFieldProps['actionOnChange']> = useCallback(
    (value) => {
      const vinString = value.toUpperCase().replace(VIN_REGEX_NEGATED_SYMBOL, '');

      // if vin is masked clear VIN and patch it
      if (isMasked(vin.value)) {
        if (vin.value !== null) vin.props.actionOnComplete(null);
        vin.props.actionOnChange(null);
      } else {
        vin.props.actionOnChange(vinString);
      }

      // reset fields on change
      if (!vinString) {
        checkAndClearFields([make, model]);
        setMaskRequiredFieldError(true);
      }
    },
    [make, model, setMaskRequiredFieldError, checkAndClearFields, vin],
  );

  const handleVinOnComplete: NonNullable<TextFieldProps['actionOnComplete']> = useCallback(
    async (value) => {
      if (isMasked(value)) return;
      if (!value) {
        setMaskRequiredFieldError(true);
        checkAndClearFields([make, model]);
      }
      if (year.value && !validateVinFormat(value)) {
        validateVinNumber();
      }
      if (env.static.isAgent) vin.props.actionOnComplete(value);
    },
    [
      year.value,
      validateVinNumber,
      setMaskRequiredFieldError,
      checkAndClearFields,
      model,
      make,
      vin,
    ],
  );

  return (
    <>
      <FormLabel>Enter vehicle information or vehicle identification number (VIN)</FormLabel>
      <Grid container>
        <Grid
          container
          columnSpacing={4}
          item
          xs={12}
          lg={12}
          className={classes.vehicleFieldsContainer}
        >
          {year.exists && (
            <Grid item xs={12} md={6}>
              <Select
                {...year.props}
                id='VehicleYear'
                label='Vehicle year'
                actionOnComplete={handleYearComplete}
                actionOnChange={handleYearChange}
                options={yearOptions}
                data-testid='year'
                trackingName='vin_year_selection'
              />
            </Grid>
          )}
          {vin.exists && (
            <Grid item xs={12} md={6}>
              <TextField
                {...vin.props}
                error={vin.props.error || validateVinFormat(vin.value as string)}
                id='VIN'
                label='VIN'
                ariaLabel='VIN'
                actionOnChange={handleVinOnChange}
                actionOnComplete={handleVinOnComplete}
                trackingName='VIN'
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
              />
            </Grid>
          )}
          {make.exists && (
            <Grid item xs={12} md={6}>
              <TextField
                {...make.props}
                id='VehicleMake'
                label='Vehicle make'
                ariaLabel='Vehicle make'
                helperText='E.g. Toyota, Honda, etc'
                trackingName='vehicle_make_selection'
                maxLength={30}
              />
            </Grid>
          )}
          {model.exists && (
            <Grid item xs={12} md={6}>
              <TextField
                {...model.props}
                id='VehicleModel'
                label='Vehicle model'
                ariaLabel='Vehicle model'
                helperText='E.g. Corolla, Civic, etc'
                trackingName='vehicle_model_selection'
                maxLength={30}
              />
            </Grid>
          )}
        </Grid>
        {/* Commenting following grid temporarily, we'll be using it again */}
        {/* <Grid container item xs={12} lg={6}>
          <div className={classes.imageContainer}>
            <img
              className={classes.image}
              alt={`${year.value} ${make.value} ${model.value}`}
              src={GraphicGenericCarImageUrl}
            />
          </div>
        </Grid> */}
      </Grid>
    </>
  );
};
