import { useCallback } from 'react';

import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import { DERIVED_DISCOUNT_HOME, EXISTING_HOME_PRODUCT } from '@ecp/features/sales/shared/constants';
import { useField } from '@ecp/features/sales/shared/store';
import type { AnswerValue } from '@ecp/features/sales/shared/types';

export const ResidencyTypeQuestion: React.FC = () => {
  const residencyType = useField('residencyType');
  const derivedDiscountHome = useField(DERIVED_DISCOUNT_HOME);
  const existingHomeProduct = useField(EXISTING_HOME_PRODUCT);
  useInitValues({ [residencyType.key]: 'RESIDENCY_TYPE.OWN' });
  useAddFields({ residencyType });

  const handleResidenceTypeChange = useCallback(
    (value: AnswerValue): void => {
      residencyType.props.actionOnComplete(value);
      if (value === 'RESIDENCY_TYPE.RENT') {
        existingHomeProduct.validateUpdateAndPatch(null);
        derivedDiscountHome.validateUpdateAndPatch(null);
      }
    },
    [derivedDiscountHome, existingHomeProduct, residencyType.props],
  );

  if (!residencyType.exists) return null;

  return (
    <RadioGroupWithOptions
      {...residencyType.props}
      label='Do you own or rent your current residence?'
      trackingName='own_or_rent_selection'
      variant='button'
      actionOnComplete={handleResidenceTypeChange}
    />
  );
};
