import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { OptionProps } from '@ecp/features/sales/shared/types';

import metadata from './metadata/metadata';
interface Props extends QuestionProps {
  driverRef: string;
  helperText?: string;
}

export const DriverRoleQuestion: React.FC<Props> = (props) => {
  const {
    driverRef,
    id = 'DriverRole',
    label,
    groupLabel,
    trackingName = 'DriverRole',
    trackingLabel = GoogleAnalyticsLabels.REDACTED,
    placeholder = 'Select Driver Role',
    helperText,
  } = props;
  const useDriverField = useFieldWithPrefix(driverRef, 'driver.<id>');
  const driverStatus = useDriverField('driverStatus');

  useInitValues({ [driverStatus.key]: metadata.defaultValue });
  useAddFields({ driverStatus });

  if (!driverStatus.exists) return null;

  return (
    <Select
      {...(driverStatus.props as OptionProps)}
      fullWidth={false}
      placeholder={placeholder}
      id={id}
      inputButtonAriaLabel='Drivers Role'
      label={label}
      groupLabel={groupLabel}
      trackingName={trackingName}
      trackingLabel={trackingLabel}
      helperText={helperText}
    />
  );
};
