import { isTruthy } from '@ecp/utils/common';

import type { ExperienceId, PartnerName } from './mapping';
import { mapping } from './mapping';

declare global {
  interface Window {
    store: {
      /** Reads the state tree managed by the store. */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      getState: () => any;
    };
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getOverrideLevels = () => {
  let partnerName: PartnerName | undefined = undefined;

  // Global store would not be initialized during the modules import resolution initially
  try {
    const state = window.store.getState();
    const partnerExpId = state.inquiry.answers['partner.experienceId'] as ExperienceId | undefined;
    partnerName = partnerExpId ? mapping[partnerExpId] : undefined;
  } catch {
    // Skip error
  }

  return [partnerName].filter(isTruthy);
};

export const loadableTmp = <Module extends object>(importer: (path: string) => Module): Module => {
  let _module: Module;
  const _moduleCache: { [key: string]: Module } = {};

  return new Proxy({} as Module, {
    get: (_, prop) => {
      const overrideLevels = getOverrideLevels();
      const cacheKey = overrideLevels.join();

      if (_module && _module === _moduleCache[cacheKey]) {
        return Reflect.get(_moduleCache[cacheKey], prop);
      }

      // Traverse override levels, for each level try to load module and return it if it's found
      for (const overrideLevel of overrideLevels) {
        try {
          _module = importer(`.${overrideLevel}`);
          _moduleCache[cacheKey] = _module;

          return Reflect.get(_module, prop);
        } catch {
          // Skip error
        }
      }

      _module = importer('');

      return Reflect.get(_module, prop);
    },
  });
};
