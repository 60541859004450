import { createSelector } from 'reselect';

import { useSearchParams } from '@ecp/utils/routing';

import { LogoSpinner } from '@ecp/components';
import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { DriverType } from '@ecp/features/sales/quotes/auto';
import { Page } from '@ecp/features/sales/shared/components';
import {
  PagePath,
  useNavigateToNextPage,
  useNavigateToPage,
} from '@ecp/features/sales/shared/routing';
import { getAssignmentDrivers, getField } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { VehicleBasic } from '@ecp/features/sales/shared/types';
import type { Fields } from '@ecp/types';

import { useVehiclesForSidebar } from '../../state';
import { DriverAssignmentForm } from './DriverAssignmentForm';
import { useStyles } from './DriverAssignmentPage.styles';

const useGetDriverAssignmentFields = (vehicles: VehicleBasic[], driverType: DriverType): Fields => {
  const dispatch = useDispatch();

  const selectors = vehicles.map((vehicle) => (state: RootStore) => {
    const f = getField(state, {
      key: `${vehicle.ref}.${driverType}.driver.ref`,
      questionKey: `vehicle.<id>.${driverType}.driver.ref`,
      dispatch,
    });

    return f;
  });

  const fieldsSelector = createSelector(selectors, (...fields) => {
    return fields.reduce((acc, field) => {
      if (!field.exists) return acc;
      acc[field.key] = field;

      return acc;
    }, {} as Fields);
  });

  return useSelector(fieldsSelector);
};
export const DriverAssignmentPage: React.FC = () => {
  const { classes } = useStyles();

  const assignmentDrivers = useSelector(getAssignmentDrivers);

  const vehicles = useVehiclesForSidebar();
  const { referencePage } = useSearchParams();

  const fields = useGetDriverAssignmentFields(vehicles, DriverType.PRIMARY);
  const secondaryFields = useGetDriverAssignmentFields(vehicles, DriverType.SECONDARY);

  const handleNext = useNavigateToNextPage();

  const previousPage = referencePage
    ? `${PagePath.EDIT_AUTO_PROFILE}?referencePage=${referencePage}`
    : PagePath.EDIT_AUTO_PROFILE;
  const navigateBack = useNavigateToPage(previousPage);

  const title = (
    <>
      Vehicle & driver assignment
      <h2 className={classes.subTitle}>
        Use this form to assign a primary driver for your vehicle(s)
      </h2>
    </>
  );

  const nextPageInstructions =
    referencePage === 'quotes'
      ? 'Your quote is ready on the next screen'
      : 'Your discounts are ready on the next screen';

  return (
    <div className={classes.root}>
      <Page
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.EDIT_AUTO_PROFILE} />,
        }}
        title={title}
        analyticsElement='choice.driverAssignmentPage.page'
      >
        {assignmentDrivers.length === 0 ? (
          <LogoSpinner />
        ) : (
          <DriverAssignmentForm
            drivers={assignmentDrivers}
            vehicles={vehicles}
            onNext={handleNext}
            onBack={navigateBack}
            nextPageInstructions={nextPageInstructions}
            driverAssignmentFields={fields}
            secondaryDriverAssignmentFields={secondaryFields}
          />
        )}
      </Page>
    </div>
  );
};
