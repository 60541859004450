import { Grid } from '@mui/material';

import type { OfferErrorDetails } from '@ecp/features/sales/shared/store/types';

import { useStyles } from './ErrorDetails.styles';

export interface ErrorDetailsProps {
  dalSessionId?: string;
  inquiryId?: string;
  offerSetId?: string;
  xRequestId?: string;
  errorDetails: OfferErrorDetails[];
  alignment?: 'left' | 'center';
}

export const ErrorDetails: React.FC<ErrorDetailsProps> = (props) => {
  const {
    dalSessionId,
    inquiryId,
    offerSetId,
    xRequestId,
    errorDetails,
    alignment = 'left',
  } = props;
  const { classes, cx } = useStyles();
  const defaultLabel = 'Not Available';

  return (
    <Grid
      container
      className={cx(classes.root, alignment === 'left' ? classes.leftAlign : classes.centerAlign)}
    >
      {errorDetails.map((errorDetail) => (
        <Grid container className={classes.errorContainer} key={errorDetail.product}>
          <Grid item>
            {errorDetail.reasons.map((reason) => (
              <p key={reason}>{reason} </p>
            ))}
          </Grid>
          <br />
          <Grid item className={classes.details}>
            <p className={classes.subject}>DalSession-Id: </p>&nbsp;{dalSessionId || defaultLabel}
          </Grid>
          <Grid item className={classes.details}>
            <p className={classes.subject}>Inquiry-Id: </p>&nbsp;{inquiryId || defaultLabel}
          </Grid>
          <Grid item className={classes.details}>
            <p className={classes.subject}>Offer-Set-Id: </p>&nbsp;{offerSetId || defaultLabel}
          </Grid>
          <Grid item className={classes.details}>
            <p className={classes.subject}>X-Request-Id: </p>&nbsp;{xRequestId || defaultLabel}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
