import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'VehicleForm' })((theme) => ({
  ...theme.mixins.formAll,
  buttonsPanel: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  addButton: {
    [theme.breakpoints.up('md')]: {
      marginRight: 20,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  cancelButton: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
