import { useCallback } from 'react';

import { DRIVERS_REF, POLICY_VEHICLE_REF } from '@ecp/features/sales/shared/constants';
import {
  deleteAnswers,
  fetchApi,
  isAnyApiInProgress,
  updateAnswers,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

const API_PREFIX = 'autoProfileAPI';

export const useUpdateVehiclesAndDrivers = (): {
  // TODO I don't think we need `isAutoProfileApiInProgress`
  isAutoProfileApiInProgress: boolean;
  updateVehiclesAndDrivers: (
    vehicleRefs: string[],
    driverRefs: string[],
    vehicleRefsRemoved: string[],
    driverRefsRemoved: string[],
  ) => Promise<void>;
} => {
  const dispatch = useDispatch();

  const isAutoProfileApiInProgress = useSelector((state: RootStore) =>
    isAnyApiInProgress(state, API_PREFIX),
  );
  const updateVehiclesAndDrivers = useCallback(
    async (
      vehicleRefs: string[],
      driverRefs: string[],
      vehicleRefsRemoved: string[],
      driverRefsRemoved: string[],
    ) => {
      dispatch(
        fetchApi({
          fn: () => {
            return dispatch(
              updateAnswers({
                answers: {
                  [POLICY_VEHICLE_REF]: vehicleRefs,
                  [DRIVERS_REF]: driverRefs,
                },
              }),
            );
          },
          idPrefix: API_PREFIX,
        }),
      );
      const removedRefs = [];
      if (vehicleRefsRemoved?.length > 0) removedRefs.push(...vehicleRefsRemoved);
      if (driverRefsRemoved?.length > 0) removedRefs.push(...driverRefsRemoved);
      if (removedRefs.length > 0)
        await Promise.all(
          removedRefs.map((removedRef) => dispatch(deleteAnswers({ ref: removedRef }))),
        );
    },
    [dispatch],
  );

  return { isAutoProfileApiInProgress, updateVehiclesAndDrivers };
};
