import type { LineOfBusiness } from '@ecp/features/shared/product';

import type { AgentScripts } from './types';

const title = 'Fraud disclosure';
const defaultAdditionalFraudDisclosure = {
  title,
  text: 'Any person who, with intent to defraud or knowing that they are facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement is guilty of insurance fraud.',
  spanishText:
    'Cualquier persona que, con intención de defraudar o sabiendo que está facilitando un fraude contra un asegurador, presente una solicitud o presente un reclamo que contenga una declaración falsa o engañosa es culpable de fraude de seguros.',
};

export const additionalFraudStatementsMetadata = (
  stateCode: string,
  lob: LineOfBusiness,
): AgentScripts => {
  switch (stateCode) {
    case 'AL':
      switch (lob) {
        case 'LINE_OF_BUSINESS.HOME':
        case 'LINE_OF_BUSINESS.RENTERS':
        case 'LINE_OF_BUSINESS.BUNDLE':
        case 'LINE_OF_BUSINESS.BUNDLE_AUTO_RENTERS':
          return {
            title: 'Alabama state-specific fraud disclosure',
            text: 'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or who knowingly presents false information in an application for insurance is guilty of a crime and may be subject to restitution fines or confinement in prison, or any combination thereof.',
            spanishText:
              'Toda persona que a sabiendas presente una reclamación falsa o fraudulenta para el pago de una pérdida o beneficio o que a sabiendas presente información falsa en una solicitud de seguro es culpable de un delito y puede estar sujeta a multas de restitución o reclusión en prisión, o cualquier combinación de ambas.',
          };
        default:
          return defaultAdditionalFraudDisclosure;
      }
    case 'CA':
      return {
        title: 'California state-specific fraud disclosure',
        text: 'For your protection California law requires the following to appear on this form. Any person who knowingly presents false or fraudulent information to obtain or amend insurance coverage or to make a claim for the payment of a loss is guilty of a crime and may be subject to fines and confinement in state prison.',
        spanishText:
          'Para su protección, la ley de California exige que aparezca lo siguiente en este formulario. Cualquier persona que a sabiendas presente información falsa o fraudulenta para obtener o modificar la cobertura del seguro o para presentar un reclamo por el pago de una pérdida es culpable de un delito y puede estar sujeta a multas y reclusión en una prisión estatal.',
      };
    case 'DC':
      switch (lob) {
        case 'LINE_OF_BUSINESS.HOME':
        case 'LINE_OF_BUSINESS.RENTERS':
        case 'LINE_OF_BUSINESS.BUNDLE':
        case 'LINE_OF_BUSINESS.BUNDLE_AUTO_RENTERS':
          return {
            title: 'District of Columbia state-specific fraud disclosure',
            text: 'Your premium and eligibility are based on information provided. WARNING: it is a crime to provide false or misleading information to an insurer for the purpose of defrauding the insurer or any other person. Penalties include imprisonment and/or fines. In addition, an insurer may deny insurance benefits if false information materially related to a claim was provided by the applicant.',
            spanishText:
              'Su prima y elegibilidad se basan en la información proporcionada. ADVERTENCIA: es un delito proporcionar información falsa o engañosa a una aseguradora con el propósito de defraudar a la aseguradora o a cualquier otra persona. Las sanciones incluyen prisión y/o multas. Además, una aseguradora puede denegar los beneficios del seguro si el solicitante proporcionó información falsa materialmente relacionada con un reclamo.',
          };
        default:
          return defaultAdditionalFraudDisclosure;
      }
    case 'FL':
      switch (lob) {
        case 'LINE_OF_BUSINESS.HOME':
        case 'LINE_OF_BUSINESS.RENTERS':
        case 'LINE_OF_BUSINESS.BUNDLE':
        case 'LINE_OF_BUSINESS.BUNDLE_AUTO_RENTERS':
          return {
            title: 'Florida state-specific fraud disclosure',
            text: 'Any person who knowingly and with intent to injure, defraud, or deceive any insurer files a statement of claim or an application containing any false, incomplete, or misleading information is guilty of a felony of the third degree.',
            spanishText:
              'Cualquier persona que, a sabiendas y con la intención de dañar, defraudar o engañar a cualquier aseguradora, presente una declaración de reclamo o una solicitud que contenga información falsa, incompleta o engañosa es culpable de un delito grave de tercer grado.',
          };
        default:
          return defaultAdditionalFraudDisclosure;
      }
    case 'KY':
      switch (lob) {
        case 'LINE_OF_BUSINESS.HOME':
        case 'LINE_OF_BUSINESS.RENTERS':
        case 'LINE_OF_BUSINESS.BUNDLE':
        case 'LINE_OF_BUSINESS.BUNDLE_AUTO_RENTERS':
          return {
            title: 'Kentucky state-specific fraud disclosure',
            text: 'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or who knowingly presents false information in an application for insurance is guilty of a crime and may be subject to restitution fines or confinement in prison, or any combination thereof.',
            spanishText:
              'Toda persona que a sabiendas presente una reclamación falsa o fraudulenta para el pago de una pérdida o beneficio o que a sabiendas presente información falsa en una solicitud de seguro es culpable de un delito y puede estar sujeta a multas de restitución o reclusión en prisión, o cualquier combinación de ambas.',
          };
        default:
          return defaultAdditionalFraudDisclosure;
      }
    case 'LA':
      switch (lob) {
        case 'LINE_OF_BUSINESS.HOME':
        case 'LINE_OF_BUSINESS.RENTERS':
        case 'LINE_OF_BUSINESS.BUNDLE':
        case 'LINE_OF_BUSINESS.BUNDLE_AUTO_RENTERS':
          return {
            title: 'Louisiana state-specific fraud disclosure',
            text: 'Your premium and eligibility are based on information provided. Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.',
            spanishText:
              'Su prima y elegibilidad se basan en la información proporcionada. Toda persona que a sabiendas presente una reclamación falsa o fraudulenta para el pago de una pérdida o beneficio o a sabiendas presente información falsa en una solicitud de seguro es culpable de un delito y puede estar sujeta a multas y reclusión en prisión.',
          };
        default:
          return defaultAdditionalFraudDisclosure;
      }
    case 'NJ':
      switch (lob) {
        case 'LINE_OF_BUSINESS.HOME':
        case 'LINE_OF_BUSINESS.RENTERS':
        case 'LINE_OF_BUSINESS.BUNDLE':
        case 'LINE_OF_BUSINESS.BUNDLE_AUTO_RENTERS':
          return {
            title: 'New Jersey state-specific fraud disclosure',
            text: 'Your premium and eligibility are based on information provided. Any person who includes any false or misleading information on an application for an insurance policy is subject to criminal and civil penalties.',
            spanishText:
              'Su prima y elegibilidad se basan en la información proporcionada. Toda persona que incluya información falsa o engañosa en una solicitud de póliza de seguro está sujeta a sanciones penales y civiles.',
          };
        default:
          return defaultAdditionalFraudDisclosure;
      }
    case 'OH':
      switch (lob) {
        case 'LINE_OF_BUSINESS.HOME':
        case 'LINE_OF_BUSINESS.RENTERS':
        case 'LINE_OF_BUSINESS.BUNDLE':
        case 'LINE_OF_BUSINESS.BUNDLE_AUTO_RENTERS':
          return {
            title: 'Ohio state-specific fraud disclosure',
            text: 'Any person who, with intent to defraud or knowing that they are facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement is guilty of insurance fraud.',
            spanishText:
              'Toda persona que, con la intención de defraudar o a sabiendas de que está facilitando un fraude contra un asegurador, presente una solicitud o presente un reclamo que contenga una declaración falsa o engañosa es culpable de fraude de seguros.',
          };
        default:
          return defaultAdditionalFraudDisclosure;
      }
    case 'OR':
      switch (lob) {
        case 'LINE_OF_BUSINESS.HOME':
        case 'LINE_OF_BUSINESS.RENTERS':
        case 'LINE_OF_BUSINESS.BUNDLE':
        case 'LINE_OF_BUSINESS.BUNDLE_AUTO_RENTERS':
          return {
            title: 'Oregon state-specific fraud disclosure',
            text: 'Any person who, with intent to defraud or knowing that they are facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement is guilty of insurance fraud.',
            spanishText:
              'Toda persona que, con la intención de defraudar o a sabiendas de que está facilitando un fraude contra un asegurador, presente una solicitud o presente un reclamo que contenga una declaración falsa o engañosa es culpable de fraude de seguros.',
          };
        default:
          return defaultAdditionalFraudDisclosure;
      }
    case 'TN':
      switch (lob) {
        case 'LINE_OF_BUSINESS.HOME':
        case 'LINE_OF_BUSINESS.RENTERS':
        case 'LINE_OF_BUSINESS.BUNDLE':
        case 'LINE_OF_BUSINESS.BUNDLE_AUTO_RENTERS':
          return {
            title: 'Tennessee state-specific fraud disclosure',
            text: 'Your premium and eligibility are based on information provided. It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.',
            spanishText:
              'Su prima y elegibilidad se basan en la información proporcionada. Es un delito proporcionar a sabiendas información falsa, incompleta o engañosa a una compañía de seguros con el fin de defraudar a la compañía. Las sanciones incluyen encarcelamiento, multas y denegación de beneficios de seguro.',
          };
        default:
          return defaultAdditionalFraudDisclosure;
      }
    case 'VA':
      return {
        title: 'Virginia state-specific fraud disclosure',
        text: 'Your premium and eligibility are based on information provided. It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.',
        spanishText:
          'Su prima y elegibilidad se basan en la información proporcionada. Es un delito proporcionar información falsa, incompleta o engañosa a sabiendas a una compañía de seguros con el fin de defraudar a la compañía. Las sanciones incluyen encarcelamiento, multas y denegación de los beneficios del seguro.',
      };
    case 'WA':
      switch (lob) {
        case 'LINE_OF_BUSINESS.AUTO':
        case 'LINE_OF_BUSINESS.BUNDLE':
        case 'LINE_OF_BUSINESS.BUNDLE_AUTO_RENTERS':
          return {
            title: 'Washington state-specific fraud disclosure',
            text: 'Your premium and eligibility are based on information provided. It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.',
            spanishText:
              'Su prima y elegibilidad se basan en la información proporcionada. Es un delito proporcionar información falsa, incompleta o engañosa a sabiendas a una compañía de seguros con el fin de defraudar a la compañía. Las sanciones incluyen encarcelamiento, multas y denegación de los beneficios del seguro.',
          };
        default:
          return defaultAdditionalFraudDisclosure;
      }
    case 'WV':
      switch (lob) {
        case 'LINE_OF_BUSINESS.HOME':
        case 'LINE_OF_BUSINESS.RENTERS':
        case 'LINE_OF_BUSINESS.BUNDLE':
        case 'LINE_OF_BUSINESS.BUNDLE_AUTO_RENTERS':
          return {
            title: 'West Virginia state-specific fraud disclosure',
            text: 'Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.',
            spanishText:
              'Toda persona que a sabiendas presente una reclamación falsa o fraudulenta para el pago de una pérdida o beneficio o a sabiendas presente información falsa en una solicitud de seguro es culpable de un delito y puede estar sujeta a multas y reclusión en prisión',
          };
        default:
          return defaultAdditionalFraudDisclosure;
      }
    default:
      return defaultAdditionalFraudDisclosure;
  }
};
