import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'VehicleDiscounts' })((theme) => ({
  label: {
    ...theme.typography.body1Bold,
    display: 'flex',
    alignItems: 'center',
    marginTop: -5,
    height: 30,
  },
  labelImage: {
    width: 50,
    marginRight: 12,
  },
  vehicleDiscountCheckBoxGroup: {
    '&:not(:first-child)': {
      marginTop: 40,
    },
  },
  container: {
    border: `1px solid ${theme.palette.other.border}`,
    borderRadius: 4,
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
  },
}));
