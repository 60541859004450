import type { MutableRefObject } from 'react';
import { useCallback } from 'react';

import type { TypographyVariant } from '@mui/material';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Typography,
} from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { emptyArray, isKeyOf, isType } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';
import { isHTMLElementWithValue } from '@ecp/utils/web';

import { AutoAdjustableGrid, ShowMoreOrLess, TooltipWithIcon } from '@ecp/components';
import type {
  AutoAdjustableGridProps,
  CardFormControlLabel,
  ShowMoreOrLessProps,
} from '@ecp/components';
import { useFieldRef } from '@ecp/features/sales/form';
import type { CardOption } from '@ecp/types';

import type { AddMoreProps } from '../AddMore';
import { AddMore } from '../AddMore';
import { Checkbox } from './Checkbox';
import { useStyles } from './CheckboxGroup.styles';

export type NoPatchProps = Pick<
  React.ComponentProps<typeof CardFormControlLabel>,
  'classes' | 'className'
> & {
  name: string;
  cardSize?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  error?: string;
  helperText?: string;
  label?: React.ReactNode;
  message?: React.ReactNode;
  /** @deprecated Remove after migrating driver/vehicle cards to the new implementation which is independent from CheckboxGroup. */
  messageClassName?: string;
  actionOnComplete: (value: string[], valueClicked?: string, newChecked?: boolean) => void;
  actionOnEdit?: (ref: string) => void;
  actionOnDelete?: (ref: string) => void;
  options?: CardOption[];
  mutuallyExclusiveOptions?: NoPatchProps['values'][];
  mutuallyDisabledOptions?: NoPatchProps['values'][];
  mutuallyDisabledOptionInfoTip?: string;
  showMoreOrLessProps?: ShowMoreOrLessProps;
  addMoreProps?: AddMoreProps;
  adjustableGrid?: boolean;
  adjustableGridProps?: AutoAdjustableGridProps;
  values: string[];
  variant?: 'classic' | 'card' | 'editCard' | 'classicCompact' | 'horizontalCard' | 'iconCard';
  trackingName?: string;
  dataTestIdPrefix?: string;
  allOptions?: CardOption[];
  customVariant?: TypographyVariant | 'inherit';
  labelClassName?: string;
  prevStatusRef: MutableRefObject<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [k: string]: any;
  }>;
  forceUpdateParentComponent: () => void;
};

export const CheckboxNoPatchGroup: React.FC<NoPatchProps> = (props) => {
  const {
    name,
    cardSize = 'small',
    className,
    disabled,
    error,
    helperText,
    label,
    message,
    messageClassName,
    actionOnComplete,
    options = emptyArray,
    mutuallyExclusiveOptions = emptyArray,
    mutuallyDisabledOptions = emptyArray,
    showMoreOrLessProps,
    addMoreProps,
    adjustableGrid = false,
    adjustableGridProps,
    values = emptyArray,
    variant: variantProp = 'classic',
    trackingName,
    dataTestIdPrefix = '',
    allOptions,
    customVariant = 'body4',
    prevStatusRef,
    forceUpdateParentComponent,
  } = props;
  const cardCount = options.length + Number(!!showMoreOrLessProps) + Number(!!addMoreProps);
  const { classes, cx } = useStyles({ ...props, cardCount });
  const convertedMutuallyExclusiveOptions = mutuallyExclusiveOptions.map((el) => el.map(String));
  const convertedMutuallyDisabledOptions = mutuallyDisabledOptions.map((el) => el.map(String));

  const ref = useFieldRef(name);

  // auto-detect 'card' variant
  const variant =
    options[0] &&
    (options[0].icon || options[0].image || options[0].iconProduct) &&
    variantProp !== 'editCard' &&
    variantProp !== 'horizontalCard' &&
    variantProp !== 'iconCard'
      ? 'card'
      : variantProp;

  const onValueChanged = useCallback(
    (selectedValue: string, newChecked: boolean) => {
      let updatedValues = values.map(String);
      const gaLabel: Record<string, string> = {};
      if (updatedValues.includes(selectedValue)) {
        updatedValues.splice(updatedValues.indexOf(selectedValue), 1);
      }
      if (newChecked) {
        updatedValues = convertedMutuallyExclusiveOptions.reduce(
          (acc, option) => {
            let newValues = [...acc];
            if (option.includes(selectedValue)) {
              newValues = newValues.filter((value) => !option.includes(value));
            }

            return newValues;
          },
          [...updatedValues],
        );
        updatedValues.push(selectedValue);
      }

      actionOnComplete(updatedValues, selectedValue, newChecked);
      const prevCheckValue = prevStatusRef.current.filter(
        (item: { key: string | string[] }) => item.key.indexOf(selectedValue) !== -1,
      );
      if (prevCheckValue[0].value !== newChecked) {
        forceUpdateParentComponent();
      }
      if (trackingName) {
        const trackingOptions = allOptions || options;
        trackingOptions.forEach((item) => {
          if (updatedValues.includes(item.value)) {
            gaLabel[item.value] = '1';
          } else {
            gaLabel[item.value] = '0';
          }
        });
        trackClick({ action: trackingName, label: JSON.stringify(gaLabel) });
      }
    },
    [
      actionOnComplete,
      allOptions,
      convertedMutuallyExclusiveOptions,
      forceUpdateParentComponent,
      options,
      prevStatusRef,
      trackingName,
      values,
    ],
  );
  const handleChange = useCallback(
    (event: React.ChangeEvent<unknown>, newChecked: boolean) => {
      if (
        !isType(event.currentTarget, HTMLElement) ||
        !isHTMLElementWithValue(event.currentTarget)
      ) {
        datadogLog({
          logType: 'error',
          message: 'Not a valid event target for Checkbox',
          context: {
            logOrigin:
              'libs/features/sales/shared/components/src/CheckboxGroup/CheckboxNoPatchGroup.tsx',
            functionOrigin: 'handleChange',
          },
        });
        throw new Error('Not a valid event target for Checkbox');
      }
      onValueChanged(event.currentTarget.value, newChecked);
    },
    [onValueChanged],
  );

  const isCard = variant === 'card' || variant === 'editCard' || variant === 'iconCard';
  const isClassic = variant === 'classic' || variant === 'classicCompact';
  const formControlLabels = options.map((option, index) => {
    const idForLabel = name ? `${name}-${option.value}` : option.value;

    // Attach ref only to the first control component
    // To avoid confusion later when setting focus on it or scrolling to it in getValidateForm
    const inputRef = index === 0 ? ref : undefined;
    const checked = values.map(String).includes(String(option.value));
    const isMutuallyDisabled = convertedMutuallyDisabledOptions.some((pairedOptions) => {
      if (pairedOptions.includes(option.value)) {
        const mututalOption = pairedOptions.find((value: string) => value !== option.value);

        return values.map(String).includes(mututalOption);
      }

      return false;
    });
    const isDisabled = disabled || option.disabled || isMutuallyDisabled;

    // use classic
    return (
      <FormControlLabel
        control={
          <Checkbox
            data-testid={option.testId}
            id={idForLabel}
            className={classes.classicCheckbox}
          />
        }
        classes={{
          root: classes.classicRoot,
          label: variant === 'classicCompact' ? classes.classicCompactLabel : classes.classicLabel,
        }}
        checked={checked}
        disabled={isDisabled}
        htmlFor={idForLabel}
        inputRef={inputRef}
        key={idForLabel}
        name={name}
        label={
          <>
            <span className={classes.classicCheckbox}>{option.label}</span>
            {option.helpText && <TooltipWithIcon title={option.helpText} />}
          </>
        }
        value={option.value}
        className={classes.formControl}
        onChange={handleChange}
      />
    );
  });

  if (showMoreOrLessProps) {
    formControlLabels.push(
      <ShowMoreOrLess
        cardSize={cardSize !== 'large' ? cardSize : undefined}
        {...showMoreOrLessProps}
        key='showMoreLess'
      />,
    );
  }
  if (addMoreProps) {
    formControlLabels.push(<AddMore {...addMoreProps} key='addMore' />);
  }

  return (
    <FormControl
      component='fieldset'
      className={cx(
        classes.root,
        isCard && classes.card,
        variant === 'classic' && classes.classic,
        className,
      )}
      error={!!error}
      data-testid={dataTestIdPrefix}
    >
      <FormLabel
        component='legend'
        focused={false}
        className={classes.label}
        id={name && `${name}-label`}
      >
        <Typography variant={customVariant}>{label || name}</Typography>
        {helperText && (
          <FormHelperText className={classes.subLabel} error={false}>
            {helperText}
          </FormHelperText>
        )}
      </FormLabel>
      <p className={messageClassName}>{message}</p>
      <FormGroup
        row={!isClassic}
        className={cx(classes.group, isKeyOf(variant, classes) && classes[variant])}
      >
        {adjustableGrid ? (
          <AutoAdjustableGrid spacing={10} {...adjustableGridProps}>
            {formControlLabels}
          </AutoAdjustableGrid>
        ) : (
          formControlLabels
        )}
      </FormGroup>
      {error && (
        <FormHelperText
          className={variant === 'classicCompact' ? classes.listError : classes.errorText}
          role='alert'
        >
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};
