import type { FieldCardOption } from '@ecp/features/sales/shared/types';
import {
  IconCard5mphBumper,
  IconCardAntiLockBreaks,
  IconCardBusinessOccupation,
  IconCardCommuteLess,
  IconCardCommuteMore,
  IconCardCommuteToWorkSchool,
  IconCardDaytimeRunningLights,
  IconCardElectronicStabilityControl,
  IconCardFarming,
  IconCardFrontAirBag,
  IconCardPassiveSeatBelt,
  IconCardSideAirBag,
  IconCardVacationPleasure,
} from '@ecp/themes/base';
import type { CardOption } from '@ecp/types';

export const PrimaryUseOptions: CardOption[] = [
  {
    label: 'Vacation/pleasure',
    value: 'VEHICLE_USE.PRIMARY.PLEASURE',
    icon: <IconCardVacationPleasure />,
    helpText: 'No driving to work/school.',
  },
  {
    label: 'Commute to work/school',
    value: 'VEHICLE_USE.PRIMARY.COMMUTE',
    icon: <IconCardCommuteToWorkSchool />,
    helpText: 'Used to drive to work/school.',
  },
  {
    label: 'Work less than 10 miles',
    value: 'VEHICLE_USE.PRIMARY.COMMUTE_LESS_THAN_10_MILES',
    icon: <IconCardCommuteLess />,
    stateOptions: {
      NC: {
        label: 'Commute less than 10 miles',
      },
    },
  },
  {
    label: 'Work 10+ miles',
    value: 'VEHICLE_USE.PRIMARY.COMMUTE_MORE_THAN_10_MILES',
    icon: <IconCardCommuteMore />,
    stateOptions: {
      NC: {
        label: 'Commute 10+ miles',
      },
    },
  },
  {
    label: 'Business/occupation',
    value: 'VEHICLE_USE.PRIMARY.BUSINESS',
    icon: <IconCardBusinessOccupation />,
    helpText:
      "Used in the insured's business, occupation or profession, other than driving to and from work.",
  },
  {
    label: 'Farming',
    value: 'VEHICLE_USE.PRIMARY.FARMING',
    icon: <IconCardFarming />,
    helpText: 'A truck used year-round in a family farming operation.',
  },
];

export const SafetyFeatureOptions: FieldCardOption[] = [
  {
    fieldName: 'antiLockBrakes',
    value: 'FEATURES.SAFETY.ANTI_LOCK_BRAKES', // only for frontend use
    label: 'Anti-lock brakes',
    icon: <IconCardAntiLockBreaks />,
    helpText:
      'ABS operates by preventing the wheels from locking up during braking, thereby maintaining traction with the road surface.',
  },
  {
    fieldName: 'dayTimeRunningLights',
    value: 'FEATURES.SAFETY.DAYTIME_RUNNING_LIGHTS', // only for frontend use
    label: 'Daytime running lights',
    icon: <IconCardDaytimeRunningLights />,
    helpText:
      'Lights on the front of a motor vehicle, automatically switched on when the vehicle is in drive.',
  },
  {
    fieldName: 'electronicStabilityControl',
    value: 'FEATURES.SAFETY.ELECTRONIC_STABILITY_CONTROLS', // only for frontend use
    label: 'Electronic stability controls',
    icon: <IconCardElectronicStabilityControl />,
    helpText:
      "A computerized technology that improves a vehicle's stability by detecting and reducing loss of traction.",
  },
  {
    fieldName: 'passiveSeatbelt',
    value: 'FEATURES.SAFETY.PASSIVE_SEATBELT',
    label: 'Passive seat belt restraint',
    icon: <IconCardPassiveSeatBelt />,
    helpText: 'You may qualify for a discount for driving a vehicle with passive seatbelts.',
  },
  {
    fieldName: 'fiveMphBumper',
    value: 'FEATURES.SAFETY.FIVE_MPH_BUMPER',
    label: '5 mile per hour bumper',
    icon: <IconCard5mphBumper />,
  },
  // airbag fields used what is returned from the backend, not that from the mockup. Mockup mismatch backend.
  {
    fieldName: 'airbag',
    value: 'FEATURES.SAFETY.DRIVER_AIRBAG',
    label: 'Driver airbags',
    icon: <IconCardFrontAirBag />,
    helpText:
      "Airbags designed to inflate to prevent a driver's head and chest from contacting hard structures in the vehicle.",
  },
  {
    fieldName: 'airbag',
    value: 'FEATURES.SAFETY.DRIVER_PASSENGER_AIRBAG',
    label: 'Driver & passenger airbags',
    icon: <IconCardFrontAirBag />,
    helpText:
      "Airbags designed to inflate to prevent a driver or passenger's head and chest from contacting hard structures in the vehicle.",
  },
  {
    fieldName: 'airbag',
    value: 'FEATURES.SAFETY.DRIVER_PASSENGER_SIDE_AIRBAG',
    label: 'Driver, passenger & side airbags',
    icon: <IconCardSideAirBag />,
    helpText:
      "Front airbags are designed to inflate to prevent a person's head and chest from contacting hard structures in the vehicle. Side-impact airbags are usually located in the seat or door panel, and inflate between the seat occupant and the door.",
  },
];

export const antiTheftDiscountsOptions: FieldCardOption[] = [
  {
    fieldName: 'alarm',
    value: 'features.antitheft.alarm',
    label: 'Alarm',
    helpText:
      'An alarm produces a sound that can be heard at least 300 feet away for a minimum of 3 minutes.',
  },
  {
    fieldName: 'active',
    value: 'features.antitheft.active',
    label: 'Active',
    helpText: 'An active anti-theft device is one that the driver has to manually activate.',
  },
  {
    fieldName: 'passive',
    value: 'features.antitheft.passive',
    label: 'Passive',
    helpText:
      'A passive anti-theft device is automatically activated when the vehicle key is removed and all doors are locked.',
  },
  {
    fieldName: 'vehicleRecoverySystem',
    value: 'features.antitheft.vehicleRecoverySystem',
    label: 'Vehicle recovery system',
    helpText:
      'A vehicle recovery system, when activated, provides information regarding the location of the vehicle.',
  },
  {
    fieldName: 'windowEtching',
    value: 'features.antitheft.windowEtching',
    label: 'Window etching',
    helpText:
      'A window etching is a permanent engraving of the vehicle’s VIN number onto the windshield and windows of the vehicle.',
  },
  {
    fieldName: 'category1',
    value: 'features.antitheft.category1',
    label: 'Category I',
  },
  {
    fieldName: 'category2',
    value: 'features.antitheft.category2',
    label: 'Category II',
  },
  {
    fieldName: 'category3',
    value: 'features.antitheft.category3',
    label: 'Category III',
  },
  {
    fieldName: 'category4',
    value: 'features.antitheft.category4',
    label: 'Category IV',
  },
];
