import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AgentLoginPage' })((theme) => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',

    [theme.breakpoints.down('lg')]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  container: {
    border: `solid ${theme.palette.grey[50]} 4px`,
    borderRadius: 20,
    overflow: 'hidden',
    backgroundColor: theme.palette.grey[50],
    width: '100%',
    marginTop: 100,
    maxWidth: 1080,
  },

  form: {
    padding: '128px 62px',
    [theme.breakpoints.down('xl')]: {
      padding: '114px 37px',
    },
    [theme.breakpoints.down('lg')]: {
      padding: '66px 25px',
    },
    [theme.breakpoints.down('md')]: {
      padding: '33px 16px',
    },
  },
  logoContainer: {
    backgroundColor: theme.palette.grey[900],
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  appLogo: {
    ...theme.mixins.setColorSvg('white'),
    width: '80%',
    height: 'auto',
    paddingBottom: 10,
  },
  pageBackground: {
    backgroundColor: theme.palette.grey[900],
  },
}));
