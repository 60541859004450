import { LineOfBusiness } from '@ecp/features/shared/product';

import baseMetadata from './metadata.js';
import type { Metadata } from './types';
import { convertArrayOfUnbundledLobTuplesToArrayOfProductNamesWithStaticPrefixTuples } from './util';

const mutuallyExclusiveOptions: Metadata['mutuallyExclusiveOptions'] = [
  [LineOfBusiness.HOME, LineOfBusiness.RENTERS],
  [LineOfBusiness.AUTO, LineOfBusiness.RENTERS],
];

const mutuallyDisabledOptions: Metadata['mutuallyDisabledOptions'] = [
  [LineOfBusiness.AUTO, LineOfBusiness.RENTERS],
];

const mutuallyExclusiveOptionsWithStaticPrefix: Metadata['mutuallyExclusiveOptionsWithStaticPrefix'] =
  convertArrayOfUnbundledLobTuplesToArrayOfProductNamesWithStaticPrefixTuples(
    mutuallyExclusiveOptions,
  );

const mutuallyDisabledOptionsWithStaticPrefix: Metadata['mutuallyDisabledOptionsWithStaticPrefix'] =
  convertArrayOfUnbundledLobTuplesToArrayOfProductNamesWithStaticPrefixTuples(
    mutuallyDisabledOptions,
  );

const metadata: Metadata = {
  ...baseMetadata,
  mutuallyExclusiveOptions,
  mutuallyDisabledOptions,
  mutuallyExclusiveOptionsWithStaticPrefix,
  mutuallyDisabledOptionsWithStaticPrefix,
};

export default metadata;
