import type { GeoAddress } from '@ecp/utils/geo';

import { GridItem } from '@ecp/components';
import { PRIMARY_INSURED_PERSON_LOCK } from '@ecp/features/sales/shared/constants';
import {
  AddressAutoCompleteQuestion,
  EmailQuestion,
  FirstNameQuestion,
  LastNameQuestion,
  MiddleNameQuestion,
  PhoneNumberQuestion,
  SuffixQuestion,
} from '@ecp/features/sales/shared/questions';
import { useField, useFieldWithPrefix, usePniRef } from '@ecp/features/sales/shared/store';
import { LineOfBusiness } from '@ecp/features/shared/product';

import metadata from './metadata';
import { useStyles } from './PersonForm.styles';

const getDynamicAddressLabel = (lineOfBusiness: LineOfBusiness): string => {
  switch (lineOfBusiness) {
    case LineOfBusiness.AUTO:
      return 'Where are your vehicles kept?';
    case LineOfBusiness.HOME:
      return 'What’s your address?';
    case LineOfBusiness.BUNDLE:
    default:
      return 'What’s your property address?';
  }
};

interface Props {
  setGeoAddressSuggestions(value: GeoAddress[]): void;
  geoAddressSuggestions: GeoAddress[];
  lineOfBusiness: LineOfBusiness;
  emailHelpText?: string;
}

export const PersonFormQuestions: React.FC<Props> = (props) => {
  const { lineOfBusiness, setGeoAddressSuggestions, geoAddressSuggestions, emailHelpText } = props;
  const { classes } = useStyles();

  const pniRef = usePniRef();
  const primaryInsuredPersonLock = useField(PRIMARY_INSURED_PERSON_LOCK);
  const usePersonField = useFieldWithPrefix(pniRef, 'person.<id>');
  const middleName = usePersonField('middleName');
  const suffix = usePersonField('suffix');
  const addressLabel = getDynamicAddressLabel(lineOfBusiness);

  return (
    <>
      <GridItem xs={12}>
        <p className={classes.subHeader}>What's your name?</p>
      </GridItem>
      <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
        <FirstNameQuestion personRef={pniRef} disabled={!!primaryInsuredPersonLock.props.value} />
      </GridItem>
      {middleName.exists && (
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
          <MiddleNameQuestion
            personRef={pniRef}
            disabled={!!primaryInsuredPersonLock.props.value}
          />
        </GridItem>
      )}
      <GridItem
        topSpacing='sm'
        xs={12}
        md={6}
        className={middleName.exists ? classes.columnLeft : classes.columnRight}
      >
        <LastNameQuestion personRef={pniRef} disabled={!!primaryInsuredPersonLock.props.value} />
      </GridItem>
      {suffix.exists && (
        <GridItem
          topSpacing='sm'
          xs={12}
          md={6}
          className={middleName.exists ? classes.columnRight : classes.columnLeft}
        >
          <SuffixQuestion personRef={pniRef} disabled={!!primaryInsuredPersonLock.props.value} />
        </GridItem>
      )}
      <GridItem topSpacing='lg' xs={12}>
        <p className={classes.subHeader}>{addressLabel}</p>
      </GridItem>
      <GridItem xs={12}>
        <AddressAutoCompleteQuestion
          setGeoAddressSuggestions={setGeoAddressSuggestions}
          geoAddressSuggestions={geoAddressSuggestions}
        />
      </GridItem>
      {!metadata.hideContactFields && (
        <>
          <GridItem topSpacing='lg' xs={12}>
            <p className={classes.subHeader}>What's your contact info?</p>
          </GridItem>
          <GridItem topSpacing='sm' md={6} xs={12} className={classes.columnLeft}>
            <EmailQuestion
              personRef={pniRef}
              disabled={!!primaryInsuredPersonLock.props.value}
              helperText={emailHelpText}
            />
          </GridItem>
          <GridItem topSpacing='sm' md={6} xs={12} className={classes.columnRight}>
            <PhoneNumberQuestion
              personRef={pniRef}
              disabled={!!primaryInsuredPersonLock.props.value}
              label='Phone (optional)'
            />
          </GridItem>
        </>
      )}
    </>
  );
};
