import type { Metadata } from './metadata.js';
import baseMetadata from './metadata.js';

const metadata: Metadata = {
  ...baseMetadata,
  shouldRenderSubTitle: true,
  shouldRenderIcon: true,
  showWelcomeTitle: true,
};

export default metadata;
