import type { Metadata } from './metadata.js';
import baseMetadata from './metadata.js';

const metadata: Metadata = {
  ...baseMetadata,
  authorizationText:
    'By accepting, you are indicating that the information provided above is true and correct, and that you authorize General Motors Insurance Services to share your information with Midvale Indemnity Company in connection with your application for an insurance quote. This information may include telematics data relating to your driving behavior which may be utilized in the rating of your policy.',
};

export default metadata;
