import { useCallback, useState } from 'react';

import { Button, GridItem, RadioGroupWithOptions } from '@ecp/components';
import { IconUIExpandLess, IconUIExpandMore } from '@ecp/themes/base';
import type { AnswerValue } from '@ecp/types';

import { useStyles } from './AgentScriptBox.styles';
import type { AgentScripts } from './metadata/types';
import { renderFragmentOrPelement } from './util';

interface AgentScriptContentProps {
  agentScript: AgentScripts;
  includeDisclaimerButton?: boolean;
}

export const AgentScriptContent: React.FC<AgentScriptContentProps> = ({
  agentScript,
  includeDisclaimerButton = false,
}): React.ReactElement | null => {
  const { title, text, spanishText } = agentScript;
  const { classes } = useStyles();

  const [showTranslationText, setShowTranslationText] = useState(false);

  const [booleanValue, setBooleanValue] = useState<AnswerValue>('No');

  const showDisclaimerButton = includeDisclaimerButton ? (
    <RadioGroupWithOptions
      className={classes.yesOrNoQuestion}
      value={booleanValue}
      actionOnChange={setBooleanValue}
      options={[
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' },
      ]}
      variant='buttonCard'
      trackingName={`agent_yes_or_no_${title}`}
      trackingLabel={`${booleanValue}`}
    />
  ) : null;

  const handleTranslationClick = useCallback(() => {
    setShowTranslationText(showTranslationText ? false : true);
  }, [showTranslationText]);

  if (!Object.keys(agentScript).length) return null;

  const contentSection = (
    <>
      {renderFragmentOrPelement(text)}
      {spanishText && (
        <>
          <Button
            className={classes.translationText}
            variant='iconTextLarge'
            iconLast
            onClick={handleTranslationClick}
            icon={showTranslationText ? <IconUIExpandLess /> : <IconUIExpandMore />}
          >
            {showTranslationText ? 'Hide Spanish version' : 'View Spanish version'}
          </Button>

          {showTranslationText && renderFragmentOrPelement(spanishText)}
        </>
      )}
    </>
  );

  const getScriptContent = (): JSX.Element | null => {
    return includeDisclaimerButton && booleanValue === 'No' ? null : contentSection;
  };

  return (
    <GridItem className={classes.scriptContent} lg={12} md={12} sm={12} topSpacing='xs'>
      {title && <h2 className={classes.scriptContentHeader}>{title}</h2>}
      {showDisclaimerButton}
      {getScriptContent()}
    </GridItem>
  );
};
