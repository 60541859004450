import { Dialog, TextItem } from '@ecp/features/sales/shared/components';
import {
  getPrimaryInsuredStateCode,
  getStateSpecificAntiTheftCategoryMetadata,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { AntiTheftCategoryMetadata } from '../../../../metadata';
import { useStyles } from './AntiTheftDevicesDialog.styles';

interface Props {
  open: boolean;
  onClose(): void;
}

export const AntiTheftDevicesDialog: React.FC<Props> = (props) => {
  const { open = false, onClose } = props;
  const { classes } = useStyles();
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const stateData = getStateSpecificAntiTheftCategoryMetadata(AntiTheftCategoryMetadata, stateCode);

  return (
    <Dialog
      className={classes.root}
      actionButtonLabel='Close'
      titleText='Anti theft devices'
      open={open}
      onClose={onClose}
      buttonPlacement='right'
    >
      <p>Does this vehicle have any of the following anti-theft devices?</p>
      {Object.keys(stateData).map((key, index) => {
        const { title, description } = stateData[key];

        return description && <TextItem key={index} title={title} description={description} />;
      })}
    </Dialog>
  );
};
