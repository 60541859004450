import { Grid } from '@mui/material';

import { useField } from '@ecp/features/sales/shared/store';

import { AwayAtSchoolSubQuestions } from '../AwayAtSchoolSubQuestions';
import { CollegeDegreeDiscountsQuestion } from '../CollegeDegreeQuestion';
import { DefensiveDriverDiscountsQuestion } from '../DefensiveDriverQuestion';
import { GmEmployeeQuestion } from '../GmEmployeeQuestion';
import { useStyles } from './DiscountsSubQuestions.styles';

interface Props {
  driverRef: string;
  isContinueClicked?: boolean;
}

export const DiscountsSubQuestions: React.FC<Props> = (props) => {
  const { driverRef, isContinueClicked } = props;
  const { classes } = useStyles();
  const defensiveDriverCourse = useField(`${driverRef}.discount.defensiveDriverCourse`);
  const awayAtSchool = useField(`${driverRef}.discount.awayAtSchool`);
  const collegeDegreeDiscount = useField(`${driverRef}.discount.collegeDegree`);
  const gmEmployeeDiscount = useField(`${driverRef}.discount.gmEmployee`);
  const defensiveDriverDiscount = useField(`${driverRef}.discount.defensiveDriver`);

  const shouldDisplayGrid = Boolean(
    awayAtSchool.value ||
      defensiveDriverCourse.value ||
      collegeDegreeDiscount.value ||
      gmEmployeeDiscount.value ||
      awayAtSchool.value ||
      defensiveDriverDiscount.value,
  );

  return (
    <Grid
      className={shouldDisplayGrid ? classes.root : undefined}
      container
      spacing={shouldDisplayGrid ? 2.5 : 0}
      key={driverRef}
    >
      {awayAtSchool.value && <AwayAtSchoolSubQuestions driverRef={driverRef} />}
      <DefensiveDriverDiscountsQuestion driverRef={driverRef} />
      <CollegeDegreeDiscountsQuestion driverRef={driverRef} isContinueClicked={isContinueClicked} />
      <GmEmployeeQuestion driverRef={driverRef} />
    </Grid>
  );
};
