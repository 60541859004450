import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'QuoteHO2Prompt' })((theme) => ({
  root: {},
  container: {
    ...theme.mixins.form,
    textAlign: 'center',
    marginBottom: 30,
    marginTop: 30,
  },
  pageTitle: theme.typography.h1,
  pageSubTitle: {
    ...theme.typography.h2,
    marginTop: 10,
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {},
  },
  guidance: {
    ...theme.typography.body1,
    marginBottom: 20,
    [theme.breakpoints.down('md')]: {
      marginBottom: 10,
    },
  },
  lastGuidance: {
    ...theme.typography.body1,
    marginBottom: 30,
    [theme.breakpoints.down('md')]: {
      marginBottom: 10,
    },
  },
  next: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      fontSize: '1rem',
    },
  },
}));
