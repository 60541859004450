import { useCallback, useRef, useState } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { isMasked } from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { useEvent } from '@ecp/utils/react';

import { LogoSpinner } from '@ecp/components';
import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import { Button, Form, NextPageInstructions } from '@ecp/features/sales/shared/components';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import {
  submitProofDraft,
  useAddressFieldValue,
  useField,
  useForm,
  usePrimaryAddressRef,
} from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import { goToFirstError } from '@ecp/features/sales/shared/utils/web';

import { VIN_REGEX_FULL } from '../../constants';
import { VehicleFormQuestions } from '../../formBody';
import {
  useValidateVehicleInfo,
  useValidateVinNumber,
  useVehicleEditValidation,
  useVehicleInfoOrVinValue,
} from '../../state';
import {
  useCheckDuplicateVin,
  useGarageField,
  useRemoveUnusedRefsForVehicle,
  useRestrictedGaragedState,
} from '../../state/modelUtil';
import { useStyles } from './VehicleForm.styles';
import { useVehicleInfo } from './vehicleOptions';

interface Props {
  vehicleRef: string;
  needActions: boolean;
  maskRequiredFieldError: boolean;
  setMaskRequiredFieldError: (value: boolean) => void;
  onAddVehicle?: () => void;
  onNext?: () => Promise<void>;
  nextPageInstructions: string;
  isEdit: boolean;
}

export const VehicleForm: React.FC<Props> = (props) => {
  const {
    vehicleRef,
    needActions,
    setMaskRequiredFieldError,
    onNext,
    nextPageInstructions,
    isEdit,
  } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const disableVehicleServiceCallFlag = flagValues[FeatureFlags.DISABLE_VEHICLE_SERVICE_CALL];

  const vehicleInfoOrVin = useField(`static.${vehicleRef}.vehicleInfoOrVin`);
  const make = useField(`${vehicleRef}.make`);
  const model = useField(`${vehicleRef}.model`);
  const series = useField(`${vehicleRef}.series`);
  const vin = useField(`${vehicleRef}.vin`);
  const year = useField(`${vehicleRef}.year`);
  const stubVin = useField(`${vehicleRef}.stubVin`);
  const vehicleDetailId = useField(`${vehicleRef}.vehicleDetailId`);
  const riskAddress = useField('static.vehicleKeptAddress');
  const garageState = useGarageField(vehicleRef, 'state');
  const primaryAddress = usePrimaryAddressRef();
  const primaryAddressState = useAddressFieldValue(primaryAddress, 'state');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isContinueClicked, setContinueClicked] = useState(false);

  const vehicleInfoOrVinValue = useVehicleInfoOrVinValue(vehicleInfoOrVin, vin);
  const validateGarageAddress = useRestrictedGaragedState(garageState, primaryAddressState);

  const getFields = useGetFields();
  const fields = getFields();
  const fieldsLength = Object.keys(fields).length;
  const getInitValues = useGetInitValues();
  const getConditions = useGetConditionValues();
  const conditions = getConditions();
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions,
  });
  const validateVehicleInfo = useValidateVehicleInfo(vehicleInfoOrVinValue, make, model, series);
  const validateVinNumber = useValidateVinNumber(vin);

  const removeUnusedVehicleRef = useRemoveUnusedRefsForVehicle();
  const navigateToAutoProfile = useNavigateToPage(PagePath.EDIT_AUTO_PROFILE, {
    replace: true,
    removeQuery: false,
    replaceProfileAdd: true,
  });

  const { isVehicleApiInProgress: isFetchVehicleInProgress, fetchVehicleInfo } = useVehicleInfo(
    make,
    model,
    series,
    vin,
    stubVin,
    vehicleDetailId,
  );

  useVehicleEditValidation(
    validateForm,
    vehicleInfoOrVinValue,
    fetchVehicleInfo,
    year.value,
    vin.value,
    goToFirstError,
    isEdit,
    fieldsLength,
    vehicleRef,
  );

  // TODO: duplicate but slightly different function, see vehicleModelUtil.ts and VehicleInfoOrVinQuestion.tsx
  const validateVinFormat = (value: string | undefined): string | undefined => {
    if (value) {
      if (value.length > 17) {
        return 'Please enter 17 digits';
      }

      return VIN_REGEX_FULL.test(value) ? undefined : 'Please enter a valid VIN';
    }

    return undefined;
  };

  const checkDuplicateVin = useCheckDuplicateVin(vin, vehicleRef);
  const updateAnswers = useEvent(async () => {
    // set mask-required-field-error to false for form-level validation
    setMaskRequiredFieldError(false);

    const isFormValid = validateForm().isValid;
    if (!isFormValid) return false;

    const isGarageValid = validateGarageAddress();
    if (!isGarageValid) return false;

    const isVehicleInfoValid = validateVehicleInfo();
    if (!isVehicleInfoValid) {
      goToFirstError();

      return false;
    }

    // send form values to answers endpoint
    await patchFormValues();

    // This is for a special scenario when user types in a VIN
    // and directly click on submit and avoids onBlur event of VIN
    let vehicleInfoError = false;
    // we need to make sure the length of the masked VIN is equal to 17 characters
    if (isMasked(vin.value) && vin.props.value?.length !== 17) {
      goToFirstError();

      return false;
    }
    if (
      year.value &&
      !validateVinFormat(vin.value as string) &&
      !isMasked(vin.value) &&
      !disableVehicleServiceCallFlag
    ) {
      vehicleInfoError = await fetchVehicleInfo(year.value as string, vin.value as string);
      if (!vehicleInfoError) {
        checkDuplicateVin();
      }
    }
    if (vehicleInfoError) {
      goToFirstError();

      return false;
    }
    if (vin.value && !validateVinNumber() && !isMasked(vin.value)) {
      // if there is a 'vin'-related issue, but the user is on the 'info' tab,
      // we need to manually switch tabs in order for the user to see the error message
      if (vehicleInfoOrVinValue === 'vehicleInfo') {
        vehicleInfoOrVin.props.actionOnChange('vin');
      }
      goToFirstError();

      return false;
    }

    await dispatch(submitProofDraft({ policyTypes: ['auto'] }));

    return true;
  });

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    setContinueClicked(true);
    if (await updateAnswers()) await onNext?.();
    setIsSubmitting(false);
  }, [onNext, updateAnswers]);

  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
  }, []);

  const handleDeleteVehicle = useCallback(async (): Promise<void> => {
    setIsDeleting(true);

    await removeUnusedVehicleRef(vehicleRef);
    await navigateToAutoProfile();
    setIsDeleting(false);
  }, [navigateToAutoProfile, removeUnusedVehicleRef, vehicleRef]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container>
          <VehicleFormQuestions isContinueClicked={isContinueClicked} {...props} />
          {needActions && (
            <>
              <Grid item xs={12}>
                <NextPageInstructions divider>{nextPageInstructions}</NextPageInstructions>
              </Grid>
              <Grid className={classes.buttonsPanel} container item xs={12}>
                <div className={classes.addButton}>
                  <Button
                    className={classes.next}
                    variant='primary'
                    onClick={handleSubmit}
                    data-testid='autoVehicleContinue'
                    // This stops the onBlur from firing only when this button is clicked
                    // and the button retains its coordinates for the mouseup to eventually
                    // register it as a click event. If you want the onBlur as well, you can
                    // fire it yourself from the onClick handler. Touch should keep working.
                    onMouseDown={handleMouseDown}
                    isProcessing={isPatchFormInProgress || isSubmitting}
                    trackingName={GoogleAnalyticsLabels.CONTINUE}
                    trackingLabel='vehicle_details_page_continue'
                    analyticsElement='choice.vehicleDetailsPage.continueButton'
                    disabled={riskAddress.value === 'differentState'}
                  >
                    Continue
                  </Button>
                </div>
                <div className={classes.cancelButton}>
                  <Button
                    className={classes.next}
                    variant='iconTextMedium'
                    onClick={handleDeleteVehicle}
                    data-testid='autoVehicleCancel'
                    // This stops the onBlur from firing only when this button is clicked
                    // and the button retains its coordinates for the mouseup to eventually
                    // register it as a click event. If you want the onBlur as well, you can
                    // fire it yourself from the onClick handler. Touch should keep working
                    onMouseDown={handleMouseDown}
                    isProcessing={isDeleting}
                    trackingName='remove_vehicle_link'
                    trackingLabel={vehicleRef}
                    analyticsElement='choice.reviewVehicleAndDriverPage.DeleteVehicleButton'
                    analyticsEventDetail={vehicleRef}
                  >
                    Remove vehicle
                  </Button>
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </Form>
      {isFetchVehicleInProgress && <LogoSpinner />}
    </div>
  );
};
