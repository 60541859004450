import { amfamConnectLegalPolicy } from '@ecp/features/sales/shared/metadata';
import { PagePath } from '@ecp/features/sales/shared/routing';

import type { Metadata } from './metadata';
import { default as baseMetadata } from './metadata';

export const metadata: Metadata = {
  ...baseMetadata,
  links: [
    {
      url: amfamConnectLegalPolicy,
      text: 'Privacy policy',
      action: 'BodyTextPrivacyPolicyLink',
      label: 'PrivacyPolicy',
    },
    {
      url: PagePath.PARTICIPATING_INSURERS,
      text: 'Participating insurers',
      action: 'ParticipatingInsurersLink',
      label: 'ParticipatingInsurers',
    },
  ],
};

export default metadata;
