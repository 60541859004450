import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { PagePath } from '@ecp/features/sales/shared/routing';
import {
  getCheckoutDataFromOffers,
  getOfferProductsSelectedByType,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import {
  getReducedProductNameFromProduct,
  type LineOfBusiness,
} from '@ecp/features/shared/product';
import { partner } from '@ecp/partners';

import {
  additionalCreditDisclaimersMetadata,
  additionalFraudStatementsMetadata,
  additionalMiscellaneousStatementsMetadata,
  creditDisclaimersMetadata,
} from './metadata';
import type { AgentScripts } from './metadata/types';

export const getAgentScripts = (
  pagePath: string,
  stateCode: string,
  lob: LineOfBusiness,
  statePremium?: string,
  ourPremium?: string,
): Array<AgentScripts> => {
  switch (pagePath) {
    case PagePath.PERSON:
      return [creditDisclaimersMetadata(), additionalCreditDisclaimersMetadata(stateCode, lob)];
    case PagePath.CHECKOUT:
      return [
        additionalFraudStatementsMetadata(stateCode, lob),
        additionalMiscellaneousStatementsMetadata(stateCode, lob, statePremium, ourPremium),
      ];
    default:
      return [];
  }
};

export const useGetOfferPropertyPremiums = (): { statePremium?: string; ourPremium?: string } => {
  const checkoutData = useSelector(getCheckoutDataFromOffers);
  const { property: propertyOfferProduct } = useSelector(getOfferProductsSelectedByType);
  if (!propertyOfferProduct) return {};

  const premiumKey = getReducedProductNameFromProduct(propertyOfferProduct);
  const statePremium = checkoutData[premiumKey]?.statePremium;
  const ourPremium = checkoutData[premiumKey]?.premiumAmount;

  return { statePremium, ourPremium };
};

export const renderFragmentOrPelement = (text: React.ReactNode): React.ReactNode => {
  if (typeof text === 'string') {
    return <p>{text}</p>;
  } else {
    return <>{text}</>;
  }
};

export const getUnderwriterName = (): string => {
  const isAutoProductSwapStateRollout = flagValues[FeatureFlags.AUTO_PRODUCT_SWAP_STATE_ROLLOUT];
  const midvaleDisplayName = 'Midvale Home & Auto Insurance Program';

  if (isAutoProductSwapStateRollout) return midvaleDisplayName;

  return partner.shared.partnerDisplayName;
};
