import type { StateOption } from '@ecp/types';

interface BaseMetadata {
  subHeader?: string;
  subTitle?: string;
  title?: string;
}

interface StateMetadata extends StateOption<BaseMetadata>, BaseMetadata {}

const sharedHeaderMetadata = {
  subHeader: 'Help us determine what discounts you may be eligible for.',
  subTitle: 'Policy-level discounts',
  title: 'Let’s see what discounts you might qualify for!',
};

export const autoHeaderMetadata: {
  [productKey: string]: StateMetadata;
} = {
  'amfam.auto': {
    ...sharedHeaderMetadata,
    stateOptions: {
      MA: {
        subHeader: 'Help us determine what discounts and savings you may be eligible for.',
        subTitle: 'Additional auto policy savings',
      },
      HI: {
        subHeader: 'Help us determine what discounts and savings you may be eligible for.',
        subTitle: 'Additional auto policy savings',
      },
    },
  },
  'connect.auto': sharedHeaderMetadata,
  'amfam-adv.auto': sharedHeaderMetadata,
};
