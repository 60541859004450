import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AgentDashboardTable' })((theme) => ({
  root: {},
  tableWrapper: {
    ...theme.mixins.tableContainer,
    overflow: 'auto',
    maxWidth: 'none',
    marginTop: 'none',
  },
  tableHeader: {
    ...theme.mixins.tableFirstRow,
    '& th': {
      ...(theme.mixins.tableFirstRow['& th'] as object),
      ...theme.typography.body1Bold,
    },
  },
  tableHeaderUnavailable: {
    '& th': {
      backgroundColor: theme.palette.warning.light,
    },
  },
  tableBody: {
    ...theme.typography.body2,
    '& tr:first-child td': {
      borderTop: 'none',
    },
  },
  tableRow: {
    '& td': {
      ...theme.typography.body1,
      textAlign: 'left',
      padding: '10px',
      border: 'none',
      borderTop: `1px solid ${theme.palette.other.border}`,
      borderLeft: `1px solid ${theme.palette.other.border}`,
    },
    background: theme.palette.grey[50],
  },
  tableRowOdd: {
    background: theme.palette.grey[50],
  },
  tableCellNoLeftBorder: {
    borderLeft: 'none !important',
  },
  quoteDetailCell: {
    textAlign: 'left !important' as 'left',
    display: 'flex',
    justifyContent: 'space-between',
  },
  premiumLabel: theme.typography.body2,
  premiumLabelTooltip: {
    bottom: 10,
  },
  recallButton: {
    padding: 0,
  },
  detailsDescription: {
    ...theme.typography.body1Bold,
  },
  detailsDnqCnq: {
    color: theme.palette.warning.dark,
  },
  tableCnqDnq: {
    width: '100%',
  },
  recallPurchasedContainer: {
    textAlign: 'left',
  },
  recallPurchasedPolicy: {
    color: theme.palette.success.main,
  },
}));
