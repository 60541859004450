import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutoSelectDiscountsForm' })((theme) => ({
  ...theme.mixins.formAll,
  root: { ...theme.mixins.form, marginTop: 0 },
  subTitle: {
    ...theme.typography.subtitle,
    marginTop: 5,
  },
  vehicleImage: {
    width: 'auto',
    height: 40,
    margin: '30px auto auto auto',
    [theme.breakpoints.down('md')]: {
      margin: 0,
    },
  },
  discountsSubQuestions: {
    border: `1px solid ${theme.palette.other.border}`,
    borderRadius: 4,
    padding: 20,
    margin: 'auto auto 10px auto',
  },
  driverIcon: theme.mixins.setColorSvg(theme.palette.grey[50]),
  textTertiary: {
    color: theme.palette.text.tertiary,
  },
}));
