export const partner = {
  shared: {
    partnerDisplayName: 'American Family Insurance Company',
    salesEmailQuotesAddress: 'AmFamService@connectbyamfam.com',
    servicePhoneHours: '8:00am-9:00pm CST M-F, 9:00am-6:00pm CST Sat',
    qnbPhoneNumber: '1-800-692-6326',
    dnqPhoneNumber: '1-800-692-6326',
    salesPhoneNumber: '1-800-692-6326',
    servicePhoneNumber: '1-800-692-6326',
    agentFromEmailAddress: '',
    agentFromEmailName: 'American Family Insurance Company',
    dnqEmail: 'BA_PL@amfam.com',
  },
  lobDetail: { AUTO: { dnqNumbers: {}, discount: {} } },
} as const;
