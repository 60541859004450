import { useCallback } from 'react';

import { Divider, FormControl, FormLabel, Grid } from '@mui/material';

import { Alert, GridItem, TooltipWithIcon } from '@ecp/components';
import { Button, PhoneLink } from '@ecp/features/sales/shared/components';
import { partner } from '@ecp/partners';
import { IconUIExclaimTriangle } from '@ecp/themes/base';

import { useVehicleOptions } from '../../../../../forms';
import { useStyles } from '../VehicleInfoQuestions.styles';
import { VehicleMakeQuestion } from '../VehicleMakeQuestion';
import { VehicleModelQuestion } from '../VehicleModelQuestion';
import { VehicleSeriesQuestion } from '../VehicleSeriesQuestion';
import { VehicleVinQuestion } from '../VehicleVinQuestion';
import { VehicleYearQuestion } from '../VehicleYearQuestion';
import type { VehicleInfoProps } from './types';
import { useVehicleInfoOrVinQuestion } from './util';

const VIN_HELP_TEXT = `The VIN can be found either on the vehicle registration, by looking in through the windshield on the driver's side dashboard, or on the interior driver's side door jamb.`;

export const VehicleInfoOrVinQuestion: React.FC<VehicleInfoProps> = (props) => {
  const { vehicleRef, setMaskRequiredFieldError, maskRequiredFieldError } = props;
  const { classes } = useStyles();

  const {
    year,
    make,
    model,
    series,
    vin,
    vehicleInfoOrVin,
    handleVehicleInfoOrVinActionOnComplete,
  } = useVehicleInfoOrVinQuestion(vehicleRef);
  const vehicleOptions = useVehicleOptions(
    year.value as string,
    make.value as string,
    model.value as string,
    series.value as string,
    vehicleInfoOrVin.value as string,
    vehicleRef,
    false,
  );
  const apiError = Boolean(
    vehicleOptions.makeError ||
      vehicleOptions.modelError ||
      (vehicleOptions.seriesError && !vin.value),
  );
  const switchToVinEntryMode = useCallback(
    () => handleVehicleInfoOrVinActionOnComplete('vin'),
    [handleVehicleInfoOrVinActionOnComplete],
  );
  const switchToInfoEntryMode = useCallback(
    () => handleVehicleInfoOrVinActionOnComplete('vehicleInfo'),
    [handleVehicleInfoOrVinActionOnComplete],
  );

  return (
    <FormControl component='fieldset'>
      <Grid container columnSpacing='20px' rowSpacing='40px'>
        <Grid container columnSpacing={4} item xs={12} className={classes.vehicleFieldsContainer}>
          {vehicleInfoOrVin.value !== 'vin' && (
            <Grid item xs={12}>
              <Button
                variant='iconTextMedium'
                onClick={switchToVinEntryMode}
                trackingName='EnterVinLink'
                trackingLabel='EnterVin'
              >
                Enter vehicle identification number (VIN)
              </Button>
              <Divider className={classes.divider}>
                <p>or</p>
              </Divider>
            </Grid>
          )}
          {apiError && (
            <GridItem xs={12} topSpacing='lg'>
              {/* TODO pull out to warning component */}
              <Alert type='warning' className={classes.warningContainer}>
                <IconUIExclaimTriangle className={classes.warningImage} />
                {'  '}We are unable to add this vehicle to your quote. Please try another vehicle or
                call us at
                <PhoneLink withUnderlinedLinkStyle number={partner.shared.salesPhoneNumber} />
              </Alert>
            </GridItem>
          )}
          <GridItem xs={12}>
            <FormLabel component='legend' focused={false}>
              {vehicleInfoOrVin.value === 'vin' ? (
                <>
                  Enter vehicle identification number (VIN){' '}
                  <TooltipWithIcon title={VIN_HELP_TEXT} />
                </>
              ) : (
                <>
                  Enter vehicle details
                  <Alert type='info' className={classes.infoContainer}>
                    You can get an estimated quote without a VIN but we'll need the VIN before you
                    can purchase your policy.
                  </Alert>
                </>
              )}
            </FormLabel>
          </GridItem>
          <GridItem xs={12} md={6}>
            <VehicleYearQuestion
              vehicleRef={vehicleRef}
              setMaskRequiredFieldError={setMaskRequiredFieldError}
            />
          </GridItem>
          {vehicleInfoOrVin.value === 'vehicleInfo' && (
            <>
              <GridItem xs={12} md={6}>
                <VehicleMakeQuestion
                  {...vehicleOptions}
                  setMaskRequiredFieldError={setMaskRequiredFieldError}
                  vehicleRef={vehicleRef}
                  maskRequiredFieldError={maskRequiredFieldError}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <VehicleModelQuestion
                  {...vehicleOptions}
                  vehicleRef={vehicleRef}
                  setMaskRequiredFieldError={setMaskRequiredFieldError}
                  maskRequiredFieldError={maskRequiredFieldError}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <VehicleSeriesQuestion
                  {...vehicleOptions}
                  vehicleRef={vehicleRef}
                  maskRequiredFieldError={maskRequiredFieldError}
                />
              </GridItem>
            </>
          )}
          {vehicleInfoOrVin.value === 'vin' && (
            <>
              <GridItem xs={12} md={6}>
                <VehicleVinQuestion
                  maskRequiredFieldError={maskRequiredFieldError}
                  vehicleRef={vehicleRef}
                  setMaskRequiredFieldError={setMaskRequiredFieldError}
                />
              </GridItem>
              <GridItem xs={12}>
                <Divider className={classes.divider}>
                  <p>or</p>
                </Divider>
                <Button
                  variant='iconTextMedium'
                  onClick={switchToInfoEntryMode}
                  trackingName='NoVinLink'
                  trackingLabel='NoVin'
                >
                  I don't have the vehicle identification number
                </Button>
              </GridItem>
            </>
          )}
        </Grid>
        {/* Commenting following grid temporarily, we'll be using it again */}
        {/* <Grid container item xs={12} lg={6}>
          <VehicleImage vehicleImageUrl={vehicleOptions.vehicleImageUrl} />
        </Grid> */}
      </Grid>
    </FormControl>
  );
};
