import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import type { ButtonVariant } from '@ecp/components';
import type { CarrierName } from '@ecp/features/shared/product';
import {
  getCarrierActualNameFromDeprecatedName,
  LineOfBusiness,
} from '@ecp/features/shared/product';
import { partner } from '@ecp/partners';

import { Button } from '../Button';
import { convertPhoneCharsToNumbers } from '../PhoneLink';
import { ConfirmationRequired } from './ConfirmationRequired';

export type CallToActionsArgs = {
  carrierName?: CarrierName;
  estimateTrackingName: string;
  isBundleSelected: boolean;
  isEstimate: boolean;
  isQuoteNoBind: boolean;
  isConfirmationRequired: boolean;
  retrieveLink?: string;
  selectedLob: LineOfBusiness;
  trackingLabel: string;
  handleBuy: () => void;
  handleFinishAndBuy: () => void;
  handleRetrieveLinkClick: () => void;
  className?: string;
  variant?: 'desktop' | 'mobile';
  inProgress: boolean;
  buttonVariant?: ButtonVariant;
  buttonText?: string;
  disabled?: boolean;
};

export const callToActions = ({
  carrierName,
  estimateTrackingName,
  isBundleSelected,
  isEstimate,
  isConfirmationRequired,
  isQuoteNoBind,
  retrieveLink,
  selectedLob,
  trackingLabel,
  handleBuy,
  handleFinishAndBuy,
  handleRetrieveLinkClick,
  className,
  variant,
  inProgress,
  buttonVariant = 'primary',
  buttonText = 'Review and Edit Coverages',
  disabled = false,
}: CallToActionsArgs): {
  actions: React.ReactElement;
  callUsPhoneNumber: string;
} => {
  let actions = null;

  const carrierActualName = getCarrierActualNameFromDeprecatedName(carrierName);

  // From https://theexperimentationlab.atlassian.net/wiki/spaces/CCR/pages/1018626505/DRAFT+Phone+Numbers+for+Choice+Platform
  // 877-658-1928
  // Choice Platform - Midvale RVP
  // Phone number has been provisioned for use on the Quote Summary Page, specifically for Quote No Bind situations where High and Medium RVP flags are the reason. Will be used for the Click to Call.
  // Quote Summary page
  // 877-658-1933
  // Choice Platform - QNB
  // Phone number has been provisioned for use on the Quote Summary Page, specifically for Quote No Bind situations (excluding Midvale RVP). Will be used for the Click to Call.
  // Quote summary page
  const qnbNumber = partner.shared.qnbPhoneNumber;
  // RVP is only for Midvale. None of the other carries will have it
  const rvpNumber =
    carrierActualName === 'midvale' || carrierActualName === 'homesite'
      ? partner.shared.salesPhoneNumber
      : '';

  const callUsPhoneNumber = isQuoteNoBind
    ? isConfirmationRequired
      ? rvpNumber
      : qnbNumber
    : partner.shared.salesPhoneNumber;

  if (isQuoteNoBind) {
    // extract the phone number part of the number prop
    // only consider it a phone number if it exists and of reasonable length
    // also consider a bracketed string as a number (this is the way numbers should occur when developing)
    // split by spaces based on change for CPUI-4331
    const matchNumber = convertPhoneCharsToNumbers(callUsPhoneNumber.split(' ')[0]).match(
      /[-\d()]{7,}|^\[.*\]$/,
    );
    const parsedNumber = matchNumber ? matchNumber[0] : null;
    // the parsedNumber is used for the link, the number prop is displayed as-is
    const href = parsedNumber ? `tel:${parsedNumber}` : undefined;
    actions = (
      <>
        {isConfirmationRequired && <ConfirmationRequired number={rvpNumber} variant={variant} />}
        <Button
          variant={buttonVariant}
          href={href}
          trackingName='CallUsLink'
          trackingLabel={trackingLabel}
          className={className}
          disabled={disabled}
        >
          {callUsPhoneNumber}
        </Button>
      </>
    );
  }

  // Can be used by carriers who fulfil the condition
  else if (selectedLob === LineOfBusiness.AUTO && retrieveLink) {
    actions = (
      <Button
        variant={buttonVariant}
        onClick={handleRetrieveLinkClick}
        isProcessing={inProgress}
        trackingName={GoogleAnalyticsLabels.CONTINUE}
        trackingLabel={trackingLabel}
        className={className}
        data-testid={`${carrierName}-finishAndBuy`}
        disabled={disabled}
      >
        {buttonText}
      </Button>
    );
  } else if (isEstimate) {
    actions = (
      <Button
        variant={buttonVariant}
        onClick={handleFinishAndBuy}
        isProcessing={inProgress}
        className={className}
        trackingName={GoogleAnalyticsLabels.CONTINUE}
        trackingLabel={trackingLabel}
        data-testid={`${carrierName}-${buttonText.replace(/\s/g, '')}`}
        disabled={disabled}
      >
        {buttonText}
      </Button>
    );
  } else {
    actions = (
      <Button
        variant={buttonVariant}
        onClick={handleBuy}
        isProcessing={inProgress}
        trackingName={GoogleAnalyticsLabels.CONTINUE}
        trackingLabel={trackingLabel}
        className={className}
        data-testid={
          isBundleSelected
            ? `${carrierName}-buyAutoAndHomeBundle`
            : `${carrierName}-${buttonText.replace(/\s/g, '')}`
        }
        disabled={disabled}
      >
        {buttonText}
      </Button>
    );
  }

  return { actions, callUsPhoneNumber };
};
