import type { Metadata } from './metadata.js';
import baseMetadata from './metadata.js';

const metadata: Metadata = {
  ...baseMetadata,
  shouldDisplayBillingReceipt: true,
  shouldDisplayRouteToPLPC: true,
  product: ['amfam-adv.renters', 'amfam-adv.home'],
  variant: 'payment',
  showPoiDocument: true,
};

export default metadata;
