import type { LineOfBusiness } from '@ecp/features/shared/product';

import type { AgentScripts } from './types';

export const additionalMiscellaneousStatementsMetadata = (
  stateCode: string,
  lob: LineOfBusiness,
  statePremium?: string,
  ourPremium?: string,
): AgentScripts => {
  switch (stateCode) {
    case 'MN':
      switch (lob) {
        case 'LINE_OF_BUSINESS.HOME':
        case 'LINE_OF_BUSINESS.RENTERS':
        case 'LINE_OF_BUSINESS.BUNDLE':
        case 'LINE_OF_BUSINESS.BUNDLE_AUTO_RENTERS':
          return {
            title: 'Minnesota state-specific disclosure',
            text: 'The insurer may elect to cancel coverage at any time during the first 59 days following issuance of the coverage for any reason which is not specifically prohibited by statute.',
            spanishText:
              'El asegurador puede eligir cancelar la cobertura en cualquier momento durante los primeros 59 días siguientes a la emisión de la cobertura por cualquier motivo que no esté específicamente prohibido por estatuto.',
          };
        default:
          return {};
      }
    case 'NC':
      switch (lob) {
        case 'LINE_OF_BUSINESS.HOME':
        case 'LINE_OF_BUSINESS.RENTERS':
        case 'LINE_OF_BUSINESS.BUNDLE':
        case 'LINE_OF_BUSINESS.BUNDLE_AUTO_RENTERS':
          return {
            title: 'North Carolina state-specific disclosure',
            text: (
              <>
                <p>
                  Notice: In accordance with G.S. 58-36-30(B1), your policy is currently being rated
                  under North Carolina Consent to Rate regulations. The premium using North Carolina
                  Bureau's rates for Homeowners insurance coverage applied for is {statePremium}.
                  Our premium for this coverage is {ourPremium}.
                </p>
                <p>
                  This will only apply if the quote is a Consent to Rate (CTR) policy and&nbsp;
                  <strong>
                    <u>
                      <i> will not </i>
                    </u>
                  </strong>
                  &nbsp;apply if the quote is rated in the NCRB.
                </p>
              </>
            ),
            spanishText: (
              <>
                <p>
                  Aviso: De acuerdo con G.S. 58-36-30(B1), su póliza actualmente está siendo
                  calificada bajo las regulaciones de Consentimiento para Tarifas de Carolina del
                  Norte. La prima que se aplica utilizando las tarifas de la Oficina de Carolina del
                  Norte para la cobertura de seguro de propietarios de viviendas es {statePremium}.
                  Nuestra prima para esta cobertura es {ourPremium}.
                </p>
                <p>
                  Esto sólo se aplicará si la cotización es una póliza de consentimiento para la
                  tarificación (CTR) y&nbsp;
                  <strong>
                    <u>
                      <i>no se</i>
                    </u>
                  </strong>
                  &nbsp;aplicará si la cotización está tarificada en el NCRB.
                </p>
              </>
            ),
          };
        default:
          return {};
      }
    case 'ND':
      switch (lob) {
        case 'LINE_OF_BUSINESS.HOME':
        case 'LINE_OF_BUSINESS.RENTERS':
        case 'LINE_OF_BUSINESS.BUNDLE':
        case 'LINE_OF_BUSINESS.BUNDLE_AUTO_RENTERS':
          return {
            title: 'North Dakota state-specific disclosure',
            text: 'We will consider the insured’s claims history in determining whether to decline, cancel, nonrenew, or surcharge a policy and claims you incur will be reported to an insurance support organization.',
            spanishText:
              'Consideraremos el historial de reclamos del asegurado para determinar si rechazamos, cancelamos, no renovamos o cobramos un recargo a una póliza y los reclamos en los que incurra se informarán a una organización de apoyo de seguros.',
          };
        default:
          return {};
      }
    case 'SC':
      switch (lob) {
        case 'LINE_OF_BUSINESS.HOME':
        case 'LINE_OF_BUSINESS.RENTERS':
        case 'LINE_OF_BUSINESS.BUNDLE':
        case 'LINE_OF_BUSINESS.BUNDLE_AUTO_RENTERS':
          return {
            title: 'South Carolina state-specific disclosure',
            text: 'The insurer can cancel this policy for which you are applying without cause during the first 120 days.  That is the insurer’s choice.  After the first 120 days, the insurer can only cancel this policy for reasons stated in the policy.',
            spanishText:
              'La aseguradora puede cancelar esta póliza que usted solicita sin causa durante los primeros 120 días.  Esa es la elección de la aseguradora.  Después de los primeros 120 días, la aseguradora solo puede cancelar esta póliza por las razones establecidas en la póliza.',
          };
        default:
          return {};
      }
    case 'MI':
      switch (lob) {
        case 'LINE_OF_BUSINESS.RENTERS':
        case 'LINE_OF_BUSINESS.BUNDLE_AUTO_RENTERS':
          return {
            title: 'Michigan state-specific disclosure',
            text: 'The first $25 of the premium of this policy is non-refundable if the policy is cancelled at the insured’s request for any reason.  This only applies at new business when you first purchase the policy.',
            spanishText:
              'Los primeros $25 de la prima de esta póliza no son reembolsables si la póliza se cancela a solicitud del asegurado por cualquier motivo. Esto solo se aplica en negocios nuevos cuando compra la póliza por primera vez.',
          };
        default:
          return {};
      }
    default:
      return {};
  }
};
