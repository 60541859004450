import { useCallback, useState } from 'react';

import type { SelectProps } from '@ecp/components';
import { Select } from '@ecp/features/sales/shared/components';
import { PARTNER_ACCOUNT } from '@ecp/features/sales/shared/constants';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';
import type { Option } from '@ecp/types';

interface Props<T extends string = string> extends QuestionProps {
  onAccountSelect?: (value: T | string) => Promise<void>;
  selectedAccount: T;
  accountOptions: Option<T>[];
}

export const AccountQuestion = <T extends string = string>(props: Props<T>): React.ReactElement => {
  const { accountOptions, selectedAccount, onAccountSelect } = props;
  const accountField = useField(PARTNER_ACCOUNT);
  // As we use session storage for exp id and store for inquiry id,
  // when we patch answers the function reads exp id and inquiry id from 2 different sources
  // where session storage always returns latest value but store returns a stale value.
  // So to avoid out of sync values issue, we disable the field while the callback promise is pending.
  const [isProcessing, setIsProcessing] = useState(false);

  const actionOnChange: NonNullable<SelectProps<T>['actionOnChange']> = useCallback(
    async (value) => {
      setIsProcessing(true);
      await onAccountSelect?.(value).finally(() => setIsProcessing(false));
    },
    [onAccountSelect],
  );

  return (
    <Select
      {...accountField.props}
      id='account'
      value={selectedAccount}
      label='Select an account'
      options={accountOptions}
      autoSelect
      actionOnChange={actionOnChange}
      actionOnComplete={undefined}
      disabled={isProcessing || !accountOptions?.length}
    />
  );
};
