import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AppHeader' })((theme) => ({
  root: {
    ...theme.typography.body3,
    ...theme.mixins.shadow1,
    alignItems: 'center',
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.primary,
    flexDirection: 'row',
    padding: '0 30px',
    height: theme.appVars.appHeaderHeightAgent,
    zIndex: theme.zIndex.drawer + 11,
    [theme.breakpoints.down('md')]: {
      padding: '0 15px',
    },
  },
  logos: {
    height: 64,
    '& > *': {
      height: '100%',
      padding: '10px 20px',
      [theme.breakpoints.down('md')]: {
        width: '60%',
        padding: 10,
      },
    },
  },
  appLogo: {
    paddingLeft: 0,
    [theme.breakpoints.down('md')]: {
      width: '30%',
      minWidth: 50,
    },
  },
  partnerLogo: {
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    maxWidth: 450,
  },
  actions: {
    marginLeft: 'auto',
  },
}));
