import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { useGetPaymentType } from '@ecp/features/sales/checkout';
import {
  getCurrentPage,
  getOfferProductsSelectedByType,
  getPniPersonInfo,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { getProductNameFromProduct } from '@ecp/features/shared/product';

import { AgentScriptContent } from '../AgentScriptContent';
import type { NachaProductName } from './metadata/metadata';
import { getNachaScriptContent } from './util';

export const NachaScripts: React.FC = () => {
  const { firstName, lastName } = useSelector(getPniPersonInfo);
  const insuredName = firstName + ' ' + lastName;
  const nachaProducts: NachaProductName[] = [];
  const currentPage = useSelector(getCurrentPage);
  const renderNachaScripts = currentPage === '/app/checkout';
  const isAutoProductSwapStateRollout = flagValues[FeatureFlags.AUTO_PRODUCT_SWAP_STATE_ROLLOUT];

  const { auto: autoOfferProduct, property: propertyOfferProduct } = useSelector(
    getOfferProductsSelectedByType,
  );
  const autoPaymentType = useGetPaymentType(autoOfferProduct);
  const propertyPaymentType = useGetPaymentType(propertyOfferProduct);
  if (autoOfferProduct && autoPaymentType === 'EFT')
    nachaProducts.push(
      isAutoProductSwapStateRollout ? 'autoMidvale' : getProductNameFromProduct(autoOfferProduct),
    );
  if (propertyOfferProduct && propertyPaymentType === 'EFT')
    nachaProducts.push(getProductNameFromProduct(propertyOfferProduct));

  return renderNachaScripts && nachaProducts.length ? (
    <AgentScriptContent agentScript={getNachaScriptContent(insuredName, nachaProducts)} />
  ) : null;
};
