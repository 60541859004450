import { useCallback, useEffect, useState } from 'react';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { PagePath, useNavigateToNextPage } from '@ecp/features/sales/shared/routing';
import { getDrivers, questionExists, updateAnswers } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

import { VehicleOwnershipDateForm } from '../../forms';
import { useVehiclesForSidebar } from '../../state';

const getDriverAssignmentQuestionExists = (state: RootStore): boolean =>
  questionExists('vehicle.<id>.primary.driver.ref')(state);

export const VehicleOwnershipDatePage: React.FC = () => {
  const drivers = useSelector(getDrivers);
  const vehicles = useVehiclesForSidebar();
  const dispatch = useDispatch();
  const navigateToNextPage = useNavigateToNextPage();
  const driverAssignmentQuestionExists = useSelector(getDriverAssignmentQuestionExists);
  const [nextPageInstructions, setNextPageInstructions] = useState(
    'Thanks. Next, you’ll see your discounts.',
  );

  const autoAssign = useCallback(async () => {
    await dispatch(
      updateAnswers({ answers: { [`${vehicles[0].ref}.primary.driver.ref`]: drivers[0].ref } }),
    );
  }, [dispatch, drivers, vehicles]);

  useEffect(() => {
    if (!(drivers.length === 1 && vehicles.length === 1)) {
      if (driverAssignmentQuestionExists) {
        setNextPageInstructions('Thanks. Next, you’ll see your driver assignment.');
      }
    }
  }, [drivers.length, vehicles.length, driverAssignmentQuestionExists]);

  const handleNext = useCallback(async () => {
    if (driverAssignmentQuestionExists && drivers.length === 1 && vehicles.length === 1) {
      await autoAssign();
    }
    await navigateToNextPage();
  }, [
    autoAssign,
    driverAssignmentQuestionExists,
    drivers.length,
    navigateToNextPage,
    vehicles.length,
  ]);

  return (
    <div>
      <Page
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.EDIT_AUTO_PROFILE} />,
        }}
        title="Let's confirm your vehicles"
        analyticsElement='choice.vehicleOwnershipDatePage.Page'
      >
        <VehicleOwnershipDateForm onNext={handleNext} nextPageInstructions={nextPageInstructions} />
      </Page>
    </div>
  );
};
