import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AppFooter' })((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[50],
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: 0,
  },
  footerContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 30,
  },
  copyrightText: {
    ...theme.typography.body2,
    marginRight: 'auto',
    [theme.breakpoints.down('md')]: {
      marginRight: 'inherit',
      margin: '0px auto',
    },
  },
  siteLinks: {
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      marginLeft: 'inherit',
      margin: '20px auto 0px',
    },
  },
  links: theme.typography.body1,
  linkDivider: {
    ...theme.typography.body1,
    color: theme.palette.divider,
    margin: '0px 10px',
  },
  debugWrapperRoot: {
    backgroundColor: theme.palette.warning.light,
  },
  debugWrapperTitlePanel: {
    backgroundColor: 'inherit',
    borderBottom: 'none',
    borderTop: `1px solid ${theme.palette.warning.main}`,
    '& button': {
      justifyContent: 'center',
      paddingLeft: 0,
      fontSize: '.875rem',
    },
  },
  debugWrapperCollapseContent: {
    backgroundColor: 'inherit',
    borderBottom: 'none',
  },
}));
