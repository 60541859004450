import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'VehicleOwnershipDateForm' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    ...theme.mixins.form,
    '& .MuiFormLabel-root': {
      paddingBottom: 0,
    },
  },
  form: {
    [theme.breakpoints.down('md')]: {
      marginTop: 0,
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row',
      columnGap: 30,
    },
  },
  subTitle: theme.typography.body4,
  next: {
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  nextText: {
    ...theme.typography.h3,
    marginTop: 40,
  },
  text: {
    marginTop: 15,
  },
}));
