import { useCallback } from 'react';

import { Select } from '@ecp/features/sales/shared/components';
import { PARTNER_SEGMENT } from '@ecp/features/sales/shared/constants';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';
import type { AnswerValue, Option } from '@ecp/types';

interface Props extends QuestionProps {
  onSegmentSelect?: (value: AnswerValue) => void;
  selectedSegment: string;
  segmentOptions: Option[];
}

export const SegmentQuestion: React.FC<Props> = (props) => {
  const segmentField = useField(PARTNER_SEGMENT);
  const { segmentOptions, selectedSegment, onSegmentSelect } = props;

  const actionOnChange = useCallback(
    (value: AnswerValue): void => {
      onSegmentSelect?.(value);
    },
    [onSegmentSelect],
  );

  return (
    <Select
      {...segmentField.props}
      id='segment'
      value={selectedSegment}
      label='Select a segment'
      options={segmentOptions}
      autoSelect
      actionOnChange={actionOnChange}
      actionOnComplete={undefined}
      disabled={!segmentOptions.length}
    />
  );
};
