import { useAddFields } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { getShownFields, useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { Fields } from '@ecp/types';

interface Props extends QuestionProps {
  driverRef: string;
  personRef: string;
}

export const DriverLicenseRelationToInsuredQuestion: React.FC<Props> = (props) => {
  const { driverRef, personRef } = props;
  const useDriverField = useFieldWithPrefix(driverRef, 'driver.<id>');
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const {
    props: { value: name = '' },
  } = usePersonField('firstName');
  const possessiveNameOrYour = name ? `${name}'s` : 'your';
  const relationToInsured = useDriverField('license.relationshipToInsured');
  useAddFields({ relationToInsured });
  const dispatch = useDispatch();

  const filteredFields = dispatch(
    getShownFields({ fields: { [relationToInsured.key]: relationToInsured } }),
  ) as Fields;

  if (!relationToInsured.exists || !filteredFields[relationToInsured.key]) return null;

  return (
    <Select
      {...relationToInsured.props}
      id='RelationToInsured'
      inputButtonAriaLabel='RelationToInsured'
      fullWidth={false}
      data-testid='relationToInsured'
      groupLabel={`What is ${possessiveNameOrYour} relationship to the insured?`}
      trackingName='RelationToInsured'
      trackingLabel={relationToInsured.props.value}
    />
  );
};
