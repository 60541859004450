export const AUTO_COVERAGE_KEYS = [
  'bodilyInjury',
  'propertyDamage',
  'uninsuredMotorist',
  'medicalExpense',
];

export const AUTO_COVERAGE_LABELS: { [key: string]: string } = {
  bodilyInjury: 'Bodily Injury',
  propertyDamage: 'Property Damage',
  uninsuredMotorist: 'Uninsured Motorist',
  medicalExpense: 'Medical Expense',
};

export const AUTO_COVERAGE_TOOLTIPS: { [key: string]: string } = {
  bodilyInjury:
    'Coverage may pay damages owed to others as a result of physical injuries, sickness, disease or death of any person for which you are legally liable as a result of a covered accident.',
  propertyDamage:
    "Coverage may pay for damage to other people's property resulting from an accident caused by your vehicle for which you are legally responsible and is covered under your policy",
  uninsuredMotorist:
    'May cover expenses for bodily injury damages or death caused by a driver who did not have liability. Applies to damages for you as well as passengers in your vehicle for a covered loss',
  medicalExpense:
    ' Coverage may pay for expenses for all passengers in the insured vehicle for medical or funeral expenses resulting from a covered vehicle related accident.',
};

export const HOME_COVERAGE_KEYS = [
  'dwellingCoverage',
  'personalPosessions',
  'personalLiability',
  'standardDeductible',
];

export const HOME_COVERAGE_LABELS: { [key: string]: string } = {
  dwellingCoverage: 'Dwelling Coverage',
  personalPosessions: 'Personal Posessions',
  personalLiability: 'Personal Liability',
  standardDeductible: 'Standard Deductible',
};

export const HOME_COVERAGE_TOOLTIPS: { [key: string]: string } = {
  dwellingCoverage:
    'This amount is the minimum coverage needed to adequately insure your residence. It is an estimate based on the current cost to rebuild your home, not including the value of the land.',
  personalPosessions:
    'Protect your possessions with additional coverage for the things that are most important to you',
  personalLiability:
    'This offers protection against property damage or bodily injury to others that you or a member of your household accidentally caused',
  standardDeductible:
    "The amount you'd have to pay out of pocket if you experienced a covered loss caused by an incident other than hurricane, earthquake, or wind/hail.",
};

/* State specific feature for MI: Allows user to be notified if they don't qualify for HO3 but can call for a H02 quote. User will proceed to 
   H02Confirm Page. https://theexperimentationlab.atlassian.net/browse/CSUI-1086*/
export const HO2_SESSION_STORAGE_KEY = 'MI-CNQ-HO2-Quote';
