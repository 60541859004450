import { Dialog, PhoneLink } from '@ecp/features/sales/shared/components';
import { partner } from '@ecp/partners';

import { useStyles } from './SpecialCircumstancesModal.styles';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const SpecialCircumstancesModal: React.FC<Props> = (props) => {
  const { open, onClose } = props;
  const { classes } = useStyles();

  const InfoList = (): React.ReactElement => (
    <div className={classes.specialCircumstancesList}>
      <ul>
        <li>Spouse has a separate vehicle/policy.</li>
        <li>Spouse is military personnel deployed overseas and not driving the vehicle.</li>
        <li>
          Spouse has never been licensed, is not licensed by choice, or has a suspended license.
        </li>
        <li>
          Spouse is on a missionary trip, incarcerated, overseas for work, or not driving for
          another reason.
        </li>
        <li>Spouse does not live in the same residence.</li>
        <li>I am recently married or divorced.</li>
        <li>
          I have a multi-generational household with an uneven number of married drivers who may be
          non-operators.{' '}
        </li>
      </ul>
      <p>
        Call{' '}
        <PhoneLink
          trackingName='special_circumstances_phone_link'
          trackingLabel='phone_number'
          withUnderlinedLinkStyle
          number={partner.shared.salesPhoneNumber}
        />{' '}
        to speak to a licensed advisor who can answer your questions.
      </p>
    </div>
  );

  const titleText = 'Call us if any apply:';

  return (
    <Dialog
      actionButtonLabel='Close'
      titleText={titleText}
      actionButtonOnClick={onClose}
      open={open}
      buttonPlacement='right'
      onClose={onClose}
      trackingNameButton='special_circumstances_close_button'
      trackingLabelButton='close'
      trackingNameCloseIcon='special_circumstances_x_button'
      trackingLabelCloseIcon='x'
      aria-labelledby='special-circumstances-modal'
      data-testid='specialCircumstancesModal'
    >
      <div className={classes.dialogContainer}>
        <InfoList />
      </div>
    </Dialog>
  );
};
