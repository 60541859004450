export interface Metadata {
  ageLicensedDisabled: boolean;
}
/**
 * We need this workaround for now because SAPI doesn't have the capability to fix this on their side.
 * They can't remove the “driver.<id>.insurance.prior.*” questions because they are needed for the PNI.
 */

const metadata: Metadata = {
  ageLicensedDisabled: false,
};

export default metadata;
