import { Divider } from '@mui/material';

import { GridItem } from '@ecp/components';
import { useIsMobile } from '@ecp/themes/base';

import { DriverDiscountsQuestion, VehicleDiscountsQuestion } from '../../components';
import { AutoDiscountsQuestions } from '../AutoDiscountsForm';

interface AutoShortDiscountsFormQuestionsProps {
  isContinueClicked: boolean;
  subTitle: string;
}

export const AutoShortDiscountsFormQuestions: React.FC<AutoShortDiscountsFormQuestionsProps> = (
  props,
) => {
  const { subTitle } = props;
  const isMobile = useIsMobile();

  return (
    <>
      <VehicleDiscountsQuestion {...props} />
      {!isMobile && (
        <GridItem topSpacing='lg' xs={12}>
          <Divider aria-hidden='true' />
        </GridItem>
      )}
      <DriverDiscountsQuestion {...props} />
      {!isMobile && (
        <GridItem topSpacing='lg' xs={12}>
          <Divider aria-hidden='true' />
        </GridItem>
      )}
      <AutoDiscountsQuestions header={<h2>{subTitle}</h2>} />
    </>
  );
};
