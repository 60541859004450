import { useCallback, useState } from 'react';

import type { TypographyProps } from '@mui/material';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { parseDollar } from '@ecp/utils/common';
import { formatDate } from '@ecp/utils/date';

import { TooltipWithIcon } from '@ecp/components';
import { callToActions } from '@ecp/features/sales/shared/components';
import { PrefillFlow } from '@ecp/features/sales/shared/constants';
import { nextPage, PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import {
  describeOfferSummary,
  getAutoDeltaQuestionExists,
  getCurrentFlows,
  getPolicyStartDates,
  isConfirmationRequired,
  isOfferEstimated,
  isOfferQuoteNoBind,
  patchUserSelectionAndUpdateOffer,
  updateOfferProductChanged,
  updateProductLobUserSelection,
} from '@ecp/features/sales/shared/store';
import type { OfferDetails, QuoteSummaryOffers } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type {
  CarrierDisplayName,
  Product,
  ProductDisplayName,
  ProductName,
} from '@ecp/features/shared/product';
import {
  getCarrierDisplayName,
  getCarrierNameFromProduct,
  getProductDisplayNameFromProduct,
  getProductNameFromProduct,
  getReducedProductNameFromProduct,
  isProductRenters,
  LineOfBusiness,
} from '@ecp/features/shared/product';

import { useStyles } from './AgentDashboardTable.styles';

interface Column {
  id: string;
  label: string | React.ReactElement;
  minWidth?: number;
  align?: TypographyProps['align'];
  format?: (value: number) => string;
}

interface OfferData {
  carrierDisplayName: CarrierDisplayName;
  products: (ProductData | BundleData)[];
}

interface BundleData {
  isBundle: boolean;
  isPurchased: boolean;
  isLocked: boolean;
  auto?: ProductData;
  home?: ProductData;
  renters?: ProductData;
  button?: React.ReactElement;
  displayNotAvailableAndHideRecall: boolean;
}

interface ProductData {
  isBundle: boolean;
  policyStartDate?: string;
  name: ProductName;
  isPurchased: boolean;
  isLocked: boolean;
  displayName: ProductDisplayName;
  monthlyPremium?: number;
  yearlyPremium: number;
  quoteDetails: {
    description: string;
    value: string;
  }[];
  button?: React.ReactElement;
}

interface Props {
  offers: QuoteSummaryOffers;
  hasExpiredQuotes?: boolean;
}

const isBundleData = (obj: ProductData | BundleData): obj is BundleData => {
  return obj.isBundle;
};

const isProductData = (obj: ProductData | BundleData): obj is ProductData => {
  return !obj.isBundle;
};

const buttonVariant = 'iconText';
const buttonText = 'Recall quote';

const gaTrackClick = (trackingLabel: string): void => {
  trackClick({ action: 'RecallQuoteLink', label: trackingLabel });
};

export const AgentDashboardTable: React.FC<Props> = (props) => {
  const { offers, hasExpiredQuotes } = props;
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();

  const flow = useSelector(getCurrentFlows);
  const isFlowShortAuto = flow.auto === PrefillFlow.SHORT;
  const navigateToCoverages = useNavigateToPage(PagePath.COVERAGES);
  const navigateToAutoDelta = useNavigateToPage(PagePath.AUTO_DELTA);
  const policyStartDates = useSelector(getPolicyStartDates);
  const [recalcInProgressForSelectedOffer, setRecalcInProgressForSelectedOffer] = useState(false);

  const hasAutoDeltaQuestions = useSelector(getAutoDeltaQuestionExists);

  const processOfferDetail = (
    offerDetails: OfferDetails,
  ): { carrierDisplayName: CarrierDisplayName; products: ProductData[] } =>
    Object.keys(offerDetails).reduce(
      (acc, product) => {
        const carrierName = getCarrierNameFromProduct(product);
        const carrierDisplayName = getCarrierDisplayName(carrierName);
        const productName = getProductNameFromProduct(product);
        const productReducedName = getReducedProductNameFromProduct(product);
        const productDisplayName = getProductDisplayNameFromProduct(product);
        const offerInfo = offerDetails[product];
        if (!offerInfo) return acc;
        const productData: ProductData = {
          isBundle: false,
          name: productName,
          isPurchased: offerInfo.isPurchased,
          isLocked: offerInfo.isLocked,
          displayName: productDisplayName,
          policyStartDate: policyStartDates[productReducedName],
          yearlyPremium: offerInfo.fullPremium?.totalPremium,
          monthlyPremium: offerInfo.monthlyPremium?.installmentPayment,
          quoteDetails: Object.keys(offerInfo.coverages).map((coverageKey) => {
            return {
              description: coverageKey,
              value: offerInfo.coverages[coverageKey],
            };
          }),
        };
        acc.carrierDisplayName = carrierDisplayName;
        acc.products.push(productData);

        return acc;
      },
      { carrierDisplayName: undefined, products: [] } as unknown as ReturnType<
        typeof processOfferDetail
      >,
    );

  const processBundle = (
    offerDetails: OfferDetails,
  ): { carrierDisplayName: CarrierDisplayName; bundleData: BundleData } => {
    const { carrierDisplayName, products } = processOfferDetail(offerDetails);
    const isPurchased = products.every((product) => product.isPurchased === true);
    const isLocked = products.filter((product) => product.isLocked).length > 0;
    const displayNotAvailableAndHideRecall = !products.every(
      (product) => product.isPurchased === false,
    );
    const bundleData: BundleData = {
      isBundle: true,
      isPurchased: isPurchased,
      isLocked: isLocked,
      displayNotAvailableAndHideRecall: displayNotAvailableAndHideRecall,
    };
    products.forEach((product: ProductData) => {
      bundleData[product.name] = product;
    });

    return { carrierDisplayName, bundleData };
  };

  const navigateToNext = useCallback(
    async (indicative: boolean) => {
      const path = nextPage(hasAutoDeltaQuestions, isFlowShortAuto, indicative);

      if (path === PagePath.AUTO_DELTA) {
        await navigateToAutoDelta();
      } else {
        await navigateToCoverages();
      }
    },
    [isFlowShortAuto, hasAutoDeltaQuestions, navigateToAutoDelta, navigateToCoverages],
  );

  // const offerSetId = useSelector(getOfferSetId);
  // This is for Bindable offers
  // Quotes -> Coverages
  // Wait for the UserSelection key to be set, update SelectedOffer to get appropriate discounts on coverages page
  const handleBuy = useCallback(
    async (trackingLabel: string, offerProductsSelected: Product[]) => {
      if (offerProductsSelected.length > 0) {
        setRecalcInProgressForSelectedOffer(true);
        await dispatch(updateOfferProductChanged(offerProductsSelected));
        await dispatch(updateProductLobUserSelection(offerProductsSelected));
        await dispatch(patchUserSelectionAndUpdateOffer());
        setRecalcInProgressForSelectedOffer(false);
        gaTrackClick(trackingLabel);
        await navigateToNext(false);
      }
    },
    [dispatch, navigateToNext],
  );

  // This is for indicative offers. In case of bundling,
  // even if auto product is indicative, the offer is
  // indicative. Property offers are always bindable.
  const handleFinishAndBuy = useCallback(
    async (trackingLabel: string, offerProductsSelected: Product[]) => {
      if (offerProductsSelected.length > 0) {
        await dispatch(updateProductLobUserSelection(offerProductsSelected));
        await dispatch(updateOfferProductChanged(offerProductsSelected));
        gaTrackClick(trackingLabel);
        await navigateToNext(true);
      }
    },
    [dispatch, navigateToNext],
  );

  // Can be used by carriers who fulfil the condition
  const handleRetrieveLinkClick = useCallback(
    async (trackingLabel: string, offerProductsSelected: Product[], retrieveLink?: string) => {
      if (offerProductsSelected.length > 0) {
        gaTrackClick(trackingLabel);
        await dispatch(updateProductLobUserSelection(offerProductsSelected));
        await dispatch(updateOfferProductChanged(offerProductsSelected));
      }
      if (retrieveLink) {
        window.location.href = retrieveLink;
      }
    },
    [dispatch],
  );

  const createRecallButton = (
    quoteSummary: Props['offers'],
    selectedLob: LineOfBusiness,
    isPurchased: boolean,
    isLocked: boolean,
    displayNotAvailableAndHideRecall: boolean,
  ): React.ReactElement => {
    const {
      autoBundledOffer,
      autoOnlyOffer,
      homeBundledOffer,
      homeOnlyOffer,
      rentersBundledOffer,
      rentersOnlyOffer,
      carrierName,
      isBundleSelected,
      offerProductsSelected,
      trackingLabel,
    } = describeOfferSummary(quoteSummary, selectedLob);

    // Different checks for Actions
    const { isEstimate, estimateTrackingName } = isOfferEstimated({
      autoOnlyOffer,
      autoBundledOffer,
      selectedLob,
    });

    // If product is Purchased display Not available instead of Recall button
    if (displayNotAvailableAndHideRecall) {
      return (
        <div className={classes.recallPurchasedContainer}>
          <p>Not available</p>
          {isPurchased && <p className={classes.recallPurchasedPolicy}>Purchased policy</p>}
        </div>
      );
    }

    const retrieveLink =
      selectedLob === LineOfBusiness.AUTO && autoOnlyOffer && autoOnlyOffer.retrieveLink
        ? autoOnlyOffer.retrieveLink
        : undefined;
    const disabled = isLocked;
    const { actions: button } = callToActions({
      carrierName,
      estimateTrackingName,
      isBundleSelected,
      isEstimate,
      isQuoteNoBind: isOfferQuoteNoBind({
        autoOnlyOffer,
        homeOnlyOffer,
        rentersOnlyOffer,
        autoBundledOffer,
        homeBundledOffer,
        rentersBundledOffer,
        selectedLob,
      }),
      isConfirmationRequired: isConfirmationRequired({
        autoOnlyOffer,
        autoBundledOffer,
        selectedLob,
      }),
      retrieveLink,
      selectedLob,
      trackingLabel,
      handleBuy: () => {
        handleBuy(trackingLabel, offerProductsSelected);
      },
      handleFinishAndBuy: () => {
        handleFinishAndBuy(trackingLabel, offerProductsSelected);
      },
      handleRetrieveLinkClick: () => {
        handleRetrieveLinkClick(trackingLabel, offerProductsSelected, retrieveLink);
      },
      inProgress: recalcInProgressForSelectedOffer && !disabled,
      buttonVariant,
      buttonText,
      className: classes.recallButton,
      disabled,
    });

    return button;
  };

  const displayData = (): OfferData => {
    const result: OfferData = {
      carrierDisplayName: undefined as unknown as CarrierDisplayName,
      products: [],
    };

    if (offers.bundle) {
      const { carrierDisplayName, bundleData } = processBundle(offers.bundle);
      result.carrierDisplayName = carrierDisplayName;
      const selectedLob = Object.keys(offers.bundle).reduce(
        (acc, product) => (isProductRenters(product) ? LineOfBusiness.BUNDLE_AUTO_RENTERS : acc),
        LineOfBusiness.BUNDLE,
      );
      bundleData.button = createRecallButton(
        offers,
        selectedLob,
        bundleData.isPurchased,
        bundleData.isLocked,
        bundleData.displayNotAvailableAndHideRecall,
      );
      result.products.push(bundleData);
    }

    const productNameToLob: Record<ProductName, LineOfBusiness> = {
      auto: LineOfBusiness.AUTO,
      home: LineOfBusiness.HOME,
      renters: LineOfBusiness.RENTERS,
    };
    const productKeys = Object.keys(offers);
    productKeys.forEach((productKey) => {
      const offerDetails = offers[productKey];
      if (offerDetails) {
        const { carrierDisplayName, products } = processOfferDetail(offerDetails);
        result.carrierDisplayName ??= carrierDisplayName;
        if (products && products.length === 1) {
          const selectedLob = productNameToLob[products[0].name];
          const displayNotAvailableAndHideRecall = offers.bundle
            ? processBundle(offers.bundle).bundleData.displayNotAvailableAndHideRecall
            : products[0].isPurchased;
          products[0].button = createRecallButton(
            offers,
            selectedLob,
            products[0].isPurchased,
            products[0].isLocked,
            displayNotAvailableAndHideRecall,
          );
          result.products.push(products[0]);
        }
      }
    });

    return result;
  };

  const premiumLabel = hasExpiredQuotes ? (
    <>
      Premium
      <TooltipWithIcon
        className={classes.premiumLabelTooltip}
        title='Updated premium with new policy start date.'
      />
    </>
  ) : (
    'Premium'
  );

  const columns: Column[] = [
    { id: 'product', label: 'Product', minWidth: 110, align: 'center' },
    { id: 'policyStartDate', label: 'Policy start date', minWidth: 160 },
    { id: 'premium', label: premiumLabel, minWidth: 120 },
    { id: 'quoteDetails', label: 'Quote details', minWidth: 260 },
    { id: 'quoteActions', label: 'Recall', minWidth: 135 },
  ];

  const rows: React.ReactElement[] = [];
  const createRow = (
    ctx: {
      carrierDisplayName: CarrierDisplayName;
      productRowSpan: number;
      productDisplayName: ProductDisplayName | 'Bundle';
      premiumIdx: number;
      premiumRowSpan: number;
      bundleProduct?: ProductDisplayName;
      monthlyPremium?: number;
      yearlyPremium: number;
      offerIdx: number;
    },
    product: ProductData,
    bundle?: BundleData,
  ): void => {
    const {
      carrierDisplayName,
      productRowSpan,
      productDisplayName,
      premiumIdx,
      premiumRowSpan,
      bundleProduct,
      monthlyPremium,
      yearlyPremium,
      offerIdx,
    } = ctx;
    product.quoteDetails.forEach((detail, detailIdx: number) => {
      rows.push(
        <TableRow
          key={`${productDisplayName} ${yearlyPremium} ${detailIdx}`}
          className={cx(classes.tableRow, offerIdx % 2 === 1 && classes.tableRowOdd)}
          tabIndex={-1}
        >
          {premiumIdx === 0 && detailIdx === 0 && (
            <TableCell
              className={classes.tableCellNoLeftBorder}
              data-testid={`${productDisplayName}${carrierDisplayName}Label`}
              rowSpan={productRowSpan}
            >
              <p>
                <strong>{productDisplayName}</strong>
              </p>
            </TableCell>
          )}
          {detailIdx === 0 && (
            <TableCell rowSpan={premiumRowSpan}>
              {product.policyStartDate && formatDate(product.policyStartDate)}
            </TableCell>
          )}
          {detailIdx === 0 && (
            <TableCell rowSpan={premiumRowSpan}>
              {bundleProduct && (
                <p>
                  <strong>{bundleProduct}</strong>
                </p>
              )}
              {monthlyPremium && (
                <p>
                  {parseDollar(`$${monthlyPremium}`)}
                  <span className={classes.premiumLabel}>/monthly</span>
                </p>
              )}
              {yearlyPremium && (
                <p>
                  {parseDollar(`$${yearlyPremium}`)}{' '}
                  <span className={classes.premiumLabel}>/pay in full</span>
                </p>
              )}
            </TableCell>
          )}
          <TableCell className={classes.quoteDetailCell}>
            <p>{detail.description}</p>
            <p>{detail.value}</p>
          </TableCell>
          {premiumIdx === 0 && detailIdx === 0 && (
            <TableCell
              data-testid={
                bundle ? `Bundle${carrierDisplayName}Button` : `Product${carrierDisplayName}Button`
              }
              rowSpan={productRowSpan}
            >
              {bundle ? bundle.button : product.button}
            </TableCell>
          )}
        </TableRow>,
      );
    });
  };

  const { carrierDisplayName, products } = displayData();
  const offerIdx = 1;
  products.forEach((product: ProductData | BundleData) => {
    if (isBundleData(product)) {
      // TODO make this more dynamic; BundleData has additional properties like button isBundle making it more complicated
      const productNames = ['auto', 'home', 'renters'] as const;
      const productRowSpan = productNames.reduce((acc, productName) => {
        const productData = product[productName];
        if (productData) {
          const premiumRowSpan = productData.quoteDetails.length;

          return acc + premiumRowSpan;
        }

        return acc;
      }, 0);
      productNames.forEach((productName, index) => {
        const productData = product[productName];
        if (productData) {
          const premiumRowSpan = productData.quoteDetails.length;
          createRow(
            {
              carrierDisplayName,
              productRowSpan,
              productDisplayName: 'Bundle',
              premiumIdx: index,
              premiumRowSpan,
              bundleProduct: productData.displayName,
              yearlyPremium: productData.yearlyPremium,
              monthlyPremium: productData.monthlyPremium,
              offerIdx,
            },
            productData,
            product,
          );
        }
      });
    } else if (isProductData(product)) {
      const productData = product;
      const productRowSpan = product.quoteDetails.length;
      createRow(
        {
          carrierDisplayName,
          productRowSpan,
          productDisplayName: productData.displayName,
          premiumIdx: 0,
          premiumRowSpan: productRowSpan,
          yearlyPremium: product.yearlyPremium,
          monthlyPremium: product.monthlyPremium,
          offerIdx,
        },
        product,
      );
    }
  });

  return (
    <div data-testid='agentDashboardTable' className={classes.root}>
      <Grid item xs={12} className={classes.tableWrapper}>
        <Table style={{ width: '100%' }} stickyHeader>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontSize: 14 }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>{rows}</TableBody>
        </Table>
      </Grid>
    </div>
  );
};
