import type { LineOfBusinessUnbundled } from '@ecp/features/shared/product';
import { LineOfBusiness } from '@ecp/features/shared/product';
import { IconCardAuto, IconCardHome, IconCardRent } from '@ecp/themes/base';
import type { CardOption } from '@ecp/types';

export const options: Record<LineOfBusinessUnbundled, CardOption> = {
  [LineOfBusiness.AUTO]: {
    icon: <IconCardAuto />,
    label: 'Auto',
    value: LineOfBusiness.AUTO,
  },
  [LineOfBusiness.HOME]: {
    icon: <IconCardHome />,
    label: 'Home',
    value: LineOfBusiness.HOME,
  },
  [LineOfBusiness.RENTERS]: {
    icon: <IconCardRent />,
    value: LineOfBusiness.RENTERS,
    label: 'Renters',
  },
};

export const supportedLobOptions = {
  options: [
    options[LineOfBusiness.AUTO],
    options[LineOfBusiness.HOME],
    options[LineOfBusiness.RENTERS],
  ] as CardOption[],
};

export const disableRentersBundle = false;

export { productMetadata } from '@ecp/features/sales/shared/metadata';
