import type { RouteProps } from '@ecp/utils/routing';
import { Redirect, Route } from '@ecp/utils/routing';

import { CheckoutErrorPage } from '@ecp/features/sales/checkout';
import {
  AutoDeltaPage,
  AutoProfilePage,
  AutoShortDiscountsPage,
  DriverAssignmentPage,
  DriverPage,
  VehicleOwnershipDatePage,
  VehiclePage,
} from '@ecp/features/sales/quotes/auto';
import {
  HomeBasicPage,
  HomeExteriorPage,
  HomeInteriorPage,
  HomeMarketValuePage,
} from '@ecp/features/sales/quotes/property/home';
import { PropertyDetailsPage } from '@ecp/features/sales/quotes/property/renters';
import { PagePath } from '@ecp/features/sales/shared/routing';
import {
  AgentDashboardPage,
  AutoSelectDiscountsPage,
  DiscountsPage,
  ErrorNotFoundPage,
  MembershipPage,
  PersonAddressPage,
  RatingCriteriaPage,
  SecondaryNamedInsuredPage,
  ThirdPartyInterestPage,
} from '@ecp/features/sales/shell';

import {
  AgentCheckoutPage,
  AgentCheckoutRetryPage,
  AgentLoginPage,
  AgentPersonPage,
  CoveragesSummaryPage,
  LandingPage,
  QuotesPage,
} from '../../views';
import { AgentPostBindPage } from '../../views/AgentPostBindPage';
import { ProtectedRoute } from '../ProtectedRoute';

export const dynamicRoutes = [
  // Agent pages
  <Route path={PagePath.AGENT_LOGIN} component={AgentLoginPage} />,
  <ProtectedRoute path={PagePath.AGENT_DASHBOARD} component={AgentDashboardPage} />,
  // Quick quote flow pages
  <ProtectedRoute path={PagePath.BUNDLE_DISCOUNTS} component={DiscountsPage} />,
  <ProtectedRoute path={PagePath.LANDING} component={LandingPage} />,
  <ProtectedRoute path={PagePath.MEMBERSHIP} component={MembershipPage} />,
  <ProtectedRoute
    path={PagePath.PERSON_ADDRESS}
    component={PersonAddressPage as RouteProps['component']}
  />,
  <ProtectedRoute path={PagePath.PERSON} component={AgentPersonPage} />,
  <ProtectedRoute path={PagePath.QUOTES} component={QuotesPage} />,
  // Indicative quote flow pages
  <ProtectedRoute path={PagePath.EDIT_AUTO_PROFILE} component={AutoProfilePage} />,
  <ProtectedRoute path={PagePath.DRIVER_ASSIGNMENT} component={DriverAssignmentPage} />,
  <ProtectedRoute path={PagePath.VEHICLE_PROFILE} component={VehiclePage} />,
  <ProtectedRoute path={PagePath.DRIVER_PROFILE} component={DriverPage} />,
  <ProtectedRoute path={PagePath.AUTO_DISCOUNTS} component={AutoSelectDiscountsPage} />,
  <ProtectedRoute path={PagePath.HOME_BASIC} component={HomeBasicPage} />,
  <ProtectedRoute path={PagePath.HOME_EXTERIOR} component={HomeExteriorPage} />,
  <ProtectedRoute path={PagePath.HOME_INTERIOR} component={HomeInteriorPage} />,
  <ProtectedRoute path={PagePath.HOME_MARKET_VALUE} component={HomeMarketValuePage} />,
  // App pages
  <ProtectedRoute path={PagePath.SECONDARY_NAMED_INSURED} component={SecondaryNamedInsuredPage} />,
  <ProtectedRoute path={PagePath.COVERAGES} component={CoveragesSummaryPage} />,
  <ProtectedRoute path={PagePath.AUTO_DELTA} component={AutoDeltaPage} />,
  <ProtectedRoute path={PagePath.THIRD_PARTY_INTEREST} component={ThirdPartyInterestPage} />,

  <ProtectedRoute path={PagePath.CHECKOUT_RETRY} component={AgentCheckoutRetryPage} />,
  <ProtectedRoute path={PagePath.CHECKOUT_ERROR} component={CheckoutErrorPage} />,
  <ProtectedRoute path={PagePath.CHECKOUT} component={AgentCheckoutPage} />,
  <ProtectedRoute path={PagePath.POST_BIND} component={AgentPostBindPage} />,
  <ProtectedRoute path={PagePath.RATING_CRITERIA} component={RatingCriteriaPage} />,
  <ProtectedRoute path={PagePath.VEHICLE_OWNERSHIP_DATE} component={VehicleOwnershipDatePage} />,
  <ProtectedRoute path={PagePath.AUTO_DISCOUNTS_SHORT} component={AutoShortDiscountsPage} />,
  <ProtectedRoute path={PagePath.HOME_DISCOUNTS} component={DiscountsPage} />,
  <ProtectedRoute path={PagePath.RENTERS_DISCOUNTS} component={DiscountsPage} />,
  <ProtectedRoute path={PagePath.RENTERS_PROPERTY_DETAILS} component={PropertyDetailsPage} />,

  <Route path={PagePath.DEFAULT}>
    <Redirect to={PagePath.LANDING} replace />
  </Route>,
  // Fallback page
  <Route component={ErrorNotFoundPage} />,
];
