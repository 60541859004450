import { LineOfBusiness } from '@ecp/features/shared/product';
import type { CardOption } from '@ecp/types';

import { options } from './metadata.js';

export const supportedLobOptions = {
  options: [options[LineOfBusiness.RENTERS]] as CardOption[],
};

export { productMetadata } from '@ecp/features/sales/shared/metadata';
