import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutoShortDiscountsForm' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    ...theme.mixins.form,
    marginTop: 0,
  },
  subTitle: {
    ...theme.typography.subtitle,
    [theme.breakpoints.down('md')]: {
      ...theme.typography.body4,
    },
  },
  next: {
    marginTop: 40,
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
}));
