import { Accordion, AccordionDetails, AccordionSummary, Container } from '@mui/material';

import { Link, Page } from '@ecp/features/sales/shared/components';
import { IconUIExpandMore } from '@ecp/themes/base';

import metadata from './metadata';
import { useStyles } from './UseOfCreditDisclosurePage.styles';

export const UseOfCreditDisclosurePage: React.FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Page>
        <Container classes={{ root: classes.container }}>
          <h1 className={classes.pageTitle}>Use of credit disclosure</h1>
          <p className={classes.introText}>
            Learn more about how we use insurance-based credit information in your state.
          </p>

          {metadata.disclosureGroups?.map((disclosureGroup, index) => {
            const { groupTitle, disclosures } = disclosureGroup;

            return (
              <Accordion className={classes.accordionSection}>
                <AccordionSummary
                  expandIcon={<IconUIExpandMore />}
                  className={classes.accordionTitle}
                  aria-controls={`panel${index}a-content`}
                  id={`panel${index}a-header`}
                >
                  <p>{groupTitle}</p>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetails}>
                  {disclosures}
                </AccordionDetails>
              </Accordion>
            );
          })}
          {metadata.disclosureLink && (
            <div className={classes.linkContainer}>
              <Link href={metadata.disclosureLink} target='_blank' rel='noreferrer'>
                {metadata.disclosureLink}
              </Link>
            </div>
          )}
        </Container>
      </Page>
    </div>
  );
};
