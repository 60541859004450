import { datadogLog } from '@ecp/utils/logger';
import type { RequestInitExtended } from '@ecp/utils/network';
import { fetchWithTimeout } from '@ecp/utils/network';

import { env } from '@ecp/env';
import { getSapiAuthToken } from '@ecp/features/sales/shared/utils/network';

interface ConsumerData {
  requesterName: string;
  requestEffectiveDate: string;
  requestGUID?: string;
}

interface DLValidationRequest {
  dlNumber: string;
  dlAlphaStateCode: string;
}

interface DLValidationResponse {
  dlNumber: string;
  dlAlphaStateCode: string;
  isValid?: 'true' | 'false';
  message?: string;
}

interface ErrorDetails {
  errorCode: string;
  errorMessage: string;
  NestedFault: string;
  stackTrace: string;
}

interface ApiMessage {
  descrption: string;
  code: string;
  level: string;
}

interface ApiStatus {
  reason: string;
  code: number;
  transactionID: string;
  messages: ApiMessage[];
}

export interface ValidateDriverLicenseResponse {
  ConsumerData?: ConsumerData;
  DLValidationResponse?: DLValidationResponse;
  ErrorDetails?: ErrorDetails;
  status?: ApiStatus;
}

export interface ValidateDriversLicenseRequest {
  ConsumerData: ConsumerData;
  DLValidationRequest: DLValidationRequest;
}

export const postDriverLicenseValidation = async ({
  driverLicenseNumber,
  state,
  requestEffectiveDate,
}: {
  driverLicenseNumber: string;
  state: string;
  requestEffectiveDate: string;
}): Promise<ValidateDriverLicenseResponse> => {
  // This will be sure STATE_CODE.OK, OK, etc. return the desired state code
  const cleanStateCode = state?.slice(-2).toUpperCase();
  const generateValidationResponse = (
    isValid: 'true' | 'false',
  ): ValidateDriverLicenseResponse => ({
    DLValidationResponse: {
      dlNumber: driverLicenseNumber,
      dlAlphaStateCode: cleanStateCode,
      isValid,
    },
  });
  const url = `${env.externalReportApiRoot}/validatedriverslicense`;
  const headers: HeadersInit = {
    'afi-appname': env.afiAppname,
    'afi-api-key': env.afiApiKey,
    'Content-Type': 'application/json',
    Authorization: `Bearer ${await getSapiAuthToken()}`,
  };
  const body: ValidateDriversLicenseRequest = {
    ConsumerData: {
      requesterName: 'AFHS',
      requestEffectiveDate,
    },
    DLValidationRequest: {
      dlNumber: driverLicenseNumber,
      dlAlphaStateCode: cleanStateCode,
    },
  };
  const init: RequestInitExtended = {
    headers,
    body: JSON.stringify(body),
    method: 'POST',
    params: {},
  };

  return fetchWithTimeout<ValidateDriverLicenseResponse>({ url, init })
    .then((resp) => resp.json())
    .catch((error) => {
      // if the API response contains a Syntax error (which can happen when the license # contains double quotes '"'),
      // we want to invalidate the field and ensure the value is not accepted/patched to SAPI

      // On server error/network error return successful result anyways, so internal server errors and network errors don't block the user
      const { status, statusText } = error;
      const message =
        error instanceof SyntaxError
          ? `Error parsing DL validation Api response - reason - [${error?.message}]`
          : `Exception calling DL validation Api ${
              status ? `- code: [${status}] - reason - [${statusText}]` : '- code: timeout'
            }`;

      datadogLog({
        logType: 'error',
        message,
        context: {
          logOrigin:
            'libs/features/sales/quotes/auto/src/api/driverLicenseValidation/driverLicenseValidation.ts',
          functionOrigin: 'postDriverLicenseValidation',
          responseStatus: status,
          statusText,
        },
        error,
      });

      if (error instanceof SyntaxError) {
        return Promise.resolve(generateValidationResponse('false'));
      } else {
        return Promise.resolve(generateValidationResponse('true'));
      }
    });
};
