import { PhoneLink } from '@ecp/features/sales/shared/components';
import type { Product } from '@ecp/features/shared/product';
import { partner } from '@ecp/partners';

import { NachaAuthorizationAgreement } from '../../../../../metadata/RecurringPayment/GeneralCheckoutDisclaimers';
import type { PaymentPlanAcknowledgement } from './metadata';
import baseMetadata from './metadata.js';

export type AcknowledgementFunction = (partnerName?: string) => React.ReactElement | undefined;

// TODO paymentPlan has to be enum vs string, getPaymentPlanForProduct typing is too wide
// TODO This overlaps with libs/features/sales/checkout/src/metadata/RecurringPayment/GeneralCheckoutDisclaimers.tsx
// TODO Short term decision is to maintain this in a separate location and look at the payment disclosure holistically
export type Metadata = Partial<Record<Product, Record<string, PaymentPlanAcknowledgement>>>;

const defaultAcknowledgement: PaymentPlanAcknowledgement = {
  default: () => (
    <>
      You authorize Homesite Group, Incorporated (Homesite) to initiate the payment listed on this
      page. Your initial payment will be submitted once you select "Purchase Policy". IMPORTANT: YOU
      ARE NOT YET ENROLLED IN AUTOMATIC BILLING. To finalize your payment plan enrollment, please
      visit the online servicing portal to set up automatic payments and to authorize automatic
      billing. Once enrolled, Homesite will make payment deductions in the amounts and on the due
      dates indicated on your billing statement. Refer to your bill for this schedule of deductions
      and any applicable fees. Changes to your policy or premium may change the amount debited. If
      you have questions regarding this payment or about how to enroll in automatic billing, please
      contact Homesite at{' '}
      <PhoneLink withUnderlinedLinkStyle number={partner.shared.salesPhoneNumber} />.
    </>
  ),
  Full: () => (
    <>
      I authorize Homesite Group, Incorporated to initiate the payment listed on this page. I
      understand that payment will be submitted once I select "Purchase Policy". If you have
      questions regarding this payment, please contact Homesite at{' '}
      <PhoneLink withUnderlinedLinkStyle number={partner.shared.salesPhoneNumber} />. Agents are
      available {partner.shared.salesPhoneHours}.
    </>
  ),
};

const metadata: Metadata = {
  ...baseMetadata,
  'homesite.home': {
    EFT: {
      default: () => (
        <NachaAuthorizationAgreement
          product='renters'
          phoneHours={partner.shared.salesPhoneHours}
          defaultComponent={defaultAcknowledgement.default()}
        />
      ),
      Full: () => (
        <NachaAuthorizationAgreement
          product='renters'
          phoneHours={partner.shared.salesPhoneHours}
          defaultComponent={defaultAcknowledgement.Full()}
        />
      ),
    },
    CreditCard: defaultAcknowledgement,
    Mortgage: defaultAcknowledgement,
    CostcoVisa: defaultAcknowledgement,
  },
};

export default metadata;
