import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'VehicleInfoQuestions' })((theme) => ({
  warningImage: {
    verticalAlign: 'middle',
    ...theme.mixins.setColorSvg(theme.palette.warning.dark),
  },
  warningContainer: {
    alignItems: 'center',
    backgroundColor: theme.palette.warning.light,
  },
  infoContainer: {
    marginTop: 10,
  },
  vehicleFieldsContainer: {
    alignContent: 'baseline',
  },
  vehicleInfoSelect: {
    '& .MuiFormControl-root': {
      marginBottom: 0,
    },
  },
  link: {
    ...theme.typography.button,
    color: theme.palette.primary.darkText,
    textDecoration: 'none',
    textTransform: 'capitalize',
    textAlign: 'right',
    whiteSpace: 'inherit',
  },
  divider: {
    margin: '20px 0px',
  },
}));
