import {
  IconCardAwayAtSchool,
  IconCardDefensiveDriver,
  IconCardGoodStudent,
} from '@ecp/themes/base';

import type { DriverDiscountCardOption } from './AutoSelectDiscounts.metadata.js';

// !TODO Replace with a static value ensuring it gets imported after feature flags got initialized
export const driverDiscountsOptions: () => Array<DriverDiscountCardOption> = () => {
  const options: Array<DriverDiscountCardOption> = [
    {
      value: 'goodStudent',
      label: 'Good student',
      // TODO: Remove this once SAPI is able to send the discounts questions based on display rules
      ageLimit: 25,
      icon: <IconCardGoodStudent />,
      helpText:
        'Full time college or high school student under age of 25. May need to provide a recent grade report maintaining a "B" average.',
    },
    {
      value: 'awayAtSchool',
      label: 'Away at school',
      // TODO: Remove this once SAPI is able to send the discounts questions based on display rules
      ageLimit: 25,
      additionalAgeLimit: 24,
      icon: <IconCardAwayAtSchool />,
      helpText: 'A driver who attends school more than 100 miles from home without a vehicle.',
    },
    {
      value: 'defensiveDriver',
      label: 'Defensive driver',
      // TODO: Remove this once SAPI is able to send the discounts questions based on display rules
      ageGreater: 54,
      icon: <IconCardDefensiveDriver />,
      helpText:
        'Driver must have completed a defensive driver course in the last 3 years. State criteria may apply.',
      stateOptions: {
        KY: {
          helpText:
            'To qualify for this discount the driver must be 55 years of age or older and completed the driving course within the last 5 years. Military personnel, regardless of age, may be eligible if they have completed a U.S. Armed Forces Defensive Driver course, please contact us for more information.',
        },
        DE: {
          value: 'defensiveDriverCourse',
        },
        DC: {
          helpText:
            'Applies to licensed drivers who have taken a defensive driver course in the last 2 years. State mandated criteria may apply.',
        },
      },
    },
    {
      value: 'youngVolunteer',
      label: 'Young Volunteer',
      // TODO: Remove this once SAPI is able to send the discounts questions based on display rules
      ageLimit: 25,
      icon: <IconCardAwayAtSchool />,
      helpText:
        'If under 25 and has completed 40 hours of volunteer work per year for a non-profit, could save money on your auto policy.',
    },
  ];

  return options;
};
