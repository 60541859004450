import { Container } from '@mui/material';

import { Page, ParticipatingCarriers } from '@ecp/features/sales/shared/components';

import { useStyles } from './ParticipatingCarriersPage.styles';

export const ParticipatingCarriersPage: React.FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Page>
        <Container classes={{ root: classes.container }}>
          <h1 className={classes.pageTitle}>Participating insurers</h1>
          <ParticipatingCarriers />
        </Container>
      </Page>
    </div>
  );
};
