import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DriverProfileForm' })((theme) => ({
  ...theme.mixins.formAll,
  divider: {
    backgroundColor: theme.palette.other.divider,
    marginTop: 30,
  },
  buttonsPanel: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  addButton: {
    [theme.breakpoints.up('md')]: {
      marginRight: 15,
    },
  },
  noPaddingTop: {
    paddingTop: 0,
  },
  fullWidthColumn: {
    '& .MuiFormControl-root': {
      [theme.breakpoints.up('md')]: {
        maxWidth: '100%',
      },
    },
  },
}));
