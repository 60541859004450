export interface Metadata {
  label?: string;
}

const metadata: Metadata = {
  label:
    'Do you want to sign up for paperless documents and billing? This discount requires that you enroll after you purchase your policy.',
};

export default metadata;
