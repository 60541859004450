import { formatDate } from '@ecp/utils/date';

import { PagePath } from '@ecp/features/sales/shared/routing';
import {
  getCurrentPage,
  getOfferProductsSelectedByType,
  getPolicyStartDates,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AutoProduct } from '@ecp/features/shared/product';
import { getProductNameFromProduct } from '@ecp/features/shared/product';

import { AgentScriptContent } from '../AgentScriptContent';
import type { PostBindProduct } from './metadata/PostBindContent';
import { postBindStatementsMetadata } from './metadata/PostBindContent';

export const PostBindScripts: React.FC = () => {
  const policyStartDates = useSelector(getPolicyStartDates);
  const postBindProducts: PostBindProduct[] = [];

  const currentPage = useSelector(getCurrentPage);
  const renderPostBindScripts = currentPage === PagePath.POST_BIND;

  const { auto: autoOfferProduct, property: propertyOfferProduct } = useSelector(
    getOfferProductsSelectedByType,
  );

  const autoProduct = (autoProduct: AutoProduct): PostBindProduct =>
    autoProduct === 'amfam.auto' ? 'midvaleAuto' : 'auto';
  if (autoOfferProduct) postBindProducts.push(autoProduct(autoOfferProduct));

  if (propertyOfferProduct) postBindProducts.push(getProductNameFromProduct(propertyOfferProduct));

  const { property, auto } = policyStartDates;

  const autoForms = undefined;
  const propertyForms = undefined;

  const postBindProduct = (): PostBindProduct => {
    if (postBindProducts.includes('midvaleAuto') && postBindProducts.length > 1) {
      if (postBindProducts.includes('home')) return 'ho3MidvaleBundle';
      if (postBindProducts.includes('renters')) return 'ho4MidvaleBundle';
    } else if (postBindProducts.length > 1) {
      if (postBindProducts.includes('home')) return 'ho3Bundle';
      if (postBindProducts.includes('renters')) return 'ho4Bundle';
    }

    return postBindProducts[0];
  };

  return renderPostBindScripts ? (
    <AgentScriptContent
      agentScript={postBindStatementsMetadata(
        postBindProduct(),
        formatDate(auto),
        formatDate(property),
        autoForms,
        propertyForms,
      )}
    />
  ) : null;
};
