import { Button, Link } from '@ecp/components';
import type { LineOfBusiness } from '@ecp/features/shared/product';

import { getUnderwriterName } from '../util';
import type { AgentScripts } from './types';

const customerfirstEmail = 'customersfirst@connectbyamfam.com';
const title = 'Credit disclosure';
const defaultAdditionalCreditDisclaimer = {
  title,
  text: 'In connection with this application for insurance, we will review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit history. We will use a third party in connection with the development of your insurance score.',
  spanishText:
    'En relación con esta solicitud de seguro, revisaremos su informe crediticio u obtendremos o utilizaremos un puntaje de seguro basado en la información contenida en ese historial crediticio. Utilizaremos a un tercero en relación con la determinación de su puntaje de seguro.',
};

export const additionalCreditDisclaimersMetadata = (
  stateCode: string,
  lob: LineOfBusiness,
): AgentScripts => {
  switch (stateCode) {
    case 'CA':
      return {
        title: 'California state-specific credit disclosure',
        text: 'Credit is not used for rating policies as it is prohibited by the state.',
        spanishText:
          'El crédito no se utiliza para las políticas de calificactión ya que está prohibido por el estado.',
      };
    case 'HI':
      return {
        title: 'Hawaii state-specific credit disclosure',
        text: 'Credit is not used for rating policies as it is prohibited by the state.',
        spanishText:
          'El crédito no se utiliza para las políticas de calificactión ya que está prohibido por el estado.',
      };
    case 'DE':
      return {
        title: 'Delaware state-specific credit disclosure',
        text: (
          <>
            <p>
              In connection with this application for insurance, we will review your credit report
              or obtain or use a credit-based insurance score based on the information contained in
              that credit history. We will use a third party in connection with the development of
              your insurance score.
            </p>
            <br />
            <p>
              If we do use a credit based score, you will have the right on an annual basis to
              request that we obtain a current credit report for you and determine whether use of
              the new credit report would result in a decrease in your insurance premiums. If the
              new credit report that we receive would result in a decrease in your insurance
              premiums, we will make that reduction. If the new credit information would not reduce
              your insurance premiums, the credit report will not be used to impact your premiums in
              any way.
            </p>
          </>
        ),
        spanishText: (
          <>
            <p>
              En relación con esta solicitud de seguro, revisaremos su informe crediticio u
              obtendremos o utilizaremos un puntaje de seguro basado en la información contenida en
              ese historial crediticio. Utilizaremos a un tercero en relación con la determinación
              de su puntaje de seguro.
            </p>
            <br />
            <p>
              Si usamos un puntaje basado en el crédito, tendrá derecho a solicitar anualmente que
              obtengamos su puntaje de seguro actualizado, con base en un informe de crédito actual,
              para determinar si el uso del nuevo puntaje de seguro resultaría en una disminución de
              la prima de su seguro. Si el nuevo puntaje resultara en una disminución en su prima,
              haremos esa reducción. Si la nueva información de crédito no reduce su prima, su
              informe de crédito no se utilizará para afectar su prima de ninguna manera.
            </p>
          </>
        ),
      };
    case 'FL':
      switch (lob) {
        case 'LINE_OF_BUSINESS.RENTERS':
        case 'LINE_OF_BUSINESS.BUNDLE_AUTO_RENTERS':
          return {
            title: 'Florida state-specific credit disclosure',
            text: (
              <>
                <p>
                  In connection with your application for insurance, we will obtain an insurance
                  score based on information in your credit history. You may request a copy of your
                  credit information from the credit bureau. If there are corrections we will, upon
                  your request, re-evaluate you based on the corrected information.
                </p>
                <br />
                <p>
                  The Department of Financial Services offers free financial literacy programs to
                  assist you with insurance-related questions, including how credit works and how
                  credit scores are calculated. To learn more, visit{' '}
                  <Link target='_blank' href='https://www.myfloridacfo.com' rel='noreferrer'>
                    www.myfloridacfo.com
                  </Link>
                  .
                </p>
              </>
            ),
            spanishText: (
              <>
                <p>
                  En relación con esta solicitud de seguro, revisaremos su informe creditico u
                  obtendremos o utilizaremos un puntaje de seguro basado en la información contenida
                  en ese informe de crédito. Usted puede solicitar una copia de su información de
                  crédito a la oficina de crédito. Si hay correcciones, a su solicitud, volveremos a
                  evaluarle basándonos en la información corregida.
                </p>
                <br />
                <p>
                  El Departamento de Servicios Financieros ofrece programas gratuitos de educación
                  financiera para ayudarle con preguntas relacionadas con los seguros, incluido cómo
                  funciona el crédito y cómo se calcula la puntuación crediticia. Para más
                  información, visite{' '}
                  <Link target='_blank' href='https://www.myfloridacfo.com' rel='noreferrer'>
                    www.myfloridacfo.com
                  </Link>
                  .
                </p>
              </>
            ),
          };
        default:
          return defaultAdditionalCreditDisclaimer;
      }
    case 'KS':
      return {
        title: 'Kansas state-specific credit disclosure',
        text: 'In connection with this application for insurance, we will review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We will use a third party in connection with the development of your insurance score. Should an adverse action be taken in part as a result of your insurance score, the reasons will be made available to you.',
        spanishText:
          'En relación con esta solicitud de seguro, revisaremos su informe de crédito u obtendremos o utilizaremos un puntaje de seguro basado en la información contenida en ese informe de crédito. Utilizaremos un tercero en relación con el desarrollo de su puntaje de seguro. En caso de que se tome una acción adversa debido en parte a su puntaje de seguro, las razones se le harán disponibles.',
      };
    case 'MA':
      switch (lob) {
        case 'LINE_OF_BUSINESS.AUTO':
          return {
            title: 'Massachusetts state-specific credit disclosure',
            text: 'Credit is not used when rating auto policies as it is prohibited by the state.',
            spanishText:
              'El crédito no se utiliza al calificar pólizas de auto, ya que está prohibido por el estado.',
          };
        case 'LINE_OF_BUSINESS.HOME':
          return {
            title: 'Massachusetts state-specific credit disclosure',
            text: 'In connection with this application for insurance, we will review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We will use a third party in connection with the development of your insurance score. Should an adverse action be taken in part as a result of your insurance score, the reasons will be made available to you.  ',
            spanishText:
              'En relación con esta solicitud de seguro, revisaremos su informe crediticio u obtendremos o utilizaremos un puntaje de seguro basado en la información contenida en ese informe de credito. Utilizaremos a un tercero en relación con la determinación de su puntaje de seguro.En caso de que se tome una acción adversa debido en parte a su puntaje de seguro, las razones se le harán disponibles.',
          };
        case 'LINE_OF_BUSINESS.BUNDLE':
          return {
            title: 'Massachusetts state-specific credit disclosure',
            text: (
              <>
                <p>
                  <i>
                    In connection with this application for insurance, we will review your credit
                    report or obtain or use a credit-based insurance score based on the information
                    contained in that credit report. We will use a third party in connection with
                    the development of your insurance score. Should an adverse action be taken in
                    part as a result of your insurance score, the reasons will be made available to
                    you.
                  </i>
                </p>
                <br />
                <p>
                  Credit is only used for rating home policies. Credit is not used when rating auto
                  policies as it is prohibited by the state.
                </p>
              </>
            ),
            spanishText: (
              <>
                <p>
                  <i>
                    En relación con esta solicitud de seguro, revisaremos su informe crediticio u
                    obtendremos o utilizaremos un puntaje de seguro basado en la información
                    contenida en ese informe de credito. Utilizaremos a un tercero en relación con
                    la determinación de su puntaje de seguro.En caso de que se tome una acción
                    adversa debido en parte a su puntaje de seguro, las razones se le harán
                    disponibles.
                  </i>
                </p>
                <br />
                <p>
                  El crédito solo se usa para pólizas de casa, el uso de crédito está prohibido para
                  seguro de auto por el estado.
                </p>
              </>
            ),
          };
        case 'LINE_OF_BUSINESS.BUNDLE_AUTO_RENTERS':
          return {
            title: 'Massachusetts state-specific credit disclosure',
            text: 'In connection with this application for insurance, we will review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We will use a third party in connection with the development of your insurance score. Should an adverse action be taken in part as a result of your insurance score, the reasons will be made available to you.  Credit is only used for rating home policies. Credit is not used when rating auto policies as it is prohibited by the state.',
            spanishText:
              'El crédito solo se usa para pólizas de casa, el uso de crédito está prohibido para seguro de auto por el estado',
          };
        default:
          return defaultAdditionalCreditDisclaimer;
      }
    case 'MD':
      switch (lob) {
        case 'LINE_OF_BUSINESS.AUTO':
          return {
            title: 'Maryland state-specific credit disclosure',
            text: 'In connection with this application for insurance, we will review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We will use a third party in connection with the development of your insurance score. Should an adverse action be taken in part as a result of your insurance score, the reasons will be made available to you.',
            spanishText:
              'En relación con esta solicitud de seguro, revisaremos su informe crediticio u obtendremos o utilizaremos un puntaje de seguro basado en la información contenida en ese informe de credito. Utilizaremos a un tercero en relación con la determinación de su puntaje de seguro.En caso de que se tome una acción adversa debido en parte a su puntaje de seguro, las razones se le harán disponibles.',
          };
        case 'LINE_OF_BUSINESS.HOME':
          return {
            title: 'Maryland state-specific credit disclosure',
            text: 'Credit is not used when rating home policies as it is prohibited by the state.',
            spanishText:
              'El crédito no se utiliza para las políticas de calificactión ya que está prohibido por el estado.',
          };
        case 'LINE_OF_BUSINESS.BUNDLE':
          return {
            title: 'Maryland state-specific credit disclosure',
            text: (
              <>
                <p>
                  <i>
                    In connection with this application for insurance, we will review your credit
                    report or obtain or use a credit-based insurance score based on the information
                    contained in that credit report. We will use a third party in connection with
                    the development of your insurance score. Should an adverse action be taken in
                    part as a result of your insurance score, the reasons will be made available to
                    you.
                  </i>
                </p>
                <br />
                <p>
                  Credit is only used for rating auto policies. Credit is not used when rating home
                  policies as it is prohibited by the state.
                </p>
              </>
            ),
            spanishText: (
              <>
                <p>
                  <i>
                    En relación con esta solicitud de seguro, revisaremos su informe crediticio u
                    obtendremos o utilizaremos un puntaje de seguro basado en la información
                    contenida en ese informe de credito. Utilizaremos a un tercero en relación con
                    la determinación de su puntaje de seguro.En caso de que se tome una acción
                    adversa debido en parte a su puntaje de seguro, las razones se le harán
                    disponibles.
                  </i>
                </p>
                <br />
                <p>
                  El crédito solo se usa para pólizas de auto, el uso de crédito está prohibido para
                  seguro de casa.
                </p>
              </>
            ),
          };
        case 'LINE_OF_BUSINESS.BUNDLE_AUTO_RENTERS':
          return {
            title: 'Maryland state-specific credit disclosure',
            text: 'In connection with this application for insurance, we will review your credit report or obtain or use a credit-based insurance score based on the information contained in that credit report. We will use a third party in connection with the development of your insurance score. Should an adverse action be taken in part as a result of your insurance score, the reasons will be made available to you.',
            spanishText:
              'En relación con esta solicitud de seguro, revisaremos su informe crediticio u obtendremos o utilizaremos un puntaje de seguro basado en la información contenida en ese informe de credito. Utilizaremos a un tercero en relación con la determinación de su puntaje de seguro.En caso de que se tome una acción adversa debido en parte a su puntaje de seguro, las razones se le harán disponibles.',
          };
        default:
          return defaultAdditionalCreditDisclaimer;
      }
    case 'MI':
      switch (lob) {
        case 'LINE_OF_BUSINESS.HOME':
        case 'LINE_OF_BUSINESS.RENTERS':
          return {
            title: 'Michigan state-specific credit disclosure',
            text: (
              <>
                <p>
                  In connection with this application for insurance, we will review your credit
                  report or obtain or use a credit-based insurance score based on the information
                  contained in that credit history. We will use a third party in connection with the
                  development of your insurance score.
                </p>
                <br />
                <p>
                  If your credit information has been adversely impacted by an extraordinary life
                  circumstance that has occurred in the last 3 years, you may request in writing
                  that we consider this when using your credit information. More information will be
                  included with your policy documents.{' '}
                  <Link
                    target='_blank'
                    href='https://go.homesite.com/privacy-policy.html#insuranceCreditScore'
                    rel='noreferrer'
                  >
                    Learn more now
                  </Link>
                </p>
              </>
            ),
            spanishText: (
              <>
                <p>
                  En relación con esta solicitud de seguro, revisaremos su informe crediticio u
                  obtendremos o utilizaremos un puntaje de seguro basado en la información contenida
                  en ese historial crediticio. Utilizaremos a un tercero en relación con la
                  determinación de su puntaje de seguro.
                </p>
                <br />
                <p>
                  Si su información de crédito se ha visto afectada negativamente por una
                  circunstancia de vida extraordinaria que ha ocurrido en los últimos 3 años, puede
                  solicitar por escrito que tengamos esto en cuenta al usar su información de
                  crédito. Se incluirá más información con los documentos de su póliza.{' '}
                  <Link
                    target='_blank'
                    href='https://go.homesite.com/privacy-policy.html#insuranceCreditScore'
                    rel='noreferrer'
                  >
                    Aprende más ahora
                  </Link>
                </p>
              </>
            ),
          };
        default:
          return defaultAdditionalCreditDisclaimer;
      }
    case 'NJ':
      return {
        title: 'New Jersey state-specific credit disclosure',
        text: (
          <>
            <p>
              In connection with your application for insurance, we will obtain an insurance score
              based on information in your credit history. We will use this information in providing
              you with a rate. You may request a copy of your credit information from the credit
              bureau. If there are corrections we will, upon your request, re-evaluate you based on
              the corrected information.
            </p>
            <br />
            <p>
              You may request reconsideration of credit score because of the direct influence of an
              extraordinary life event on your credit information. An extraordinary life event may
              include but is not limited to catastrophic illness or injury; death of a spouse, child
              or parent; temporary loss of employment; divorce; or identity theft. Please contact us
              by email at{' '}
              <Button variant='text' href={`mailto:${customerfirstEmail}`} target='_top'>
                {customerfirstEmail}
              </Button>{' '}
              or by calling{' '}
              <Button variant='text' href='tel:+1888-404-5365' target='_top'>
                888-404-5365
              </Button>{' '}
              Monday through Friday from 7 a.m. to 10 p.m or Saturday from 8:30 a.m. to 7 p.m CST.
            </p>
          </>
        ),
        spanishText: (
          <>
            <p>
              En relación con su solicitud de seguro, obtendremos un puntaje de seguro basado en la
              información de su historial de crédito. Utilizaremos esta información para
              proporcionarle una tarifa. Puede solicitar una copia de su información de crédito a la
              agencia de crédito. Si hay correcciones, a petición suya, lo reevaluaremos basándose
              en la información corregida.
            </p>
            <br />
            <p>
              Puede solicitar la reconsideración de su puntaje de crédito si su información
              crediticia fue influenciada directamente por un evento extraordinario de la vida.Un
              evento extraordinario de la vida puede incluir, entre otros, enfermedades o lesiones
              catastróficas; muerte de un cónyuge, hijo o padre; pérdida temporal de empleo;
              divorcio; o robo de identidad. Comuníquese con nosotros por correo electrónico a{' '}
              <Button variant='text' href={`mailto:${customerfirstEmail}`} target='_top'>
                {customerfirstEmail}
              </Button>{' '}
              o llamando al{' '}
              <Button variant='text' href='tel:+1888-404-5365' target='_top'>
                888-404-5365
              </Button>{' '}
              de lunes a viernes de 7 a.m. a 10 p.m o sábados de 8:30 a.m. a 7 p.m CST.
            </p>
          </>
        ),
      };
    case 'NM':
      switch (lob) {
        case 'LINE_OF_BUSINESS.HOME':
        case 'LINE_OF_BUSINESS.RENTERS':
        case 'LINE_OF_BUSINESS.BUNDLE':
        case 'LINE_OF_BUSINESS.BUNDLE_AUTO_RENTERS':
          return {
            title: 'New Mexico state-specific credit disclosure',
            text: (
              <>
                <p>
                  In connection with your application for insurance coverage, we may review and use
                  information contained in your credit report to help determine your premium or your
                  eligibility for coverage.
                </p>
                <br />
                <p>
                  Personal information including but not limited to confidential abuse information
                  may be collected from persons other than the individual or individuals proposed
                  for coverage.
                </p>
                <br />
                <p>
                  Such information as well as other personal or privileged information subsequently
                  collected by {getUnderwriterName()} or our agent may in certain circumstances be
                  disclosed to third parties without authorization.
                </p>
                <br />
                <p>
                  A right of access and correction exists with respect to all personal information
                  collected; and
                </p>
                <br />
                <p>
                  A more detailed notice as prescribed in Subsection B of Section 4 of the NAIC
                  Insurance Information and Privacy Protection Model Act will be furnished to you
                  upon request.
                </p>
                <br />
                <p>
                  To sign up for “Safe at Home” (SAH) or for more information, call toll-free at{' '}
                  <Button variant='text' href='tel:+1800-477-3632' target='_top'>
                    1-800-477-3632
                  </Button>
                  , email{' '}
                  <Button variant='text' href='mailto:Safe.sos@state.nm.us' target='_top'>
                    Safe.sos@state.nm.us
                  </Button>{' '}
                  or send direct mail to P.O. Box 1888, Santa Fe, NM 87504 requesting information.
                </p>
              </>
            ),
            spanishText: (
              <>
                <p>
                  En relación con su solicitud de seguro, podemos revisar y utilizar la información
                  contenida en su informe de crédito para ayudar a determinar su prima o su
                  elegibilidad para la cobertura.
                </p>
                <br />
                <p>
                  La información personal, incluida, entre otros, la información confidencial sobre
                  abusos, puede recabarse de personas distintas del individuo o individuos
                  propuestos para la cobertura.
                </p>
                <br />
                <p>
                  Esta información, así como otra información personal o privilegiada recopilada
                  posteriormente por {getUnderwriterName()} o nuestro agente, puede en determinadas
                  circunstancias ser revelada a terceros sin autorización.
                </p>
                <br />
                <p>
                  Existe un derecho de acceso y rectificación con respecto a toda la información
                  personal recopilada; y
                </p>
                <br />
                <p>
                  Si lo solicita, se le facilitará un aviso más detallado según lo prescrito en el
                  subapartado B de la sección 4 de la Ley Modelo de Protección de la Información y
                  la Privacidad en el Seguro de la NAIC.
                </p>
                <br />
                <p>
                  Para inscribirse en "Safe at Home" (SAH) u obtener más información, llame al
                  número gratuito{' '}
                  <Button variant='text' href='tel:+1800-477-3632' target='_top'>
                    1-800-477-3632
                  </Button>
                  , envíe un correo electrónico a{' '}
                  <Button variant='text' href='mailto:Safe.sos@state.nm.us' target='_top'>
                    Safe.sos@state.nm.us
                  </Button>{' '}
                  o envíe un correo directo a P.O. Box 1888, Santa Fe, NM 87504 solicitando
                  información.
                </p>
              </>
            ),
          };
        default:
          return {
            title: 'New Mexico state-specific credit disclosure',
            text: 'In connection with your application for insurance coverage, we may review and use information contained in your credit report to help determine your premium or your eligibility for coverage.',
            spanishText:
              'En relación con su solicitud de seguro, podemos revisar y utilizar la información contenida en su informe de crédito para ayudar a determinar su prima o su elegibilidad para la cobertura.',
          };
      }
    case 'NY':
      return {
        title: 'New York state-specific credit disclosure',
        text: (
          <p>
            In connection with this insurance, we may review your credit report or obtain or use a
            credit-based insurance score based on information contained in that report. An insurance
            score uses information from your credit report to help predict how often you are likely
            to file claims and how expensive those claims will be. Typical items from a credit
            report that could affect a score include, but are not limited to, the following: payment
            history, number of revolving accounts, number of new accounts, the presence of
            collection accounts, bankruptcies and foreclosures. The information used to develop the
            insurance score comes from Equifax, P.O. Box 740256 Atlanta, GA 30374,{' '}
            <Button variant='text' href='tel:+1800-685-1111' target='_top'>
              800-685-1111
            </Button>{' '}
            or{' '}
            <Link target='_blank' href='https://www.equifax.com' rel='noreferrer'>
              Equifax | Credit Bureau | Check Your Credit Report & Credit Score
            </Link>
            .
          </p>
        ),
        spanishText: (
          <p>
            En relación con este seguro, podriamos revisar su informe de crédito u obtener o
            utilizar un puntaje de seguro basado en la información contenida en ese informe. Un
            puntaje de seguro utiliza información de su informe de crédito para ayudar a predecir
            con qué frecuencia es probable que presente reclamos y qué tan costosos serán esos
            reclamos. Las cosas típicas de un informe de crédito que podrían afectar un puntaje
            incluyen, entre otras, las siguientes: historial de pagos, número de cuentas rotativas,
            número de cuentas nuevas, presencia de cuentas de cobro, quiebras y ejecuciones
            hipotecarias. La información utilizada para desarrollar el puntaje de seguro proviene de
            Equifax, P.O. Box 740256 Atlanta, GA 30374,{' '}
            <Button variant='text' href='tel:+1800-685-1111' target='_top'>
              800-685-1111
            </Button>{' '}
            o{' '}
            <Link target='_blank' href='https://www.equifax.com' rel='noreferrer'>
              Equifax | Credit Bureau | Check Your Credit Report & Credit Score
            </Link>
            .
          </p>
        ),
      };
    case 'OR':
      return {
        title: 'Oregon state-specific credit disclosure',
        text: (
          <>
            <p>
              In connection with this insurance, we will review your credit report or obtain or use
              a credit-based insurance score based on information contained in that report. The
              information is used to potentially discount your insurance premium and eligibility in
              combination with other underwriting criteria. The absence of credit information or an
              inability to determine credit information does not affect our consideration of an
              application of insurance.
            </p>
            <br />
            <p>
              Insurers use insurance scores, which uses information from your credit report to help
              predict how often you are likely to file claims and how expensive those claims will
              be. Typical items from a credit report that could affect a score include, but are not
              limited to, the following: payment history, number of revolving accounts, number of
              new accounts, the presence of collection accounts, bankruptcies and foreclosures. We
              cannot use credit history or a credit score for any arbitrary, capricious, or unfairly
              discriminatory reason. Additionally, credit history or a credit score based wholly or
              partly on residence, sex, race, color, creed, occupation, income, physical handicap or
              disability of an applicant or insured is prohibited.
            </p>
            <br />
            <p>
              The information used to develop the insurance score comes from Equifax. If you would
              like to review your personal credit history information, please call Equifax at{' '}
              <Button variant='text' href='tel:+1800-685-1111' target='_top'>
                800-685-1111
              </Button>
              , write P.O. Box 740256, Atlanta, GA 30374, or visit{' '}
              <Link target='_blank' href='https://www.equifax.com' rel='noreferrer'>
                Equifax | Credit Bureau | Check Your Credit Report & Credit Score
              </Link>
              .
            </p>
          </>
        ),
        spanishText: (
          <>
            <p>
              En relación con este seguro, revisaremos su informe crediticio u obtendremos o
              utilizaremos un puntaje de seguro basado en la información contenida en ese informe.
              La información se utiliza para un posible descuento en su prima de seguro y para la
              elegibilidad en combinación con otros criterios de suscripción. La ausencia de
              información crediticia o la incapacidad de determinar la información crediticia no
              afecta nuestra consideración de una solicitud de seguro.
            </p>
            <br />
            <p>
              Las aseguradoras utilizan los puntajes de seguro, que utilizan la información de su
              informe de crédito para ayudar a predecir con qué frecuencia es probable que presente
              reclamos y qué tan costosos serán esos reclamos. Los elementos típicos de un informe
              de crédito que podrían afectar un puntaje incluyen, entre otros, los siguientes:
              historial de pagos, número de cuentas rotativas, número de cuentas nuevas, presencia
              de cuentas de cobro, bancarrotas y ejecuciones hipotecarias. No podemos usar el
              historial de crédito o un puntaje de crédito por ninguna razón arbitraria, caprichosa
              o injustamente discriminatoria. Adicionalmente, se prohíbe que el historial de crédito
              o un puntaje de crédito sea basado totalmente o parcialmente en la residencia, el
              sexo, la raza, el color, el credo, la ocupación, los ingresos, la discapacidad física
              o la discapacidad de un solicitante o asegurado.
            </p>
            <br />
            <p>
              La información utilizada para desarrollar el puntaje de seguro proviene de Equifax. Si
              desea revisar la información de su historial de crédito personal, llame a Equifax all{' '}
              <Button variant='text' href='tel:+1800-685-1111' target='_top'>
                800-685-1111
              </Button>
              , escriba P.O. Box 740256, Atlanta, GA 30374, o visite{' '}
              <Link target='_blank' href='https://www.equifax.com' rel='noreferrer'>
                Equifax | Credit Bureau | Check Your Credit Report & Credit Score
              </Link>
              .
            </p>
          </>
        ),
      };
    case 'SC':
      return {
        title: 'South Carolina state-specific credit disclosure',
        text: 'In connection with this application for insurance and in the future, we will review your credit history or obtain or use a credit-based insurance score based on the information contained in that credit history.',
        spanishText:
          'En relación con esta solicitud de seguro y en el futuro, revisaremos su historial crediticio u obtendremos o utilizaremos un puntaje de seguro basado en la información contenida en ese historial de credito.',
      };
    case 'VA':
      return {
        title: 'Virginia state-specific credit disclosure',
        text: 'In connection with this application for insurance, we shall review your credit report or obtain or use an insurance credit score based on the information contained in that credit report. We may use a third party in connection with the development of your insurance credit score. You may request that your credit information be updated and if you question the accuracy of the credit information, we will, upon your request, reevaluate you based on corrected credit information from a consumer reporting agency.',
        spanishText:
          'En relación con esta solicitud de seguro, revisaremos su reporte de crédito u obtendremos o usaremos una puntuación de seguro basada en la información contenida en ese reporte de crédito. Podemos utilizar a un tercero en relación con el desarrollo de su puntaje crediticio del seguro. Puede solicitar que se actualice su información crediticia y, si cuestiona la exactitud de la información crediticia, a petición suya, lo reevaluaremos usando la información crediticia corregida de una agencia de informes del consumidor.',
      };
    case 'VT':
      return {
        title: 'Vermont state-specific credit disclosure',
        text: (
          <>
            <p>
              In connection with this application for insurance, we may review your credit report or
              obtain or use a credit-based insurance score based on the information contained in
              that credit report. We may use a third party in connection with the development of
              your insurance score.
            </p>
            <br />
            <p> Reasonable exceptions are available in certain circumstances.</p>
          </>
        ),
        spanishText: (
          <>
            <p>
              En relación con esta solicitud de seguro, revisaremos su informe crediticio u
              obtendremos o utilizaremos un puntaje de seguro basado en la información contenida en
              ese historial crediticio. Utilizaremos a un tercero en relación con la determinación
              de su puntaje de seguro.
            </p>
            <br />
            <p> Hay excepciones razonables disponibles en determinadas circunstancias.</p>
          </>
        ),
      };
    case 'WA':
      return {
        title: 'Washington state-specific credit disclosure',
        text: 'If your credit information has been adversely impacted by an extraordinary life circumstance that has occurred in the last 3 years, you may request in writing that we consider this when using your credit information. More information will be included with your policy documents.',
        spanishText:
          'Si su información de crédito se ha visto afectada negativamente por una circunstancia de vida extraordinaria que ha ocurrido en los últimos 3 años, puede solicitar por escrito que tengamos esto en cuenta al usar su información de crédito. Se incluirá más información con los documentos de su póliza.',
      };
    case 'WV':
      switch (lob) {
        case 'LINE_OF_BUSINESS.HOME':
        case 'LINE_OF_BUSINESS.RENTERS':
        case 'LINE_OF_BUSINESS.BUNDLE':
        case 'LINE_OF_BUSINESS.BUNDLE_AUTO_RENTERS':
          return {
            title: 'West Virginia state-specific credit disclosure',
            text: 'We use your credit information to produce an insurance score. This insurance score has an effect on the premium that you pay for your insurance. We are required by the Insurance Commissioner to recheck your credit information no less than once every 36 months for changes. You have the option to request that we recheck your insurance score more frequently than once every 36 months, but you can only make this request once during any 12-month period. If there has been a change in your insurance score, we will re-underwrite and re-rate the policy based upon the current credit report or insurance score. The change in your insurance score may result in an increase or a decrease in the premium that you pay for your insurance. Any changes in your premium will take place upon renewal if your request is made at least 45 days before your renewal. If the request is made less than 45 days before your renewal date, the insurer shall re-underwrite and re-rate the policy for the following renewal.',
            spanishText:
              'Usamos su información de crédito para producir un puntaje de seguro. Este puntaje de seguro tiene un efecto en la prima que paga por su seguro. El Comisionado de Seguros nos exige que volvamos a verificar su información crediticia al menos una vez cada 36 meses para detectar cambios. Tiene la opción de solicitar que volvamos a verificar su puntaje de seguro con más frecuencia que una vez cada 36 meses, pero solo puede realizar esta solicitud una vez durante cualquier período de 12 meses. Si ha habido un cambio en su puntaje de seguro, volveremos a suscribir y calificar la póliza según el informe de crédito actual o el puntaje de seguro. El cambio en su puntaje de seguro puede resultar en un aumento o una disminución en la prima que paga por su seguro. Cualquier cambio en su prima tendrá lugar al momento de la renovación si su solicitud se realiza al menos 45 días antes de la renovación. Si la solicitud se realiza con menos de 45 días de antelación a su fecha de renovación, la aseguradora deberá volver a suscribir y tasar la póliza para la siguiente renovación.',
          };
        default:
          return defaultAdditionalCreditDisclaimer;
      }

    case 'WI':
      return {
        title: 'Wisconsin state-specific credit disclosure',
        text: 'In order to underwrite the insurance for which you are applying, we will request your insurance credit score, which is based on information found in an insurance credit report. The information may be used, in combination with other underwriting criteria, as a factor in determining eligibility, assessing risk and establishing premium discounts.',
        spanishText:
          'Para suscribir el seguro que está solicitando, solicitaremos su puntaje de crédito de seguro, que se basa en la información que se encuentra en un informe de crédito de seguro. La información puede usarse, en combinación con otros criterios de suscripción, como un factor para determinar la elegibilidad, evaluar el riesgo y establecer descuentos.',
      };
    default:
      return defaultAdditionalCreditDisclaimer;
  }
};
