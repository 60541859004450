import type { Metadata } from './metadata';
import baseMetadata from './metadata.js';

const metadata: Metadata = {
  ...baseMetadata,
  helperText:
    'Home insurance may be available in your state. Contact us after your auto quote to learn more.',
  labelStart: 'Do you currently have or plan to get a home insurance policy with ',
  partnerDisplayNameOverride: 'CONNECT, powered by American Family Insurance',
};

export default metadata;
