import { SECONDARY_INSURED_PERSON_REF } from '@ecp/features/sales/shared/constants';
import type { ThunkAction } from '@ecp/features/sales/shared/store/types';

import { getPersonRefForDriver } from '../../form/selectors';
import { updateAnswers } from './updateAnswers';

export const updateSecondaryPolicyHolderByDriver =
  (driverRef: string): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const personRef = getPersonRefForDriver(getState(), { driverRef }) || '';
    await dispatch(
      updateAnswers({
        answers: { [`${SECONDARY_INSURED_PERSON_REF}`]: personRef },
      }),
    );
  };
