import { useCallback, useEffect } from 'react';

import { datadogLog } from '@ecp/utils/logger';

import { THIRD_PARTY_INTEREST_REF } from '@ecp/features/sales/shared/constants';
import {
  createRef,
  deleteInquiryRef,
  getAnswer,
  getDeltaField,
  getInquiryLoaded,
  getNavTracking,
  getReferencePagePaths,
  getTPIRefs,
  getVehicles,
  setPageStatusRemoved,
  updateAnswers,
  useField,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector, useStore } from '@ecp/features/sales/shared/store/utils';
import type { Vehicle } from '@ecp/features/sales/shared/types';
import type { Field } from '@ecp/types';

interface AddTPIResult {
  updateTPIVehicleAddressRef: Promise<[string, string]>;
  tpiRef: string;
}

/*
 * NOTE: ThirdPartyInterest is not supported with sapiTarget=v3
 */

export const useAddTPIAddressRef = (): ((tpiRef: string) => Promise<void>) => {
  // we will assume store will not change for the lifetime of the application
  const store = useStore();

  /*
   * @param tpiRef which third party interest will have it's address ref created
   * e.g. 'thirdPartyInterests.123' will have 'delta.thirdPartyInterests.123.address.ref' be created and set
   */
  return async (tpiRef: string) => {
    const tpiAddressRef = store.dispatch(createRef('address'));
    const tpiAddressRefFieldName = getDeltaField(store.getState(), tpiRef, 'address.ref');

    await store.dispatch(
      updateAnswers({
        answers: {
          [tpiAddressRefFieldName]: tpiAddressRef,
        },
      }),
    );
  };
};

export const useAddTPI = (): ((vehicleRef: string) => AddTPIResult) => {
  // we will assume store will not change for the lifetime of the application
  const store = useStore();
  const inquiryLoaded = useSelector(getInquiryLoaded);

  if (!inquiryLoaded) {
    datadogLog({
      logType: 'error',
      message: 'inquiry not loaded',
      context: {
        logOrigin: 'libs/features/sales/quotes/auto/src/state/modelUtil/tpiModelUtil.ts',
        functionOrigin: 'useAddTPI/useCallback',
      },
    });
    throw new Error('inquiry not loaded');
  }

  /*
   * This callback will create a new third party interest to the specified vehicle,
   * and to delta.
   * Finally, it will also create a new address.
   * (both refs that are created are returned)
   * e.g. addTPI('vehicle.123') => (adds to answers the following)
   * {
   *   'vehicle.123.thirdPartyInterests: 'thirdPartyInterests.123',
   *   'delta.thirdPartyInterests.123.address.ref: 'address.123',
   * }
   * @param vehicleRef the vehicle to add to a new third party interest
   */
  return (vehicleRef: string) => {
    const tpiRef = store.dispatch(createRef('thirdPartyInterests'));
    const tpiAddressRef = store.dispatch(createRef('address'));
    const tpiAddressRefFieldName = getDeltaField(store.getState(), tpiRef, 'address.ref');

    const updateAddress = store.dispatch(
      updateAnswers({
        answers: {
          [`${vehicleRef}.${THIRD_PARTY_INTEREST_REF}`]: tpiRef,
          [tpiAddressRefFieldName]: tpiAddressRef,
        },
      }),
    );
    const updateTPIVehicleAddressRef = Promise.all([updateAddress]).then((): [string, string] => [
      tpiRef,
      tpiAddressRef,
    ]);

    return {
      // NOTE: this is a promise so that the function itself returns immediately.
      // the caller may await this if he wants to know when the work has completed.
      updateTPIVehicleAddressRef,
      tpiRef,
      tpiAddressRef,
    };
  };
};

const useAndEnsureCurrentTPIRef = (
  vehicleRef: string,
  tpiRefParam?: string,
): string | undefined => {
  const tpiRefs = useSelector(getTPIRefs);
  const addTPI = useAddTPI();
  const inquiryLoaded = useSelector(getInquiryLoaded);

  if (!inquiryLoaded) {
    datadogLog({
      logType: 'error',
      message: 'inquiry not loaded',
      context: {
        logOrigin: 'libs/features/sales/quotes/auto/src/state/modelUtil/tpiModelUtil.ts',
        functionOrigin: 'useAndEnsureCurrentTPIRef',
      },
    });
    throw new Error('inquiry not loaded');
  }
  const tpiLastRef = (tpiRefs.length && tpiRefs[tpiRefs.length - 1]) || undefined;
  const tpiRef = tpiRefParam || tpiLastRef;
  useEffect(() => {
    if (!tpiRef) {
      // NOTE: this is async but we will not wait for it
      addTPI(vehicleRef);
    }
  }, [addTPI, vehicleRef, tpiRef]);

  return tpiRef;
};

export const useTPIRef = (vehicleRef: string, tpiId?: string): string | undefined => {
  const tpiRef = tpiId && `thirdPartyInterests.${tpiId}`;

  return useAndEnsureCurrentTPIRef(vehicleRef, tpiRef);
};

export const useGetVehicleInfoByTPIRef = (tpiRef?: string): Vehicle => {
  const getVehicleValues = useSelector(getVehicles);
  const getVehicleRef = getVehicleValues.filter(
    (value) => value.tpiRef === `thirdPartyInterests.${tpiRef}`,
  )[0];

  return getVehicleRef;
};

type TPIAddressFieldName = 'city' | 'state' | 'country';
// given a thirdPartyInterests ref, use it's address, and the specified address's fieldName
// e.g. 'thirdPartyInterests.123' (who has delta.thirdPartyInterests.123.address.ref: 'address.123')
// and 'line1' would return the field 'address.123.line1'
export const useTPIAddressField = (ref: string, addressFieldName: TPIAddressFieldName): Field => {
  const tpiAddressRef = useSelector((state: RootStore) => {
    const tpiAddressRefFieldName = getDeltaField(state, ref, 'address.ref');

    return getAnswer(state, tpiAddressRefFieldName);
  });

  return useField(`${tpiAddressRef}.${addressFieldName}`);
};

export const useRemoveUnUsedTPIRefForVehicle = (): ((
  tpiRef: string,
  vehicleRef: string,
) => Promise<void>) => {
  const dispatch = useDispatch();

  const navTracking = useSelector(getNavTracking);

  return useCallback(
    async (tpiRef: string, vehicleRef: string) => {
      const paths = tpiRef ? getReferencePagePaths(navTracking, tpiRef.split('.')[1]) : [];
      paths.forEach((path) => dispatch(setPageStatusRemoved(path)));

      await dispatch(
        deleteInquiryRef({
          refType: `delta.thirdPartyInterests`,
          refId: tpiRef.split('.')[1],
        }),
      );
      await dispatch(
        updateAnswers({
          answers: {
            [`delta.${tpiRef}.address.ref`]: null,
          },
        }),
      );
    },
    [dispatch, navTracking],
  );
};
