import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ConfirmationRequired' })((theme) => ({
  root: {
    display: 'flex',
  },
  dialogRoot: {
    ...theme.mixins.shadow1,
    maxWidth: 1050,
  },
  dialogContent: {
    ...theme.typography.body1Bold,
    color: theme.palette.text.secondary,
    padding: '20px 20px 15px 20px',
    '& ul': {
      paddingLeft: 25,
    },
    '& li': {
      ...theme.typography.body1,
    },
  },
  dialogHeader: theme.typography.body4,
  dialogSubheader: {
    ...theme.typography.body1Italics,
    paddingBottom: 20,
  },
  textWrapper: {
    ...theme.typography.body1,
    height: 'auto',
    margin: 'auto',
    marginBottom: 13,
    display: 'flex',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.text.hover,
    },
  },
  tooltipIcon: {
    // this defaults to 1.5rem
    fontSize: '1.1em',
  },
  dismissButton: {
    marginTop: 8,
    float: 'right',
  },
}));
