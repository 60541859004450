import { env } from '@ecp/env';
export interface Metadata {
  title?: string;
}

const metadata: Metadata = {
  title: env.static.isAgent
    ? 'Additional information'
    : 'Additional driver and vehicle information',
};

export default metadata;
