import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ParticipatingCarriersPage' })((theme) => ({
  root: {},
  container: {
    maxWidth: 639,
    padding: 0,
    [theme.breakpoints.down('md')]: {
      padding: '0 15px',
    },
  },
  pageTitle: {
    ...theme.typography.h1,
    margin: '40px 0',
    [theme.breakpoints.down('md')]: {
      ...theme.typography.h1,
      margin: '24px 0 10px',
    },
  },
  quoteLink: theme.mixins.linkButton,
}));
