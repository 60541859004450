import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'VehicleYearVinMakeModelQuestion' })((theme) => ({
  image: {
    width: '75%',
    height: 'auto',
  },
  imageContainer: {
    display: 'flex',
    width: '100%',
    marginLeft: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[200],
    borderRadius: 4,
    maxHeight: '89%',
    [theme.breakpoints.down('lg')]: {
      marginLeft: 0,
      marginBottom: 20,
    },
  },
  vehicleFieldsContainer: {
    alignContent: 'baseline',
    gap: 10,
    marginTop: 10,
  },
}));
