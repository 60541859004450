import { useMemo } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import { Grid } from '@mui/material';

import { env } from '@ecp/env';
import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { CheckboxGroup, CheckboxNoPatchGroup } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import {
  convertFieldsValueToPatch,
  keysTobecomparedFromFields,
  useField,
  useMultiFieldsForCheckBoxGroup,
  useMultiFieldsForNoPatchCheckBoxGroup,
} from '@ecp/features/sales/shared/store';
import type { Answers } from '@ecp/features/sales/shared/types';

import { safetyDiscountOptions } from '../../../../metadata';
import { useStyles } from './SafetyFeaturesAdditionalQuestion.styles';

interface Props extends QuestionProps {
  vehicleRef: string;
  isContinueClicked: boolean;
  variant?: 'discount';
  setCheckboxGroupValuetoBePatched: (value: Answers) => void;
  originalCheckboxGroupValue: Answers;
}

export const SafetyFeaturesAdditionalQuestion: React.FC<Props> = (props) => {
  const {
    vehicleRef,
    trackingName = 'safety_features_multiselect',
    isContinueClicked,
    variant,
    setCheckboxGroupValuetoBePatched,
    originalCheckboxGroupValue,
  } = props;

  const { classes } = useStyles();

  const safety = useField(`${vehicleRef}.features.safety`);
  const safetyAntiLockBrakes = useField(`${vehicleRef}.features.safety.antiLockBrakes`);
  const driverSafetyAirbag = useField(`${vehicleRef}.features.safety.airbag.driver`);
  const passengerSafetyAirbag = useField(`${vehicleRef}.features.safety.airbag.passenger`);
  const sideSafetyAirbag = useField(`${vehicleRef}.features.safety.airbag.side`);
  const safetyDayTimeRunningLights = useField(`${vehicleRef}.features.safety.dayTimeRunningLights`);
  const safetyElectronicStabilityControl = useField(
    `${vehicleRef}.features.safety.electronicStabilityControl`,
  );
  const [, updateState] = useState({});
  const forceUpdateParentComponent = useCallback(() => updateState({}), []);

  const safetyFeatures = [
    driverSafetyAirbag,
    passengerSafetyAirbag,
    sideSafetyAirbag,
    safetyAntiLockBrakes,
    safetyDayTimeRunningLights,
    safetyElectronicStabilityControl,
  ];
  let safetyOptions = safetyDiscountOptions;

  safetyFeatures.forEach((feature) => {
    if (!feature.exists) {
      safetyOptions = safetyOptions.filter(
        (option) => option.fieldName !== feature.key.substr(feature.key.lastIndexOf('.') + 1),
      );
    }
  });

  const safetyFeaturesFiltered = safetyFeatures.filter((feature) => feature.exists);
  const actualDisplayedArray: string[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const safetyFeatureFieldsTobecompared = safetyFeaturesFiltered.map((i: any) =>
    Object.fromEntries(keysTobecomparedFromFields.map((f) => [f, i[f]])),
  );

  if (env.static.isAgent) {
    safetyFeaturesFiltered.forEach(function (item) {
      if (item.value === true)
        actualDisplayedArray.push(item.key.split(`${vehicleRef}.`).pop() as string);
    });
  }
  const checkboxGroupValuetoBePatch = useMemo(
    () => convertFieldsValueToPatch(safetyFeatureFieldsTobecompared),
    [safetyFeatureFieldsTobecompared],
  );
  const prevStatusRef = useRef<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [k: string]: any;
  }>(safetyFeatureFieldsTobecompared);

  const safetyFeaturePropsForAgent = useMultiFieldsForNoPatchCheckBoxGroup(
    safetyFeaturesFiltered,
    safetyOptions,
    actualDisplayedArray,
  );

  const safetyFeaturePropsForSales = useMultiFieldsForCheckBoxGroup(
    safetyFeaturesFiltered,
    safetyOptions,
  );
  const safetyFeatureProps = env.static.isAgent
    ? safetyFeaturePropsForAgent
    : safetyFeaturePropsForSales;

  const isAtLeastOneSafetyCheckBoxSelected =
    !!safetyAntiLockBrakes.value ||
    !!driverSafetyAirbag.value ||
    !!passengerSafetyAirbag.value ||
    !!sideSafetyAirbag.value ||
    !!safetyDayTimeRunningLights.value ||
    !!safetyElectronicStabilityControl.value;

  const errorExists = safety.exists && safety.value && !isAtLeastOneSafetyCheckBoxSelected;
  const errorText =
    errorExists && isContinueClicked ? 'Select at least one safety feature' : undefined;

  useEffect(() => {
    if (safety.exists && !safety.value) {
      safetyFeatureProps.values.forEach((value) =>
        safetyFeatureProps.actionOnComplete(value, value),
      );
    }
  }, [
    safety.exists,
    safety.value,
    safetyFeatureProps.values,
    safetyFeatureProps.actionOnComplete,
    safety,
    safetyFeatureProps,
  ]);

  useEffect(() => {
    if (
      JSON.stringify(checkboxGroupValuetoBePatch) !== JSON.stringify(originalCheckboxGroupValue)
    ) {
      setCheckboxGroupValuetoBePatched(checkboxGroupValuetoBePatch);
    }
  }, [setCheckboxGroupValuetoBePatched, checkboxGroupValuetoBePatch, originalCheckboxGroupValue]);

  useAddFields({
    [`${safetyAntiLockBrakes.key}`]: safetyAntiLockBrakes,
    [`${driverSafetyAirbag.key}`]: driverSafetyAirbag,
    [`${passengerSafetyAirbag.key}`]: passengerSafetyAirbag,
    [`${sideSafetyAirbag.key}`]: sideSafetyAirbag,
    [`${safetyDayTimeRunningLights.key}`]: safetyDayTimeRunningLights,
    [`${safetyElectronicStabilityControl.key}`]: safetyElectronicStabilityControl,
  });

  // we only need to check one of the fields to prevent form from submitting
  useAddConditionValues({
    conditionalFields: [safetyAntiLockBrakes],
    isExcluded: () => isAtLeastOneSafetyCheckBoxSelected,
    isRequiredOverride: () => safety.value === true,
  });

  if (!safety.value) return null;

  const safetyFeaturesAdditionalQuestions = env.static.isAgent ? (
    <CheckboxNoPatchGroup
      options={safetyOptions}
      variant='classicCompact'
      label={<p className={classes.childLabel}>Which safety features?</p>}
      trackingName={trackingName}
      {...safetyFeatureProps}
      error={errorText}
      prevStatusRef={prevStatusRef}
      forceUpdateParentComponent={forceUpdateParentComponent}
    />
  ) : (
    <CheckboxGroup
      options={safetyOptions}
      variant='classicCompact'
      label={<p className={classes.childLabel}>Which safety features?</p>}
      trackingName={trackingName}
      {...safetyFeatureProps}
      error={errorText}
    />
  );

  if (variant === 'discount')
    return (
      <Grid item xs={12} md={6} className={classes.questionItem}>
        {safetyFeaturesAdditionalQuestions}
      </Grid>
    );
  else return safetyFeaturesAdditionalQuestions;
};
