import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PersonSummaryCard' })((theme) => ({
  root: {
    border: `1px solid ${theme.palette.other.border}`,
    height: '100%',
    backgroundColor: theme.palette.grey[50],
  },
  cardHeader: {
    padding: '15px 20px',
    backgroundColor: theme.palette.primary.light,
  },
  cardTitle: {
    borderBottom: `1px solid ${theme.palette.other.divider}`,
    marginBottom: 10,
    textTransform: 'none',
  },
  cardContent: {
    ...theme.typography.body1,
    display: 'flex',
    padding: 0,
    margin: 20,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
    },
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  mapLink: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.link,
  },
  mapLinks: {
    marginTop: 15,
    '& > *': {
      marginTop: 5,
    },
    '& > *:first-child': {
      marginTop: 0,
    },
  },
  mapIcon: {
    marginRight: 5,
    textAlign: 'left',
    '& g': {
      fill: theme.palette.text.link,
    },
  },
  wordOverflow: {
    overflowWrap: 'break-word',
  },
  zillowIcon: {
    marginRight: 5,
    textAlign: 'left',
    fontSize: 14,
  },
  detailLabel: theme.typography.body1Bold,
}));
