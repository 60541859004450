import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import { useField } from '@ecp/features/sales/shared/store';
import type { OptionProps } from '@ecp/features/sales/shared/types';

import type { QuestionProps } from '../../questionProps.types';

interface Props extends QuestionProps {
  disabled: boolean;
}
export const HelpYouQuestion: React.FC<Props> = (props) => {
  const {
    id = 'HowCanWeHelp',
    label = 'How can we help you today? (optional)',
    trackingName = 'how_can_we_help_you_question',
    helperText,
    disabled,
  } = props;
  const howCanWeHelp = useField('howCanWeHelp');

  useAddFields({ howCanWeHelp });

  useAddConditionValues({
    conditionalFields: [howCanWeHelp],
    isRequiredOverride: () => false,
  });

  if (!howCanWeHelp.exists) return null;

  return (
    <Select
      {...(howCanWeHelp.props as OptionProps)}
      id={id}
      data-testid='howCanWeHelp-Dropdown'
      groupLabel={label}
      helperText={helperText}
      trackingName={trackingName}
      trackingLabel={howCanWeHelp.props.value}
      inputButtonAriaLabel='How can we help you today'
      disabled={disabled}
      fullWidth={false}
    />
  );
};
