import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PageLayout' })((theme) => ({
  alertMessage: {
    ...theme.typography.body1Bold,
    color: theme.palette.error.main,
    display: 'block',
    marginBottom: 10,
  },
}));
