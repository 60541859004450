import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { TooltipWithIcon } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

interface Props extends QuestionProps {
  isPni: boolean;
}

const PRIOR_LOSS_HELP_TEXT = 'Has a member of the household had a loss in the last 5 years';

export const HouseholdPriorLossQuestion: React.FC<Props> = (props) => {
  const {
    isPni,
    trackingName = 'householdPriorLoss',
    trackingLabel = GoogleAnalyticsLabels.REDACTED,
  } = props;
  const householdPriorLoss = useField('policy.auto.lossesInThePastFiveYears');

  useInitValues({ [householdPriorLoss.key]: false });
  useAddFields({ householdPriorLoss });

  if (!householdPriorLoss.exists) return null;
  if (!isPni) return null;

  return (
    <RadioGroupWithOptions
      id='householdPriorLoss'
      {...householdPriorLoss.props}
      label={
        <>
          Has a member of the household had a loss in the last 5 years?
          <TooltipWithIcon title={PRIOR_LOSS_HELP_TEXT} />
        </>
      }
      variant='yesNoButton'
      trackingName={trackingName}
      trackingLabel={trackingLabel}
    />
  );
};
