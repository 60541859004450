import dayjs from 'dayjs';

import metadata from './metadata';

const incidentSinceDate = dayjs().subtract(6, 'year').format('MM/YYYY');
const violationSinceDate = dayjs().subtract(10, 'year').format('MM/YYYY');

export const getIncidentFieldLabel = (driverName: string, stateCode: string): string => {
  switch (stateCode) {
    case 'CA':
      return `Has ${driverName} had any accidents, tickets or claims since ${incidentSinceDate} or alcohol/drug-related traffic violations since ${violationSinceDate}?`;
    default:
      return `Has ${driverName} had any accidents, tickets, or claims since ${incidentSinceDate}?`;
  }
};

export const hasIncidentClaimAmountQuestion = (stateCode: string): boolean =>
  ['PA'].includes(stateCode) || metadata.renderDamageAmountAsText;
