import type { StateCompanyName } from '@ecp/features/sales/shared/types';
import { partner } from '@ecp/partners';

import { NachaAuthorizationAgreement } from './GeneralCheckoutDisclaimers';
import {
  GeneralAutoCheckingWithdrawlDisclaimer,
  GeneralCreditAuthDisclaimer,
  GeneralPaymentConfirmationDisclaimer,
  GeneralPaymentDisclaimer,
} from './GeneralCheckoutDisclaimers';

export const RecurringPaymentCompanyName: { [productKey: string]: StateCompanyName } = {
  'connect.auto': {
    name: partner.shared.partnerDisplayName,
  },
};

export const RecurringPaymentMetadata: Record<
  string,
  Record<
    string,
    | ((name: string, state: string) => JSX.Element)
    | ((name?: string, state?: string) => JSX.Element)
  >
> = {
  EFT: {
    'homesite.home': (...[, state]: [string, string]): JSX.Element => {
      let fraudText = '';
      if (state === 'VA') {
        fraudText =
          'Your premium and eligibility are based on information provided. It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.';
      }

      return (
        <>
          <NachaAuthorizationAgreement product='home' />
          <GeneralPaymentDisclaimer fraudText={fraudText} />
        </>
      );
    },
    'connect.auto': (name: string): JSX.Element => (
      <NachaAuthorizationAgreement
        product='auto'
        defaultComponent={<GeneralAutoCheckingWithdrawlDisclaimer name={name} />}
      />
    ),
    'amfam.auto': (): React.ReactElement => (
      <NachaAuthorizationAgreement
        product='auto'
        defaultComponent={<GeneralPaymentConfirmationDisclaimer />}
      />
    ),
    'homesite.renters': (): JSX.Element => (
      <NachaAuthorizationAgreement
        product='renters'
        defaultComponent={<GeneralPaymentConfirmationDisclaimer />}
      />
    ),
  },
  CreditCard: {
    'homesite.home': (name: string, state: string): JSX.Element => {
      let fraudText = '';
      if (state === 'VA') {
        fraudText =
          'Your premium and eligibility are based on information provided. It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.';
      }

      return <GeneralPaymentDisclaimer fraudText={fraudText} />;
    },
    'connect.auto': (name: string): JSX.Element => (
      <GeneralCreditAuthDisclaimer name={name} product='auto' />
    ),
    'amfam.auto': (): React.ReactElement => <GeneralPaymentConfirmationDisclaimer />,
    'homesite.renters': (): JSX.Element => <GeneralPaymentConfirmationDisclaimer />,
  },
  CostcoVisa: {
    'connect.auto': (name: string): JSX.Element => (
      <GeneralCreditAuthDisclaimer name={name} product='auto' />
    ),
    'amfam.auto': (): React.ReactElement => <GeneralPaymentConfirmationDisclaimer />,
    'homesite.home': (name: string, state: string): JSX.Element => {
      let fraudText = '';
      if (state === 'VA') {
        fraudText =
          'Your premium and eligibility are based on information provided. It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.';
      }

      return <GeneralPaymentDisclaimer fraudText={fraudText} />;
    },
    'homesite.renters': (): JSX.Element => <GeneralPaymentConfirmationDisclaimer />,
  },
};
