import { useCallback, useEffect, useRef, useState } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { ensureStringOrNull, isMasked } from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { LogoSpinner } from '@ecp/components';
import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import { Button, Form, NextPageInstructions } from '@ecp/features/sales/shared/components';
import { submitProofDraft, useField, useForm } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import { goToFirstError } from '@ecp/features/sales/shared/utils/web';

import { VehicleProfileFormQuestions } from '../../formBody';
import {
  gettingVehicleImage,
  useValidateVehicleInfo,
  useValidateVinNumber,
  useVehicleEditValidation,
  useVehicleInfoOrVinValue,
  validateVinFormatRegex as validateVinFormat,
} from '../../state';
import { useVehicleInfo } from '../VehicleForm/vehicleOptions';
import { useStyles } from './VehicleProfileForm.styles';

interface Props {
  vehicleRef: string;
  maskRequiredFieldError: boolean;
  setMaskRequiredFieldError: (value: boolean) => void;
  onAddVehicle?: () => void;
  onNext?: () => Promise<void>;
  nextPageInstructions: string;
  isEdit: boolean;
}

export const VehicleProfileForm: React.FC<Props> = (props) => {
  const { vehicleRef, setMaskRequiredFieldError, onNext, nextPageInstructions, isEdit } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const disableVehicleServiceCallFlag = flagValues[FeatureFlags.DISABLE_VEHICLE_SERVICE_CALL];

  const vehicleInfoOrVin = useField(`static.${vehicleRef}.vehicleInfoOrVin`);
  const make = useField(`${vehicleRef}.make`);
  const model = useField(`${vehicleRef}.model`);
  const series = useField(`${vehicleRef}.series`);
  const vin = useField(`${vehicleRef}.vin`);
  const year = useField(`${vehicleRef}.year`);
  const stubVin = useField(`${vehicleRef}.stubVin`);
  const vehicleDetailId = useField(`${vehicleRef}.vehicleDetailId`);

  const { isVehicleApiInProgress: isFetchVehicleInProgress, fetchVehicleInfo } = useVehicleInfo(
    make,
    model,
    series,
    vin,
    stubVin,
    vehicleDetailId,
  );

  const vehicleInfoOrVinValue = useVehicleInfoOrVinValue(vehicleInfoOrVin, vin);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const getFields = useGetFields();
  const fields = getFields();
  const fieldsLength = Object.keys(fields).length;
  const getInitValues = useGetInitValues();
  const getConditions = useGetConditionValues();
  const conditions = getConditions();
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions,
  });

  useVehicleEditValidation(
    validateForm,
    vehicleInfoOrVinValue,
    fetchVehicleInfo,
    year.value,
    vin.value,
    goToFirstError,
    isEdit,
    fieldsLength,
    vehicleRef,
  );

  // Set initial image url to empty string to show the generic car image
  const vehicleImageUrl = useRef('');
  useEffect(() => {
    const getVehicleImageUrl = async (): Promise<void> => {
      vehicleImageUrl.current = await dispatch(
        gettingVehicleImage({
          year: ensureStringOrNull(year.value),
          make: ensureStringOrNull(make.value),
          model: ensureStringOrNull(model.value),
        }),
      );
    };
    if (year.value && make.value && model.value && !disableVehicleServiceCallFlag) {
      getVehicleImageUrl();
    }
  }, [year, dispatch, make.value, model.value, disableVehicleServiceCallFlag]);

  const validateVehicleInfo = useValidateVehicleInfo(vehicleInfoOrVinValue, make, model, series);
  const validateVinNumber = useValidateVinNumber(vin);

  const updateAnswers = useCallback(async () => {
    // set mask-required-field-error to false for form-level validation
    setMaskRequiredFieldError(false);
    if (!validateForm().isValid) {
      goToFirstError();

      return false;
    }
    if (!validateVehicleInfo()) {
      goToFirstError();

      return false;
    }

    // send form values to answers endpoint
    await patchFormValues();

    // This is for a special scenario when user types in a VIN
    // and directly click on submit and avoids onBlur event of VIN
    let vehicleInfoError = false;
    if (year.value && !validateVinFormat(String(vin.value)) && !disableVehicleServiceCallFlag) {
      vehicleInfoError = await fetchVehicleInfo(String(year.value), String(vin.value));
    }
    if (vehicleInfoError) {
      goToFirstError();

      return false;
    }
    if (vehicleInfoOrVinValue === 'vin' && !validateVinNumber() && !isMasked(vin.value)) {
      goToFirstError();

      return false;
    }
    await dispatch(submitProofDraft({ policyTypes: ['auto'] }));

    return true;
  }, [
    setMaskRequiredFieldError,
    validateForm,
    validateVehicleInfo,
    patchFormValues,
    year.value,
    vin.value,
    vehicleInfoOrVinValue,
    validateVinNumber,
    fetchVehicleInfo,
    disableVehicleServiceCallFlag,
    dispatch,
  ]);

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);
    if (await updateAnswers()) await onNext?.();
    setIsSubmitting(false);
  }, [onNext, updateAnswers]);

  const handleMouseDown = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
  }, []);

  return (
    <div className={classes.root}>
      <Form>
        <VehicleProfileFormQuestions
          vehicleRef={vehicleRef}
          setMaskRequiredFieldError={setMaskRequiredFieldError}
          disableVehicleServiceCallFlag={disableVehicleServiceCallFlag}
        />
        <Grid item xs={12}>
          <NextPageInstructions divider>{nextPageInstructions}</NextPageInstructions>
        </Grid>
        <Grid className={classes.buttonsPanel} container item xs={12}>
          <div className={classes.addButton}>
            <Button
              className={classes.next}
              variant='primary'
              onClick={handleSubmit}
              // This stops the onBlur from firing only when this button is clicked
              // and the button retains its coordinates for the mouseup to eventually
              // register it as a click event. If you want the onBlur as well, you can
              // fire it yourself from the onClick handler. Touch should keep working.
              onMouseDown={handleMouseDown}
              isProcessing={isPatchFormInProgress || isSubmitting}
              disabled={!vin.value}
              data-testid='autoVehicleProfileContinue'
              trackingName='AutoVehicleContinueButton'
              trackingLabel={GoogleAnalyticsLabels.CONTINUE}
              analyticsElement='choice.vehicleDetailsPage.continueButton'
            >
              Save & continue
            </Button>
          </div>
        </Grid>
      </Form>
      {isFetchVehicleInProgress && <LogoSpinner />}
    </div>
  );
};
