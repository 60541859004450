import { Grid } from '@mui/material';

import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';
import type { OptionProps, VehicleWithImage } from '@ecp/features/sales/shared/types';

import { useCheckShowOwnershipDate } from '../../../../state';
import { useStyles } from './VehicleOwnershipDateQuestion.styles';

interface Props extends QuestionProps {
  vehicleItem: VehicleWithImage;
}

export const VehicleOwnershipDateQuestion: React.FC<Props> = (props) => {
  const { classes } = useStyles();
  const { vehicleItem } = props;
  const ownershipDate = useField(`${vehicleItem.vehicle.ref}.ownershipDate`);
  const showOwnershipDate = useCheckShowOwnershipDate(ownershipDate);

  useAddFields({ [`${ownershipDate.key}`]: ownershipDate });

  useAddConditionValues({
    conditionalFields: [ownershipDate],
    isRequiredOverride: () => showOwnershipDate,
  });

  if (!ownershipDate.exists || !showOwnershipDate) return null;

  return (
    <Grid item xs={12} md={6}>
      <div className={classes.label}>
        {vehicleItem.imageUrl && (
          <img className={classes.labelImage} src={vehicleItem.imageUrl} alt='Vehicle' />
        )}
        <p>{vehicleItem.vehicle.description}</p>
      </div>
      <Select
        {...(ownershipDate.props as OptionProps)}
        label='Ownership timeframe'
        id='VehicleOwnershipDate'
        inputButtonAriaLabel='Ownership timeframe'
        trackingName='ownership_timeframe'
      />
    </Grid>
  );
};
