export const partner = {
  shared: {
    partnerDisplayName: 'CONNECT, powered by American Family Insurance',
    salesEmailQuotesAddress: 'AutoHomeReferral@CONNECTbyAmFam.com',
    salesPhoneHours: 'Mon-Fri:  8:30 a.m. – 5 p.m. CT, Sat-Sun: CLOSED',
    servicePhoneHours:
      'Monday to Friday: 7:00am - 10:00pm CT; Saturday: 8:30am – 7:00pm CT; Sunday: closed',
    qnbPhoneNumber: '1-888-554-6368',
    dnqPhoneNumber: '1-888-554-6196',
    salesPhoneNumber: '1-888-554-6368',
    servicePhoneNumber: '1-866-363-5535',
    agentFromEmailAddress: 'CONNECT@email.connectbyamfam.com',
    agentFromEmailName: 'CONNECT, powered by American Family Insurance',
  },
  lobDetail: { AUTO: { dnqNumbers: {}, discount: {} } },
} as const;
