import type { ReactNode } from 'react';
import { useCallback, useRef, useState } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels, trackClick } from '@ecp/utils/analytics/tracking';
import type { GeoAddress } from '@ecp/utils/geo';

import { GridItem } from '@ecp/components';
import { env } from '@ecp/env';
import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import {
  useUpdateVehicleKeptAddressRef,
  useVehicleKeptAddressRef,
} from '@ecp/features/sales/quotes/auto';
import { Button, Form } from '@ecp/features/sales/shared/components';
import { usePrefillFlowDetermined } from '@ecp/features/sales/shared/routing';
import {
  getFieldErrors,
  getLineOfBusiness,
  useField,
  useFieldWithPrefix,
  useForm,
  usePniRef,
  usePrimaryAddressRef,
  useUpdatePNIInsuredType,
} from '@ecp/features/sales/shared/store';
import type { ThunkAction, ValidateFormResult } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { goToFirstError } from '@ecp/features/sales/shared/utils/web';
import { LineOfBusiness } from '@ecp/features/shared/product';

import { PersonLivingFormQuestions } from '../../formBody';
import { PersonFormQuestions } from '../../formBody/PersonForm';
import { useStyles } from './PersonForm.styles';

interface SubmitParams {
  lineOfBusiness: string;
  onNext: () => Promise<void>;
  patchFormValues: () => Promise<string>;
  prefillFlowDetermined: boolean;
  primaryAddressRef: string;
  setIsSubmitting: (f: boolean) => void;
  validateForm: () => ValidateFormResult;
  validateVehicleKeptAddressValues: () => boolean;
  validateVehicleKeptAddressFields: () => void;
}

interface Props {
  geoAddressSuggestions: GeoAddress[];
  setGeoAddressSuggestions(value: GeoAddress[]): void;
  onNext: () => Promise<void>;
  lineOfBusiness: LineOfBusiness;
  disclosureScripts?: ReactNode;
  vehicleKeptAddressSuggestions: GeoAddress[];
  setVehicleKeptAddressSuggestions(value: GeoAddress[]): void;
}

const doSubmit =
  ({
    lineOfBusiness,
    onNext,
    patchFormValues,
    prefillFlowDetermined,
    primaryAddressRef,
    setIsSubmitting,
    validateForm,
    validateVehicleKeptAddressValues,
    validateVehicleKeptAddressFields,
  }: SubmitParams): ThunkAction<Promise<void>> =>
  async (...[, getState]) => {
    setIsSubmitting(true);

    if (validateForm().isValid) {
      // Patch form values on Continue button click
      // This would be helpful for fields with defaults
      await patchFormValues();

      if (getFieldErrors(getState(), `${primaryAddressRef}.state`).length) {
        setIsSubmitting(false);
        goToFirstError();

        return;
      }
      if (
        (lineOfBusiness === 'LINE_OF_BUSINESS.BUNDLE' ||
          lineOfBusiness === 'LINE_OF_BUSINESS.BUNDLE_AUTO_RENTERS') &&
        !validateVehicleKeptAddressValues()
      ) {
        validateVehicleKeptAddressFields();
        goToFirstError();
      } else {
        await onNext();
        if (!prefillFlowDetermined) {
          // show spinner on continue button
          setIsSubmitting(true);
        }
      }
    }

    setIsSubmitting(false);
  };

export const PersonForm: React.FC<Props> = (props) => {
  const { onNext, disclosureScripts, lineOfBusiness } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const getFields = useGetFields();
  const getInitValues = useGetInitValues();
  const prefillFlowDetermined = usePrefillFlowDetermined();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const getConditions = useGetConditionValues();
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    conditions: getConditions(),
  });

  // for Bundle policies, we will need to ensure, if a user designates
  // an address for their vehicle that is separate from their primary address,
  // that those values exist & are valid
  const primaryAddressRef = usePrimaryAddressRef();
  const vehicleKeptAddressRef = useVehicleKeptAddressRef();
  const updateVehicleKeptAddressRef = useUpdateVehicleKeptAddressRef();
  const updatePNIInsuredType = useUpdatePNIInsuredType();
  const vehicleKeptAddressReference = useVehicleKeptAddressRef();
  const lob = useSelector(getLineOfBusiness);
  const useVKAAddressField = useFieldWithPrefix(vehicleKeptAddressRef, 'address.<id>');
  const city = useVKAAddressField('city');
  const line1 = useVKAAddressField('line1');
  const zipcode = useVKAAddressField('zipcode');
  const pniRef = usePniRef();
  const dateOfBirthRecall = useField('static.person.dob');
  const usePersonField = useFieldWithPrefix(pniRef, 'person.<id>');
  const dateOfBirth = usePersonField('dob');

  const validateVehicleKeptAddressValues = useCallback(() => {
    if (primaryAddressRef === vehicleKeptAddressRef) {
      return true;
    }

    if (line1.value && city.value && zipcode.value) {
      return true;
    }

    return false;
  }, [city.value, line1.value, primaryAddressRef, vehicleKeptAddressRef, zipcode.value]);

  const validateVehicleKeptAddressFields = useCallback(() => {
    line1.validate(line1.value);
    city.validate(city.value);
    zipcode.validate(zipcode.value);
  }, [line1, city, zipcode]);

  const handleSubmit = useCallback(async () => {
    // Patch static dob, if user clicks enter
    // without coming out of DOB filed and submits the page
    dateOfBirthRecall.props.actionOnComplete(dateOfBirth.props.value);
    trackClick({ action: 'continue', label: 'person_page_continue' });
    await dispatch(
      doSubmit({
        lineOfBusiness,
        onNext,
        patchFormValues,
        prefillFlowDetermined,
        primaryAddressRef,
        setIsSubmitting,
        validateForm,
        validateVehicleKeptAddressValues,
        validateVehicleKeptAddressFields,
      }),
    );
    if (
      (lob === LineOfBusiness.BUNDLE || lob === LineOfBusiness.BUNDLE_AUTO_RENTERS) &&
      !vehicleKeptAddressReference
    ) {
      updateVehicleKeptAddressRef(true);
    }
    updatePNIInsuredType();
  }, [
    dateOfBirth.props.value,
    dateOfBirthRecall.props,
    dispatch,
    lineOfBusiness,
    onNext,
    patchFormValues,
    prefillFlowDetermined,
    primaryAddressRef,
    validateForm,
    validateVehicleKeptAddressValues,
    validateVehicleKeptAddressFields,
    lob,
    vehicleKeptAddressReference,
    updatePNIInsuredType,
    updateVehicleKeptAddressRef,
  ]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container>
          <PersonFormQuestions {...props} />
          <PersonLivingFormQuestions {...props} />
          {env.static.isAgent && disclosureScripts ? disclosureScripts : null}
          <GridItem topSpacing='lg' xs={12}>
            <Button
              variant='primary'
              onClick={handleSubmit}
              isProcessing={isPatchFormInProgress || isSubmitting}
              className={classes.next}
              data-testid='continue'
              trackingName={GoogleAnalyticsLabels.CONTINUE}
              trackingLabel='person_page_continue'
              analyticsElement='choice.personPage.continueButton'
              type='submit'
              fullWidth
            >
              Continue
            </Button>
          </GridItem>
        </Grid>
      </Form>
    </div>
  );
};
