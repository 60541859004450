import { memo, useCallback, useState } from 'react';

import { agentAuth, useAgentAuth } from '@ecp/utils/auth';

import { Button } from '@ecp/components';
import { Dialog } from '@ecp/features/sales/shared/components';
import { getIsCreatingInquiry, startOverFlow } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { IconUILogout, IconUIRefresh } from '@ecp/themes/base';

import { useStyles } from './Actions.styles';

interface Props {
  className?: string;
}

interface HeaderActionsDialogProps {
  buttonText: string;
  onClose?: () => void;
  open: boolean;
  handleClick?: () => void;
}

export const HeaderActionsDialog: React.FC<HeaderActionsDialogProps> = memo(
  ({ buttonText, onClose, open, handleClick }) => {
    return (
      <Dialog
        actionButtonLabel='YES, CONTINUE'
        actionButtonVariant='danger'
        textButtonLabel='Cancel'
        titleText={buttonText}
        actionButtonOnClick={handleClick}
        textButtonOnClick={onClose}
        open={open}
        buttonPlacement='right'
        onClose={onClose}
      >
        Are you sure you want to {buttonText.toLowerCase()}?
      </Dialog>
    );
  },
);

export const Actions: React.FC<Props> = memo(({ className }) => {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const [dialogLogoutOpen, setDialogLogoutOpen] = useState(false);
  const [dialogStartOverOpen, setDialogStartOverOpen] = useState(false);
  // Need this to disable logout during inquiry creation.
  // Fixes edge case where post inquiry completes after logout and causes issues
  const isInquiryPending = useSelector(getIsCreatingInquiry);

  const toggleLogoutDialogOpen = useCallback(() => {
    setDialogLogoutOpen(!dialogLogoutOpen);
  }, [dialogLogoutOpen, setDialogLogoutOpen]);

  const toggleStartOverDialogOpen = useCallback(() => {
    setDialogStartOverOpen(!dialogStartOverOpen);
  }, [dialogStartOverOpen, setDialogStartOverOpen]);

  const handleStartOverClick = useCallback((): void => {
    dispatch(startOverFlow(true));
    setDialogStartOverOpen(false);
  }, [dispatch]);

  const handleLogoutClick = useCallback((): void => {
    agentAuth.logout();
    dispatch(startOverFlow());
    setDialogLogoutOpen(false);
  }, [dispatch]);

  useAgentAuth();

  if (!agentAuth.isAuth) return null;

  return (
    <div className={cx(classes.root, className)}>
      <Button
        icon={<IconUIRefresh />}
        onClick={toggleStartOverDialogOpen}
        variant='iconTextMedium'
        disabled={isInquiryPending}
      >
        Start over
      </Button>
      <Button
        icon={<IconUILogout />}
        onClick={toggleLogoutDialogOpen}
        variant='iconTextMedium'
        disabled={isInquiryPending}
      >
        Logout
      </Button>
      <HeaderActionsDialog
        open={dialogStartOverOpen}
        buttonText='Start over'
        handleClick={handleStartOverClick}
        onClose={toggleStartOverDialogOpen}
      />
      <HeaderActionsDialog
        open={dialogLogoutOpen}
        buttonText='Logout'
        handleClick={handleLogoutClick}
        onClose={toggleLogoutDialogOpen}
      />
    </div>
  );
});
