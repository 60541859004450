import type { Metadata } from './metadata.js';

const metadata: Metadata = {
  skipFetchAcknowledgement: true,
  skipFetchPostBindQuestions: true,
  shouldRenderTitle: false,
  showAcknowledgement: false,
  showCarrierMessaging: false,
  collectRecurringPay: true,
  hideContactInfo: true,
};

export default metadata;
