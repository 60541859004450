import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DebugTopbarDrawer' })(() => ({
  root: {
    flexWrap: 'nowrap',
    '& ul': {
      padding: 0,
    },
    '& li': {
      listStyleType: 'none',
    },
    '& .MuiSnackbar-anchorOriginTopCenter': {
      top: '150px',
    },
    '& .MuiSnackbarContent-root': {
      justifyContent: 'center',
    },
    '& .MuiSnackbarContent-message': {
      padding: 0,
    },
  },
}));
