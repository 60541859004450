import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { VehicleYearVinMakeModelQuestion } from '../VehicleYearVinMakeModelQuestion';
import { VehicleInfoOrVinQuestion } from './VehicleInfoOrVinQuestion';
import type { VehicleInfoProps } from './VehicleInfoOrVinQuestion/types';

export const VehicleInfoQuestions: React.FC<VehicleInfoProps> = (props) => {
  const disableVehicleServiceCallFlag = flagValues[FeatureFlags.DISABLE_VEHICLE_SERVICE_CALL];

  return disableVehicleServiceCallFlag ? (
    <VehicleYearVinMakeModelQuestion {...props} />
  ) : (
    <VehicleInfoOrVinQuestion {...props} />
  );
};
