import type { Metadata } from './metadata.js';

const metadata: Metadata = {
  allowAgeFirstLicensed: false,
  renderLicenseStateQuestion: false,
  renderDriverLicenseQuestion: false,
  renderRelationshipQuestion: false,
};

export default metadata;
