import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import { useField } from '@ecp/features/sales/shared/store';

import type { QuestionProps } from '../../questionProps.types';
import { useStyles } from './RecentLifeEventQuestion.styles';
interface Props extends QuestionProps {
  disabled: boolean;
}

export const RecentLifeEventQuestion: React.FC<Props> = (props) => {
  const {
    label = 'Have you moved, bought or sold a car, or had a change in drivers in your household in the last month?',
    trackingName = 'move_buy_sell_question',
    disabled,
  } = props;
  const { classes } = useStyles();

  const recentLifeEvent = useField('recentLifeEvent');
  useInitValues({ [recentLifeEvent.key]: false });
  useAddFields({ recentLifeEvent });

  if (!recentLifeEvent.exists) return null;

  return (
    <RadioGroupWithOptions
      {...recentLifeEvent.props}
      variant='yesNoButton'
      data-testid='recentEvent'
      id='RecentLifeEvent'
      label={label}
      trackingName={trackingName}
      trackingLabel={recentLifeEvent.props.value}
      disabled={disabled}
      className={classes.root}
    />
  );
};
