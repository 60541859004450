import type { BaseStateMetadataCollection } from '@ecp/types';

export const AntiTheftCategoryMetadata: BaseStateMetadataCollection = {
  categoryI: {
    title: 'Category I',
    stateOptions: {
      NJ: {
        description: (
          <>
            A device qualifies as a Category I anti-theft device if it meets the requirements of one
            of the devices listed below.
            <ol>
              <li>
                An ignition or starter cut-off switch device is qualified if a warning label
                announces the presence of the device, and the device is designed so that the cut-off
                switch:
                <ol type='i'>
                  <li>Is wired into the ignition’s wiring;</li>
                  <li>Is tripped and activated upon exiting of the automobile;</li>
                  <li>Shall be re-set in order to start the automobile; and</li>
                  <li>
                    Is installed so as not to be visible from the driver’s normal seating position.
                  </li>
                </ol>
              </li>
              <li>
                A nonpassive, externally operated alarm is qualified if a warning label announces
                the presence of the device, and the device is designed so that the alarm is:
                <ol type='i'>
                  <li>Turned off and on by a key used in an externally mounted lock; and</li>
                  <li>Triggered by the opening of a door, the trunk or hood, when engaged.</li>
                </ol>
              </li>
              <li>
                A steering column armored collar is qualified if a warning label announces the
                presence of the device, and the device is designed so that the collar:
                <ol type='i'>
                  <li>Clamps onto the steering column, over the ignition lock;</li>
                  <li>Prevents access to the ignition lock;</li>
                  <li>
                    Prevents the automobile from being steered, if the automobile is started; and
                  </li>
                  <li>
                    Is in no manner attached to the steering column when the device is not in use.
                  </li>
                </ol>
              </li>
            </ol>
          </>
        ),
      },
      RI: {
        description: (
          <>
            A device qualifies as a Category I anti-theft device or vehicle recovery device if it is
            an &quot;Alarm Only Device&quot;. &quot;Alarm Only Device&quot; means a device which
            sounds an audible alarm that can be heard at a distance of at least 300 feet for a
            minimum of three (3) minutes.
          </>
        ),
      },
      KY: {
        description: (
          <>
            A device qualifies as a Category I anti-theft device or vehicle recovery device if it
            meets the requirements of one of the devices listed below.
            <ol>
              <li>
                Ignition or starter cutoff switch which is inserted into the ignition wiring of a
                motor vehicle that has flush or tapered door lock buttons;
              </li>
              <li>
                Nonpassive, externally-operated alarm which is activated if any door, the trunk or
                hood is opened;
              </li>
              <li>
                Internally-operated alarm which is activated if any door, the trunk or hood is
                opened, but which does not meet all the criteria in subsection (1) of KRS 304.20-420
                or subsection (1) of KRS 304.20-430; or
              </li>
              <li>
                Steering column armored collar which clamps on the steering column over the ignition
                lock.
              </li>
            </ol>
          </>
        ),
      },
    },
  },
  categoryII: {
    title: 'Category II',
    stateOptions: {
      NJ: {
        description: (
          <>
            A device qualifies as a Category II anti-theft device if it meets the requirements of
            one of the devices listed below.
            <ol>
              <li>
                A nonpassive fuel cut-off device is qualified if a warning label announces the
                presence of the device, and the device is designed so that the device:
                <ol type='i'>
                  <li>
                    Shall be activated and deactivated by a switch or key, which is hidden from
                    normal view; and
                  </li>
                  <li>Blocks the fuel line, when activated.</li>
                </ol>
              </li>
              <li>
                A nonpassive steering wheel lock device is qualified if a warning label announces
                the presence of the device, and the device is designed so that:
                <ol type='i'>
                  <li>
                    A steel collar and barrel, into which the shackle of a lock fits, are
                    permanently attached to the steering column;
                  </li>
                  <li>
                    The shackle fits over the steering wheel spoke and into the barrel of the
                    collar;
                  </li>
                  <li>A tubular key must be used to operate the lock;</li>
                  <li>When in use, the steering wheel is prevented from turning;</li>
                  <li>The shackle is made of case hardened alloy steel; and</li>
                  <li>The shackle, collar and barrel resist cutting by a file.</li>
                </ol>
              </li>
              <li>
                An armored cable hood lock and ignition cut-off switch is qualified if the device is
                designed so that:
                <ol type='i'>
                  <li>
                    The ignition cut-off switch:
                    <ul style={{ listStyleType: 'none' }}>
                      <li>(1) Is wired into the ignition’s wiring;</li>
                      <li>(2) Is tripped and activated by exiting of the automobile;</li>
                      <li>(3) Shall be re-set in order to start the automobile; and</li>
                      <li>
                        (4) Is installed so as not to be visible from the driver’s normal seating
                        position;
                      </li>
                    </ul>
                  </li>
                  <li>
                    The armored cable hood lock:
                    <ul style={{ listStyleType: 'none' }}>
                      <li>
                        (1) Shall be engaged and disengaged by a push button or such similar device
                        installed within the driver’s reach when the driver is seated;
                      </li>
                      <li>
                        (2) Shall extend through the firewall and be secured so as to prevent
                        retraction; and
                      </li>
                      <li>(3) Must be of a material which effectively prevents cutting.</li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                An emergency handbrake lock is qualified if the device is designed so that:
                <ol type='i'>
                  <li>
                    A lock replaces the handbrake grip and is permanently attached to the handbrake
                    lever;
                  </li>
                  <li>The lock is only released by entering a preset digital combination;</li>
                  <li>The lock encasement is of all metal construction; and</li>
                  <li>The handbrake cannot be released without releasing the lock.</li>
                </ol>
              </li>
              <li>
                A car transmission lock is qualified if the device is designed so that:
                <ol type='i'>
                  <li>
                    It prevents the vehicle from moving from a parked position by locking the gear
                    shift;
                  </li>
                  <li>
                    It is a steel-encased lock which is permanently attached to the floor of the
                    vehicle by a steel stand;
                  </li>
                  <li>
                    The shackle is made of case hardened alloy steel that prevents moving of the
                    gear shift;
                  </li>
                  <li>
                    It has a high security locking system with at least 50,000 combinations; and
                  </li>
                  <li>The lock, shackle and stand resist cutting and filing.</li>
                </ol>
              </li>
            </ol>
          </>
        ),
      },
      RI: {
        description: (
          <>
            A device qualifies as a Category II anti-theft device or vehicle recovery device if it
            is an &quot;Active Disabling Device&quot;. &quot;Active Disabling Device&quot; means a
            device which disables the vehicle by making the fuel, ignition or starting system
            inoperative and a separate manual step is required to engage the device.
          </>
        ),
      },
      KY: {
        description: (
          <>
            A device qualifies as a Category II anti-theft device or vehicle recovery device if it
            meets the requirements of one of the devices listed below.
            <ol>
              <li>
                Internally-operated alarm system which automatically cuts off the ignition or
                disconnects the starter and is activated if any door, the trunk or hood is opened,
                but which does not meet all the criteria in subsection (1) of KRS 304.20-430;
              </li>
              <li>
                Nonpassive fuel cutoff device which is activated when a switch is tripped or when
                the device is engaged by a key;
              </li>
              <li>
                Nonpassive steering wheel lock consisting of a steel collar and barrel, into which
                the shackle of a lock fits, which is permanently attached to the steering post and
                the lock is operated by a tubular key;
              </li>
              <li>
                Armored cable hood lock and ignition cutoff switch where the cable extends through
                the firewall and is secured to prevent retraction, but which does not meet all the
                criteria in paragraph (a) of subsection (6) of KRS 304.20-430; or
              </li>
              <li>
                Window identification system in which identification letters or numbers are etched
                into all windows of the motor vehicle other than small vent window.
              </li>
            </ol>
          </>
        ),
      },
    },
  },
  categoryIII: {
    title: 'Category III',
    stateOptions: {
      NJ: {
        description: (
          <>
            A device qualifies as a Category III anti-theft or vehicle recovery device if it meets
            the requirements of one of the devices listed below.
            <ol>
              <li>
                A passive alarm system is qualified if a warning label announces the presence of the
                system, and the system is designed so that:
                <ol type='i'>
                  <li>The alarm is triggered by entry of the automobile’s doors, hood or trunk;</li>
                  <li>
                    The alarm sounds for not more than eight minutes and is automatically re-set
                    upon its cessation from sounding;
                  </li>
                  <li>
                    The alarm is installed in the engine compartment so as to be inaccessible
                    without opening the hood;
                  </li>
                  <li>
                    The hood shall not open unless unlocked from within the automobile by a key or a
                    keyless device;
                  </li>
                  <li>
                    The ignition or starter shall be cut-off or disabled automatically upon
                    triggering of the alarm; and
                  </li>
                  <li>
                    The system shall be disengaged by use of a tubular lock or an electronic keyless
                    device within a maximum time elapse of 20 seconds following entry or re-entry of
                    the automobile.
                  </li>
                </ol>
              </li>
              <li>
                A fuel cut-off device is qualified if a warning label announces the presence of the
                device, and the device is designed so that:
                <ol type='i'>
                  <li>
                    The fuel line is blocked when the automobile is turned off and is not re-opened
                    unless a switch is tripped each time the automobile is started;
                  </li>
                  <li>
                    The switch opening the fuel line is accessible from the driver’s seat, but:
                    <ul style={{ listStyleType: 'none' }}>
                      <li>(1) Is hidden from view; or</li>
                      <li>
                        (2) Is operable only by a tubular key or an electronic keyless device;
                      </li>
                    </ul>
                  </li>
                  <li>
                    Any under-the-dash wiring installed in connection with the system shall blend
                    with factory wiring; and
                  </li>
                  <li>
                    Any override switch which is installed shall be hidden from view. The override
                    switch shall either:
                    <ul style={{ listStyleType: 'none' }}>
                      <li>(1) Not be accessible from the forward passenger compartment; or</li>
                      <li>
                        (2) If accessible from the forward passenger compartment, be subject to
                        initiating a warning alarm which sounds while the engine is running and the
                        override switch is engaged.
                      </li>
                      <li>
                        (A) If a warning alarm is required, pursuant to (c)2iv(2) above, then the
                        system shall be designed so that disconnection of the override alarm shall
                        result in disconnection of the entire passive fuel cut-off device.
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li>
                An armored ignition cut-off switch is qualified if a warning label announces the
                presence of the device, and the device is designed so that:
                <ol type='i'>
                  <li>
                    The device, when engaged, prevents normal ignition or hot wiring of the
                    automobile, interrupting the ignition current;
                  </li>
                  <li>
                    A cable runs from a locking system, separate from the ignition lock, to the
                    ignition coil, starter solenoid, or other engine component;
                  </li>
                  <li>
                    The cable is of a material and a design which either effectively prevents
                    cutting, or collapses when cut to prevent ready re-connection of cut interior
                    wires; and
                  </li>
                  <li>
                    The separate locking system shall be installed within the interior of the
                    automobile in a position which is accessible to the driver in normal seating and
                    may be of the tubular type or an electronic keyless device.
                  </li>
                </ol>
              </li>
              <li>
                A passive multi-component cut-off switch is qualified if a warning label announces
                the presence of the device, and the device is designed so that:
                <ol type='i'>
                  <li>
                    When engaged, the primary wire to the ignition coil is disconnected, the starter
                    is disconnected and one or more wires to the electronic ignition system, or the
                    points and condenser are grounded to the chassis;
                  </li>
                  <li>The wiring blends with factory installed wiring;</li>
                  <li>
                    The disconnected/grounding wires shall be routed to random points in the
                    electrical system away from the components the wires affect;
                  </li>
                  <li>
                    The control module, if separate from the electronic locking mechanism, shall be
                    hidden in the engine compartment or other part of the automobile, so that the
                    control module is not easily detectable; and
                  </li>
                  <li>
                    The automobile cannot be started unless the device is deactivated through a
                    locking system installed within the interior of the vehicle. The locking system
                    shall be accessible to the driver in a normal seating position. The lock may be
                    either of a tubular type or a system which uses an electronic keyless device.
                  </li>
                </ol>
              </li>
              <li>
                A passive time delay ignition system is qualified if a warning label announces the
                presence of the system, and the system is designed so that:
                <ol type='i'>
                  <li>
                    The system allows the automobile to be started only if the operator waits a
                    prescribed time period before moving the ignition key from on to start;
                  </li>
                  <li>
                    The prescribed time period varies from one system to another in a range of three
                    seconds to 20 seconds;
                  </li>
                  <li>
                    The system requires an additional waiting period of at least 90 seconds before
                    the operator may try to start the automobile again with success;
                  </li>
                  <li>The system includes a hood lock which is operated by a tubular key; and</li>
                  <li>The system shall resist tampering.</li>
                </ol>
              </li>
              <li>
                An armored cable or electronically operated hood lock and ignition cut-off switch
                system is a qualified system if a warning label announces the presence of the
                system, and the system is designed so that:
                <ol type='i'>
                  <li>When engaged, the ignition cannot be started, or is cut-off;</li>
                  <li>
                    When an armored cable hood lock is used:
                    <ul style={{ listStyleType: 'none' }}>
                      <li>
                        (1) The cable shall be made of case-hardened solid steel tubing which
                        resists cutting;
                      </li>
                      <li>
                        (2) The cable shall extend through the firewall and be secured so as to
                        prevent retraction;
                      </li>
                      <li>
                        (3) No portion of the cable may be accessible so as to be grasped from
                        beneath the automobile, and if accessible through the grill work, the armor
                        shall extend to the hood locking mechanism; and
                      </li>
                      <li>
                        (4) The system shall be engaged by a push button within the automobile’s
                        interior, or a similar device, which is installed so as to be readily
                        accessible to the driver in normal seating position;
                      </li>
                    </ul>
                  </li>
                  <li>
                    When an electronically operated hood lock is used:
                    <ul style={{ listStyleType: 'none' }}>
                      <li>
                        (1) The hood lock is electronically operated and functions so as to remain
                        locked even when wiring which operates the hood is cut;
                      </li>
                      <li>
                        (2) The hood lock, if accessible through the grill work, or from beneath the
                        car, shall be shielded or armored to prevent manual operation;
                      </li>
                      <li>
                        (3) The system shall be passively engaged by turning the ignition key to the
                        off position; and
                      </li>
                      <li>
                        (4) The system shall be disengaged through use of a separate key and lock,
                        or an electronic keyless device; and
                      </li>
                    </ul>
                  </li>
                  <li>
                    The locks controlling the hood lock systems shall be either of the tubular type
                    or be operated electronically.
                  </li>
                </ol>
              </li>
              <li>
                A passive delayed ignition cut-off system is qualified if a warning label announces
                the presence of the system and the system is designed so that:
                <ol type='i'>
                  <li>
                    The ignition circuit is interrupted automatically when the engine reaches a
                    pre-set speed, unless the system is actively disengaged;
                  </li>
                  <li>
                    The speed is pre-set in a range between 1500 and 2000 revolutions per minute
                    (RPM);
                  </li>
                  <li>The system is engaged when the ignition is turned off;</li>
                  <li>
                    The system may be disengaged by a push button or other specific device within
                    the interior of the vehicle, but shall be hidden from view;
                  </li>
                  <li>
                    The system may be disengaged by use of either a lock of the tubular type or an
                    electronic keyless device;
                  </li>
                  <li>Wiring shall blend with factory wiring, if placed under the dash;</li>
                  <li>An alarm shall sound when the ignition is disabled; and</li>
                  <li>
                    If an override switch is provided, the switch shall be hidden from view, and
                    work in conjunction with an alarm that sounds continuously while the engine is
                    running.
                  </li>
                </ol>
              </li>
              <li>
                A passive ignition lock protection system is qualified if a warning label announces
                the system, and the system is designed so that:
                <ol type='i'>
                  <li>A case-hardened steel protective cap fits over the ignition;</li>
                  <li>
                    The cap fastens to a steel collar fitted around the steering column and over the
                    ignition lock; and
                  </li>
                  <li>
                    The cap contains a slotted opening through which the ignition key fits and is
                    operable.
                  </li>
                </ol>
              </li>
              <li>
                A high security replacement lock device is qualified if a warning label announces
                the device, and the device is designed so that it is a case-hardened steering column
                ignition lock conforming to the National Highway Traffic and Safety Association’s
                Standard No. 114-1, incorporated herein by reference. A copy of Standard No. 114-1
                may be obtained by writing:
                <br />
                National Highway Traffic and Safety Association
                <br />
                Docket Room
                <br />
                NAD-52 400 Seventh Street, S.W.
                <br />
                Washington, D.C. 20590
              </li>
              <li>
                A hydraulic brake lock device is qualified if a warning label announces the presence
                of the device and the device is designed so that:
                <ol type='i'>
                  <li>The device is mounted on the dash;</li>
                  <li>
                    When activated and pressurized with the brake pedal, hydraulic pressure is
                    maintained on the brakes at two or more of the automobile’s wheels;
                  </li>
                  <li>
                    The device has a high security locking system with at least 50,000 combinations;
                    and
                  </li>
                  <li>
                    The lock is such that it cannot be pulled using a conventional slide hammer or
                    lock puller equipment.
                  </li>
                </ol>
              </li>
              <li>
                A window etching vehicle identification system is qualified if a warning label
                announces the presence of the system, and the system is designed so that:
                <ol type='i'>
                  <li>
                    A specific, identifiable set of numbers is permanently etched into all primary
                    window glass areas, either by sandblasting or a chemical process;
                  </li>
                  <li>
                    The set of numbers must be traceable to the automobile’s registered owner; and
                  </li>
                  <li>
                    Immediate telephonic notification or identification of the registrant must be
                    available 24 hours a day, seven days per week.
                  </li>
                </ol>
              </li>
            </ol>
          </>
        ),
      },
      RI: {
        description: (
          <>
            A device qualifies as a Category III anti-theft or vehicle recovery device if it is a
            &quot;Passive Disabling Device&quot;. &quot;Passive Disabling Device&quot; means a
            device which disables the vehicle by making the fuel, ignition or starting system
            inoperative and a separate manual step is not required to engage the device.
          </>
        ),
      },
      KY: {
        description: (
          <>
            A device qualifies as a Category III anti-theft or vehicle recovery device if it meets
            the requirements of one of the devices listed below.
            <ol>
              <li>
                Passive alarm system which meets the following criteria:
                <ul style={{ listStyleType: 'none' }}>
                  <li>
                    (a) Ignition shall be cut off automatically, or starter shall be disabled
                    automatically;
                  </li>
                  <li>(b) Alarm shall be triggered by entry of doors, hood, or trunk;</li>
                  <li>
                    (c) Hood shall not open unless unlocked from inside the vehicle by a key or by
                    an electronic keyless device;
                  </li>
                  <li>
                    (d) Alarm shall sound for no more than eight (8) minutes and shall reset itself;
                  </li>
                  <li>
                    (e) Alarm shall not emit a pulsating, whooping, or yelping sound which could
                    cause it to be mistaken for the police, fire, or other emergency vehicle siren;
                  </li>
                  <li>
                    (f) Alarm shall be installed in the engine compartment so as to be inaccessible
                    without opening the hood; and
                  </li>
                  <li>
                    (g) The system shall be engaged passively by turning the ignition key to the off
                    position. A tubular lock or electronic keyless device shall be used to disarm
                    the system;
                  </li>
                </ul>
              </li>
              <li>
                Passive fuel cutoff device which is engaged by turning the ignition key to the off
                position and which requires that a trip be switched to start the motor vehicle and
                which meets the following criteria:
                <ul style={{ listStyleType: 'none' }}>
                  <li>(a) The fuel line shall be blocked when the power is off;</li>
                  <li>
                    (b) The switch to open the fuel line shall be hidden from view but accessible to
                    the driver from the driver&apos;s seat or a tubular key or electronic keyless
                    device may be used;
                  </li>
                  <li>
                    (c) A parking service attendant override switch may be provided which is not
                    accessible from the passenger compartment unless a warning buzzer sounds while
                    the engine is running and the override switch is engaged; and
                  </li>
                  <li>
                    (d) Any under the dash wiring installed with the device shall blend in color
                    with factory-installed wiring;
                  </li>
                </ul>
              </li>
              <li>
                Armored ignition cutoff switch which is designed to resist tampering and which meets
                the following criteria:
                <ul style={{ listStyleType: 'none' }}>
                  <li>
                    (a) Armored cable shall run from a separate key to the coil, starter, solenoid,
                    or other engine component;
                  </li>
                  <li>(b) The device shall prevent hot-wiring of the motor vehicle; and</li>
                  <li>
                    (c) A separate lock shall be of tubular type and installed inside the motor
                    vehicle or an electronic keyless device may be used;
                  </li>
                </ul>
              </li>
              <li>
                Passive multi-component cutoff switch which is activated when the key is turned to
                the off position and which meets the following criteria:
                <ul style={{ listStyleType: 'none' }}>
                  <li>(a) The primary wire to the ignition coil shall be disconnected;</li>
                  <li>(b) The device shall disconnect the starter;</li>
                  <li>
                    (c) One (1) or more wires to the electronic ignition system or to the points and
                    condenser shall be disconnected and grounded to the chassis;
                  </li>
                  <li>
                    (d) The wiring shall blend with factory-installed wiring and the
                    disconnecting/grounding wires shall be routed to random points in the electrical
                    system;
                  </li>
                  <li>
                    (e) The control module, if separate from the electronic locking mechanism, shall
                    be hidden in the engine compartment or other place where it will not be easily
                    detectable; and
                  </li>
                  <li>
                    (f) In order to start the car, a lock or electronic device shall be used to
                    deactivate the system.
                  </li>
                </ul>
              </li>
              <li>
                Passive time delay ignition system which requires the operator to wait a prescribed
                time before moving the ignition key from the on position to the start position;
              </li>
              <li>
                Armored cable or electrically operated hood lock and ignition cutoff switch which is
                a supplemental hood lock operated from within the motor vehicle and which also cuts
                off the ignition when engaged and meets the following criteria:
                <ul style={{ listStyleType: 'none' }}>
                  <li>
                    (a) Armored cable hood lock shall have tubing which extends through the
                    firewall. The system shall be engaged by a pushbutton or other device which
                    facilitates use and no portion of the hood lock cable may be accessible from
                    underneath the motor vehicle; and
                  </li>
                  <li>
                    (b) Electrically operated hood lock shall remain locked even if the wiring
                    operating the hood lock is cut. The system shall be engaged by turning the
                    ignition key to the off position. The lock shall be shielded or armored so that
                    it cannot be operated manually.
                  </li>
                </ul>
              </li>
              <li>
                Passive, delayed ignition cutoff system which disables the ignition circuit at a
                preset engine speed and meets the following criteria:
                <ul style={{ listStyleType: 'none' }}>
                  <li>
                    (a) The ignition shall cut off automatically as soon as the engine reaches a
                    speed in the range of fifteen hundred (1,500) to two thousand (2,000) RPM;
                  </li>
                  <li>
                    (b) The system shall be automatically armed when the ignition key is turned to
                    the off position;
                  </li>
                  <li>
                    (c) A pushbutton or other type of disarm switch shall be well hidden and the
                    wiring shall blend with factory-installed wiring if placed under the dash;
                  </li>
                  <li>
                    (d) An alarm or horn shall be actuated at the same time the ignition is
                    disabled; and
                  </li>
                  <li>
                    (e) If a parking service attendant switch is provided, a buzzer shall sound all
                    the time the engine is running;
                  </li>
                </ul>
              </li>
              <li>
                Passive ignition lock protective system that consists of a protective cap which fits
                over the ignition to prevent extraction of the ignition lock cylinder; or
              </li>
              <li>
                High security ignition replacement lock which conforms to federal standards and
                cannot be removed using a conventional slide hammer or lock puller equipment.
              </li>
            </ol>
          </>
        ),
      },
    },
  },
  categoryIV: {
    title: 'Category IV',
    stateOptions: {
      NJ: {
        description: (
          <>
            A device or system qualifies as a Category IV anti-theft or vehicle recovery device if a
            warning label announces the presence of the device and it meets the following
            requirements:
            <ol>
              <li>
                The device or system is designed to transmit a pulse or signal by which the location
                of the automobile in which the device or system is installed may be tracked by those
                receiving the signal;
              </li>
              <li>
                The device or system is activated or initiated when an automobile is stolen or
                reported stolen to police;
              </li>
              <li>
                The pulse or signal either must be transmittable to the New Jersey State Police or
                to a private central monitoring station which shall have direct communication with
                the New Jersey State Police for the purpose of reporting, tracking and monitoring
                the automobile; and
              </li>
              <li>
                The device or system shall be designed so that upon recovery, information concerning
                the automobile’s location may be provided to the proper authorities and/or the
                automobile’s owner or insurer.
              </li>
            </ol>
          </>
        ),
      },
      RI: {
        description: (
          <>
            A device or system qualifies as a Category IV anti-theft or vehicle recovery device if
            it is &quot;Vehicle Recovery System Device&quot;. &quot;Vehicle Recovery System
            Device&quot; means an electronic unit installed in a vehicle that is activated after the
            vehicle is stolen. When activated, the device provides information to law enforcement
            officials or another public or private entity regarding the vehicle&apos;s location. The
            system provides for the routine delivery of the information to the appropriate law
            enforcement entity to assist in the recovery of the vehicle.
          </>
        ),
      },
    },
  },
};
