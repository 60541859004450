export const partner = {
  shared: {
    partnerDisplayName: 'Homesite Insurance',
    salesEmailQuotesAddress: 'servicingrevolution@homesite.com',
    salesPhoneHours: 'Mon-Fri: 8:00AM - 11:00PM ET, Sat-Sun: 9:00AM - 6:00PM ET',
    servicePhoneHours:
      'Monday - Friday: 8:00am - 11:00pm ET, Saturday: 9:30am – 8:00pm ET, Sunday: closed',
    qnbPhoneNumber: '833-430-0184',
    dnqPhoneNumber: '833-430-0184',
    salesPhoneNumber: '833-430-0184',
    servicePhoneNumber: '833-430-0184',
    agentFromEmailAddress: 'donotreply@email.homesite.com',
    agentFromEmailName: 'Homesite Insurance',
  },
  lobDetail: {
    AUTO: {
      partnerDisplayNameShort: 'Homesite Insurance',
      salesPhoneNumber: '833-430-0184',
      servicePhoneNumber: '833-430-0184',
      dnqNumbers: { default: '' },
      discount: {},
    },
  },
} as const;
