import { useMemo } from 'react';

import type { LinkProps } from '@mui/material';
import { Card, CardContent, CardHeader, Grid, Link } from '@mui/material';

import { useVehicleItemList } from '@ecp/features/sales/quotes/auto';
import { makeDriverItemList } from '@ecp/features/sales/shared/components';
import type { AutoQuoteDetails } from '@ecp/features/sales/shared/store';
import {
  getPrimaryInsuredAddressInfo,
  getVehicleAddressInfo,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { DriverVehicleListItem } from '@ecp/features/sales/shared/types';

import { useStyles } from './AutoSummaryCard.styles';

type ItemListProps = {
  items: DriverVehicleListItem[];
  title: string;
};

const ItemList: React.FC<ItemListProps> = (props) => {
  const { items, title } = props;
  const { classes } = useStyles();

  return (
    <Grid item xs={6}>
      <div className={classes.cardTitle}>{title}</div>
      {items.map(({ description, icon, imageUrl }, index) => (
        <div className={classes.quoteItem} key={index}>
          <div className={classes.imageTitle}>
            {/* Render either icon as ReactComponent or image with src imageUrl */}
            {icon ||
              (imageUrl && (
                <img
                  data-testid='autoSummaryItemImage'
                  className={title === 'Drivers' ? classes.quoteDriverImage : classes.quoteImage}
                  src={imageUrl}
                  alt='Quote'
                />
              ))}
            <p className={classes.description}>{description}</p>
          </div>
        </div>
      ))}
    </Grid>
  );
};

type Props = AutoQuoteDetails & {
  onEditClick?: LinkProps['onClick'];
  isEditButtonHidden?: boolean;
};

export const AutoSummaryCard: React.FC<Props> = (props) => {
  const { drivers, onEditClick, isEditButtonHidden } = props;
  const { classes } = useStyles();

  const primaryInsuredAddressInfo = useSelector(getPrimaryInsuredAddressInfo);
  const vehicleAddressInfo = useSelector(getVehicleAddressInfo);
  const { line1, line2, city, state, zipcode } = vehicleAddressInfo.line1
    ? vehicleAddressInfo
    : primaryInsuredAddressInfo;

  const displayAddress = `${line1}${line2 ? `, ${line2}` : ''} ${city}, ${state} ${zipcode}`;

  const vehicleItemList = useVehicleItemList();

  const driverItemList = useMemo(() => makeDriverItemList(drivers), [drivers]);

  return (
    <Card data-testid='autoSummaryCard' className={classes.root}>
      <CardHeader
        classes={{ root: classes.cardHeader, action: classes.cardAction }}
        title={<h3>Auto quote details</h3>}
        action={
          <h6>
            <Link
              className={classes.editLink}
              component='button'
              onClick={onEditClick}
              hidden={isEditButtonHidden}
            >
              Edit
            </Link>
          </h6>
        }
      />
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid container spacing={3}>
            <ItemList title='Vehicles' items={vehicleItemList} />
            <ItemList title='Drivers' items={driverItemList} />
          </Grid>
          <Grid item className={classes.address}>
            <p className={classes.body2}>
              <i>
                <strong>Vehicle address: </strong>
                {displayAddress}
              </i>
            </p>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
