import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AwayAtSchoolSubQuestions' })(() => ({
  root: {
    marginBottom: 18,
  },
  zipCode: {
    marginTop: 10,
  },
}));
