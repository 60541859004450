import { useAddFields } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

interface Props extends QuestionProps {
  driverRef: string;
  personRef: string;
}

export const DriverLicenseUsCanadaDriverLast18MonthsQuestion: React.FC<Props> = (props) => {
  const { driverRef, personRef } = props;
  const useDriverField = useFieldWithPrefix(driverRef, 'driver.<id>');
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const {
    props: { value: name = '' },
  } = usePersonField('firstName');
  const hasNameOrHaveYou = name ? `Has ${name}` : 'Have you';
  const usCanadaDriverLast18Months = useDriverField('license.usCanadaDriverLast18Months');
  useAddFields({ usCanadaDriverLast18Months });

  if (!usCanadaDriverLast18Months.exists) return null;

  return (
    <RadioGroupWithOptions
      {...usCanadaDriverLast18Months.props}
      id='usCanadaDriverLast18Months'
      data-testid='usCanadaDriverLast18Months'
      label={`${hasNameOrHaveYou} been licensed for the last 18 months in the U.S. or Canada?`}
      trackingName='UsCanadaDriverLast18Months'
      variant='yesNoButton'
    />
  );
};
