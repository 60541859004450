import type { Metadata } from './metadata.js';

export const metadata: Metadata = {
  'connect.auto': {},
  'amfam.auto': {},
  'amfam-adv.auto': {},
  'amfam-adv.home': {},
  'homesite.home': {},
  'homesite.renters': {},
  'amfam-adv.renters': {},
  'connect-boost.auto': {},
};

export default metadata;
