import { STATIC_QUESTIONS as STATIC_QUESTIONS_BASE } from './structure.connect-opco.js';

export * from './structure.connect-opco.js';

export const STATIC_QUESTIONS: typeof STATIC_QUESTIONS_BASE = {
  ...STATIC_QUESTIONS_BASE,
  'static.driver.<id>.prior.sameAsPni': {
    answerType: 'String',
    businessType: ['auto', 'home'],
    defaultValue: 'yes',
    options: [
      {
        value: 'yes',
        label: 'Yes',
      },
      {
        value: 'no',
        label: 'No',
      },
      {
        value: 'other',
        label: "I don't have insurance",
      },
    ],
  },
  'static.vehicle.<id>.vehicleKeptAddress': {
    answerType: 'String',
    questionType: 'optional',
    businessType: ['auto', 'home'],
    required: 'false',
  },
};
