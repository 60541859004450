import type { StateOption } from '@ecp/types';

interface BaseMetadata {
  subTitle?: string;
  title?: string;
}

interface StateMetadata extends StateOption<BaseMetadata>, BaseMetadata {}

const sharedHeaderMetadata = {
  subTitle: 'Policy-level discounts',
  title:
    "We've already accounted for any applicable Costco member discount. Now let's see what others you might qualify for.",
};

export const autoHeaderMetadata: {
  [productKey: string]: StateMetadata;
} = {
  'amfam.auto': sharedHeaderMetadata,
  'connect.auto': sharedHeaderMetadata,
  'amfam-adv.auto': sharedHeaderMetadata,
};
