import { useCallback } from 'react';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { isDlNumberMasked } from '@ecp/utils/common';

import type { TextFieldProps } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { TextField } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { updateAnswers, useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

import { DRIVER_INTL, INVALID_DL_DRIVER_REFS } from '../../../../constants';
import { getInvalidDlRefs, useValidateDriverLicenseNumberForOneDriver } from '../../../../state';

interface Props extends QuestionProps {
  driverRef: string;
}

export const DriverLicenseQuestion: React.FC<Props> = (props) => {
  const {
    trackingName = 'DriversLicenseNumber',
    trackingLabel = GoogleAnalyticsLabels.REDACTED,
    driverRef,
  } = props;
  const dispatch = useDispatch();
  const useDriverField = useFieldWithPrefix(driverRef, 'driver.<id>');
  const dlState = useDriverField('license.state');
  const dlNumber = useDriverField('license.number');
  const dlStatus = useDriverField('license.status');
  useAddFields({ dlNumber });
  const validateDriverLicenseNumber = useValidateDriverLicenseNumberForOneDriver(dlNumber, dlState);
  const invalidDlRefs = useSelector(getInvalidDlRefs);

  const driverLicenseActionOnChange: NonNullable<TextFieldProps['actionOnChange']> = useCallback(
    (value) => {
      // if DL number is masked, clear out DL state and patch it
      if (isDlNumberMasked(dlNumber.value)) {
        // if state value is not empty, clear out the state and patch it
        if (dlState.value !== null) dlState.props.actionOnComplete(null);
        dlNumber.props.actionOnChange(null);
        // else change DL number with new value
      } else {
        dlNumber.props.actionOnComplete(value);
      }
    },
    [dlNumber.props, dlNumber.value, dlState.props, dlState.value],
  );

  const driverLicenseActionOnComplete: NonNullable<TextFieldProps['actionOnComplete']> =
    useCallback(
      async (value) => {
        if (isDlNumberMasked(value)) return;
        if (dlStatus.value === DRIVER_INTL) {
          dlNumber.props.actionOnComplete(value);

          return;
        }
        const isDlNumberValid = await validateDriverLicenseNumber();
        if (isDlNumberValid) {
          if (dlState.value) {
            dlNumber.props.actionOnComplete(value);
            dlState.props.actionOnComplete(dlState.value);
          }
          // since valid, will remove the current ref from the invalidDlRefs array if current ref is already in the array
          // will do nothing if the current ref is not in the invalidDlRefs array
          if (invalidDlRefs.includes(driverRef)) {
            invalidDlRefs.splice(invalidDlRefs.indexOf(driverRef), 1);
            await dispatch(updateAnswers({ answers: { [INVALID_DL_DRIVER_REFS]: invalidDlRefs } }));
          }
          // this is to ensure that if user clear out the DL number input field and jump to another field or backnav,
          // sapi will keep the same empty field as what user did, meanwhile result of validation wont confuse the user
        } else if (value === '') {
          dlNumber.props.actionOnComplete(value);
          if (!isDlNumberValid) {
            if (!invalidDlRefs.length) {
              await dispatch(updateAnswers({ answers: { [INVALID_DL_DRIVER_REFS]: [driverRef] } }));
            } else if (!invalidDlRefs.includes(driverRef)) {
              invalidDlRefs.push(driverRef);
              await dispatch(
                updateAnswers({ answers: { [INVALID_DL_DRIVER_REFS]: invalidDlRefs } }),
              );
            }
          }
          // if validation failed, add current ref into the existing invalidDlRefs array,
          // or create a new array with the current ref in if no invalidDlRefs array created yet
        } else if (!isDlNumberValid) {
          if (!invalidDlRefs.length) {
            await dispatch(updateAnswers({ answers: { [INVALID_DL_DRIVER_REFS]: [driverRef] } }));
          } else if (!invalidDlRefs.includes(driverRef)) {
            invalidDlRefs.push(driverRef);
            await dispatch(updateAnswers({ answers: { [INVALID_DL_DRIVER_REFS]: invalidDlRefs } }));
          }
        }
      },
      [
        dispatch,
        dlNumber.props,
        dlState.props,
        dlState.value,
        dlStatus.value,
        driverRef,
        invalidDlRefs,
        validateDriverLicenseNumber,
      ],
    );

  return (
    <TextField
      {...dlNumber.props}
      actionOnChange={driverLicenseActionOnChange}
      actionOnComplete={driverLicenseActionOnComplete}
      id='DriversLicense'
      label='Driver’s license #'
      helperText='Please enter as displayed on your license.'
      ariaLabel='Driver’s license number'
      trackingName={trackingName}
      trackingLabel={trackingLabel}
    />
  );
};
