import { useCallback, useEffect, useState } from 'react';

import { Page } from '@ecp/features/sales/shared/components';
import { PARTNER_EXPERIENCE_ID } from '@ecp/features/sales/shared/constants';
import { useNavigateToNextPage } from '@ecp/features/sales/shared/routing';
import {
  getInquiryLoaded,
  recallOffers,
  useField,
  useGetPersonFields,
  useGetProductFields,
  usePniRef,
  usePrimaryAddressRef,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

import { LandingPageForm } from '../../forms';
import { useStyles } from './LandingPage.styles';
import metadata from './metadata';

export const LandingPage: React.FC = () => {
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const productFields = useGetProductFields();
  const personFields = useGetPersonFields();
  const [isRecallInProgress, setIsRecallInProgress] = useState(false);
  const partnerExpIdField = useField(PARTNER_EXPERIENCE_ID);
  const inquiryLoaded = useSelector(getInquiryLoaded);
  const addressRef = usePrimaryAddressRef();
  const pniRef = usePniRef();

  useEffect(() => {
    if (inquiryLoaded) {
      trackSapiAnalyticsEvent({
        element: 'choice.landingPage.page',
        event: 'render',
        eventDetail: 'true',
      });
    }
  }, [inquiryLoaded]);

  const handleStartNewQuote = useNavigateToNextPage();

  const handleRetrieveSubmit = useCallback(async () => {
    const { person } = personFields;
    const { zipCode } = productFields;
    setIsRecallInProgress(true);
    await dispatch(
      recallOffers({
        person,
        zipcode: zipCode.props.value,
        initialRecallRequest: true,
        experienceId: partnerExpIdField.props.value as string,
        pniRef,
        addressRef,
      }),
    );
    setIsRecallInProgress(false);
  }, [personFields, productFields, dispatch, partnerExpIdField.props.value, pniRef, addressRef]);

  return (
    <div className={classes.root}>
      <Page title={metadata.landingPageTitle}>
        <LandingPageForm
          onRetrieveSubmit={handleRetrieveSubmit}
          onStartNewQuote={handleStartNewQuote}
          isRecallInProgress={isRecallInProgress}
        />
      </Page>
    </div>
  );
};
