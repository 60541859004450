export interface Metadata {
  productSectionSubtitle: string;
  topSpacing: number | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'none' | undefined;
}

export const metadata: Metadata = {
  productSectionSubtitle: 'Product type and ZIP code',
  topSpacing: 'md',
};

export default metadata;
