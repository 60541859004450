import { useAddFields } from '@ecp/features/sales/form';
import { Select } from '@ecp/features/sales/shared/components';
import { HOW_DID_YOU_HEAR_ABOUT_US } from '@ecp/features/sales/shared/constants';
import { useField } from '@ecp/features/sales/shared/store';

import metadata from './metadata';

export const HearAboutUsQuestion: React.FC = () => {
  const howDidYouHearAboutUs = useField(HOW_DID_YOU_HEAR_ABOUT_US);
  useAddFields({ howDidYouHearAboutUs });

  if (!howDidYouHearAboutUs.exists) return null;

  return (
    <Select
      {...howDidYouHearAboutUs.props}
      id={HOW_DID_YOU_HEAR_ABOUT_US}
      label={metadata.label}
      trackingName='hear_about_us'
    />
  );
};
