import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AntiTheftAdditionalQuestion' })((theme) => ({
  childLabel: theme.typography.body4,
  error: {
    color: theme.palette.error.main,
  },
  questionItem: {
    marginTop: 20,
    marginBottom: 20,
  },
}));
