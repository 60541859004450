import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DiscountsSubQuestions' })((theme) => ({
  root: {
    border: `1px solid ${theme.palette.other.border}`,
    borderRadius: 4,
    margin: '20px auto 0px auto',
    width: '100%',
  },
}));
