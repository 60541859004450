import { merge } from '@ecp/utils/common';

import type { Product } from '@ecp/features/shared/product';
import type { OptionsMetadata } from '@ecp/types';

import { CoveragePolicyLevelDiscountsMetadata as BaseCoveragePolicyLevelDiscountsMetadata } from './CoveragePolicyLevelDiscounts.metadata.js';

export const CoveragePolicyLevelDiscountsMetadata: Record<Product, OptionsMetadata> = merge(
  {},
  {
    'amfam.auto': {
      costcoMemberValue: {
        title: 'Costco member',
      },
      midvaleHomePolicy: {
        title: 'Multi-product',
      },
    },
  },
  BaseCoveragePolicyLevelDiscountsMetadata,
);
