import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DriverForm' })((theme) => ({
  ...theme.mixins.formAll,
  incidentTitle: {
    backgroundColor: theme.palette.primary.dark,
    ...theme.typography.subtitle,
    lineHeight: 1.6,
  },
  incidentInEdit: {
    backgroundColor: theme.palette.action.selected,
  },
  sniTitle: {
    ...theme.typography.body4,
    marginTop: 10,
  },
  about: {
    ...theme.typography.body4,
    fontWeight: 'normal',
    marginTop: 40,
  },
  buttonSection: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  addButton: {
    [theme.breakpoints.up('md')]: {
      marginRight: 20,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  cancelButton: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  tableContainer: {
    ...theme.mixins.tableContainer,
    margin: '30px 0px',
  },
  firstRow: {
    ...theme.mixins.tableFirstRow,
    '& th': {
      padding: 10,
      ...theme.typography.h4,
    },
  },
  tableBody: {
    ...theme.mixins.simpleTableBody,
    '& tr td': {
      padding: 10,
      lineHeight: '22px',
    },
  },
  error: {
    ...theme.typography.body2Italics,
    paddingTop: 0,
    paddingBottom: 0,
  },
  divider: {
    backgroundColor: theme.palette.other.divider,
    marginTop: 30,
  },
}));
