export interface ParsedTokenResult {
  aud: string;
  cid: string;
  exp: number;
  iat: number;
  iss: string;
  jti: string;
  partnerid: number;
  sub: string;
  ver: number;
}

export const parseJwt = (token: string): ParsedTokenResult | undefined => {
  try {
    return JSON.parse(window.atob(token.split('.')[1]));
  } catch (e) {
    return undefined;
  }
};
