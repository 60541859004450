import type { Questions } from '@ecp/types';

import { STATIC_QUESTIONS as STATIC_QUESTIONS_BASE } from './structure.js';

export * from './structure.js';

/** Includes either agent or consumer questions, depending on the experience. */
export const STATIC_QUESTIONS: Questions = {
  ...STATIC_QUESTIONS_BASE,
  'static.bridgeType': {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home'],
    required: 'false',
  },
  'static.derived.discount.homeowner': {
    answerType: 'String',
    businessType: ['auto', 'home'],
    questionType: 'provided',
    required: 'false',
  },
  'static.derived.discount.bundle': {
    answerType: 'String',
    businessType: ['auto', 'home'],
    questionType: 'provided',
    required: 'false',
  },
};
