import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import { PrefillFlow } from '@ecp/features/sales/shared/constants';
import {
  DateOfBirthQuestion,
  EmailQuestion,
  FirstNameQuestion,
  LastNameQuestion,
  MiddleNameQuestion,
  SuffixQuestion,
} from '@ecp/features/sales/shared/questions';
import { getCurrentFlows, useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import {
  DriverLicenseAgeFirstLicensedQuestion,
  DriverLicenseQuestion,
  DriverLicenseRelationToInsuredQuestion,
  DriverLicenseStatusQuestion,
  DriverLicenseUsCanadaDriverLast18MonthsQuestion,
  DriverRoleQuestion,
  GenderQuestion,
  LicenseCountryQuestion,
  LicenseStateQuestion,
  MaritalStatusQuestion,
  RelationshipQuestion,
} from '../../components';
import { DRIVER_INTL, DRIVER_UNLICENSED, DRIVER_VALID } from '../../constants';
import { useStyles } from './DriverProfileFormQuestions.styles';
import metadata from './metadata';

export interface DriverProfileFormQuestionsProps {
  isPni: boolean;
  driverRef: string;
  personRef: string;
  possessiveName: string;
  wasNameOrWereYou?: string;
  willNameOrYou?: string;
  ifNameOrYou: string;
}

export const DriverProfileFormQuestions: React.FC<
  React.PropsWithChildren<DriverProfileFormQuestionsProps>
> = (props) => {
  const { classes } = useStyles();
  const {
    personRef,
    driverRef,
    isPni,
    possessiveName,
    wasNameOrWereYou,
    willNameOrYou,
    ifNameOrYou,
    children,
  } = props;
  const useDriverField = useFieldWithPrefix(driverRef, 'driver.<id>');
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const dlStatus = useDriverField('license.status');
  const middleName = usePersonField('middleName');
  const suffix = usePersonField('suffix');
  const dlNumberEntered = useDriverField('license.number').value;
  const flow = useSelector(getCurrentFlows);
  const isFlowShortAuto = flow.auto === PrefillFlow.SHORT;

  return (
    <Grid container>
      <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
        <FirstNameQuestion personRef={personRef} disabled={isPni} />
      </GridItem>
      {middleName.exists && (
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
          <MiddleNameQuestion personRef={personRef} disabled={isPni} />
        </GridItem>
      )}
      <GridItem
        topSpacing='sm'
        xs={12}
        md={6}
        className={middleName.exists ? classes.columnLeft : classes.columnRight}
      >
        <LastNameQuestion personRef={personRef} disabled={isPni} />
      </GridItem>
      {suffix.exists && (
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
          <SuffixQuestion personRef={personRef} disabled={isPni} />
        </GridItem>
      )}
      <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
        <DateOfBirthQuestion id='DOB' personRef={personRef} disabled={isPni} staticValueWithRef />
      </GridItem>
      {(isFlowShortAuto || metadata.renderDriverLicenseQuestion) &&
        dlStatus.value !== DRIVER_UNLICENSED && (
          <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
            <DriverLicenseQuestion driverRef={driverRef} />
          </GridItem>
        )}
      {!isFlowShortAuto && isPni && (
        <GridItem topSpacing='sm' xs={12}>
          <EmailQuestion
            fullWidth={false}
            personRef={personRef}
            helperText="We'll send you the details of your quote."
          />
        </GridItem>
      )}
      <GridItem topSpacing='lg' xs={12}>
        {/* TODO add help text when clicking on the help icon */}
        <GenderQuestion personRef={personRef} label={`What's ${possessiveName} gender?`} />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <MaritalStatusQuestion
          personRef={personRef}
          groupLabel={`What's ${possessiveName} marital status?`}
        />
      </GridItem>
      {!isPni && metadata.renderRelationshipQuestion && (
        <GridItem topSpacing='lg' xs={12}>
          <RelationshipQuestion personRef={personRef} groupLabel='Relationship to PNI' />
        </GridItem>
      )}
      <GridItem topSpacing='lg' xs={12}>
        <DriverLicenseStatusQuestion
          driverRef={driverRef}
          possessiveName={possessiveName}
          ifNameOrYou={ifNameOrYou}
        />
      </GridItem>
      {/* TODO: TECH DEBT - These conditionals need to be updated to be strictly metadata-driven. */}
      {(isFlowShortAuto || (metadata.renderLicenseStateQuestion && dlNumberEntered)) &&
        dlStatus.value !== DRIVER_UNLICENSED &&
        dlStatus.value !== DRIVER_INTL && (
          <GridItem topSpacing='lg' xs={12}>
            <LicenseStateQuestion
              driverRef={driverRef}
              groupLabel={`What's ${possessiveName} driver license state?`}
            />
          </GridItem>
        )}
      {dlStatus.value === DRIVER_INTL && (
        <GridItem topSpacing='lg' xs={12}>
          <LicenseCountryQuestion
            driverRef={driverRef}
            groupLabel={`What country is ${possessiveName} driver's license from?`}
          />
        </GridItem>
      )}
      {/* TODO: TECH DEBT - These conditionals need to be updated to be strictly metadata-driven. */}
      {/* !TODO (Tech Debt): Ideally we should not be using this kind of business logic on our end of the application. */}
      {(dlStatus.value === DRIVER_VALID ||
        dlStatus.value === DRIVER_INTL ||
        metadata.allowAgeFirstLicensed) && (
        <GridItem topSpacing='lg' xs={12}>
          <DriverLicenseAgeFirstLicensedQuestion
            personRef={personRef}
            driverRef={driverRef}
            groupLabel={`At what age ${wasNameOrWereYou} first licensed in the United States?`}
          />
        </GridItem>
      )}
      <GridItem topSpacing='lg' xs={12}>
        <DriverLicenseUsCanadaDriverLast18MonthsQuestion
          driverRef={driverRef}
          personRef={personRef}
        />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <DriverLicenseRelationToInsuredQuestion driverRef={driverRef} personRef={personRef} />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <DriverRoleQuestion
          driverRef={driverRef}
          groupLabel={`What role ${willNameOrYou} play on the policy?`}
        />
      </GridItem>
      {children}
    </Grid>
  );
};
