import type { StateCompanyName } from '@ecp/features/sales/shared/types';

import {
  getRecurringPaymentMetadataForPropertyCC,
  RecurringPaymentMetadataForPropertyEFT,
} from './RecurringPaymentMetadataForProperty';

export const RecurringPaymentCompanyName: { [productKey: string]: StateCompanyName } = {};

export const RecurringPaymentMetadata: Record<
  string,
  Record<
    string,
    | ((name: string, state: string) => React.ReactElement)
    | ((name?: string, state?: string) => React.ReactElement)
  >
> = {
  EFT: {
    'homesite.home': (state: string): React.ReactElement => (
      <RecurringPaymentMetadataForPropertyEFT state={state} />
    ),
    'homesite.renters': (state: string): React.ReactElement => (
      <RecurringPaymentMetadataForPropertyEFT state={state} />
    ),
  },
  CreditCard: {
    'homesite.home': getRecurringPaymentMetadataForPropertyCC,
    'homesite.renters': getRecurringPaymentMetadataForPropertyCC,
  },
  CostcoVisa: {
    'homesite.home': getRecurringPaymentMetadataForPropertyCC,
    'homesite.renters': getRecurringPaymentMetadataForPropertyCC,
  },
};
