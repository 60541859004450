import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import type { Metadata } from './metadata';
import baseMetadata from './metadata.js';

const isAutoProductSwapState = flagValues[FeatureFlags.AUTO_PRODUCT_SWAP_STATE_ROLLOUT];

const metadata: Metadata = {
  ...baseMetadata,
  ...(isAutoProductSwapState && {
    helperText:
      'Home insurance may be available in your state. Contact us after your auto quote to learn more.',
    labelStart: 'Do you currently have or plan to get a home insurance policy with ',
    partnerDisplayNameOverride: 'CONNECT, powered by American Family Insurance',
  }),
};

export default metadata;
