import { useCallback, useMemo } from 'react';

import { Grid } from '@mui/material';

import { Select } from '@ecp/features/sales/shared/components';
import type { AnswerValue } from '@ecp/features/sales/shared/types';
import { useIsMobile } from '@ecp/themes/base';
import type { Field, Option } from '@ecp/types';

import { useStyles } from './AssignmentLineItem.styles';

interface FieldAnswers {
  key: string;
  value?: AnswerValue;
}

type Props = {
  dropDownItems?: Option[];
  field: Field;
  secondaryField: Field;
  showSecondaryOperator: boolean;
  label: string;
  labelRef?: string;
  answers: FieldAnswers[];
  secondaryFieldAnswers: FieldAnswers[];
  actionOnChange: (value: AnswerValue, field: Field, label: string) => void;
  mismatchedValues: boolean;
  index: number;
};

export const AssignmentLineItem: React.FC<Props> = (props) => {
  const {
    label,
    dropDownItems,
    field,
    showSecondaryOperator,
    secondaryField,
    answers,
    secondaryFieldAnswers,
    actionOnChange,
    mismatchedValues,
    labelRef,
    index,
  } = props;
  const { classes } = useStyles();
  const isMobile = useIsMobile();

  /**
   * We are going to filter the dropdown items based on the total answers
   * of all the fields.
   */
  const filteredDropDownItems = useMemo(
    (): Option[] | undefined =>
      dropDownItems?.filter((item) => {
        /**
         * If we are looking at the currently selected value we want to return true
         * since we always want the value we have selected for the current field to show
         * up
         */
        if (item.value === field?.value) {
          return true;
        }

        /**
         * We have a list of answers for all the other fields and if we can match
         * an answer that has been selected in another dropdown to an option
         * then we want to remove that option
         */
        const foundAnswer = !!answers.concat(secondaryFieldAnswers).find((answer) => {
          return answer.value === item.value;
        });

        /**
         * In the case of Driver Assignment we need to be able to assign
         * Drivers -> Vehicles and Vehicles -> Drivers BUT the question that
         * needs to be answered is vehicle.<id>.primary.driver.ref which will
         * always need a driver ref.
         *
         * In the case where we have vehicles in the dropdown we are calling a
         * mismatch and making sure we are sending the appropriate data to the
         * dropdown.
         */
        if (mismatchedValues) {
          const foundMismatchAnswer = !!answers.find((answer) => {
            return answer.value && answer.key.includes(item.value);
          });

          return !foundAnswer && !foundMismatchAnswer;
        }

        /**
         * We want to keep the dropdown item if the foundAnswer is undefined
         */
        return !foundAnswer;
      }),
    [answers, dropDownItems, field, mismatchedValues, secondaryFieldAnswers],
  );

  /**
   * We are going to filter the dropdown items based on the total answers
   * of all the fields.
   */
  const filteredSecondaryDropDownItems = useMemo(
    (): Option[] | undefined =>
      dropDownItems?.filter((item) => {
        /**
         * If we are looking at the currently selected value we want to return true
         * since we always want the value we have selected for the current field to show
         * up
         */
        if (item.value === secondaryField?.value) {
          return true;
        }

        /**
         * We have a list of answers for all the other fields and if we can match
         * an answer that has been selected in another dropdown to an option
         * then we want to remove that option
         */
        const foundAnswer = !!answers.concat(secondaryFieldAnswers).find((answer) => {
          return answer.value === item.value;
        });

        /**
         * We want to keep the dropdown item if the foundAnswer is undefined
         */
        return !foundAnswer;
      }),
    [answers, dropDownItems, secondaryField, secondaryFieldAnswers],
  );

  const handleAssignmentLineItemOnChange = useCallback(
    (value: AnswerValue) => {
      if (!actionOnChange) return;
      actionOnChange(value, field, labelRef || label);
    },
    [actionOnChange, field, label, labelRef],
  );

  const handleSecondaryAssignmentLineItemOnChange = useCallback(
    (value: AnswerValue) => {
      if (!actionOnChange) return;
      actionOnChange(value, secondaryField, labelRef || label);
    },
    [actionOnChange, secondaryField, label, labelRef],
  );

  return (
    <Grid container spacing='30px' className={classes.container}>
      <Grid item xs={12} md={showSecondaryOperator ? 4 : 6}>
        <Select
          {...field?.props}
          options={filteredDropDownItems}
          id={`driverAssignmentSelect-${index}`}
          actionOnChange={handleAssignmentLineItemOnChange}
        />
      </Grid>
      {showSecondaryOperator && (
        <Grid item xs={12} md={4}>
          {filteredSecondaryDropDownItems && filteredSecondaryDropDownItems.length > 0 && (
            <Select
              {...secondaryField?.props}
              options={filteredSecondaryDropDownItems}
              id={`secondaryDriverAssignmentSelect-${index}`}
              actionOnChange={handleSecondaryAssignmentLineItemOnChange}
            />
          )}
        </Grid>
      )}
      {!isMobile && (
        <Grid item xs={12} md={showSecondaryOperator ? 4 : 6}>
          <p>{label}</p>
        </Grid>
      )}
    </Grid>
  );
};
