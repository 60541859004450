import { GridItem } from '@ecp/components';

import {
  CommuterVanQuestion,
  KitCarReplicaQuestion,
  VehicleAnnualMileageQuestion,
  VehicleAntiTheftQuestion,
  VehicleComprehensiveQuestion,
  VehicleCoOwnsQuestion,
  VehicleInfoQuestions,
  VehicleKeptAtQuestion,
  VehicleSafetyQuestion,
  VehicleUseQuestion,
} from '../../components';
import metadata from './metadata';

interface Props {
  vehicleRef: string;
  maskRequiredFieldError: boolean;
  setMaskRequiredFieldError: (value: boolean) => void;
  isContinueClicked: boolean;
}

export const VehicleFormQuestions: React.FC<Props> = (props) => {
  return (
    <>
      <GridItem xs={12}>
        <VehicleInfoQuestions {...props} />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <VehicleKeptAtQuestion {...props} />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <VehicleUseQuestion {...props} />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <VehicleComprehensiveQuestion {...props} />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <VehicleCoOwnsQuestion {...props} />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <CommuterVanQuestion {...props} />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <KitCarReplicaQuestion {...props} />
      </GridItem>
      {metadata.renderVehicleAnnualMileageQuestion && (
        <GridItem topSpacing='lg' xs={12}>
          <VehicleAnnualMileageQuestion {...props} />
        </GridItem>
      )}
      <GridItem topSpacing='lg' xs={12}>
        <VehicleSafetyQuestion {...props} />
      </GridItem>
      <GridItem topSpacing='lg' xs={12}>
        <VehicleAntiTheftQuestion {...props} />
      </GridItem>
    </>
  );
};
