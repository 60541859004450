import { useCallback } from 'react';

import { Button } from '@ecp/features/sales/shared/components';
import { setAntiTheftDevicesDialogToggled } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';

import { useStyles } from './AntiTheftHelpText.styles';

export const AntiTheftHelpText: React.FC = () => {
  const dispatch = useDispatch();
  const { classes } = useStyles();

  const handleClick = useCallback(() => {
    dispatch(setAntiTheftDevicesDialogToggled(true));
  }, [dispatch]);

  return (
    <>
      You may qualify for a discount if your vehicle is equipped with at least one or more
      anti-theft devices.
      <br />
      <Button variant='iconText' className={classes.linkTag} onClick={handleClick}>
        See the list of qualifying anti-theft devices
      </Button>
    </>
  );
};
