import { Grid } from '@mui/material';

import { getDrivers, getPrimaryInsuredStateCode } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { useGetDriverDiscountMetadata } from '../../../../state';
import { useDriverDiscounts } from '../util';
import { DriverDiscounts } from './DriverDiscounts';
import { useStyles } from './DriverDiscountsQuestion.styles';

interface Props {
  isContinueClicked?: boolean;
}

export const DriverDiscountsQuestion: React.FC<Props> = (props) => {
  const { isContinueClicked } = props;
  const { classes } = useStyles();

  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const drivers = useSelector(getDrivers);
  const driverMetadata = useGetDriverDiscountMetadata(drivers, stateCode);
  const driverDiscounts = useDriverDiscounts(drivers, stateCode);

  if (!driverMetadata.length) return null;

  return (
    <>
      <Grid className={classes.headingContainer} item xs={12}>
        <h2>Choose your driver discounts</h2>
      </Grid>
      <Grid container item xs={12}>
        {driverMetadata.map((driver, index) => (
          <DriverDiscounts
            key={index}
            index={index}
            driver={driver}
            driverDiscounts={driverDiscounts}
            isContinueClicked={isContinueClicked}
          />
        ))}
      </Grid>
    </>
  );
};
