import { useCallback, useState } from 'react';

import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import {
  updateAnswers,
  useCheckboxNoPatchGroupSelectionsCleanUp,
  useField,
} from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';
import type { Answers } from '@ecp/features/sales/shared/types';

import { safetyDiscountOptions } from '../../../../metadata';
import * as metadata from './metadata';
import { SafetyFeaturesAdditionalQuestion } from './SafetyFeaturesAdditionalQuestion';

interface Props extends QuestionProps {
  vehicleRef: string;
  isContinueClicked: boolean;
}

export const VehicleSafetyQuestion: React.FC<Props> = (props) => {
  const {
    vehicleRef,
    label = 'Does this vehicle have any of the following safety features? (optional)',
    helperText = metadata.helperText,
    trackingName = 'safety_features_question',
    isContinueClicked,
  } = props;
  const dispatch = useDispatch();
  const safety = useField(`${vehicleRef}.features.safety`);
  useAddFields({ safety });
  const initialCheckBoxGroupValue: Answers = {};
  const [checkboxGroupValuetoBePatched, setCheckboxGroupValuetoBePatched] =
    useState(initialCheckBoxGroupValue);

  const mouseMovingOutAndPatch = useCallback(
    () => dispatch(updateAnswers({ answers: checkboxGroupValuetoBePatched })),
    [checkboxGroupValuetoBePatched, dispatch],
  );

  const handleChange = useCallback((value: Answers) => setCheckboxGroupValuetoBePatched(value), []);

  const safetyAntiLockBrakes = useField(`${vehicleRef}.features.safety.antiLockBrakes`);
  const driverSafetyAirbag = useField(`${vehicleRef}.features.safety.airbag.driver`);
  const passengerSafetyAirbag = useField(`${vehicleRef}.features.safety.airbag.passenger`);
  const sideSafetyAirbag = useField(`${vehicleRef}.features.safety.airbag.side`);
  const safetyDayTimeRunningLights = useField(`${vehicleRef}.features.safety.dayTimeRunningLights`);
  const safetyElectronicStabilityControl = useField(
    `${vehicleRef}.features.safety.electronicStabilityControl`,
  );

  const safetyFeatures = [
    driverSafetyAirbag,
    passengerSafetyAirbag,
    sideSafetyAirbag,
    safetyAntiLockBrakes,
    safetyDayTimeRunningLights,
    safetyElectronicStabilityControl,
  ];

  let safetyOptions = safetyDiscountOptions;

  safetyFeatures.forEach((feature) => {
    if (!feature.exists) {
      safetyOptions = safetyOptions.filter(
        (option) => option.fieldName !== feature.key.substr(feature.key.lastIndexOf('.') + 1),
      );
    }
  });

  const safetyFeaturesFiltered = safetyFeatures.filter((feature) => feature.exists);

  useCheckboxNoPatchGroupSelectionsCleanUp({
    theQuestionValueNeedsToBeCleanedUp: safety.value,
    theQuestionValueGroup: safetyFeaturesFiltered,
  });

  return (
    <Grid container>
      {safety.exists && (
        <RadioGroupWithOptions
          {...safety.props}
          label={label}
          helperText={helperText}
          id='SafetyFeatures'
          variant='yesNoButton'
          trackingName={trackingName}
          dataTestId='SafetyFeaturesButton'
        />
      )}
      <GridItem topSpacing='lg' onMouseLeave={mouseMovingOutAndPatch}>
        <SafetyFeaturesAdditionalQuestion
          isContinueClicked={isContinueClicked}
          vehicleRef={vehicleRef}
          setCheckboxGroupValuetoBePatched={handleChange}
          originalCheckboxGroupValue={checkboxGroupValuetoBePatched}
        />
      </GridItem>
    </Grid>
  );
};
