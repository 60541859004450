import { useCallback, useState } from 'react';

import { Divider, Grid } from '@mui/material';

import { IconUITooltip } from '@ecp/themes/base';

import { Button } from '../../Button';
import { Dialog } from '../../Dialog';
import { PhoneLink } from '../../PhoneLink';
import { useStyles } from './ConfirmationRequired.styles';

interface Props {
  number: string;
  variant?: 'desktop' | 'mobile';
}

export const ConfirmationRequired: React.FC<Props> = (props) => {
  const { number, variant } = props;
  const { classes } = useStyles();
  const [showDialog, setShowDialog] = useState(false);

  const handleShowDialog = useCallback(() => setShowDialog(!showDialog), [showDialog]);

  const handleDialogClose = useCallback(() => {
    setShowDialog(false);
  }, []);

  return (
    <div className={classes.root}>
      <Button variant='iconText' className={classes.textWrapper} onClick={handleShowDialog}>
        <IconUITooltip className={classes.tooltipIcon} />
        &nbsp;
        <span>Confirmation required</span>
      </Button>
      <Dialog
        onClose={handleDialogClose}
        open={showDialog}
        className={classes.dialogRoot}
        titleText='Confirmation required'
        hideTitleCloseButton
      >
        <>
          <p className={classes.dialogHeader}>
            In order to complete this quote, please review the information you have provided.
          </p>
          <p className={classes.dialogSubheader}>
            If you need assistance, please call{' '}
            <PhoneLink withUnderlinedLinkStyle number={number} /> and be prepared to provide
            information from the list below.
          </p>
          <Grid container className={classes.dialogContent}>
            <Grid item xs={variant === 'mobile' ? 12 : 6}>
              <p>Customer identification documentation:</p>
              <ul>
                <li>Photo ID with date of birth.</li>
                <li>Current bank statement.</li>
                <li>Current paycheck stub.</li>
                <li>Current utility bill.</li>
                <li>Mortgage or lease agreements.</li>
                <li>
                  Non-photo ID issued by the United States or any of it&rsquo;s agencies which name
                  and address of customer.
                </li>
              </ul>
            </Grid>
            <Grid item xs={variant === 'mobile' ? 12 : 6}>
              <p>Vehicle identification documentation:</p>
              <ul>
                <li>Vehicle registration.</li>
                <li>DMV vehicle registration renewal notice.</li>
                <li>
                  If the vehicle is a new purchase, please obtain one of the following along with a
                  current address:
                  <ul>
                    <li>Bill of sale for a licensed dealer or private seller.</li>
                    <li>Temporary DMV registration.</li>
                    <li>
                      Finance or lease agreement identifying the vehicle and providing owner
                      information.
                    </li>
                  </ul>
                </li>
              </ul>
            </Grid>
          </Grid>
          <Divider aria-hidden='true' />
          <Button variant='iconText' className={classes.dismissButton} onClick={handleDialogClose}>
            Dismiss
          </Button>
        </>
      </Dialog>
    </div>
  );
};
