import { createSelector } from '@reduxjs/toolkit';

import { emptyObject } from '@ecp/utils/common';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { driverDiscountsOptions } from '@ecp/features/sales/quotes/auto';
import { Page } from '@ecp/features/sales/shared/components';
import { PagePath, useNavigateToNextPage } from '@ecp/features/sales/shared/routing';
import {
  getAutoProduct,
  getDrivers,
  getLineOfBusiness,
  getPageFlowProducts,
  getPrimaryInsuredStateCode,
  getVehicles,
  questionExists,
  selectFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type {
  AutoSelectDiscountsFields,
  Driver,
  DriverDiscount,
  DriverDiscounts,
  Vehicle,
  VehicleDiscounts,
} from '@ecp/features/sales/shared/types';
import type { Product } from '@ecp/features/shared/product';
import { LineOfBusiness } from '@ecp/features/shared/product';
import type { Field } from '@ecp/types';

import { AutoSelectDiscountsForm } from './AutoSelectDiscountsForm';
import { autoHeaderMetadata } from './metadata';

const useVehicleDiscounts = (vehicles: Vehicle[]): VehicleDiscounts => {
  const dispatch = useDispatch();

  // const selectors : Selector<RootStore, Field>[];
  const selectors = vehicles.map((vehicle) =>
    selectFieldWithPrefix(
      dispatch,
      `${vehicle.ref}.discount`,
      'vehicle.<id>.discount',
    )('keptInGarage'),
  );

  const keptInGarageQuestionExists = useSelector((state: RootStore) =>
    questionExists('vehicle.<id>.discount.keptInGarage')(state),
  );

  const combiner = (...res: Field[]): VehicleDiscounts => {
    if (!keptInGarageQuestionExists) return emptyObject;
    const discounts: VehicleDiscounts = {};
    for (let i = 0; i < res.length; i += 1) {
      discounts[vehicles[i].ref] = { keptInGarage: res[i] };
    }

    return discounts;
  };

  const mySelector = createSelector(selectors, combiner);

  return useSelector(mySelector);
};

const useDriverDiscounts = (drivers: Driver[], stateCode: string): DriverDiscounts => {
  const dispatch = useDispatch();

  const selectors = drivers.map((driver) => {
    const makeSelector = selectFieldWithPrefix(
      dispatch,
      `${driver.ref}.discount`,
      'driver.<id>.discount',
    );
    const selectorValues = driverDiscountsOptions().map((discount) => {
      // use value from stateOption if it exists
      const discountValueForState = discount.stateOptions?.[stateCode]?.value;
      if (discountValueForState) return makeSelector(discountValueForState);

      return makeSelector(discount.value);
    });

    return createSelector(selectorValues, (...res: Field[]): DriverDiscount => {
      return res.reduce((acc, cur) => {
        acc[cur.key.substring(cur.key.lastIndexOf('.') + 1)] = cur;

        return acc;
      }, {} as DriverDiscount);
    });
  });

  const combiner = (...res: DriverDiscount[]): DriverDiscounts => {
    const discounts: DriverDiscounts = {};
    for (let i = 0; i < res.length; i += 1) {
      discounts[drivers[i].ref] = res[i];
    }

    return discounts;
  };

  const mySelector = createSelector(selectors, combiner);

  return useSelector(mySelector);
};

const useDiscountsFields = (
  vehicles: Vehicle[],
  drivers: Driver[],
  stateCode: string,
): AutoSelectDiscountsFields => {
  const vehicleDiscounts = useVehicleDiscounts(vehicles);
  const driverDiscounts = useDriverDiscounts(drivers, stateCode);

  return {
    vehicleDiscounts,
    driverDiscounts,
  };
};

export const AutoSelectDiscountsPage: React.FC = () => {
  const drivers = useSelector(getDrivers);
  const vehicles = useSelector(getVehicles);
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const lineOfBusiness = useSelector(getLineOfBusiness);
  const productOrder = useSelector(getPageFlowProducts);
  const autoProduct = useSelector(getAutoProduct) as Product;
  const discountsFields = useDiscountsFields(vehicles, drivers, stateCode);
  // TODO This needs to be updated with Auto+Renters bundle
  const nextPageInstructions =
    lineOfBusiness === LineOfBusiness.BUNDLE && productOrder[0]?.id?.includes('auto')
      ? 'Thanks, we have questions about your home.'
      : 'Thanks! Your quotes are on the next page.';
  const title =
    autoHeaderMetadata[autoProduct].stateOptions?.[stateCode]?.title ||
    autoHeaderMetadata[autoProduct].title;
  const subHeader =
    autoHeaderMetadata[autoProduct].stateOptions?.[stateCode]?.subHeader ||
    autoHeaderMetadata[autoProduct].subHeader;
  const subTitleHelpText =
    'We strive to find you the best policy at the right price. You may be eligible for one or more discounts depending on your location and qualification.';

  const navigateToNextPage = useNavigateToNextPage();
  const navigateToAlternateNextPage = useNavigateToNextPage({ skipNextProductFlow: true });

  return (
    <div>
      <Page
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.AUTO_DISCOUNTS} />,
        }}
        title={title}
        subTitle={subHeader}
        subTitleHelpText={subTitleHelpText}
        analyticsElement='choice.discountPage.page'
      >
        <AutoSelectDiscountsForm
          fields={discountsFields}
          drivers={drivers}
          onNext={navigateToNextPage}
          onAlternateNext={navigateToAlternateNextPage}
          nextPageInstructions={nextPageInstructions}
        />
      </Page>
    </div>
  );
};
