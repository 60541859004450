import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'LandingPage' })((theme) => ({
  ...theme.mixins.formAll,
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('lg')]: {
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    },
  },
}));
