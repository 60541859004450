import { useCallback, useState } from 'react';

import {
  AccordionDetails,
  AccordionSummary,
  FormHelperText,
  Grid,
  Accordion as MuiAccordion,
} from '@mui/material';
import { Avatar as MuiAvatar } from '@mui/material';

import { castToBoolean } from '@ecp/utils/common';
import { useEvent } from '@ecp/utils/react';

import { GridItem, HelpBox } from '@ecp/components';
import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { Dialog } from '@ecp/features/sales/shared/components';
import { Checkbox } from '@ecp/features/sales/shared/components';
import {
  getCurrentPage,
  getLineOfBusiness,
  getPrimaryInsuredStateCode,
  useField,
  useForm,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { GraphicUIAgentAvatarImageUrl, IconUIExpandMore } from '@ecp/themes/base';

import { useStyles } from './AgentScriptBox.styles';
import { AgentScriptContent } from './AgentScriptContent';
import type { AgentScripts } from './metadata/types';
import { NachaScripts } from './NachaScripts';
import { PostBindScripts } from './PostBindScripts';
import { ProtectedPersonScripts } from './ProtectedPersonScripts';
import { getAgentScripts, useGetOfferPropertyPremiums } from './util';

export type AgentScriptBoxVariants = 'dialog' | 'accordion';
interface AgentScriptBoxProps {
  trackingName: string;
  variant?: AgentScriptBoxVariants;
  hideRequiredCheckbox?: boolean;
  hideCheckBoxHeader?: boolean;
  scriptContent?: AgentScripts[];
}

export const AgentScriptBox: React.FC<AgentScriptBoxProps> = ({
  trackingName,
  variant,
  hideRequiredCheckbox = false,
  hideCheckBoxHeader = false,
  scriptContent,
}): React.ReactElement | null => {
  const { cx, classes } = useStyles();
  const checkboxAgentAgreeToProceed = useField(`static.agent.agreeToProceed.${trackingName}`);
  useAddFields({
    [`static.agent.agreeToProceed.${trackingName}`]: checkboxAgentAgreeToProceed,
  });
  const {
    props: { name, value, error, actionOnComplete },
  } = checkboxAgentAgreeToProceed;

  // If hideRequiredCheckbox is true, these fields are not required.
  useAddConditionValues({
    conditionalFields: [checkboxAgentAgreeToProceed],
    isExcluded: () => hideRequiredCheckbox === true,
    isRequiredOverride: () => hideRequiredCheckbox === false,
  });

  const { validateForm } = useForm({
    fields: { checkboxAgentAgreeToProceed },
  });

  const onAgentAgreedToProceedChangeCheck = useEvent((_, value) =>
    value ? actionOnComplete(String(value)) : actionOnComplete(''),
  );

  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const lob = useSelector(getLineOfBusiness);
  const currentPage = useSelector(getCurrentPage);

  const { statePremium, ourPremium } = useGetOfferPropertyPremiums();

  // scriptContent can be used as an override for storybook.
  const agentScripts = scriptContent
    ? scriptContent
    : currentPage
    ? getAgentScripts(currentPage, stateCode, lob, statePremium, ourPremium)
    : [];

  const agentScriptContent = agentScripts?.map((script, i) => (
    <AgentScriptContent agentScript={script} key={`${i}-${script.title}`} />
  ));

  const agentScriptFooter = hideRequiredCheckbox ? null : (
    <GridItem className={classes.scriptContent} lg={12} md={12} sm={12} topSpacing='xs'>
      {!hideCheckBoxHeader && (
        <h2 className={classes.scriptContentHeader}>Am I okay to proceed?</h2>
      )}
      <Grid>
        <Checkbox
          name={name}
          value={value}
          checked={castToBoolean(value)}
          onChange={onAgentAgreedToProceedChangeCheck}
          trackingName={`agent_agree_to_proceed_checkbox_${trackingName}`}
          trackingLabel={`${checkboxAgentAgreeToProceed.props.value}`}
          inputProps={{
            'aria-label': 'Agent agree to proceed checkbox',
          }}
        />
        <p className={classes.checkboxText}>
          Agents: Only check box when statements above have been read out loud and caller agrees to
          proceed.
        </p>
      </Grid>
    </GridItem>
  );

  const [dialogButtonClicked, setDialogButtonClicked] = useState(false);

  const handleDialogButtonClick = useCallback(async () => {
    validateForm();
    if (value && validateForm().isValid) {
      setDialogButtonClicked(true);
      if (variant === 'dialog') {
        actionOnComplete('clickedContinue');
      }
    }
  }, [value, validateForm, actionOnComplete, variant]);

  const contentStructure = (
    <HelpBox
      image={variant !== 'accordion' ? GraphicUIAgentAvatarImageUrl : ''}
      data-testid='helpBoxIcon'
      classes={{
        root: cx(variant === 'dialog' && classes.dialogScriptBox),
      }}
      content={
        <Grid container className={cx(variant === 'accordion' && classes.hideHeader)}>
          {variant !== 'accordion' && (
            <h1 className={classes.header}>Agent required call script</h1>
          )}
          {agentScriptContent}
          <NachaScripts />
          <ProtectedPersonScripts />
          <PostBindScripts />
          {agentScriptFooter}
          {error && <FormHelperText error>{error}</FormHelperText>}
        </Grid>
      }
    />
  );

  if (variant === 'accordion') {
    return (
      <GridItem topSpacing='lg' xs={12}>
        <MuiAccordion
          defaultExpanded={false}
          className={cx(classes.scriptBoxAccordion, error && classes.scriptBoxAccordionError)}
        >
          <AccordionSummary
            expandIcon={
              <IconUIExpandMore
                id='_CoveragesAccordionExpandIcon'
                className={classes.accordionExpand}
              />
            }
          >
            <MuiAvatar
              className={classes.icon}
              alt='Help Box Icon'
              src={GraphicUIAgentAvatarImageUrl}
            />
            <h1 className={cx(classes.header, classes.accordionTitle)}>
              Agent required call script
            </h1>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionContent}>
            {contentStructure}
          </AccordionDetails>
        </MuiAccordion>
      </GridItem>
    );
  }
  if (variant === 'dialog') {
    if (checkboxAgentAgreeToProceed.props.value === 'clickedContinue') return null;

    return (
      <Dialog
        actionButtonLabel='CONTINUE'
        actionButtonOnClick={handleDialogButtonClick}
        buttonPlacement='left'
        hideTitleCloseButton
        open={!dialogButtonClicked}
        showAppBar={false}
        className={classes.dialogRoot}
      >
        {contentStructure}
      </Dialog>
    );
  }

  return (
    <GridItem xs={12} topSpacing='lg'>
      {contentStructure}
    </GridItem>
  );
};
