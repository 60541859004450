import dayjs from 'dayjs';

import baseMetadata, { type Metadata } from './metadata.js';

// E1P auto year options
const currentYear = dayjs().year();
export const yearOptions = Array.from({ length: currentYear - 1981 + 2 }, (_, i) => {
  const year = String(currentYear + 1 - i);

  return { label: year, value: year };
});
const metadata: Metadata = {
  ...baseMetadata,
  yearOptions,
};

export default metadata;
