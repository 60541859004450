export interface Metadata {
  helperText?: string;
  label?: string;
}

const metadata: Metadata = {
  helperText: 'You will be able to enroll after you purchase your policy.',
  label: 'Do you plan to enroll in paperless billing?',
};

export default metadata;
