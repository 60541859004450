import { useStyles as useStylesTss } from '@ecp/themes/base';

import { useStyles as useStylesBase } from './QuotesTabs.styles.js';

export const useStyles: typeof useStylesBase = (params, styleOverrides) => {
  const { css, theme } = useStylesTss();

  return useStylesBase(params, {
    ...styleOverrides,
    props: {
      ...styleOverrides?.props,
      classes: {
        root: css({
          ...theme.mixins.columnMiddle,
          [theme.breakpoints.down('lg')]: {
            justifyContent: 'normal',
          },
          marginBottom: 20,
        }),
        container: css({
          ...theme.mixins.form,
          marginTop: 0,
          display: 'flex',
          alignItems: 'flex-end',
          [theme.breakpoints.up('lg')]: {
            padding: 'initial',
          },
        }),
        tabs: css({
          backgroundColor: 'unset',
          borderBottom: `1px solid ${theme.palette.other.border}`,
          display: 'flex',
          justifyContent: 'start',
          marginBottom: 0,
          marginTop: 40,
          maxWidth: 'none',
          paddingLeft: 16,
          position: 'static',
          right: 'auto',
          top: 'auto',
          width: '100%',
          [theme.breakpoints.down('lg')]: {
            justifyContent: 'center',
          },
          [theme.breakpoints.down('md')]: {
            maxWidth: 'none',
            top: 'auto',
          },
          '& td': {
            width: 234,
            height: 'auto',
            [theme.breakpoints.down('md')]: {
              width: 112,
            },
          },
        }),
        tabsTable: css({
          alignItems: 'center',
          border: 0,
          borderSpacing: '5px 0',
          verticalAlign: 'bottom',
        }),
        tab: css({
          backgroundColor: theme.palette.actions.disabledBg,
          border: `1px solid ${theme.palette.other.border}`,
          borderBottom: 0,
          borderRadius: '4px 4px 0 0',
          color: theme.palette.text.primary,
          height: 60,
          lineHeight: '40px',
          marginBottom: -2,
          padding: 20,
          width: '100%',
          [theme.breakpoints.down('md')]: {
            padding: 8,
          },
          '& span': {
            display: 'block',
            lineHeight: '20px',
            [theme.breakpoints.down('md')]: {
              lineHeight: '16px',
              margin: '0 auto',
            },
          },
          '&:hover': {
            backgroundColor: theme.palette.grey[50],
            textDecorationColor: 'currentcolor',
          },
        }),
        tabSelected: css({
          '&.MuiButton-root': {
            backgroundColor: theme.palette.grey[50],
            height: 75,
          },
        }),
        tabTitle: css(theme.typography.body3Bold),
        tabTitleSelected: css(theme.typography.body3Bold),
        tabSubTitleDiscount: css({
          ...theme.typography.body1Italics,
          color: theme.palette.success.main,
          [theme.breakpoints.down('md')]: {
            ...theme.typography.body2Italics,
          },
        }),
        ...styleOverrides?.props.classes,
      },
    },
  });
};
