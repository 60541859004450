import type { Metadata } from './metadata.js';
import baseMetadata from './metadata.js';

// This file may need to be changed to a opco 'amfam-opco' override in the future.
export const metadata: Metadata = {
  ...baseMetadata,
  hideHelpCard: true,
};

export default metadata;
