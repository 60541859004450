export const partner = {
  shared: {
    partnerDisplayName: 'CONNECT, powered by American Family Insurance',
    salesEmailQuotesAddress: 'SalesAD@connectbyamfam.com',
    salesPhoneHours: 'Mon-Fri: 7:00am – 10:00pm CT, Sat: 8:30am – 7:00pm CT, Sun: CLOSED',
    servicePhoneHours:
      'Monday to Friday: 7:00am - 10:00pm CT; Saturday: 8:30am – 7:00pm CT; Sunday: closed',
    qnbPhoneNumber: '1-877-658-1929',
    dnqPhoneNumber: '1-888-554-6196',
    salesPhoneNumber: '1-877-658-1929',
    servicePhoneNumber: '1-866-363-5535',
    agentFromEmailAddress: 'CONNECT@email.connectbyamfam.com',
    agentFromEmailName: 'CONNECT, powered by American Family Insurance',
  },
  lobDetail: { AUTO: { dnqNumbers: {}, discount: {} } },
} as const;
