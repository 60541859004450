import type { Metadata } from './metadata';

const label = (
  <>
    Do you want to sign up to pay your auto premium through automatic withdrawal from a checking or
    savings account? This discount <strong>does not</strong> apply to credit card payments.
  </>
);
const metadata: Metadata = {
  helperText: '',
  label,
};

export default metadata;
