export interface Metadata {
  shouldRenderSubTitle?: boolean;
  shouldRenderIcon?: boolean;
  showWelcomeTitle?: boolean;
}

const metadata: Metadata = {
  shouldRenderSubTitle: false,
  shouldRenderIcon: false,
  showWelcomeTitle: false,
};

export default metadata;
