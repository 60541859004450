import type { Metadata } from './metadata.js';

const metadata: Metadata = {
  allowAgeFirstLicensed: true,
  renderLicenseStateQuestion: true,
  renderDriverLicenseQuestion: true,
  renderRelationshipQuestion: true,
};

export default metadata;
