import { useCallback, useRef } from 'react';

import { Box, Divider, Grid, Typography } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem, RadioGroupWithOptions } from '@ecp/components';
import { useGetConditionValues, useGetFields, useInitValues } from '@ecp/features/sales/form';
import { getVehicleDescription } from '@ecp/features/sales/quotes/auto';
import { useAddTPI } from '@ecp/features/sales/quotes/auto';
import { Button, Form } from '@ecp/features/sales/shared/components';
import {
  getLineOfBusinessOrder,
  getNoOfMortgages,
  getPrimaryInsuredAddressInfo,
  getVehicles,
  navigateToAddTPI,
  useField,
  useForm,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { LineOfBusiness } from '@ecp/features/shared/product';
import { IconCardAuto, IconCardHome, IconUIPlus } from '@ecp/themes/base';
import type { Field } from '@ecp/types';

import { useStyles } from './ThirdPartyInterestForm.styles';

export interface ThirdPartyInterestFormProps {
  onNext: () => void;
}
interface ContentProps {
  selectedProduct: string;
}
export const ThirdPartyInterestForm: React.FC<ThirdPartyInterestFormProps> = (props) => {
  const { onNext } = props;
  const { classes } = useStyles();

  const hasThirdPartyInterestInd: Field = useField('static.delta.hasThirdPartyInterestInd');
  const selectedProducts = useSelector(getLineOfBusinessOrder);
  const initValues = useRef({});
  const getFields = useGetFields();
  const getConditions = useGetConditionValues();

  useInitValues({ [hasThirdPartyInterestInd.key]: false });
  const { isPatchFormInProgress } = useForm({
    initValues,
    fields: getFields(),
    conditions: getConditions(),
  });
  const handleSubmit = useCallback(async () => {
    await onNext();
  }, [onNext]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container className={classes.content}>
          <GridItem topSpacing='lg' xs={12}>
            <RadioGroupWithOptions
              {...hasThirdPartyInterestInd.props}
              label='Do any policies have third-party interests that need to be included?'
              variant='yesNoButton'
              trackingName='PriorInsurance'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
            />
          </GridItem>
          {hasThirdPartyInterestInd.value && (
            <>
              <Divider className={classes.divider} />
              <GridItem>
                <Typography className={classes.contentTitle}>
                  Add a third-party interest where applicable.
                </Typography>
                <Typography className={classes.contentSubTitle}>
                  At least one TPI must be added.
                </Typography>
              </GridItem>
              {selectedProducts.map((selectedProduct) => (
                <GridItem xs={12} key={selectedProduct}>
                  <Content selectedProduct={selectedProduct} />
                </GridItem>
              ))}
            </>
          )}
          <Divider className={classes.divider} />
          <GridItem>
            <Button
              className={classes.next}
              variant='primary'
              onClick={handleSubmit}
              trackingName='sni_details_continue'
              trackingLabel={GoogleAnalyticsLabels.CONTINUE}
            >
              Continue
            </Button>
          </GridItem>
        </Grid>
      </Form>
    </div>
  );
};

const Content: React.FC<ContentProps> = (props): React.ReactElement => {
  const { classes } = useStyles();
  const { selectedProduct } = props;

  const vehicles = useSelector(getVehicles);
  const addressInformation = useSelector(getPrimaryInsuredAddressInfo);
  const noOfMortgages = useSelector(getNoOfMortgages);
  const addTPI = useAddTPI();
  const dispatch = useDispatch();

  const label = (icon: React.ReactElement, label: string): React.ReactElement => {
    return (
      <GridItem topSpacing='lg' xs={12} className={classes.productTitleSection}>
        <Box>
          <div className={classes.productIcon}>{icon}</div>
        </Box>
        <Typography className={classes.productTitle}>{label} Policy?</Typography>
      </GridItem>
    );
  };
  const handleAddTPI = useCallback(
    async (vehicleRef: string) => {
      const newTPIRef = addTPI(vehicleRef);
      const { tpiRef } = newTPIRef;
      await newTPIRef.updateTPIVehicleAddressRef;
      await dispatch(navigateToAddTPI({ ref: tpiRef, replace: false, referencePage: 'AddTPI' }));
    },
    [addTPI, dispatch],
  );

  return (
    <>
      {selectedProduct === LineOfBusiness.AUTO ? (
        <GridItem>
          {label(<IconCardAuto />, 'Auto')}
          <GridItem>
            {vehicles.map((vehicle, index) => (
              <GridItem key={index} className={classes.card}>
                <Grid container key={vehicle.ref}>
                  <GridItem xs={12} md={6}>
                    <Typography className={classes.contentTitle}>
                      {getVehicleDescription(vehicle)}
                    </Typography>
                    {vehicle.vin && (
                      <Typography
                        className={classes.contentSubTitle}
                      >{`VIN: ${vehicle.vin}`}</Typography>
                    )}
                  </GridItem>
                  <GridItem xs={12} md={6} className={classes.columnRight}>
                    <Button
                      className={classes.button}
                      variant='outlinePrimary'
                      // eslint-disable-next-line react/jsx-no-bind
                      onClick={() => handleAddTPI(vehicle.ref)}
                      trackingName='sni_details_continue'
                      trackingLabel={GoogleAnalyticsLabels.CONTINUE}
                    >
                      <IconUIPlus className={classes.plusIcon} />
                      ADD TPI
                    </Button>
                  </GridItem>
                </Grid>
              </GridItem>
            ))}
          </GridItem>
        </GridItem>
      ) : (
        <GridItem>
          {label(<IconCardHome />, 'Home')}
          <Grid container className={classes.card}>
            <GridItem xs={12} md={6}>
              <Typography className={classes.contentTitle}>
                {addressInformation.line1 && (
                  <>
                    <div>
                      {addressInformation.line1}
                      {addressInformation.line2 ? ` ${addressInformation.line2},` : ','}{' '}
                    </div>
                    <div>
                      {`${addressInformation.city}, `}
                      {addressInformation.state}
                      {` ${addressInformation.zipcode}`}
                    </div>
                  </>
                )}
              </Typography>
              {noOfMortgages > 0 && (
                <Typography className={classes.contentSubTitle}>
                  This home has {noOfMortgages} mortgages. All mortgagees must be added as a TPI.
                </Typography>
              )}
            </GridItem>
            <Grid item xs={12} md={6} className={classes.columnRight}>
              <Button
                className={classes.button}
                variant='outlinePrimary'
                trackingName='sni_details_continue'
                trackingLabel={GoogleAnalyticsLabels.CONTINUE}
              >
                <IconUIPlus className={classes.plusIcon} />
                ADD TPI
              </Button>
            </Grid>
          </Grid>
        </GridItem>
      )}
    </>
  );
};
