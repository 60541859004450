import type { Product } from '@ecp/features/shared/product';

import metadataBase from './metadata.js';

const metadata = {
  ...metadataBase,
  error: (product: Product) =>
    'Your proof of insurance isn’t available yet. Within the next 24 hours, you’ll receive an email with a link to create an account, where you’ll be able to access and download it.',
} as const;

export default metadata;
