import { IncidentQuestionsHelpText } from '../../IncidentQuestions';
import baseMetadata, { type Metadata } from './metadata.js';

export const accidentHelpText: { [stateCode: string]: string } = {
  NC: `No need to enter the violations where you have been granted a ‘Prayer for Judgement’ by the state.`,
};

export const helpText = (): JSX.Element | string => {
  const text = `We’ll confirm your accident(s)/violation(s) with third party reports prior to purchase to determine your final quote, which may impact your premium. `;
  const link = `View incident descriptions`;
  const dialogContent = (
    <>
      <p style={{ marginBottom: '20px' }}>
        Find the accident/or violation that best matches your incident:
      </p>
      <p>
        <ul style={{ marginTop: '0' }}>
          <li>
            <strong>At-fault accident, with injuries: </strong>A driver listed on your policy is
            found to be responsible for a collision resulting in injury to another person.
          </li>
          <li>
            <strong>At-fault accident, no injuries: </strong>A driver listed on your policy is found
            to be responsible for a collision but with no resulting injuries to another person.
          </li>
          <li>
            <strong>Not at fault accident: </strong>Either a driver listed on your policy is found
            to not be responsible for a collision resulting in damage to you, your passengers,
            vehicle, or property, or another driver was found to be responsible for the collision.
          </li>
          <li>
            <strong>Glass repair: </strong>Damage to the windshield and/or windows of a vehicle on
            your policy is determined to be repairable.
          </li>
          <li>
            <strong>Glass replacement: </strong>Damage to the windshield and/or windows of a vehicle
            on your policy is beyond repair and will need to be replaced.
          </li>
          <li>
            <strong>Comprehensive: </strong>Damage to a vehicle on your policy resulting from
            anything other than a collision. This includes theft, fire, vandalism, striking an
            animal, weather related damage, and more.
          </li>
          <li>
            <strong>Emergency roadside service: </strong>A vehicle on your policy was serviced at
            the site of a breakdown. This can include a tow, dead battery, flat tire, out of fuel,
            and more.
          </li>
          <li>
            <strong>Permissive driver, at fault: </strong>A driver with permission to use your
            vehicle but is not listed on your policy at the time of the incident is found to be
            responsible for a collision.
          </li>
          <li>
            <strong>Permissive driver, not at fault: </strong>A driver with permission to use your
            vehicle but is not listed on your policy at the time of the incident is found to not be
            responsible for a collision.
          </li>
        </ul>
      </p>
    </>
  );

  return (
    <IncidentQuestionsHelpText helperText={text} linkText={link} dialogContent={dialogContent} />
  );
};
const metadata: Metadata = {
  ...baseMetadata,
  renderDamageAmountAsText: true,
  getIncidentAmountLabel: 'Total damage amount',
  accidentHelpText,
  helpText,
};

export default metadata;
