export const partner = {
  shared: {
    partnerDisplayName: 'CONNECT, powered by American Family Insurance',
    salesEmailQuotesAddress: 'sales@connectbyamfam.com',
    salesPhoneHours: 'Mon-Fri: 7:00am – 10:00pm CT, Sat: 8:30am – 7:00pm CT, Sun: CLOSED',
    servicePhoneHours: 'Monday - Friday 7:00AM - 10:00PM CT, Saturday 8:30AM - 7:00PM CT',
    chatHours: 'Mon-Fri: 7:00am - 6:00pm CT, Sat-Sun: CLOSED',
    qnbPhoneNumber: '1-888-404-5365',
    dnqPhoneNumber: '1-888-554-7206',
    salesPhoneNumber: '1-888-404-5365',
    servicePhoneNumber: '1-888-404-5365',
    agentFromEmailAddress: 'CONNECT@email.connectbyamfam.com',
    agentFromEmailName: 'CONNECT, powered by American Family Insurance',
  },
  lobDetail: { AUTO: { dnqNumbers: {}, discount: {} } },
} as const;
