import { useCallback } from 'react';

import { TooltipWithIcon } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { PhoneLink, Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import {
  getDrivers,
  getField,
  getPrimaryInsuredPersonRef,
  getPrimaryInsuredStateCode,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { OptionProps } from '@ecp/features/sales/shared/types';
import { partner } from '@ecp/partners';

interface Props extends QuestionProps {
  personRef: string;
}

export const MaritalStatusQuestion: React.FC<Props> = (props) => {
  const {
    personRef,
    label,
    id = 'MaritalStatus',
    dataTestId = 'maritalStatus',
    trackingName = 'marital_status',
    trackingLabel,
    groupLabel,
  } = props;
  const dispatch = useDispatch();
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const maritalStatus = usePersonField('married');
  const drivers = useSelector(getDrivers);
  const primaryInsuredPersonRef = useSelector(getPrimaryInsuredPersonRef);
  const getFieldByKey = useSelector(
    (state: RootStore) => (key: string) => getField(state, { key, dispatch }),
  );

  const stateCode = useSelector(getPrimaryInsuredStateCode);

  const maritalStatusChange = useCallback(
    (value: string) => {
      maritalStatus.props.actionOnChange(value);
      if (personRef === primaryInsuredPersonRef && value !== 'MARITAL_STATUS.MARRIED') {
        drivers.forEach((driver) => {
          const driverRelationship = driver.relationshipToApplicant;
          const driverPerson = driver.personRef;
          const driverPersonRelationshipToPNI = getFieldByKey(
            `${driverPerson}.relationshipToApplicant`,
          );
          // If PNI is changed from married to unmarried
          // the relationship option for spouse disappears for each other person,
          // but we also need to clear that selection from the store
          if (driverRelationship === 'RELATIONSHIP_TO_APPLICANT.SPOUSE') {
            driverPersonRelationshipToPNI.validateUpdateAndPatch('');
          }
        });
      }
    },
    [drivers, getFieldByKey, maritalStatus.props, personRef, primaryInsuredPersonRef],
  );

  useAddFields({ maritalStatus });

  const martialStatusTooltip = (
    <>
      {'To receive a quote without providing your marital status, please call '}
      <PhoneLink number={partner.shared.salesPhoneNumber} />.
    </>
  );
  if (!maritalStatus.exists) return null;

  return (
    <>
      {/* TODO add help text to this component */}
      <Select
        {...(maritalStatus.props as OptionProps)}
        fullWidth={false}
        groupLabel={
          <>
            {groupLabel}
            {stateCode === 'MI' && <TooltipWithIcon title={martialStatusTooltip} />}
          </>
        }
        label={label}
        id={id}
        inputButtonAriaLabel='Marital Status'
        data-testid={dataTestId}
        trackingName={trackingName}
        trackingLabel={trackingLabel}
        actionOnChange={maritalStatusChange}
      />
    </>
  );
};
