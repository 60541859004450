import { Link } from '@mui/material';

import { PhoneLink } from '@ecp/features/sales/shared/components';
import { equifax } from '@ecp/features/sales/shared/metadata';
import { partner } from '@ecp/partners';

import { useStyles } from '../UseOfCreditDisclosurePage.styles';
import type { Metadata } from './metadata';

type GeneralCreditDisclosureProps = { title: string };

export const GeneralCreditDisclosure: React.FC<GeneralCreditDisclosureProps> = ({ title }) => (
  <>
    <p>
      <strong>{title}</strong> - In connection with this application for insurance, we will review
      your credit report or obtain or use a credit-based insurance score based on the information
      contained in that credit history. We will use a third party in connection with the development
      of your insurance score.
    </p>
    <p>
      En relación con esta solicitud de seguro, revisaremos su informe crediticio u obtendremos o
      utilizaremos un puntaje de seguro basado en la información contenida en ese historial
      crediticio. Utilizaremos a un tercero en relación con la determinación de su puntaje de
      seguro.
    </p>
  </>
);

export const AThroughK: React.FC = () => (
  <>
    <GeneralCreditDisclosure title='Alabama' />
    <GeneralCreditDisclosure title='Arkansas' />
    <GeneralCreditDisclosure title='Arizona' />
    <p>
      <strong>California</strong> – Credit is not used for rating policies as it is prohibited by
      the state.
    </p>
    <p>
      El crédito no se utiliza para las políticas de calificactión ya que está prohibido por el
      estado.
    </p>
    <GeneralCreditDisclosure title='Colorado' />
    <GeneralCreditDisclosure title='Connecticut' />
    <GeneralCreditDisclosure title='Delaware' />
    <p>
      <strong>Florida</strong> - In connection with your application for insurance, we will obtain
      an insurance score based on information in your credit history. You may request a copy of your
      credit information from the credit bureau. If there are corrections we will, upon your
      request, re-evaluate you based on the corrected information.
    </p>
    <p>
      The Department of Financial Services offers free financial literacy programs to assist you
      with insurance-related questions, including how credit works and how credit scores are
      calculated. To learn more, visit www.MyFloridaCFO.com.
    </p>
    <p>
      En relación con su solicitud de seguro, obtendremos un puntaje de seguro basado en la
      información de su historial de crédito. Puede solicitar una copia de su información de crédito
      con la agencia de crédito. Si hay correcciones, a petición suya, lo reevaluaremos basándonos
      en la información corregida.
    </p>
    <p>
      El Departamento de Servicios Financieros ofrece programas gratuitos de educación financiera
      para ayudarle con preguntas relacionadas con el seguro, incluyendo cómo funciona el crédito y
      cómo se calculan los puntajes de crédito. Para obtener más información, visite
      www.MyFloridaCFO.com.
    </p>
    <GeneralCreditDisclosure title='Georgia' />
    <p>
      <strong>Hawaii</strong> – Credit is not used for rating policies as it is prohibited by the
      state.
    </p>
    <p>
      El crédito no se utiliza para las políticas de calificactión ya que está prohibido por el
      estado.
    </p>
    <GeneralCreditDisclosure title='Iowa' />
    <GeneralCreditDisclosure title='Idaho' />
    <GeneralCreditDisclosure title='Illinois' />
    <GeneralCreditDisclosure title='Indiana' />
    <p>
      <strong>Kansas</strong> - In connection with this application for insurance, we will review
      your credit report or obtain or use a credit-based insurance score based on the information
      contained in that credit report. We will use a third party in connection with the development
      of your insurance score. Should an adverse action be taken in part as a result of your
      insurance score, the reasons will be made available to you.
    </p>
    <p>
      En relación con esta solicitud de seguro, revisaremos su informe de crédito u obtendremos o
      utilizaremos un puntaje de seguro basado en la información contenida en ese informe de
      crédito. Utilizaremos un tercero en relación con el desarrollo de su puntaje de seguro. En
      caso de que se tome una acción adversa debido en parte a su puntaje de seguro, las razones se
      le harán disponibles.
    </p>
    <GeneralCreditDisclosure title='Kentucky' />
  </>
);

export const LThroughZ: React.FC<{ customerfirstEmail?: string }> = ({
  customerfirstEmail = 'customersfirst@connectbyamfam.com',
}) => {
  const { classes } = useStyles();

  return (
    <>
      <p>
        <strong>Massachusetts</strong> – In connection with this application for insurance, we will
        review your credit report or obtain or use a credit-based insurance score based on the
        information contained in that credit report. We will use a third party in connection with
        the development of your insurance score. Should an adverse action be taken in part as a
        result of your insurance score, the reasons will be made available to you.
      </p>
      <p>
        Credit is only used for rating home policies. Credit is not used when rating auto policies
        as it is prohibited by the state.
      </p>
      <p>
        En relación con esta solicitud de seguro, revisaremos su informe crediticio u obtendremos o
        utilizaremos un puntaje de seguro basado en la información contenida en ese historial
        crediticio. Utilizaremos a un tercero en relación con la determinación de su puntaje de
        seguro. En caso de que se tome una acción adversa en parte como resultado de su puntaje de
        seguro, se le comunicará los motivos.
      </p>
      <p>
        El crédito solo se usa para pólizas de casa, el uso de crédito está prohibido para seguro de
        auto.
      </p>
      <p>
        <strong>Maryland</strong> - In connection with this application for insurance, we will
        review your credit report or obtain or use a credit-based insurance score based on the
        information contained in that credit report. We will use a third party in connection with
        the development of your insurance score. Should an adverse action be taken in part as a
        result of your insurance score, the reasons will be made available to you.
      </p>
      <p>
        *Note: In Maryland, credit is only used for rating auto policies. Credit is not used when
        rating home policies as it is prohibited by the state.
      </p>
      <p>
        En relación con esta solicitud de seguro, revisaremos su informe crediticio u obtendremos o
        utilizaremos un puntaje de seguro basado en la información contenida en ese informe de
        credito. Utilizaremos a un tercero en relación con la determinación de su puntaje de
        seguro.En caso de que se tome una acción adversa debido en parte a su puntaje de seguro, las
        razones se le harán disponibles.
      </p>
      <p>
        *Nota: En Maryland el crédito solo se usa para pólizas de automóvil, el uso de crédito está
        prohibido para seguro de hogar y no se usa
      </p>
      <GeneralCreditDisclosure title='Michigan' />
      <GeneralCreditDisclosure title='Minnesota' />
      <GeneralCreditDisclosure title='Missouri' />
      <GeneralCreditDisclosure title='Mississippi' />
      <GeneralCreditDisclosure title='Montana' />
      <GeneralCreditDisclosure title='North Carolina' />
      <GeneralCreditDisclosure title='Nebraska' />
      <GeneralCreditDisclosure title='Nevada' />
      <GeneralCreditDisclosure title='New Hampshire' />
      <p>
        <strong>New Jersey</strong> - In connection with your application for insurance, we will
        obtain an insurance score based on information in your credit history. We will use this
        information in providing you with a rate. You may request a copy of your credit information
        from the credit bureau. If there are corrections we will, upon your request, re-evaluate you
        based on the corrected information.
      </p>
      <p>
        You may request reconsideration of credit score because of the direct influence of an
        extraordinary life event on your credit information. An extraordinary life event may include
        but is not limited to catastrophic illness or injury; death of a spouse, child or parent;
        temporary loss of employment; divorce; or identity theft. Please contact us by email at{' '}
        <Link className={classes.link} href={customerfirstEmail} target='_blank' rel='noreferrer'>
          {customerfirstEmail}
        </Link>{' '}
        or by calling <PhoneLink withUnderlinedLinkStyle number={partner.shared.salesPhoneNumber} />{' '}
        Monday through Friday from 7 a.m. to 10 p.m or Saturday from 8:30 a.m. to 7 p.m CST.
      </p>
      <p>
        En relación con su solicitud de seguro, obtendremos un puntaje de seguro basado en la
        información de su historial de crédito. Utilizaremos esta información para proporcionarle
        una tarifa. Puede solicitar una copia de su información de crédito a la agencia de crédito.
        Si hay correcciones, a petición suya, lo reevaluaremos basándose en la información
        corregida.
      </p>
      <p>
        Puede solicitar la reconsideración de su puntaje de crédito si su información crediticia fue
        influenciada directamente por un evento extraordinario de la vida.Un evento extraordinario
        de la vida puede incluir, entre otros, enfermedades o lesiones catastróficas; muerte de un
        cónyuge, hijo o padre; pérdida temporal de empleo; divorcio; o robo de identidad.
        Comuníquese con nosotros por correo electrónico a{' '}
        <a className={classes.link} href={`mailto:${customerfirstEmail}`} target='_top'>
          {customerfirstEmail}
        </a>{' '}
        o llamando al <PhoneLink withUnderlinedLinkStyle number={partner.shared.salesPhoneNumber} />{' '}
        de lunes a viernes de 7 a.m. a 10 p.m o sábados de 8:30 a.m. a 7 p.m CST.
      </p>
      <p>
        <strong>New Mexico</strong> - In connection with your application for insurance coverage, we
        may review and use information contained in your credit report to help determine your
        premium or your eligibility for coverage.
      </p>
      <p>
        En relación con su solicitud de seguro, podemos revisar y utilizar la información contenida
        en su informe de crédito para ayudar a determinar su prima o su elegibilidad para la
        cobertura.
      </p>
      <p>
        <strong>New York</strong> - In connection with this insurance, we may review your credit
        report or obtain or use a credit-based insurance score based on information contained in
        that report. An insurance score uses information from your credit report to help predict how
        often you are likely to file claims and how expensive those claims will be. Typical items
        from a credit report that could affect a score include, but are not limited to, the
        following: payment history, number of revolving accounts, number of new accounts, the
        presence of collection accounts, bankruptcies and foreclosures. The information used to
        develop the insurance score comes from Equifax, P.O. Box 740256 Atlanta, GA 30374,
        <PhoneLink withUnderlinedLinkStyle number='800-685-1111' /> or{' '}
        <Link className={classes.link} href={equifax} target='_blank' rel='noreferrer'>
          Equifax | Credit Bureau | Check Your Credit Report & Credit Score
        </Link>
        .
      </p>
      <p>
        En relación con este seguro, podriamos revisar su informe de crédito u obtener o utilizar un
        puntaje de seguro basado en la información contenida en ese informe. Un puntaje de seguro
        utiliza información de su informe de crédito para ayudar a predecir con qué frecuencia es
        probable que presente reclamos y qué tan costosos serán esos reclamos. Las cosas típicas de
        un informe de crédito que podrían afectar un puntaje incluyen, entre otras, las siguientes:
        historial de pagos, número de cuentas rotativas, número de cuentas nuevas, presencia de
        cuentas de cobro, quiebras y ejecuciones hipotecarias. La información utilizada para
        desarrollar el puntaje de seguro proviene de Equifax, P.O. Box 740256 Atlanta, GA 30374,{' '}
        <PhoneLink withUnderlinedLinkStyle number='800-685-1111' /> o{' '}
        <Link className={classes.link} href={equifax} target='_blank' rel='noreferrer'>
          Equifax | Credit Bureau | Check Your Credit Report & Credit Score
        </Link>
        .
      </p>
      <GeneralCreditDisclosure title='Ohio' />
      <p>
        <strong>Oregon</strong> - In connection with this insurance, we will review your credit
        report or obtain or use a credit-based insurance score based on information contained in
        that report. The information is used to potentially discount your insurance premium and
        eligibility in combination with other underwriting criteria. The absence of credit
        information or an inability to determine credit information does not affect our
        consideration of an application of insurance.
      </p>
      <p>
        Insurers use insurance scores, which uses information from your credit report to help
        predict how often you are likely to file claims and how expensive those claims will be.
        Typical items from a credit report that could affect a score include, but are not limited
        to, the following: payment history, number of revolving accounts, number of new accounts,
        the presence of collection accounts, bankruptcies and foreclosures. We cannot use credit
        history or a credit score for any arbitrary, capricious, or unfairly discriminatory reason.
        Additionally, credit history or a credit score based wholly or partly on residence, sex,
        race, color, creed, occupation, income, physical handicap or disability of an applicant or
        insured is prohibited.
      </p>
      <p>
        The information used to develop the insurance score comes from Equifax. If you would like to
        review your personal credit history information, please call Equifax at
        <PhoneLink withUnderlinedLinkStyle number='800-685-1111' />, write P.O. Box 740256, Atlanta,
        GA 30374, or visit{' '}
        <Link className={classes.link} href={equifax} target='_blank' rel='noreferrer'>
          Equifax | Credit Bureau | Check Your Credit Report & Credit Score
        </Link>
        .
      </p>
      <p>
        En relación con este seguro, revisaremos su informe crediticio u obtendremos o utilizaremos
        un puntaje de seguro basado en la información contenida en ese informe. La información se
        utiliza para un posible descuento en su prima de seguro y para la elegibilidad en
        combinación con otros criterios de suscripción. La ausencia de información crediticia o la
        incapacidad de determinar la información crediticia no afecta nuestra consideración de una
        solicitud de seguro.
      </p>
      <p>
        Las aseguradoras utilizan los puntajes de seguro, que utilizan la información de su informe
        de crédito para ayudar a predecir con qué frecuencia es probable que presente reclamos y qué
        tan costosos serán esos reclamos. Los elementos típicos de un informe de crédito que podrían
        afectar un puntaje incluyen, entre otros, los siguientes: historial de pagos, número de
        cuentas rotativas, número de cuentas nuevas, presencia de cuentas de cobro, bancarrotas y
        ejecuciones hipotecarias. No podemos usar el historial de crédito o un puntaje de crédito
        por ninguna razón arbitraria, caprichosa o injustamente discriminatoria. Adicionalmente, se
        prohíbe que el historial de crédito o un puntaje de crédito sea basado totalmente o
        parcialmente en la residencia, el sexo, la raza, el color, el credo, la ocupación, los
        ingresos, la discapacidad física o la discapacidad de un solicitante o asegurado.
      </p>
      <p>
        La información utilizada para desarrollar el puntaje de seguro proviene de Equifax. Si desea
        revisar la información de su historial de crédito personal, llame a Equifax all{' '}
        <PhoneLink withUnderlinedLinkStyle number='800-685-1111' />, escriba P.O. Box 740256,
        Atlanta, GA 30374, o visite{' '}
        <Link className={classes.link} href={equifax} target='_blank' rel='noreferrer'>
          Equifax | Credit Bureau | Check Your Credit Report & Credit Score
        </Link>
        .
      </p>
      <GeneralCreditDisclosure title='Pennsylvania' />
      <p>
        <strong>South Carolina</strong> - In connection with this application for insurance and in
        the future, we will review your credit history or obtain or use a credit-based insurance
        score based on the information contained in that credit history.
      </p>
      <p>
        En relación con esta solicitud de seguro y en el futuro, revisaremos su historial crediticio
        u obtendremos o utilizaremos un puntaje de seguro basado en la información contenida en ese
        historial de credito.
      </p>
      <GeneralCreditDisclosure title='South Dakota' />
      <GeneralCreditDisclosure title='Tennessee' />
      <p>
        <strong>Texas</strong> -{' '}
        <Link
          target='_blank'
          href='https://www.connectbyamfam.com/documents/auto/CREDITDISCLOSURETX.PDF '
          rel='noreferrer'
        >
          Use of credit information disclosure
        </Link>
      </p>
      <GeneralCreditDisclosure title='Utah' />
      <p>
        <strong>Virginia</strong> - In connection with this application for insurance, we shall
        review your credit report or obtain or use an insurance credit score based on the
        information contained in that credit report. We may use a third party in connection with the
        development of your insurance credit score. You may request that your credit information be
        updated and if you question the accuracy of the credit information, we will, upon your
        request, reevaluate you based on corrected credit information from a consumer reporting
        agency.
      </p>
      <em>
        En relación con esta solicitud de seguro, revisaremos su reporte de crédito u obtendremos o
        usaremos una puntuación de seguro basada en la información contenida en ese reporte de
        crédito. Podemos utilizar a un tercero en relación con el desarrollo de su puntaje
        crediticio del seguro. Puede solicitar que se actualice su información crediticia y, si
        cuestiona la exactitud de la información crediticia, a petición suya, lo reevaluaremos
        usando la información crediticia corregida de una agencia de informes del consumidor.
      </em>
      <GeneralCreditDisclosure title='Vermont' />
      <GeneralCreditDisclosure title='Washington' />
      <GeneralCreditDisclosure title='Washington D.C.' />
      <p>
        <strong>West Virginia</strong> - We use your credit information to produce an insurance
        score. This insurance score has an effect on the premium that you pay for your insurance. We
        are required by the Insurance Commissioner to recheck your credit information no less than
        once every 36 months for changes. You have the option to request that we recheck your
        insurance score more frequently than once every 36 months, but you can only make this
        request once during any 12-month period. If there has been a change in your insurance score,
        we will re-underwrite and re-rate the policy based upon the current credit report or
        insurance score. The change in your insurance score may result in an increase or a decrease
        in the premium that you pay for your insurance. Any changes in your premium will take place
        upon renewal if your request is made at least 45 days before your renewal. If the request is
        made less than 45 days before your renewal date, the insurer shall re-underwrite and re-rate
        the policy for the following renewal.
      </p>
      <p>
        Usamos su información de crédito para producir un puntaje de seguro. Este puntaje de seguro
        tiene un efecto en la prima que paga por su seguro. El Comisionado de Seguros nos exige que
        volvamos a verificar su información crediticia al menos una vez cada 36 meses para detectar
        cambios. Tiene la opción de solicitar que volvamos a verificar su puntaje de seguro con más
        frecuencia que una vez cada 36 meses, pero solo puede realizar esta solicitud una vez
        durante cualquier período de 12 meses. Si ha habido un cambio en su puntaje de seguro,
        volveremos a suscribir y calificar la póliza según el informe de crédito actual o el puntaje
        de seguro. El cambio en su puntaje de seguro puede resultar en un aumento o una disminución
        en la prima que paga por su seguro. Cualquier cambio en su prima tendrá lugar al momento de
        la renovación si su solicitud se realiza al menos 45 días antes de la renovación. Si la
        solicitud se realiza con menos de 45 días de antelación a la fecha de renovación, la
        aseguradora deberá volver a suscribir y tasar la póliza para la siguiente renovación.
      </p>
      <p>
        <strong>Wisconsin</strong> - In order to underwrite the insurance for which you are
        applying, we will request your insurance credit score, which is based on information found
        in an insurance credit report. The information may be used, in combination with other
        underwriting criteria, as a factor in determining eligibility, assessing risk and
        establishing premium discounts.
      </p>
      <p>
        Para suscribir el seguro que está solicitando, solicitaremos su puntaje de crédito de
        seguro, que se basa en la información que se encuentra en un informe de crédito de seguro.
        La información puede usarse, en combinación con otros criterios de suscripción, como un
        factor para determinar la elegibilidad, evaluar el riesgo y establecer descuentos.
      </p>
    </>
  );
};

const metadata: Metadata = {
  disclosureGroups: [
    {
      groupTitle: 'United States of America: States A through K',
      disclosures: <AThroughK />,
    },
    {
      groupTitle: 'United States of America: States L through Z',
      disclosures: <LThroughZ />,
    },
  ],
};

export default metadata;
