import { Grid } from '@mui/material';

import { getVehicles } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { vehicleDiscountsOptions } from '../../../../metadata';
import { useGetVehicleItemsWithImage } from '../../../../state';
import { useVehicleDiscounts } from '../util';
import { VehicleDiscounts } from './VehicleDiscounts';
import { useStyles } from './VehicleDiscountsQuestion.styles';

interface Props {
  isContinueClicked: boolean;
}

export const VehicleDiscountsQuestion: React.FC<Props> = (props) => {
  const { isContinueClicked } = props;
  const { classes } = useStyles();
  const vehicles = useSelector(getVehicles);
  const vehicleDiscounts = useVehicleDiscounts(vehicles);

  const vehicleItemList = useGetVehicleItemsWithImage();

  if (!vehicleItemList.length) return null;

  return (
    <>
      <Grid item xs={12} className={classes.headingContainer}>
        <h2>Choose your vehicle discounts</h2>
      </Grid>
      <Grid container item xs={12}>
        {vehicleItemList.map((vehicleItem) => (
          <VehicleDiscounts
            key={vehicleItem.vehicle.ref}
            vehicleDiscounts={vehicleDiscounts}
            vehicleDiscountsOptions={vehicleDiscountsOptions}
            vehicleItem={vehicleItem}
            isContinueClicked={isContinueClicked}
          />
        ))}
      </Grid>
    </>
  );
};
