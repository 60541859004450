export const partner = {
  shared: {
    partnerDisplayName: 'CONNECT, powered by American Family Insurance',
    salesEmailQuotesAddress: 'salesNP@connectbyamfam.com',
    salesPhoneHours: 'Mon-Fri: 7:00am – 10:00pm CT, Sat: 8:30am – 7:00pm CT, Sun: CLOSED',
    servicePhoneHours: 'Mon – Fri: 7AM – 10PM CT, Sat: 8:30AM - 7PM CT, Sun: Closed',
    chatHours: ' Mon-Fri: 7:00am - 6:00pm CT, Sat-Sun: CLOSED',
    qnbPhoneNumber: '1-800-535-2001',
    dnqPhoneNumber: '1-888-554-6196',
    salesPhoneNumber: '1-800-535-2001',
    servicePhoneNumber: '1-800-535-2001',
    agentFromEmailAddress: 'CONNECT@email.connectbyamfam.com',
    agentFromEmailName: 'CONNECT, powered by American Family Insurance',
    dnqEmail: '',
  },
  lobDetail: { AUTO: { dnqNumbers: {}, discount: {} } },
} as const;
