import type { ContentByProductType } from './metadata.js';
import baseMetadata from './metadata.js';

const metadata: () => ContentByProductType = () => ({
  ...baseMetadata(),
  auto: {
    insuranceType: '"Auto insurance."',
    underwritingGroupFull: 'Midvale Indemnity Company, and its subsidiaries',
    underwritingGroupShort: 'Midvale',
  },
});

export default metadata;
