import { useCallback, useEffect, useMemo, useState } from 'react';

import { FormLabel, Grid } from '@mui/material';

import { GoogleAnalyticsLabels, trackClick } from '@ecp/utils/analytics/tracking';
import { castToBoolean } from '@ecp/utils/common';
import type { GeoAddress } from '@ecp/utils/geo';
import { useGeoAddressOptions, validateAndCombineAddress } from '@ecp/utils/geo';

import { GridItem, NumberFormat, TooltipWithIcon } from '@ecp/components';
import { useAddConditionValues, useAddFields, useInitValues } from '@ecp/features/sales/form';
import {
  AutoComplete,
  RadioGroupWithOptions,
  Select,
  TextField,
} from '@ecp/features/sales/shared/components';
import { STATE_CODE_PREFIX } from '@ecp/features/sales/shared/constants';
import {
  createAddressForUpdate,
  getPrimaryInsuredAddressInfo,
  updateAnswers,
  useAddressFieldValue,
  useField,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, OptionProps } from '@ecp/features/sales/shared/types';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

import {
  useAddressRefForVehicle,
  useDefaultVehicleAddressRef,
  useGarageField,
  useKeptAtRiskAddressValue,
  useUpdateVehicleAddressRef,
} from '../../../../state';
import metadata from './metadata';
import { useStyles } from './VehicleKeptAtQuestion.styles';
const KEPT_AT_RISK_HELP_TEXT =
  'Enter the address where you regularly park this vehicle when not at work.';

const gaTrackSuggestionClick = (): void => {
  trackClick({
    action: 'SmartyStreetSuggestedAddress',
    label: 'ClickedSuggestion',
  });
  trackSapiAnalyticsEvent({
    element: 'choice.vehicleForm.smartyStreetSuggestion',
    event: 'click',
    eventDetail: 'true',
  });
};

interface Props {
  vehicleRef: string;
}

export const VehicleKeptAtQuestion: React.FC<Props> = (props) => {
  const { vehicleRef } = props;
  const dispatch = useDispatch();
  const garageAddressRef = useAddressRefForVehicle(vehicleRef);
  const garageAddress = useGarageField(vehicleRef, 'line1');
  const addressInformation = useSelector(getPrimaryInsuredAddressInfo);
  const { classes } = useStyles();
  const garageUnitOrApt = useGarageField(vehicleRef, 'line2');
  const garageCity = useGarageField(vehicleRef, 'city');
  const garageState = useGarageField(vehicleRef, 'state');
  const garageAtZip = useGarageField(vehicleRef, 'zipcode');

  const keptAtRiskAddress = useField(`${vehicleRef}.keptAtRiskAddress`);
  const staticVehicleKeptAtAddressField = useField(`static.${vehicleRef}.vehicleKeptAddress`);
  const garagedOutOfStateInd = useField(`${vehicleRef}.garagedOutOfStateInd`);
  const studentOrActiveMilitaryOperatorInd = useField(
    `${vehicleRef}.studentOrActiveMilitaryOperatorInd`,
  );

  const model = useField(`${vehicleRef}.model`);

  const [vehicleKeptAddressSuggestions, setVehicleKeptAddressSuggestions] = useState<GeoAddress[]>(
    [],
  );

  useInitValues({
    // TODO This likely should be boolean true, not string true
    [keptAtRiskAddress.key]: 'true',
    [garagedOutOfStateInd.key]: 'GARAGED_OUT_OF_STATE.MORE_THAN_SIX_MONTHS.NO',
  });

  const updateVehicleAddressRef = useUpdateVehicleAddressRef(vehicleRef);

  // TODO: useInitValues issue here is it is not saving the default value to true
  useEffect(() => {
    if (keptAtRiskAddress.value == null) {
      keptAtRiskAddress.props.actionOnComplete('true');
      updateVehicleAddressRef(true);
    }
    if (garagedOutOfStateInd.value == null) {
      garagedOutOfStateInd.props.actionOnComplete('GARAGED_OUT_OF_STATE.MORE_THAN_SIX_MONTHS.NO');
    }
  }, [
    garagedOutOfStateInd.props,
    garagedOutOfStateInd.value,
    keptAtRiskAddress.props,
    keptAtRiskAddress.value,
    updateVehicleAddressRef,
  ]);

  const keptAtRiskAddressValue = Boolean(
    useKeptAtRiskAddressValue(keptAtRiskAddress, garageAddress),
  );

  useAddFields({
    garage: {
      line1: garageAddress,
      line2: garageUnitOrApt,
      city: garageCity,
      state: garageState,
      zipcode: garageAtZip,
    },
  });
  useAddConditionValues({
    conditionalFields: [garageAddress, garageUnitOrApt, garageCity, garageState, garageAtZip],
    isExcluded: () => keptAtRiskAddressValue,
  });

  const {
    handleSuggestionsFetchRequested: handleVKASuggestionsFetchRequested,
    handleSuggestionsClearRequested,
    handleAptSuggestionFetchRequested,
  } = useGeoAddressOptions();

  const defaultVehicleAddress = useDefaultVehicleAddressRef();
  const defaultVehicleAddressLine1 = useAddressFieldValue(defaultVehicleAddress, 'line1');
  const defaultVehicleAddressState = useAddressFieldValue(defaultVehicleAddress, 'state');

  const handleStateChange = useCallback(
    (value: AnswerValue) => {
      if (metadata.useStaticKey) {
        if (value !== defaultVehicleAddressState) {
          staticVehicleKeptAtAddressField.props.actionOnChange('differentAddressAndState');
        } else {
          staticVehicleKeptAtAddressField.props.actionOnChange('differentAddress');
        }
      }
    },
    [staticVehicleKeptAtAddressField, defaultVehicleAddressState],
  );

  const handleKeptAtRiskAddressChange = useCallback(
    async (value: AnswerValue) => {
      // because this change action is triggered on every click,
      // we should only run the actionOnComplete if the value has changed
      if (keptAtRiskAddress.value !== value) {
        await updateVehicleAddressRef(castToBoolean(value));
        keptAtRiskAddress.props.actionOnComplete(value);
      }
    },
    [keptAtRiskAddress.props, keptAtRiskAddress.value, updateVehicleAddressRef],
  );

  const handleKeptAtRiskAddressStaticQuestionChange = useCallback(
    async (value: AnswerValue) => {
      // because this change action is triggered on every click,
      // we should only run the actionOnComplete if the value has changed
      staticVehicleKeptAtAddressField.props.actionOnComplete(value);
      if (value === 'currentAddress') {
        await updateVehicleAddressRef(castToBoolean(value === 'currentAddress'));
      }
      if (value === 'differentAddress') {
        await updateVehicleAddressRef(castToBoolean(false));
      }
    },
    [staticVehicleKeptAtAddressField.props, updateVehicleAddressRef],
  );

  const vehicle = model.props.value || 'vehicle';

  const autoCompleteVKASuggestions = useMemo(
    () =>
      vehicleKeptAddressSuggestions.map((s: GeoAddress, index, arr) => {
        if (arr.length - 1 === index) {
          return s.street_line;
        }

        return (
          s.street_line +
          (s.secondary !== ''
            ? ` ${s.secondary} (${s.entries} more entries) ${s.city}, ${s.state} ${s.zipcode}`
            : ` ${s.city}, ${s.state} ${s.zipcode}`)
        );
      }),
    [vehicleKeptAddressSuggestions],
  );

  const handleVKASuggestionsFetch = useCallback(
    async (value: string): Promise<void> => {
      if (!value.includes('Apt') || !value.includes('Ste')) {
        // Need to pass state value as well to grab suggestions instead of just filtering on zipcode because garage address
        // may be different from primary address filled in.
        const output = await handleVKASuggestionsFetchRequested(
          value,
          garageAtZip.value as string,
          (garageState.value as string) || '',
        );
        setVehicleKeptAddressSuggestions(output);
      }
    },
    [garageAtZip.value, garageState.value, handleVKASuggestionsFetchRequested],
  );

  const handleVehickeKeptAddressSelection = useCallback(
    async (value: string) => {
      const vehicleKeptAddressToBeValidated = vehicleKeptAddressSuggestions.find(
        (vehicleKeptaddressVal: GeoAddress) => {
          const fullAddress =
            vehicleKeptaddressVal.street_line +
            (vehicleKeptaddressVal.secondary !== ''
              ? ` ${vehicleKeptaddressVal.secondary} (${vehicleKeptaddressVal.entries} more entries) ${vehicleKeptaddressVal.city}, ${vehicleKeptaddressVal.state} ${vehicleKeptaddressVal.zipcode}`
              : ` ${vehicleKeptaddressVal.city}, ${vehicleKeptaddressVal.state} ${vehicleKeptaddressVal.zipcode}`);

          return fullAddress === value;
        },
      );
      // If selected address is just a house
      if (vehicleKeptAddressToBeValidated) {
        if (vehicleKeptAddressToBeValidated.entries <= 1) {
          garageAddress.props.actionOnComplete(vehicleKeptAddressToBeValidated.street_line);
          const inputAddress = {
            street: vehicleKeptAddressToBeValidated.street_line,
            city: vehicleKeptAddressToBeValidated.city,
            street2:
              vehicleKeptAddressToBeValidated.secondary !== ''
                ? vehicleKeptAddressToBeValidated.secondary
                : '',
            state: vehicleKeptAddressToBeValidated.state,
            zipcode: vehicleKeptAddressToBeValidated.zipcode,
          };
          const newVehicleKeptAddress = await validateAndCombineAddress(
            inputAddress,
            garageAddressRef,
          );
          if (newVehicleKeptAddress) {
            // we should manually update these field values to account for any prior existing error messages,
            // because updating the answers in redux will not handle that for us
            garageAddress.props.actionOnComplete(newVehicleKeptAddress.line1);
            garageUnitOrApt.props.actionOnComplete(newVehicleKeptAddress.line2);
            garageCity.props.actionOnComplete(newVehicleKeptAddress.city);
            garageState.props.actionOnComplete(
              `${STATE_CODE_PREFIX}${newVehicleKeptAddress.state}`,
            );
            garageAtZip.props.actionOnComplete(newVehicleKeptAddress.zipcode);
            const vehicleKeptAddressToBeSaved = createAddressForUpdate(newVehicleKeptAddress);
            await dispatch(updateAnswers({ answers: { ...vehicleKeptAddressToBeSaved } }));
          }
        } else {
          garageAddress.props.actionOnComplete(vehicleKeptAddressToBeValidated.street_line);
          // need to pass selected address as well as the letter A to limit the results to number of valid entries recieved for address.
          // Will  return # of entries whose secondary value starts with A.
          const selectedValue = `${vehicleKeptAddressToBeValidated.street_line} ${vehicleKeptAddressToBeValidated.secondary} A (${vehicleKeptAddressToBeValidated.entries}) ${vehicleKeptAddressToBeValidated.city} ${vehicleKeptAddressToBeValidated.state} ${vehicleKeptAddressToBeValidated.zipcode}`;
          // If selected address is a series of apartments
          const fetchNewAptSuggestions = await handleAptSuggestionFetchRequested(
            vehicleKeptAddressToBeValidated.street_line,
            selectedValue,
          );

          setVehicleKeptAddressSuggestions(fetchNewAptSuggestions);
        }
      }
    },
    [
      vehicleKeptAddressSuggestions,
      garageAddress.props,
      garageAddressRef,
      garageUnitOrApt.props,
      garageCity.props,
      garageState.props,
      garageAtZip.props,
      dispatch,
      handleAptSuggestionFetchRequested,
    ],
  );
  const optionForKeptAtRiskAddress = [
    {
      label: `${addressInformation.line1} ${addressInformation.line2} ${addressInformation.city}, ${addressInformation.state}`,
      value: 'currentAddress',
    },
    { label: `Other ${addressInformation.state} address`, value: 'differentAddress' },
    { label: `State other than ${addressInformation.state}`, value: 'differentState' },
  ];

  return (
    <>
      {keptAtRiskAddress.exists && (
        <Grid item xs={12}>
          {metadata.useStaticKey ? (
            <RadioGroupWithOptions
              {...staticVehicleKeptAtAddressField.props}
              options={optionForKeptAtRiskAddress}
              actionOnComplete={handleKeptAtRiskAddressStaticQuestionChange}
              label={
                <>
                  {`Where is ${vehicle} kept?`}
                  <TooltipWithIcon title={KEPT_AT_RISK_HELP_TEXT} />
                </>
              }
              id='staticVehicleKeptAtAddressField'
              variant='button'
              dataTestId='staticVehicleKeptAtAddressField'
              trackingName='garaging_address_question'
              trackingLabel={staticVehicleKeptAtAddressField.props.value}
            />
          ) : (
            <RadioGroupWithOptions
              {...keptAtRiskAddress.props}
              value={keptAtRiskAddressValue}
              actionOnComplete={handleKeptAtRiskAddressChange}
              label={
                <>
                  {`Is this ${vehicle} kept at ${defaultVehicleAddressLine1}?`}
                  <TooltipWithIcon title={KEPT_AT_RISK_HELP_TEXT} />
                </>
              }
              id='KeptAtRiskAddress'
              variant='yesNoButton'
              dataTestId='KeptAtButton'
              trackingName='garaging_address_question'
              trackingLabel={keptAtRiskAddress.props.value}
            />
          )}
        </Grid>
      )}
      {((metadata.useStaticKey &&
        (staticVehicleKeptAtAddressField.value === 'differentAddress' ||
          staticVehicleKeptAtAddressField.value === 'differentAddressAndState')) ||
        (!metadata.useStaticKey && keptAtRiskAddressValue === false)) && (
        <>
          <GridItem topSpacing='lg' xs={12}>
            <FormLabel>{`Where is the ${vehicle} kept?`}</FormLabel>
          </GridItem>

          <Grid container columnSpacing={4}>
            {garageAddress.exists && (
              <GridItem topSpacing='sm' xs={12} md={6}>
                {' '}
                <AutoComplete
                  {...garageAddress.props}
                  label={metadata.garagedAddressLabel}
                  suggestions={autoCompleteVKASuggestions}
                  geoAddressFormattedSuggestions={vehicleKeptAddressSuggestions}
                  onSuggestionsClearRequested={handleSuggestionsClearRequested}
                  onSuggestionsFetchRequested={handleVKASuggestionsFetch}
                  onSuggestionSelected={handleVehickeKeptAddressSelection}
                  id='GaragingAddressAutoComplete'
                  trackingName='garaging_address'
                  trackingLabel={GoogleAnalyticsLabels.REDACTED}
                  gaTrackSuggestionClick={gaTrackSuggestionClick}
                />
              </GridItem>
            )}
            {garageUnitOrApt.exists && (
              <GridItem topSpacing='sm' xs={12} md={6}>
                <TextField
                  {...garageUnitOrApt.props}
                  id='GaragingApt'
                  label='Apt./Unit #'
                  trackingName='garaging_address_line_2'
                  trackingLabel={GoogleAnalyticsLabels.REDACTED}
                />
              </GridItem>
            )}
            {garageCity.exists && (
              <GridItem topSpacing='sm' xs={12} md={garageState.exists ? 4 : 6}>
                <TextField
                  {...garageCity.props}
                  id='GaragingCity'
                  label='City'
                  trackingName='garaging_city'
                  trackingLabel={garageCity.props.value}
                />
              </GridItem>
            )}
            {garageState.exists && (
              <GridItem topSpacing='sm' xs={12} md={4}>
                {' '}
                <Select
                  {...(garageState.props as OptionProps)}
                  id='State'
                  label='State'
                  trackingName='garaging_state'
                  trackingLabel={garageState.props.value}
                  actionOnComplete={handleStateChange}
                />
              </GridItem>
            )}
            {garageAtZip.exists && (
              <GridItem topSpacing='sm' xs={12} md={garageState.exists ? 4 : 6}>
                <NumberFormat
                  {...garageAtZip.props}
                  id='GaragingZip'
                  formatType='zipcode'
                  label='ZIP code'
                  trackingName='garaging_zip_code'
                />
              </GridItem>
            )}
            {garagedOutOfStateInd.exists && (
              <>
                <GridItem topSpacing='lg' xs={12} md={12}>
                  <RadioGroupWithOptions
                    {...garagedOutOfStateInd.props}
                    label='Is this vehicle kept out of state for 6 months or longer?'
                    id='garagedOutOfStateInd'
                    variant='button'
                    dataTestId='garageOutOfStateButton'
                    trackingName='GarageOutOfState'
                  />
                </GridItem>
                {garagedOutOfStateInd.value === 'GARAGED_OUT_OF_STATE.MORE_THAN_SIX_MONTHS.YES' && (
                  <GridItem topSpacing='lg' xs={12} md={12}>
                    <RadioGroupWithOptions
                      {...studentOrActiveMilitaryOperatorInd.props}
                      label='Do any of these apply to the primary operator of this vehicle?'
                      id='studentOrActiveMilitaryOperatorInd'
                      variant='button'
                      dataTestId='studentOrActiveMilitaryOperatorButton'
                      trackingName='StudentOrActiveMilitaryOperator'
                    />
                  </GridItem>
                )}
              </>
            )}
          </Grid>
        </>
      )}
      {metadata.useStaticKey &&
        (staticVehicleKeptAtAddressField.value === 'differentState' ||
          staticVehicleKeptAtAddressField.value === 'differentAddressAndState') && (
          <div className={classes.differentStateError}>
            We’re unable to provide coverage for a vehicle kept outside of your residence state on
            this quote. To proceed with your in-state vehicles, please remove this vehicle.
          </div>
        )}
    </>
  );
};
