import { TextField } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { REFERENCE_NUMBER } from '@ecp/features/sales/shared/constants';
import { useField } from '@ecp/features/sales/shared/store';

export const ReferenceNumberQuestion: React.FC = () => {
  const referenceNumber = useField(REFERENCE_NUMBER);
  useAddFields({ referenceNumber });

  if (!referenceNumber.exists) return null;

  return (
    <TextField
      {...referenceNumber.props}
      id={REFERENCE_NUMBER}
      data-testid='referenceNumber'
      label='Reference # (optional)'
      trackingName='reference_number'
    />
  );
};
