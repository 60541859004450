import type { StateCompanyName } from '@ecp/features/sales/shared/types';

import {
  GeneralAutoBillingDisclaimer,
  GeneralPaymentConfirmationDisclaimer,
  GeneralPaymentDisclaimer,
  NachaAuthorizationAgreement,
} from './GeneralCheckoutDisclaimers';

export const RecurringPaymentCompanyName: { [productKey: string]: StateCompanyName } = {};

export const RecurringPaymentMetadata: Record<
  string,
  Record<
    string,
    | ((name: string, state: string) => JSX.Element)
    | ((name?: string, state?: string) => JSX.Element)
  >
> = {
  EFT: {
    'homesite.home': (...[, state]: [string, string]): JSX.Element => {
      let fraudText = '';
      if (state === 'VA') {
        fraudText =
          'Your premium and eligibility are based on information provided. It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.';
      }

      return (
        <>
          <NachaAuthorizationAgreement product='home' />
          <GeneralPaymentDisclaimer fraudText={fraudText} />
        </>
      );
    },
    'amfam.auto': (): JSX.Element => {
      return (
        <>
          <NachaAuthorizationAgreement
            product='auto'
            defaultComponent={<GeneralPaymentConfirmationDisclaimer />}
          />
          <GeneralAutoBillingDisclaimer />
        </>
      );
    },
  },
  CreditCard: {
    'homesite.home': (name: string, state: string): JSX.Element => {
      let fraudText = '';
      if (state === 'VA') {
        fraudText =
          'Your premium and eligibility are based on information provided. It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines and denial of insurance benefits.';
      }

      return <GeneralPaymentDisclaimer fraudText={fraudText} />;
    },
    'amfam.auto': (): JSX.Element => {
      return (
        <>
          <GeneralPaymentConfirmationDisclaimer />
          <GeneralAutoBillingDisclaimer />
        </>
      );
    },
  },
};
