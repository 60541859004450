import { env } from '@ecp/env';
import { partner } from '@ecp/partners';

import baseMetadata from './metadata.js';
import type { Metadata } from './metadata.js';

const metadata: Metadata = {
  ...baseMetadata,
  DisplayErrorRecallMessage: {
    recallErrorMessage: env.static.isAgent
      ? 'Unable to find any quotes for this product. Please confirm or adjust your selection.'
      : `Your quote was not found. Adjust your information or click "Start a new quote" below for a new one. Still need help? Call us at ${partner.shared.salesPhoneNumber}.`,
  },
};

export default metadata;
