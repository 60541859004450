import { GridItem } from '@ecp/components';

import { HearAboutUsQuestion } from '../../Person/HearAboutUs/HearAboutUsQuestion';
import { ReferenceNumberQuestion } from '../../Person/ReferenceNumber';
import { useStyles } from './HearAboutUsReferenceNumberQuestions.styles';
import metadata from './metadata';

export const HearAboutUsReferenceNumberQuestions: React.FC = () => {
  const { classes } = useStyles();

  return (
    <>
      {metadata.header && (
        <GridItem xs={12} topSpacing='lg'>
          <p className={classes.subHeader}>{metadata.header}</p>
        </GridItem>
      )}
      <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
        <HearAboutUsQuestion />
      </GridItem>
      <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
        <ReferenceNumberQuestion />
      </GridItem>
    </>
  );
};
