import { Fragment, useCallback, useEffect, useRef } from 'react';

import { Divider, Grid } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';

import { getDriverLabel } from '@ecp/features/sales/quotes/auto';
import { Button, Form, NextPageInstructions } from '@ecp/features/sales/shared/components';
import { questionExists, useForm } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, Driver, VehicleBasic } from '@ecp/features/sales/shared/types';
import { useIsMobile } from '@ecp/themes/base';
import { IconUIRefresh as ResetIcon } from '@ecp/themes/base';
import type { Field, Fields, Option } from '@ecp/types';

import { AssignmentLineItem } from './AssignmentLineItem/AssignmentLineItem';
import { useStyles } from './DriverAssignmentForm.styles';
export interface DriverAssignmentFormProps {
  vehicles: VehicleBasic[];
  drivers: Driver[];
  onNext: () => Promise<void>;
  onBack: () => Promise<void>;
  nextPageInstructions: string;
  driverAssignmentFields: Fields;
  secondaryDriverAssignmentFields: Fields;
}

type AssignmentQuestionItem = (
  | { assignmentType: 'driver'; driver: Driver }
  | { assignmentType: 'vehicle'; vehicle: VehicleBasic }
) & {
  dropDownOptions: Option[];
  question: Field;
  secondaryQuestion: Field;
};

type GetLabelArgs = ['driver', Driver] | ['vehicle', VehicleBasic];
type GetLabel = (...args: GetLabelArgs) => string;
const getLabel: GetLabel = (type, item) =>
  type === 'driver' ? getDriverLabel(item) : `${item.year} ${item.make} ${item.model}`;

export const DriverAssignmentForm: React.FC<DriverAssignmentFormProps> = (props) => {
  const isMobile = useIsMobile();
  const { classes } = useStyles();
  const {
    onNext,
    nextPageInstructions,
    driverAssignmentFields,
    secondaryDriverAssignmentFields,
    vehicles,
    drivers,
    onBack,
  } = props;

  const sapiAnalyticsOfferEventDetail = `driversUpdated:${drivers.length}, vehiclesUpdated:${vehicles.length}`;

  const secondaryDriverQuestionExists = useSelector((state: RootStore) =>
    questionExists('vehicle.<id>.secondary.driver.ref')(state),
  );

  /**
   * We need to get all the answers and the associated key and pass those into
   * the component for filtering
   */
  const fieldAnswers = Object.keys(driverAssignmentFields).map((key) => {
    return { key, value: driverAssignmentFields[key]?.value as AnswerValue };
  });

  const secondaryFieldAnswers = Object.keys(secondaryDriverAssignmentFields).map((key) => {
    return { key, value: secondaryDriverAssignmentFields[key]?.value as AnswerValue };
  });

  const getLineItems = useCallback((): AssignmentQuestionItem[] => {
    const fieldKeys = Object.keys(driverAssignmentFields);
    const secondaryFieldKeys = Object.keys(secondaryDriverAssignmentFields);

    /**
     * If we have an equal number of drivers & vehicles OR there are more drivers than vehicles
     * then we want to display each vehicle as the label and allow the user to choose a driver
     */
    if (vehicles.length === drivers.length || drivers.length > vehicles.length) {
      return vehicles.map((vehicle, index) => {
        const questionKey = fieldKeys[index];
        const question = driverAssignmentFields[questionKey] as Field;
        const secondaryQuestionKey = secondaryFieldKeys[index];
        const secondaryQuestion = secondaryDriverAssignmentFields[secondaryQuestionKey] as Field;

        return {
          assignmentType: 'vehicle',
          question,
          secondaryQuestion,
          dropDownOptions: drivers.map((d) => {
            return {
              value: d.ref,
              label: getDriverLabel(d),
            };
          }),
          vehicle,
        };
      });
    }

    /**
     * Otherwise we want to display each driver and allow the user to select a vehicle
     * to assign to that driver.
     */
    return drivers.map((driver) => {
      const filteredAnswers = fieldAnswers.filter((field) => field.value === driver.ref);
      const answerFound = filteredAnswers.length > 0;

      let questionKey = '';

      if (answerFound) {
        questionKey = filteredAnswers[0].key;
      } else {
        for (let i = 0; i < fieldKeys.length; i += 1) {
          if (!driverAssignmentFields[fieldKeys[i]]?.question?.value) {
            questionKey = fieldKeys[i];
            break;
          }
        }
      }

      const question = driverAssignmentFields[questionKey] as Field;
      const secondaryQuestion = secondaryDriverAssignmentFields[questionKey] as Field;

      return {
        assignmentType: 'driver',
        question,
        secondaryQuestion,
        dropDownOptions: vehicles.map((v) => {
          return {
            value: answerFound && questionKey.includes(v.ref || '') ? driver.ref : v.ref || '',
            label: `${v.year} ${v.make} ${v.model}`,
          };
        }),
        driver,
      };
    });
  }, [driverAssignmentFields, vehicles, drivers, secondaryDriverAssignmentFields, fieldAnswers]);

  /**
   * The number of questions present will be equal to the number of vehicles
   * since the question format is vehicle.<id>.blah.blah
   * Regardless of how the UI displays it we really need to just
   * assign a driver to each vehicle.
   * However, in the case where there are more drivers than vehicles there will
   * be fewer questions so 1 or more questions will not get answered
   */
  const lineItems: AssignmentQuestionItem[] = getLineItems();
  const initValues = useRef({});
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues,
    fields: { ...driverAssignmentFields, ...secondaryDriverAssignmentFields },
    /**
     * Each question that we display will need to be required override
     */
    conditions: lineItems.map((item) => {
      return { conditionalFields: [item.question], isRequiredOverride: () => true };
    }),
  });

  const saveDriverAssignment = useCallback(async () => {
    if (validateForm().isValid) {
      await patchFormValues();
      await onNext();
    }
  }, [onNext, patchFormValues, validateForm]);

  const isVehicleType = vehicles.length === drivers.length || drivers.length > vehicles.length;

  const secondaryOperatorExists = secondaryDriverQuestionExists && drivers.length > vehicles.length;

  const handleAssignmentLineItemOnChange = useCallback(
    (value: AnswerValue, field: Field, labelRef: string, reset?: boolean) => {
      if (isVehicleType) {
        field.props.actionOnChange(value);
      } else {
        const fieldKeys = Object.keys(driverAssignmentFields);
        const key = fieldKeys.filter((fkey) => fkey.includes(value as string))[0];

        const vehicleField = driverAssignmentFields[key] as Field;

        // we must remove the answer from any previous fields
        fieldKeys.forEach((fkey) => {
          if (fkey !== key) {
            const driverAssignmentField = driverAssignmentFields[fkey] as Field;
            if (driverAssignmentField.value === labelRef) {
              driverAssignmentField.props.actionOnComplete(null);
            }
          }
        });

        vehicleField?.props.actionOnChange(labelRef);
      }

      if (!reset) {
        trackClick({ action: 'primary_driver_assignment', label: `<${value}; ${labelRef}>` });
      }
    },
    [driverAssignmentFields, isVehicleType],
  );

  const resetAllItems = useCallback((): void => {
    lineItems.forEach((item) => {
      handleAssignmentLineItemOnChange(
        null,
        item.question,
        item.assignmentType === 'driver' ? item.driver.ref : item.vehicle.ref || '',
        true,
      );
      if (item.secondaryQuestion && item.secondaryQuestion.exists) {
        handleAssignmentLineItemOnChange(
          null,
          item.secondaryQuestion,
          item.assignmentType === 'driver' ? item.driver.ref : item.vehicle.ref || '',
          true,
        );
      }
    });
  }, [handleAssignmentLineItemOnChange, lineItems]);

  const goBack = useCallback(async () => {
    await patchFormValues();
    await onBack();
  }, [onBack, patchFormValues]);

  // This logic is for the case vehicles > drivers since we have to use vehicle questions
  // for more details see CPUI-5379
  const uniqueValues = new Set(lineItems.map((lineItem) => lineItem.question?.key));
  const duplicates = uniqueValues.size < lineItems.length;

  //  remove vehicle primary driver ref for the deleted driver
  useEffect(() => {
    Object.keys(driverAssignmentFields).forEach((key) => {
      const question = driverAssignmentFields[key] as Field;
      if (question.value) {
        const exist = drivers.some((driver) => driver.ref === question.value);
        if (!exist) {
          question.validateUpdateAndPatch(null);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Object.keys(secondaryDriverAssignmentFields).forEach((key) => {
      const question = secondaryDriverAssignmentFields[key] as Field;
      if (question && question.value) {
        const exist = drivers.some((driver) => driver.ref === question.value);
        if (!secondaryOperatorExists || !exist) {
          question.validateUpdateAndPatch(null);
        }
      }
    });
  }, [driverAssignmentFields, drivers, secondaryDriverAssignmentFields, secondaryOperatorExists]);

  return (
    <div className={classes.root}>
      <Form>
        <Grid container>
          <Grid item xs={12}>
            <h2>Assign primary driver</h2>
            <p className={classes.description}>Select the person who drives the vehicle the most</p>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.card}>
              {!isMobile && (
                <Grid container spacing='30px'>
                  <Grid item xs={secondaryOperatorExists ? 4 : 6}>
                    <p className={classes.cardHeader}>
                      {isVehicleType ? 'Primary driver' : 'Primary vehicle'}
                    </p>
                  </Grid>
                  {secondaryOperatorExists && (
                    <Grid item xs={4}>
                      <p className={classes.cardHeader}>Secondary Driver</p>
                    </Grid>
                  )}
                  <Grid item xs={secondaryOperatorExists ? 4 : 6}>
                    <p className={classes.cardHeader}>{isVehicleType ? 'Vehicle' : 'Driver'}</p>
                  </Grid>
                </Grid>
              )}
              {lineItems.map((item, index) => {
                const getLabelArgs: GetLabelArgs =
                  item.assignmentType === 'driver'
                    ? ['driver', item.driver]
                    : ['vehicle', item.vehicle];

                return (
                  <Fragment key={index + 1}>
                    {index > 0 && <Divider aria-hidden='true' className={classes.divider} />}
                    {isMobile && (
                      <Grid item xs={12}>
                        <p className={classes.cardHeader}>
                          {isVehicleType ? 'Primary driver' : 'Primary vehicle'}
                          <span className={classes.cardHeaderSub}>{getLabel(...getLabelArgs)}</span>
                        </p>
                      </Grid>
                    )}
                    <AssignmentLineItem
                      dropDownItems={item.dropDownOptions}
                      field={item.question}
                      showSecondaryOperator={secondaryOperatorExists}
                      secondaryField={item.secondaryQuestion}
                      answers={fieldAnswers}
                      secondaryFieldAnswers={secondaryFieldAnswers}
                      label={getLabel(...getLabelArgs)}
                      labelRef={
                        item.assignmentType === 'driver' ? item.driver.ref : item.vehicle.ref
                      }
                      mismatchedValues={!isVehicleType}
                      actionOnChange={handleAssignmentLineItemOnChange}
                      index={index}
                    />
                  </Fragment>
                );
              })}

              <Button
                data-testid='resetPrimaryDriversButton'
                variant='iconText'
                onClick={resetAllItems}
                color='primary'
                className={classes.actionButton}
                trackingName='reset_assigned_drivers_link'
                trackingLabel='reset'
                analyticsElement='choice.driverAssignment.resetPrimaryDriversButton'
                isProcessing={isPatchFormInProgress}
              >
                <ResetIcon className={classes.resetIcon} />
                Reset all assigned {isVehicleType ? 'drivers' : 'vehicles'}
              </Button>
            </div>
          </Grid>
          <Grid item xs={12}>
            <NextPageInstructions divider>{nextPageInstructions}</NextPageInstructions>
          </Grid>
          <Grid container columnSpacing='30px'>
            <Grid item sm={12} md='auto'>
              <Button
                className={classes.button}
                data-testid='addDriverOrVehicle'
                variant='outlinePrimary'
                onClick={goBack}
                trackingName='add_driver_vehicle_button'
                trackingLabel='add_driver_vehicle'
                analyticsElement='choice.driverAssignmentPage.addDriverOrVehicle'
                disabled={isPatchFormInProgress}
              >
                Add Drivers/Vehicles
              </Button>
            </Grid>
            <Grid item sm={12} md='auto'>
              <Button
                className={classes.button}
                data-testid='saveAndContinue'
                variant='primary'
                onClick={saveDriverAssignment}
                trackingName='save_and_continue'
                trackingLabel='auto_assignment_continue'
                isProcessing={isPatchFormInProgress}
                disabled={
                  lineItems.filter((lineItem) => !lineItem.question?.value).length > 0 || duplicates
                }
                analyticsElement='choice.driverAssignmentPage.saveAndContinueButton'
                analyticsEventDetail={sapiAnalyticsOfferEventDetail}
              >
                Save & continue
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};
