export const partner = {
  shared: {
    partnerDisplayName: 'Zillow Insurance Services',
    salesEmailQuotesAddress: 'servicingrevolution@homesite.com',
    salesPhoneHours: 'Mon-Fri: 8:00am - 11:00pm ET, Sat-Sun: 9:00am - 6:00pm ET',
    servicePhoneHours: 'Mon-Fri: 8:00AM - 11:00PM ET, Sat: 9:00AM - 8:00PM ET, Sun: Closed',
    chatHours: 'Mon-Fri: 8:00am - 11:00pm ET, Sat-Sun: 9:00am - 6:00pm ET',
    qnbPhoneNumber: '1-833-854-1189',
    dnqPhoneNumber: '1-833-854-1189',
    salesPhoneNumber: '1-833-854-1189',
    servicePhoneNumber: '1-833-854-1189',
    agentFromEmailAddress: 'donotreply@email.homesite.com',
    agentFromEmailName: 'Zillow Insurance Services',
    dnqEmail: '',
  },
  lobDetail: { AUTO: { dnqNumbers: {}, discount: {} } },
} as const;
