import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Grid } from '@mui/material';

import { TooltipWithIcon } from '@ecp/components';
import { env } from '@ecp/env';
import { useAddConditionValues, useAddFields } from '@ecp/features/sales/form';
import { CheckboxGroup, CheckboxNoPatchGroup } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import {
  convertFieldsValueToPatch,
  keysTobecomparedFromFields,
  useField,
  useMultiFieldsForCheckBoxGroup,
  useMultiFieldsForNoPatchCheckBoxGroup,
} from '@ecp/features/sales/shared/store';
import type { Answers } from '@ecp/features/sales/shared/types';
import type { Field } from '@ecp/types';

import { antiTheftDiscountsOptions } from '../../../../metadata';
import { useStyles } from './AntiTheftAdditionalQuestion.styles';

interface Props extends QuestionProps {
  vehicleRef: string;
  antiTheft: Field;
  isContinueClicked: boolean;
  variant?: 'discount';
  setCheckboxGroupValuetoBePatched: (value: Answers) => void;
  originalCheckboxGroupValue: Answers;
}

const ANTITHEFT_HELP_TEXT =
  'You may qualify for a discount if your vehicle is equipped with at least one or more active or passive anti-theft devices.';

export const AntiTheftAdditionalQuestion: React.FC<Props> = (props) => {
  const {
    vehicleRef,
    antiTheft,
    trackingName = 'anti_theft_devices_multiselect',
    isContinueClicked,
    variant,
    setCheckboxGroupValuetoBePatched,
    originalCheckboxGroupValue,
  } = props;

  const { classes, cx } = useStyles();
  const antiTheftActive = useField(`${vehicleRef}.features.antitheft.active`);
  const antiTheftPassive = useField(`${vehicleRef}.features.antitheft.passive`);
  const antiTheftAlarm = useField(`${vehicleRef}.features.antitheft.alarm`);
  const antiTheftVRS = useField(`${vehicleRef}.features.antitheft.vehicleRecoverySystem`);
  const antiTheftWindowEtching = useField(`${vehicleRef}.features.antitheft.windowEtching`);
  const antiTheftCategory1 = useField(`${vehicleRef}.features.antitheft.category1`);
  const antiTheftCategory2 = useField(`${vehicleRef}.features.antitheft.category2`);
  const antiTheftCategory3 = useField(`${vehicleRef}.features.antitheft.category3`);
  const antiTheftCategory4 = useField(`${vehicleRef}.features.antitheft.category4`);
  const antiTheftFieldOptions = useField(`${vehicleRef}.features.antitheftOptions`);

  const [, updateState] = useState({});
  const forceUpdateParentComponent = useCallback(() => updateState({}), []);

  const antiTheftFeatures = [
    antiTheftActive,
    antiTheftPassive,
    antiTheftAlarm,
    antiTheftVRS,
    antiTheftWindowEtching,
    antiTheftCategory1,
    antiTheftCategory2,
    antiTheftCategory3,
    antiTheftCategory4,
  ];
  const antiTheftFieldOptionsLabels = antiTheftFieldOptions.props.options?.map((option) =>
    option.label.toString().toLowerCase().replaceAll(' ', ''),
  );

  // If options available from DAL, filter options with it. This is V4 related change and driven based on question existence
  let antiTheftOptions = antiTheftFieldOptions.exists
    ? antiTheftDiscountsOptions.filter((option) =>
        antiTheftFieldOptionsLabels?.includes(option.fieldName.toLowerCase()),
      )
    : antiTheftDiscountsOptions;
  antiTheftFeatures.forEach((feature) => {
    if (!feature.exists) {
      antiTheftOptions = antiTheftOptions.filter(
        (option) => option.fieldName !== feature.key.substr(feature.key.lastIndexOf('.') + 1),
      );
    }
  });
  const antiTheftFeaturesFiltered = antiTheftFeatures.filter((feature) => feature.exists);
  const actualDisplayedArray: string[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const antiTheftFeatureFieldsTobecompared = antiTheftFeaturesFiltered.map((i: any) =>
    Object.fromEntries(keysTobecomparedFromFields.map((f) => [f, i[f]])),
  );
  if (env.static.isAgent) {
    antiTheftFeaturesFiltered.forEach(function (item) {
      if (item.value === true)
        actualDisplayedArray.push(item.key.split(`${vehicleRef}.`).pop() as string);
    });
  }
  const checkboxGroupValuetoBePatch = useMemo(
    () => convertFieldsValueToPatch(antiTheftFeatureFieldsTobecompared),
    [antiTheftFeatureFieldsTobecompared],
  );
  const prevStatusRef = useRef<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [k: string]: any;
  }>(antiTheftFeatureFieldsTobecompared);

  const antiTheftFeaturePropsForAgent = useMultiFieldsForNoPatchCheckBoxGroup(
    antiTheftFeaturesFiltered,
    antiTheftOptions,
    actualDisplayedArray,
  );

  const antiTheftFeaturePropsForSales = useMultiFieldsForCheckBoxGroup(
    antiTheftFeaturesFiltered,
    antiTheftOptions,
  );

  const antiTheftFeatureProps = env.static.isAgent
    ? antiTheftFeaturePropsForAgent
    : antiTheftFeaturePropsForSales;

  const antiTheftErrorText =
    isContinueClicked &&
    antiTheft.exists &&
    antiTheft.value &&
    !(
      antiTheftActive.value ||
      antiTheftPassive.value ||
      antiTheftAlarm.value ||
      antiTheftVRS.value ||
      antiTheftWindowEtching.value ||
      antiTheftCategory1.value ||
      antiTheftCategory2.value ||
      antiTheftCategory3.value ||
      antiTheftCategory4.value
    )
      ? 'Select at least one device'
      : undefined;

  useEffect(() => {
    if (antiTheft.exists && !antiTheft.value) {
      antiTheftFeatureProps.values.forEach((value) =>
        antiTheftFeatureProps.actionOnComplete(value, value),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [antiTheftFeatureProps.values, antiTheft.exists, antiTheft.value]);

  useEffect(() => {
    if (
      JSON.stringify(checkboxGroupValuetoBePatch) !== JSON.stringify(originalCheckboxGroupValue)
    ) {
      setCheckboxGroupValuetoBePatched(checkboxGroupValuetoBePatch);
    }
  }, [setCheckboxGroupValuetoBePatched, checkboxGroupValuetoBePatch, originalCheckboxGroupValue]);

  useAddFields({
    [`${antiTheftActive.key}`]: antiTheftActive,
    [`${antiTheftPassive.key}`]: antiTheftPassive,
    [`${antiTheftAlarm.key}`]: antiTheftAlarm,
    [`${antiTheftVRS.key}`]: antiTheftVRS,
    [`${antiTheftWindowEtching.key}`]: antiTheftWindowEtching,
    [`${antiTheftCategory1.key}`]: antiTheftCategory1,
    [`${antiTheftCategory2.key}`]: antiTheftCategory2,
    [`${antiTheftCategory3.key}`]: antiTheftCategory3,
    [`${antiTheftCategory4.key}`]: antiTheftCategory4,
  });

  const field = antiTheftActive.exists ? antiTheftActive : antiTheftCategory1;
  // we only need to check one of the fields to prevent form from submitting
  useAddConditionValues({
    conditionalFields: [field],
    isExcluded: () =>
      !!antiTheftActive.value ||
      !!antiTheftPassive.value ||
      !!antiTheftAlarm.value ||
      !!antiTheftVRS.value ||
      !!antiTheftWindowEtching.value ||
      !!antiTheftCategory1.value ||
      !!antiTheftCategory2.value ||
      !!antiTheftCategory3.value ||
      !!antiTheftCategory4.value,
    isRequiredOverride: () => antiTheft.value === true,
  });

  if (!antiTheft.value) return null;

  const antiTheftAdditionalQuestions = env.static.isAgent ? (
    <CheckboxNoPatchGroup
      variant='classicCompact'
      options={antiTheftOptions}
      label={
        <p className={cx(classes.childLabel)}>
          Which anti-theft devices?
          {antiTheftActive.exists && <TooltipWithIcon title={ANTITHEFT_HELP_TEXT} />}
        </p>
      }
      {...antiTheftFeatureProps}
      error={antiTheftErrorText}
      trackingName={trackingName}
      prevStatusRef={prevStatusRef}
      forceUpdateParentComponent={forceUpdateParentComponent}
    />
  ) : (
    <CheckboxGroup
      variant='classicCompact'
      options={antiTheftOptions}
      label={
        <p className={cx(classes.childLabel)}>
          Which anti-theft devices?
          {antiTheftActive.exists && <TooltipWithIcon title={ANTITHEFT_HELP_TEXT} />}
        </p>
      }
      {...antiTheftFeatureProps}
      error={antiTheftErrorText}
      trackingName={trackingName}
    />
  );

  if (variant === 'discount')
    return (
      <Grid item xs={12} md={6} className={classes.questionItem}>
        {antiTheftAdditionalQuestions}
      </Grid>
    );
  else return antiTheftAdditionalQuestions;
};
