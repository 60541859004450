import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AntiTheftDevicesDialog' })((theme) => ({
  root: {
    '& ol': { paddingLeft: 25 },
    '& ul': { paddingLeft: 15 },
    '& form': { paddingRight: 20 },
    '& .MuiTypography-h2': {
      ...theme.typography.h5,
      paddingTop: 20,
    },
  },
}));
