import type { Selector } from '@reduxjs/toolkit';
import { createSelector } from '@reduxjs/toolkit';

import { emptyObject } from '@ecp/utils/common';

import { getField, questionExists, selectFieldWithPrefix } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Driver, Vehicle, VehicleDiscounts } from '@ecp/features/sales/shared/types';
import type { CardOption, Field, Fields } from '@ecp/types';

import type { AutoDiscounts } from '../../../forms';
import { driverDiscountsOptions, vehicleDiscountsOptions } from '../../../metadata';

export const useGetDiscountsFields = (options: CardOption[]): Fields => {
  const dispatch = useDispatch();

  const selectors = options.map((option) => (state: RootStore) => {
    const f = getField(state, { key: `discount.auto.${option.value}`, dispatch });

    return f;
  });

  const fieldsSelector = createSelector(selectors, (...fields) => {
    return fields.reduce((acc, field) => {
      if (!field.exists) return acc;
      acc[field.key] = field;

      return acc;
    }, {} as Fields);
  });

  return useSelector(fieldsSelector);
};

export const useVehicleDiscountsByKey = (
  vehicles: Vehicle[],
  discountKey: string,
): VehicleDiscounts => {
  const dispatch = useDispatch();

  const selectors = vehicles.map((vehicle) =>
    selectFieldWithPrefix(
      dispatch,
      `${vehicle.ref}.discount`,
      'vehicle.<id>.discount',
    )(discountKey),
  );

  const isDiscountExists = useSelector((state: RootStore) =>
    questionExists(`vehicle.<id>.discount.${discountKey}`)(state),
  );

  const combiner = (...res: Field[]): VehicleDiscounts => {
    if (!isDiscountExists) return emptyObject;
    const discounts: VehicleDiscounts = {};
    for (let i = 0; i < res.length; i += 1) {
      discounts[vehicles[i].ref] = { [discountKey]: res[i] };
    }

    return discounts;
  };

  const mySelector = createSelector(selectors, combiner);

  return useSelector(mySelector);
};

export const useVehicleDiscounts = (vehicles: Vehicle[]): AutoDiscounts => {
  const dispatch = useDispatch();

  const selectors = vehicles.map((vehicle) => {
    const makeDiscountSelector = selectFieldWithPrefix(
      dispatch,
      `${vehicle.ref}.discount`,
      'vehicle.<id>.discount',
    );
    const makeSafetySelector = selectFieldWithPrefix(
      dispatch,
      `${vehicle.ref}.features`,
      'vehicle.<id>.features',
    );
    const makeAntiTheftSelector = selectFieldWithPrefix(
      dispatch,
      `${vehicle.ref}.features`,
      'vehicle.<id>.features',
    );
    const selectorValues: Selector<RootStore, Field>[] = vehicleDiscountsOptions.map((discount) => {
      if (discount.value.includes('antitheft')) {
        return makeAntiTheftSelector(discount.fieldName);
      }
      if (discount.value.includes('discount')) {
        return makeDiscountSelector(discount.fieldName);
      }

      return makeSafetySelector(discount.fieldName);
    });

    return createSelector(selectorValues, (...res: Field[]): Field => {
      return res.reduce(
        (acc, cur) => ({ ...acc, [cur.key.substring(cur.key.lastIndexOf('.') + 1)]: cur }),
        {} as Field,
      );
    });
  });

  const combiner = (...res: Field[]): AutoDiscounts => {
    const discounts: AutoDiscounts = {};
    for (let i = 0; i < res.length; i += 1) {
      discounts[vehicles[i].ref] = res[i];
    }

    return discounts;
  };

  const mySelector = createSelector(selectors, combiner);

  return useSelector(mySelector);
};

export const useDriverDiscounts = (drivers: Driver[], stateCode: string): AutoDiscounts => {
  const dispatch = useDispatch();

  const selectors = drivers.map((driver) => {
    const makeSelector = selectFieldWithPrefix(
      dispatch,
      `${driver.ref}.discount`,
      'driver.<id>.discount',
    );
    const selectorValues: Selector<RootStore, Field>[] = driverDiscountsOptions().map(
      (discount) => {
        // use value from stateOption if it exists
        if (discount.stateOptions?.[stateCode]?.value)
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return makeSelector(discount.stateOptions[stateCode].value!);

        return makeSelector(discount.value);
      },
    );

    return createSelector(selectorValues, (...res: Field[]): Field => {
      return res.reduce(
        (acc, cur) => ({ ...acc, [cur.key.substring(cur.key.lastIndexOf('.') + 1)]: cur }),
        {} as Field,
      );
    });
  });

  const combiner = (...res: Field[]): AutoDiscounts => {
    const discounts: AutoDiscounts = {};
    for (let i = 0; i < res.length; i += 1) {
      discounts[drivers[i].ref] = res[i];
    }

    return discounts;
  };

  const mySelector = createSelector(selectors, combiner);

  return useSelector(mySelector);
};
