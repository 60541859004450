import { useCallback, useEffect } from 'react';

import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import { DERIVED_DISCOUNT_HOME, EXISTING_HOME_PRODUCT } from '@ecp/features/sales/shared/constants';
import {
  getHomeProduct,
  getLineOfBusiness,
  getShownFields,
  useField,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue } from '@ecp/features/sales/shared/types';
import { LineOfBusiness } from '@ecp/features/shared/product';
import { partner } from '@ecp/partners';
import type { Fields } from '@ecp/types';

import metadata from './metadata';

// HSECP-995 existingHomeProduct question would only work with SAPI v3 and it's incorrect approach actually
// In SAPI v4 scenario DAL uses static.derived.discount.auto/home/etc. answer directly
// So removed duplicate answer static.hasExistingHomeProduct and used static.derived.discount.home instead
// TODO - Remove usage of existingHomeProduct, existingAutoProduct usages in ECP UI once we retire v3
export const HasExistingHomeProductQuestion: React.FC = () => {
  const homeProduct = useSelector(getHomeProduct);
  const lineOfBusiness = useSelector(getLineOfBusiness);
  const existingHomeProduct = useField(EXISTING_HOME_PRODUCT);
  const derivedDiscountHome = useField(DERIVED_DISCOUNT_HOME);
  const dispatch = useDispatch();
  const handleHasExistingHomeProductChange = useCallback(
    (value: AnswerValue) => {
      if (value) {
        existingHomeProduct.validateUpdateAndPatch(homeProduct);
      } else {
        existingHomeProduct.validateUpdateAndPatch(null);
      }
      derivedDiscountHome.validateUpdateAndPatch(value);
    },
    [derivedDiscountHome, existingHomeProduct, homeProduct],
  );

  const filteredFields = dispatch(
    getShownFields({ fields: { [existingHomeProduct.key]: existingHomeProduct } }),
  ) as Fields;

  const showQuestion =
    lineOfBusiness === LineOfBusiness.AUTO &&
    existingHomeProduct.exists &&
    filteredFields[existingHomeProduct.key];

  useEffect(() => {
    if (!showQuestion) {
      if (existingHomeProduct.value) existingHomeProduct.validateAndUpdate(null);
      if (derivedDiscountHome.value) derivedDiscountHome.validateAndUpdate(null);
    }
  }, [derivedDiscountHome, existingHomeProduct, showQuestion]);

  if (!showQuestion) return null;

  return (
    <RadioGroupWithOptions
      {...derivedDiscountHome.props}
      label={`${metadata.labelStart}${
        metadata.partnerDisplayNameOverride || partner.shared.partnerDisplayName
      }? (optional)`}
      helperText={metadata.helperText}
      id='HasExistingHomeProduct'
      variant='yesNoButton'
      actionOnComplete={handleHasExistingHomeProductChange}
      trackingName='current_home_insurance_selection'
      trackingLabel={derivedDiscountHome.props.value}
    />
  );
};
