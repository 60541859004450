/** Experience/Partner id to partner name mapping. */
export const mapping = {
  /** Non-existent partner. Used only for local dev purposes, tests, storybook. */
  '0000': 'base',
  // Partner ids
  '559': 'costco',
  '642': 'amfam-excl',
  '536': 'gmi',
  '534': 'connect',
  '540': 'ameriprise-direct',
  '660': 'lemonade',
  // Experience ids
  '2811': 'costco',
  '2812': 'costco',
  '2813': 'costco',
  '7089': 'connect-agent',
  '7095': 'costco',
  '7146': 'gmi', // TODO TO BE RETIRED AFTER SERVICING SWAPS GMI EXP ID ON THEIR SIDE
  '7192': 'costco',
  '7310': 'connect',
  '7312': 'connect',
  '7313': 'connect',
  '7314': 'connect',
  '7317': 'gmi',
  '7318': 'gmi',
  '7319': 'gmi',
  '7320': 'gmi',
  '7148': 'gmi',
  '8': 'progressive',
  '1600': 'homesite-agent',
  '2795': 'amfam-excl',
  '1237': 'amfam-excl',
  '2860': 'amfam-adv',
  '2844': 'zillow',
  '2845': 'zillow',
  '1263': 'zillow',
  '2874': 'zillow',
  '2808': 'ameriprise-direct',
  '2809': 'ameriprise-direct',
  '2810': 'ameriprise-direct',
  '7168': 'ameriprise-direct',
  '2805': 'ameriprise-referral',
  '2806': 'ameriprise-referral',
  '2807': 'ameriprise-referral',
  '7165': 'ameriprise-referral',
  '2853': 'progressive',
  '2854': 'progressive',
  '2870': 'homesite',
  '2871': 'homesite',
  '1198': 'homesite',
  '2877': 'covermystuff',
  '2878': 'covermystuff',
  '1228': 'covermystuff',
  // Mock experience ids
  '9999': 'connect', // test exp id to enable mocks for connect-boost initiative
  '10002': 'connect', // test exp id to enable mocks for connect-boost initiative
  '7328': 'costco-midvale',
  '7329': 'costco-midvale',
  '7330': 'costco-midvale',
} as const;

// The parent agent experiences are not used for overrides.  This is only used as an optimizely attribute
export const opcoMapping = {
  '0000': 'base',
  // Partner ids
  '559': 'connect-opco',
  '642': 'connect-opco',
  '536': 'homesite-opco',
  '534': 'connect-opco',
  '540': 'connect-opco',
  '660': 'homesite-opco',
  // Experience ids
  '2811': 'connect-opco',
  '2812': 'connect-opco',
  '2813': 'connect-opco',
  '7089': 'connect-opco-agent', // parent agent experience
  '7095': 'connect-opco',
  '7146': 'homesite-opco',
  '7192': 'connect-opco',
  '7310': 'connect-opco',
  '7312': 'connect-opco',
  '7313': 'connect-opco',
  '7314': 'connect-opco',
  '7317': 'homesite-opco',
  '7318': 'homesite-opco',
  '7319': 'homesite-opco',
  '7148': 'homesite-opco',
  '7320': 'homesite-opco-agent',
  '1600': 'homesite-opco-agent', // parent agent experience
  '8': 'homesite-opco',
  '2795': 'connect-opco',
  '1237': 'connect-opco',
  '2844': 'homesite-opco',
  '2874': 'homesite-opco',
  '2845': 'homesite-opco',
  '1263': 'homesite-opco',
  '2860': 'amfam-opco',
  '2808': 'connect-opco',
  '2809': 'connect-opco',
  '2810': 'connect-opco',
  '7168': 'connect-opco',
  '2805': 'connect-opco',
  '2806': 'connect-opco',
  '2807': 'connect-opco',
  '7165': 'connect-opco',
  '2853': 'homesite-opco',
  '2854': 'homesite-opco',
  '2870': 'homesite-opco',
  '2871': 'homesite-opco',
  '1198': 'homesite-opco',
  '2877': 'homesite-opco',
  '2878': 'homesite-opco',
  '1228': 'homesite-opco',
  // Mock experience ids
  '9999': 'connect-opco', // test exp id to enable mocks for connect-boost initiative
  '10002': 'connect-opco', // test exp id to enable mocks for connect-boost initiative
  '7328': 'amfam-opco',
  '7329': 'amfam-opco',
  '7330': 'amfam-opco',
} as const;

export const productMapping = {
  '0000': ['amfam-auto', 'homesite-home'],
  // Partner ids
  '559': ['connect-auto', 'homesite-home', 'homesite-renters'],
  '642': ['connect-auto', 'homesite-home'],
  '536': ['amfam-auto', 'homesite-home'],
  '534': ['connect-auto', 'homesite-home', 'homesite-renters'],
  '540': ['connect-auto', 'homesite-home', 'homesite-renters'],
  '660': ['homesite-home'],
  // Experience ids
  '2811': ['connect-auto', 'homesite-home'],
  '2812': ['connect-auto', 'homesite-home', 'homesite-renters'],
  '2813': ['connect-auto', 'homesite-home', 'homesite-renters'],
  '7089': [],
  '7095': ['connect-auto', 'homesite-home', 'homesite-renters'],
  '7146': ['amfam-auto', 'homesite-home'],
  '7192': ['connect-auto', 'homesite-home', 'homesite-renters'],
  '7310': ['connect-auto', 'homesite-home', 'homesite-renters'],
  '7312': ['connect-auto', 'homesite-home', 'homesite-renters'],
  '7313': ['connect-auto', 'homesite-home', 'homesite-renters'],
  '7314': ['connect-auto', 'homesite-home', 'homesite-renters'],
  '7317': ['amfam-auto', 'homesite-home'],
  '7318': ['amfam-auto', 'homesite-home'],
  '7319': ['amfam-auto', 'homesite-home'],
  '7320': ['amfam-auto', 'homesite-home'],
  '7148': ['homesite-home'],
  '1600': [],
  '8': [],
  '2795': ['connect-auto', 'homesite-home'],
  '1237': ['connect-auto', 'homesite-home'],
  '2844': ['homesite-renters', 'homesite-home'],
  '2845': ['homesite-renters', 'homesite-home'],
  '2874': ['homesite-renters'],
  '1263': ['homesite-renters'],
  '2860': ['amfam-adv-auto', 'amfam-adv-home', 'amfam-adv-renters'],
  '2808': ['connect-auto', 'homesite-home', 'homesite-renters'],
  '2809': ['connect-auto', 'homesite-home', 'homesite-renters'],
  '2810': ['connect-auto', 'homesite-home', 'homesite-renters'],
  '7168': ['connect-auto', 'homesite-home', 'homesite-renters'],
  '2805': ['connect-auto', 'homesite-home', 'homesite-renters'],
  '2806': ['connect-auto', 'homesite-home', 'homesite-renters'],
  '2807': ['connect-auto', 'homesite-home', 'homesite-renters'],
  '7165': ['connect-auto', 'homesite-home', 'homesite-renters'],
  '2853': ['homesite-home', 'homesite-renters'],
  '2854': ['homesite-home', 'homesite-renters'],
  '2870': ['homesite-home', 'homesite-renters'],
  '2871': ['homesite-home', 'homesite-renters'],
  '1198': ['homesite-home', 'homesite-renters'],
  '2877': ['homesite-renters'],
  '2878': ['homesite-renters'],
  '1228': ['homesite-renters'],
  // Mock experience ids
  '9999': ['connect-boost', 'homesite-home', 'homesite-renters'], // test exp id to enable mocks for connect-boost initiative
  '10002': ['connect-boost', 'homesite-home', 'homesite-renters'], // test exp id to enable mocks for connect-boost initiative
  '7328': ['amfam-auto'],
  '7329': ['amfam-auto'],
  '7330': ['amfam-auto'],
} as const;

// TODO:  All of these structures will be refactored as part of https://theexperimentationlab.atlassian.net/browse/ECP-2306 to make them cleaner and easier to understand
// these mappings are top level priority and will be checked before any other overrides
export const overrideExceptionMapping = {
  '0000': undefined,
  // Partner ids
  '559': undefined,
  '642': undefined,
  '536': undefined,
  '534': undefined,
  '540': undefined,
  '660': undefined,
  // Experience ids
  '2811': undefined,
  '2812': undefined,
  '2813': undefined,
  '7089': undefined,
  '7095': undefined,
  '7146': undefined,
  '7192': undefined,
  '7310': undefined,
  '7312': undefined,
  '7313': undefined,
  '7314': undefined,
  '7317': undefined,
  '7318': undefined,
  '7319': undefined,
  '7320': undefined,
  '7148': undefined,
  '1600': undefined,
  '8': undefined,
  '2795': 'amfam-excl-agent-amfam-excl', // this is an exception that applies to the amfam-excl partner within the amfam-excl-agent application
  '1237': 'homesite-agent-amfam-excl', // this is an exception applies to amfam-excl partner within the homesite-agent application
  '2844': undefined,
  '2845': undefined,
  '2874': undefined,
  '1263': undefined,
  '2860': undefined,
  '2808': undefined,
  '2809': undefined,
  '2810': undefined,
  '7168': undefined,
  '2805': undefined,
  '2806': undefined,
  '2807': undefined,
  '7165': undefined,
  '2853': undefined,
  '2854': undefined,
  '2870': undefined,
  '2871': undefined,
  '1198': undefined,
  '2877': undefined,
  '2878': undefined,
  '1228': undefined,
  // Mock experience ids
  '9999': 'connect-boost', // test exp id to enable mocks for connect-boost initiative
  '10002': 'connect-boost', // test exp id to enable mocks for connect-boost initiative
  '7328': undefined,
  '7329': undefined,
  '7330': undefined,
} as const;

export type ExperienceId = keyof typeof mapping;
export type PartnerName = (typeof mapping)[ExperienceId] | 'mock';

export type Products = (typeof productMapping)[ExperienceId];
export type OpCoName = (typeof opcoMapping)[ExperienceId];
export type OverrideException = (typeof overrideExceptionMapping)[ExperienceId];
