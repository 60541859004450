import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import { useAddConditionValues } from '@ecp/features/sales/form';
import { PRIMARY_INSURED_PERSON_LOCK } from '@ecp/features/sales/shared/constants';
import {
  DateOfBirthQuestion,
  EmailQuestion,
  FirstNameQuestion,
  LastNameQuestion,
  MiddleNameQuestion,
  SuffixQuestion,
} from '@ecp/features/sales/shared/questions';
import { useField, useFieldWithPrefix, usePniRef } from '@ecp/features/sales/shared/store';
import { useIsMobile } from '@ecp/themes/base';

import { useStyles } from '../LandingPageForm.styles';
import metadata from './metadata';

interface PersonQuestionsProps {
  disabled?: boolean;
}

export const PersonQuestions: React.FC<PersonQuestionsProps> = (props) => {
  const { disabled } = props;
  const { classes } = useStyles();
  const isMobile = useIsMobile();

  const pniRef = usePniRef();
  const usePersonField = useFieldWithPrefix(pniRef, 'person.<id>');
  const primaryInsuredPersonLock = useField(PRIMARY_INSURED_PERSON_LOCK);

  useAddConditionValues({
    conditionalFields: [usePersonField('dob'), usePersonField('email')],
    isExcluded: () => metadata.hideDobEmailQuestionSection,
    isRequiredOverride: () => true,
  });

  return (
    <div>
      <h2 className={classes.subtitle}>Personal information</h2>
      <Grid container>
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
          <FirstNameQuestion
            personRef={pniRef}
            disabled={!!primaryInsuredPersonLock.props.value || !!disabled}
          />
        </GridItem>
        {metadata.showMiddleAndSuffixSection && (
          <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
            <MiddleNameQuestion
              personRef={pniRef}
              disabled={!!primaryInsuredPersonLock.props.value}
            />
          </GridItem>
        )}
        <GridItem
          topSpacing={isMobile ? 'md' : 'sm'}
          xs={12}
          md={6}
          className={metadata.showMiddleAndSuffixSection ? classes.columnLeft : classes.columnRight}
        >
          <LastNameQuestion
            personRef={pniRef}
            disabled={!!primaryInsuredPersonLock.props.value || !!disabled}
          />
        </GridItem>
        {metadata.showMiddleAndSuffixSection && (
          <GridItem
            topSpacing={isMobile ? 'md' : 'sm'}
            xs={12}
            md={6}
            className={classes.columnRight}
          >
            <SuffixQuestion personRef={pniRef} disabled={!!primaryInsuredPersonLock.props.value} />
          </GridItem>
        )}
        {!metadata.hideDobEmailQuestionSection && (
          <>
            <GridItem topSpacing='md' xs={12} md={6} className={classes.columnLeft}>
              <DateOfBirthQuestion
                personRef={pniRef}
                disabled={!!primaryInsuredPersonLock.props.value || !!disabled}
              />
            </GridItem>
            <GridItem topSpacing='md' xs={12} md={6} className={classes.columnRight}>
              <EmailQuestion
                personRef={pniRef}
                disabled={!!primaryInsuredPersonLock.props.value || !!disabled}
              />
            </GridItem>
          </>
        )}
      </Grid>
    </div>
  );
};
