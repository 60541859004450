import { appVars } from '../appVars';
import { breakpoints } from '../breakpoints';
import { createComponents } from '../components';
import { createMixins } from '../mixins';
import { palette } from '../palette';
import type { Typography } from '../typography';
import { createTypography } from '../typography';
import { createTheme } from '../utils/createTheme';
import type { Theme } from './theme.types';

const initialTheme = createTheme({ breakpoints, palette }, { breakpoints, palette, appVars });

const typography = createTypography(initialTheme.breakpoints);
const themeWithTypography = createTheme(
  { breakpoints, palette, typography },
  { breakpoints, palette, typography, appVars },
);

const mixins = createMixins(
  themeWithTypography.breakpoints,
  themeWithTypography.palette,
  themeWithTypography.typography as Typography,
  themeWithTypography.appVars,
  themeWithTypography.zIndex,
);

const components = createComponents(
  themeWithTypography.breakpoints,
  mixins,
  themeWithTypography.palette,
  themeWithTypography.typography as Typography,
);

export const theme = createTheme(
  { breakpoints, palette, typography, mixins, components },
  { breakpoints, palette, typography, mixins, components, appVars, name: 'base' },
) as Theme;
