export const partner = {
  shared: {
    partnerDisplayName: 'Midvale Home & Auto Insurance Program',
    salesEmailQuotesAddress: 'contactus@midvaleinsurance.com',
    salesPhoneHours: 'Mon-Fri: 8:00am – 11:00pm ET, Sat: 9:30am – 8:00pm ET, Sun: CLOSED',
    servicePhoneHours: 'Monday - Friday 8:00AM - 11:00PM ET, Saturday 9:30AM - 8:00PM ET',
    qnbPhoneNumber: '1-888-404-5365',
    dnqPhoneNumber: '1-888-554-7206',
    salesPhoneNumber: '1-888-404-5365',
    servicePhoneNumber: '1-866-373-0328',
    agentFromEmailAddress: 'donotreply@email.midvaleinsurance.com',
    agentFromEmailName: 'Midvale Home & Auto Insurance Program',
  },
  lobDetail: { AUTO: { dnqNumbers: {}, discount: {} } },
} as const;
