import { useCallback } from 'react';

import { useAddFields } from '@ecp/features/sales/form';
import { CheckboxGroup } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useGetLOBFields, useGetProductFields } from '@ecp/features/sales/shared/store';
import type { LineOfBusiness } from '@ecp/features/shared/product';

import * as metadata from './metadata';
import { getLineOfBusinessFromProducts, getProductsFromLineOfBusiness } from './util';

export const ProductQuestion: React.FC<QuestionProps & { disabled?: boolean }> = (props) => {
  const { disabled } = props;

  const { product } = useGetProductFields();
  const options = metadata.supportedLobOptions.options;
  const values = getProductsFromLineOfBusiness(product.value);

  const fields = useGetLOBFields(options);
  useAddFields({ ...fields, product });

  const actionOnComplete = useCallback(
    async (value: string[]) => {
      const lineOfBusiness = getLineOfBusinessFromProducts(value as LineOfBusiness[]);
      await product.props.actionOnComplete(lineOfBusiness);
    },
    [product.props],
  );

  if (!product.exists && !disabled) return null;

  return (
    <CheckboxGroup
      {...product.props}
      name='lineOfBusiness'
      options={options}
      values={values}
      mutuallyExclusiveOptions={metadata.productMetadata.mutuallyExclusiveOptionsWithStaticPrefix}
      mutuallyDisabledOptions={metadata.productMetadata.mutuallyDisabledOptionsWithStaticPrefix}
      actionOnComplete={actionOnComplete}
      trackingName='LineOfBusinessSelection'
      disabled={disabled}
      variant='horizontalCard'
    />
  );
};
