import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'VehicleProfileForm' })((theme) => ({
  ...theme.mixins.formAll,
  root: theme.mixins.form,
  progress: {
    marginBottom: 10,
  },
  vehicleInfoImageContainer: {
    maxHeight: '89%',
  },
  imageContainer: {
    display: 'flex',
    width: '100%',
    marginLeft: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.grey[200],
    borderRadius: 4,
    [theme.breakpoints.down('lg')]: {
      marginLeft: 0,
      marginBottom: 20,
    },
  },
  buttonsPanel: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  addButton: {
    [theme.breakpoints.up('md')]: {
      marginRight: 15,
    },
  },
  image: {
    width: '75%',
    height: 'auto',
  },
  vehicleFieldsContainer: {
    alignContent: 'baseline',
  },
}));
