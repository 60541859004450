export interface Metadata {
  getLicenseStatusGroupLabel: (possessiveName?: string, ifNameOrYou?: string) => string;
}

const metadata: Metadata = {
  getLicenseStatusGroupLabel: (possessiveName) =>
    `What's ${possessiveName} driver's license status?`,
};

export default metadata;
