import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { IconCardGMEmployeeDiscount } from '@ecp/themes/partners/gmi';

import type { DriverDiscountCardOption } from './AutoSelectDiscounts.metadata.js';
import { driverDiscountsOptions as driverDiscountsOptionsBase } from './AutoSelectDiscounts.metadata.js';

const GM_EMPLOYEE_HELP_TEXT =
  'If you or any additional drivers in your household are an employee of General Motors, GM Financial or any of its subsidiaries, then you may qualify for a special discount.';

export const driverDiscountsOptions = (): DriverDiscountCardOption[] => {
  const baseDriverDiscountsOptions = driverDiscountsOptionsBase();
  const miEmployeeDiscountFeatureFlagValue = flagValues[FeatureFlags.MI_EMPLOYEE_DISCOUNT];

  if (miEmployeeDiscountFeatureFlagValue) {
    return [
      ...baseDriverDiscountsOptions,
      {
        value: 'gmEmployee',
        label: 'I am a General Motors employee',
        icon: <IconCardGMEmployeeDiscount />,
        helpText: GM_EMPLOYEE_HELP_TEXT,
      },
    ];
  }

  return baseDriverDiscountsOptions;
};

export {
  collegeDegreeOptions,
  defensiveDriverOptions,
  discountsDefaultValue,
} from './AutoSelectDiscounts.metadata.js';
