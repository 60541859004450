export interface Metadata {
  allowAgeFirstLicensed: boolean;
  renderLicenseStateQuestion: boolean;
  renderDriverLicenseQuestion: boolean;
  renderRelationshipQuestion: boolean;
}

const metadata: Metadata = {
  allowAgeFirstLicensed: false,
  renderLicenseStateQuestion: false,
  renderDriverLicenseQuestion: false,
  renderRelationshipQuestion: true,
};

export default metadata;
