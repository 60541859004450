import { useCallback, useEffect, useState } from 'react';

import { agentAuth } from '@ecp/utils/auth';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { Button, Modal } from '@ecp/components';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

import { useStyles } from './CallRecordingModal.styles';

interface CallRecordingModal {
  showDialog?: boolean;
}

export const CallRecordingModal: React.FC<CallRecordingModal> = (props) => {
  const { classes } = useStyles();
  const { showDialog = false } = props;
  const callRecordingFeature = flagValues[FeatureFlags.AGENT_CALL_RECORDING];
  const agentGenesysEmail = callRecordingFeature ? agentAuth.getAgentEmail() : null;
  const [showRecordingModal, setShowRecordingModal] = useState(!agentGenesysEmail);

  useEffect(() => {
    if (callRecordingFeature) {
      if (agentGenesysEmail) {
        trackSapiAnalyticsEvent({
          element: 'landingPage.genysysCallRecordingStart',
          event: 'update',
          eventDetail: 'true',
        });
      } else {
        trackSapiAnalyticsEvent({
          element: 'landingPage.genysysCallRecordingPause.modal',
          event: 'render',
          eventDetail: 'true',
        });
      }
    }
  }, [agentGenesysEmail, callRecordingFeature]);

  const handleClose = useCallback(() => {
    setShowRecordingModal(false);
  }, [setShowRecordingModal]);

  if (!showDialog && (!callRecordingFeature || agentGenesysEmail)) {
    return null;
  }

  return (
    <Modal
      title='Attention'
      open={showRecordingModal}
      onClose={handleClose}
      backdrop
      footer={
        <Button variant='primary' onClick={handleClose}>
          Close
        </Button>
      }
    >
      <p>
        <span className={classes.alertMessage}>You must pause/resume manually.</span>
        The call recording feature that automatically pauses and resumes calls during PCI
        discussions is unavailable.
      </p>
    </Modal>
  );
};
