import { useEffect, useState } from 'react';

import { instance as agentAuth } from './agentAuth';

/** Update a component whenever `login` and `logout` events dispatch. */
export const useAgentAuth = (): void => {
  const [, setForceUpdate] = useState(false);
  useEffect(() => {
    const listener = (): void => {
      setForceUpdate(true);
      setTimeout(() => setForceUpdate(false), 0);
    };
    agentAuth.addEventListener('login', listener);
    agentAuth.addEventListener('logout', listener);

    return () => {
      agentAuth.removeEventListener('login', listener);
      agentAuth.removeEventListener('logout', listener);
    };
  }, []);
};
