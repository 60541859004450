import { getTime } from '@ecp/utils/date';
import { sessionStorage } from '@ecp/utils/storage';

import { TokenUtil } from '../token';
import { AuthError } from '../util';
import { fetchAgentTokens, fetchAgentTokensFromRefreshToken } from './api';
import { AgentAuthUtil } from './util';

let instance: AgentAuthUtil;
const initialized = false;

/**
 * Initialize with config values and create AgentAuthUtil instance.
 * Make sure to initialize `storage` before calling this function.
 *
 * @throws {AuthError} Calling this function more than once throws.
 */
const initialize = (agentAuthBaseUrl: string, expId: string): void => {
  if (initialized) throw new AuthError(`${AgentAuthUtil.name} has already been intialized.`);

  const token = new TokenUtil(getTime);
  const fetchAgentTokensApi = fetchAgentTokens(agentAuthBaseUrl, expId);
  const fetchAgentTokensFromRefreshTokenApi = fetchAgentTokensFromRefreshToken(agentAuthBaseUrl);

  instance = new AgentAuthUtil(
    token,
    fetchAgentTokensApi,
    fetchAgentTokensFromRefreshTokenApi,
    sessionStorage,
  );
};

export { initialize, instance };
