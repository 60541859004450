import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'DriverDiscounts' })((theme) => ({
  label: {
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.body3,
  },
  labelClassName: {
    [theme.breakpoints.only('xl')]: {
      width: 181,
    },
    [theme.breakpoints.only('lg')]: {
      width: '160px !important',
    },
  },
  driverDiscountCheckBoxGroup: {
    '&:not(:first-child)': {
      marginTop: 40,
    },
  },
  driverIcon: {
    ...theme.mixins.setColorSvg(theme.palette.background.paper),
    marginRight: 10,
  },
}));
