import { IconCardBusinessOccupation } from '@ecp/themes/base';
import type { CardOption } from '@ecp/types';

import { PrimaryUseOptions as PrimaryUseOptionsBase } from './VehicleQuestion.metadata.js';

export * from './VehicleQuestion.metadata.js';

export const PrimaryUseOptions: CardOption[] = [
  ...PrimaryUseOptionsBase,
  {
    label: 'Antique/Classic/Replica',
    value: 'VEHICLE_USE.PRIMARY.ANTIQUE',
    icon: <IconCardBusinessOccupation />, // TODO- update with antique icon
  },
];
