import type { Metadata } from './metadata.js';
import baseMetadata from './metadata.js';

const metadata: Metadata = {
  ...baseMetadata,
  skipGetAcknowledgement: true,
  skipFetchPostBindQuestions: true,
};

export default metadata;
