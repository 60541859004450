import { getProductNameFromProduct, type Product } from '@ecp/features/shared/product';
import { IconMaterialDesignFileCloudDownload } from '@ecp/themes/base';

import baseMetadata from './metadata.js';

const metadata = {
  ...baseMetadata,
  ProofOfInsurance: {
    default: 'DOWNLOAD PROOF OF INSURANCE',
    processing: 'Generating proof of insurance',
    delayed: 'Still generating proof of insurance',
    error: (product: Product) =>
      `Your proof of insurance isn’t available yet. To download it, access your ${getProductNameFromProduct(
        product,
      )} policy online by creating an account.`,
  },
  BillingReceipt: {
    default: 'DOWNLOAD PAYMENT RECEIPT',
    processing: 'Generating payment receipt',
    delayed: 'Still generating payment receipt',
    error: (product: Product) =>
      `Your payment receipt isn’t available yet. To download it, access your ${getProductNameFromProduct(
        product,
      )} policy online by creating an account.`,
  },
  icon: <IconMaterialDesignFileCloudDownload />,
} as const;

export default metadata;
