import { Card, CardContent, CardHeader, Grid, Link } from '@mui/material';

import { formatDate } from '@ecp/utils/date';

import {
  getFullPniPersonInfo,
  getFullPrimaryInsuredAddressInfo,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { IconUIMap, IconUIZ } from '@ecp/themes/base';

import { useStyles } from './PersonSummaryCard.styles';

export const PersonSummaryCard: React.FC<unknown> = () => {
  const { classes } = useStyles();

  const { firstName, lastName, dateOfBirth, email, phone } = useSelector(getFullPniPersonInfo);
  const { line1, line2, city, state, zipcode, latitude, longitude } = useSelector(
    getFullPrimaryInsuredAddressInfo,
  );

  const displayAddressLine = `${line1}${line2 ? `, ${line2}` : ''}`;
  const displayCityStateZip = `${city}, ${state} ${zipcode}`;

  const googleStreetViewUrl = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${latitude},${longitude}`;
  const zillowUrl = `http://www.zillow.com/homes/${line1},-${city},-${state},-${zipcode}_rb/`;

  return (
    <Card data-testid='personSummaryCard' className={classes.root}>
      <CardHeader classes={{ root: classes.cardHeader }} title={<h3>Personal information</h3>} />
      <CardContent className={classes.cardContent}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <h4 className={classes.cardTitle}>Quoter details</h4>
            <Grid item>
              <p>
                <strong>Name:</strong> {firstName} {lastName}
              </p>
            </Grid>
            <Grid item>
              <p>
                <strong>DOB: </strong> {formatDate(dateOfBirth)}
              </p>
            </Grid>
            <Grid item className={classes.wordOverflow}>
              <p>
                <strong>Email: </strong> {email}
              </p>
            </Grid>
            <Grid item>
              <p>
                <strong>Phone: </strong> {phone}
              </p>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <h4 className={classes.cardTitle}>Property address</h4>
            <Grid item>{displayAddressLine}</Grid>
            <Grid item>{displayCityStateZip}</Grid>
            <Grid container direction='column' className={classes.mapLinks}>
              <Grid item xs={12}>
                <Link href={googleStreetViewUrl} target='_blank' className={classes.mapLink}>
                  <IconUIMap className={classes.mapIcon} />
                  Google street view
                </Link>
              </Grid>
              <Grid>
                <Link href={zillowUrl} target='_blank' className={classes.mapLink}>
                  <IconUIZ className={classes.zillowIcon} />
                  Find property on Zillow
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
