import { useEffect } from 'react';

import { useInView } from 'react-intersection-observer';

import { agentAuth } from '@ecp/utils/auth';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { callRecording } from './utils';

interface Props extends React.PropsWithChildren {
  trackingName: string;
  trackVisibility: boolean;
}

export const CallRecordingOnVisibilityWrapper: React.FC<Props> = ({
  trackingName,
  trackVisibility = true,
  children,
}): React.ReactElement => {
  const { ref, inView } = useInView();
  const callRecordingFeature = flagValues[FeatureFlags.AGENT_CALL_RECORDING];
  const agentGenesysEmail = callRecordingFeature ? agentAuth.getAgentEmail() : null;
  const recordingEvent = inView && trackVisibility;
  const automateRecording = callRecordingFeature && agentGenesysEmail && recordingEvent;

  useEffect(() => {
    if (automateRecording) {
      callRecording('pause', agentGenesysEmail, trackingName);
    }

    return () => {
      if (automateRecording) {
        callRecording('resume', agentGenesysEmail, trackingName);
      }
    };
  }, [agentGenesysEmail, automateRecording, recordingEvent, trackingName]);

  return <div ref={ref}>{children}</div>;
};
