import type { ProductName } from '@ecp/features/shared/product';

import type { AgentScripts } from '../../metadata/types';
import metadata from './index';

export type Language = 'English' | 'Spanish';

export type PostBindProduct =
  | ProductName
  | 'ho3Bundle'
  | 'ho4Bundle'
  | 'ho3MidvaleBundle'
  | 'ho4MidvaleBundle'
  | 'midvaleAuto';

const title = 'Important reminders';

export const postBindStatementsMetadata = (
  product: PostBindProduct,
  autoEffectiveDate?: string,
  propertyEffectiveDate?: string,
  autoForms?: JSX.Element[],
  propertyForms?: JSX.Element[],
): AgentScripts => {
  switch (product) {
    case 'auto':
      return {
        title,
        text: (
          <ul>
            <li>
              We are issuing your policy effective <strong>12:01 AM</strong> on{' '}
              <strong>{autoEffectiveDate}</strong>.
            </li>
            {autoForms ? (
              <>
                <li>
                  We need the following forms from you within <strong>{metadata.auto}</strong> days:
                </li>
                <ul>
                  {autoForms.map((form) => (
                    <li>{form}</li>
                  ))}
                </ul>
                <li>
                  If we do not receive these, it may affect your premium, discounts, coverage
                  limits, or eligibility for coverage.
                </li>
              </>
            ) : (
              <li>
                We must receive any required forms back within <strong>{metadata.auto}</strong>{' '}
                days. If we do not receive these, it may affect your premium, coverage limits, or
                eligibility for coverage.
              </li>
            )}
          </ul>
        ),
        spanishText: (
          <ul>
            <li>
              Su póliza comenzará el <strong>{autoEffectiveDate}</strong> a las{' '}
              <strong>12:01 AM</strong>.
            </li>
            {autoForms ? (
              <>
                <li>
                  Necesitamos que nos envíe los siguientes documentos en un plazo de{' '}
                  <strong>{metadata.auto}</strong> días:
                </li>
                <ul>
                  {autoForms.map((form) => (
                    <li>{form}</li>
                  ))}
                </ul>
                <li>
                  Si no los recibiéramos esto puderia afectar su prima, descuentos, límites de
                  cobertura o derecho a la cobertura.
                </li>
              </>
            ) : (
              <li>
                Debemos recibir los formularios requeridos en un plazo de{' '}
                <strong>{metadata.auto}</strong> días. Si no los recibimos, puede verse afectada la
                prima, los límites de la cobertura o el derecho a la misma.
              </li>
            )}
          </ul>
        ),
      };

    case 'home':
      return {
        title,
        text: (
          <ul>
            <li>
              We are issuing your policy effective <strong>12:01 AM</strong> on{' '}
              <strong>{propertyEffectiveDate}</strong>.
            </li>
            <li>
              We may conduct an inspection of your home to ensure your property is adequately
              insured and acceptable. If any changes come out of this inspection, we will notify
              you.
            </li>
            {propertyForms ? (
              <>
                <li>
                  We need the following forms from you within <strong>{metadata.property}</strong>{' '}
                  days:
                </li>
                <ul>
                  {propertyForms.map((form) => (
                    <li>{form}</li>
                  ))}
                </ul>
                <li>
                  If we do not receive these, it may affect your premium, discounts, coverage
                  limits, or eligibility for coverage.
                </li>
              </>
            ) : (
              <li>
                We must receive any required forms back within <strong>{metadata.property}</strong>{' '}
                days. If we do not receive these, it may affect your premium, coverage limits, or
                eligibility for coverage.
              </li>
            )}
            <li>
              You'll receive a paper copy of the policy within <strong>45</strong> days, unless you
              opt in to paperless through our online servicing. Our preferred method for sharing
              policy documents is through our online servicing portal. If you need a copy of your
              policy prior to that time, within <strong>24</strong> hours will receive an email with
              a link to create an online account and view policy documents online, and print them
              yourself.
            </li>
          </ul>
        ),
        spanishText: (
          <ul>
            <li>
              Su póliza comenzará el <strong>{propertyEffectiveDate}</strong> a las{' '}
              <strong>12:01 AM</strong>.
            </li>
            <li>
              Es possible que llevemos a cabo una inspección de su casa para garantizar que su
              propiedad esté adecuadamente asegurada y este en condición aceptable. Si de esta
              inspección se genera algún cambio en su póliza, se lo notificaremos.
            </li>
            {propertyForms ? (
              <>
                <li>
                  Necesitamos que nos envíe los siguientes documentos en un plazo de{' '}
                  <strong>{metadata.property}</strong> días:
                </li>
                <ul>
                  {propertyForms.map((form) => (
                    <li>{form}</li>
                  ))}
                </ul>
                <li>
                  Si no los recibiéramos esto puderia afectar su prima, descuentos, límites de
                  cobertura o derecho a la cobertura.
                </li>
              </>
            ) : (
              <li>
                Debemos recibir los formularios requeridos en un plazo de{' '}
                <strong>{metadata.property}</strong> días. Si no los recibimos, puede verse afectada
                la prima, los límites de la cobertura o el derecho a la misma.
              </li>
            )}
            <li>
              Recibirá una copia en papel de la póliza en un plazo de <strong>45</strong> días, a
              menos que opte por la opción “paperless” a través de nuestro servicio en línea.
              Nuestro método preferido para compartir los documentos de las pólizas es a través de
              nuestro portal de servicios en línea. Si necesita una copia de su póliza antes de esa
              fecha, en un plazo de <strong>24</strong> horas recibirá un correo electrónico con un
              enlace para crear una cuenta en línea, acceder a los documentos de la póliza, e
              imprimirlos usted mismo.
            </li>
          </ul>
        ),
      };
    case 'renters':
      return {
        title,
        text: (
          <ul>
            <li>
              We are issuing your policy effective <strong>12:01 AM</strong> on{' '}
              <strong>{propertyEffectiveDate}</strong>.
            </li>
            {propertyForms ? (
              <>
                <li>
                  We need the following forms from you within <strong>{metadata.property}</strong>{' '}
                  days:
                </li>
                <ul>
                  {propertyForms.map((form) => (
                    <li>{form}</li>
                  ))}
                </ul>
                <li>
                  If we do not receive these, it may affect your premium, discounts, coverage
                  limits, or eligibility for coverage.
                </li>
              </>
            ) : (
              <li>
                We must receive any required forms back within <strong>{metadata.property}</strong>{' '}
                days. If we do not receive these, it may affect your premium, coverage limits, or
                eligibility for coverage.
              </li>
            )}
            <li>
              Within <strong>24</strong> hours, you will receive an email with a link to create an
              online account and view policy documents online.
            </li>
            <li>
              You'll receive a paper copy of the policy within <strong>45</strong> days unless you
              opt-in to go paperless.
            </li>
            <li>
              Review the terms and conditions of the policy for any applicable exclusions,
              non-refundable policy fees or cancellation fees.
            </li>
          </ul>
        ),
        spanishText: (
          <ul>
            <li>
              Su póliza comenzará el <strong>{propertyEffectiveDate}</strong> a las{' '}
              <strong>12:01 AM</strong>.
            </li>
            {propertyForms ? (
              <>
                <li>
                  Necesitamos que nos envíe los siguientes documentos en un plazo de{' '}
                  <strong>{metadata.property}</strong> días:
                </li>
                <ul>
                  {propertyForms.map((form) => (
                    <li>{form}</li>
                  ))}
                </ul>
                <li>
                  Si no los recibiéramos esto puderia afectar su prima, descuentos, límites de
                  cobertura o derecho a la cobertura.
                </li>
              </>
            ) : (
              <li>
                Debemos recibir los formularios requeridos en un plazo de{' '}
                <strong>{metadata.property}</strong> días. Si no los recibimos, puede verse afectada
                la prima, los límites de la cobertura o el derecho a la misma.
              </li>
            )}
            <li>
              En un plazo de <strong>24</strong> horas recibirá un correo electrónico con un enlace
              para crear una cuenta en línea y acceder a los documentos de la póliza.
            </li>
            <li>
              Tambien recibirá una copia impresa de la póliza en un plazo de <strong>45</strong>{' '}
              días, a menos que seleccione la opcion “paperless” en su cuenta en linea.
            </li>
            <li>
              Por favor, revise los términos y condiciones de la póliza para conocer las exclusiones
              aplicables, o si hubiera algun gasto de póliza que sea no reembolsable o por
              cancelación.
            </li>
          </ul>
        ),
      };
    case 'midvaleAuto':
      return {
        title,
        text: (
          <ul>
            <li>
              Your policy goes into effect at <strong>12:01 AM</strong> on{' '}
              <strong>{autoEffectiveDate}</strong>.
            </li>
            <li>
              You’ll have <strong>10</strong> days to complete any required actions to keep your
              discounts, or they will be removed from your policy and your premium will change. This
              includes enrolling in Autopay and Paperless through your online account and providing
              additional documentation for other selected discounts.
            </li>
            <li>
              A Welcome Email will be sent to the email address you provided outlining these
              instructions. It will contain a link to set up your online account and let you know if
              any further documentation is needed.
            </li>
          </ul>
        ),
        spanishText: (
          <ul>
            <li>
              Su póliza de auto comenzara el <strong>{autoEffectiveDate}</strong> a las{' '}
              <strong>12:01 AM</strong>.
            </li>
            <li>
              Dispondrá de <strong>10</strong> días para llevar a cabo las acciones necesarias para
              mantener sus descuentos, o se eliminarán de su póliza y su prima cambiará. Esto
              incluye inscribirse en Autopay y Paperless a través de su cuenta en línea y
              proporcionar documentación adicional para otros descuentos seleccionados.
            </li>
            <li>
              Se le enviará un correo electrónico de bienvenida a la dirección de correo electrónico
              que nos haya facilitado con estas instrucciones. Contendrá un enlace para configurar
              su cuenta en línea y le indicará si necesita más documentación.
            </li>
          </ul>
        ),
      };

    case 'ho3Bundle':
      return {
        title,
        text: (
          <ul>
            {propertyEffectiveDate !== autoEffectiveDate ? (
              <>
                <li>
                  We are issuing your Auto policy effective <strong>12:01 AM</strong> on{' '}
                  <strong>{autoEffectiveDate}</strong>.
                </li>
                <li>
                  We are issuing your Home policy effective <strong>12:01 AM</strong> on{' '}
                  <strong>{propertyEffectiveDate}</strong>.
                </li>
              </>
            ) : (
              <li>
                We are issuing your Auto and Home policies effective <strong>12:01 AM</strong> on{' '}
                <strong>{autoEffectiveDate}</strong>.
              </li>
            )}
            <li>For your Auto Policy:</li>
            <ul>
              {autoForms ? (
                <>
                  <li>
                    We need the following forms from you within <strong>{metadata.auto}</strong>{' '}
                    days:
                  </li>
                  {autoForms.map((form) => (
                    <li>{form}</li>
                  ))}
                  <li>
                    If we do not receive these, it may affect your premium, discounts, coverage
                    limits, or eligibility for coverage.
                  </li>
                </>
              ) : (
                <li>
                  We must receive any required forms back within <strong>{metadata.auto}</strong>{' '}
                  days. If we do not receive these, it may affect your premium, coverage limits, or
                  eligibility for coverage.
                </li>
              )}
            </ul>
            <li>For your Home policy:</li>
            <ul>
              <li>
                We may conduct an inspection of your home to ensure your property is adequately
                insured and acceptable. If any changes come out of this inspection, we will notify
                you.
              </li>
              {propertyForms ? (
                <>
                  <li>
                    We need the following forms from you within <strong>{metadata.property}</strong>{' '}
                    days:
                  </li>
                  {propertyForms.map((form) => (
                    <li>{form}</li>
                  ))}
                  <li>
                    If we do not receive these, it may affect your premium, discounts, coverage
                    limits, or eligibility for coverage.
                  </li>
                </>
              ) : (
                <li>
                  We must receive any required forms back within{' '}
                  <strong>{metadata.property}</strong> days. If we do not receive these, it may
                  affect your premium, coverage limits, or eligibility for coverage.
                </li>
              )}
              <li>
                You’ll receive a paper copy of the policy within <strong>45</strong> days, unless
                you opt in to paperless through our online servicing. Our preferred method for
                sharing policy documents is through our online servicing portal. If you need a copy
                of your policy prior to that time, within <strong>24</strong> hours will receive an
                email with a link to create an online account and view policy documents online, and
                print them yourself.
              </li>
            </ul>
          </ul>
        ),
        spanishText: (
          <ul>
            {propertyEffectiveDate !== autoEffectiveDate ? (
              <>
                <li>
                  Su póliza de auto comenzara el <strong>{autoEffectiveDate}</strong> a las{' '}
                  <strong>12:01 AM</strong>.
                </li>
                <li>
                  Su póliza de hogar comenzara el <strong>{propertyEffectiveDate}</strong> a las
                  <strong>12:01 AM</strong>.
                </li>
              </>
            ) : (
              <li>
                Su póliza comenzará el <strong>{autoEffectiveDate}</strong> a las{' '}
                <strong>12:01 AM</strong>.
              </li>
            )}
            <li>Para su póliza de auto:</li>
            <ul>
              {autoForms ? (
                <>
                  <li>
                    Necesitamos que nos envíe los siguientes documentos en un plazo de{' '}
                    <strong>{metadata.auto}</strong> días:
                  </li>
                  {autoForms.map((form) => (
                    <li>{form}</li>
                  ))}
                  <li>
                    Si no los recibiéramos esto puderia afectar su prima, descuentos, límites de
                    cobertura o derecho a la cobertura.
                  </li>
                </>
              ) : (
                <li>
                  Debemos recibir los formularios requeridos en un plazo de{' '}
                  <strong>{metadata.auto}</strong> días. Si no los recibimos, puede verse afectada
                  la prima, los límites de la cobertura o el derecho a la misma.
                </li>
              )}
            </ul>
            <li>Para su póliza de hogar:</li>
            <ul>
              <li>
                Es possible que llevemos a cabo una inspección de su casa para garantizar que su
                propiedad esté adecuadamente asegurada y este en condicion aceptable. Si de esta
                inspección se genera algún cambio en su póliza, se lo notificaremos.
              </li>
              {propertyForms ? (
                <>
                  <li>
                    Necesitamos que nos envíe los siguientes documentos en un plazo de{' '}
                    <strong>{metadata.property}</strong> días:
                  </li>
                  {propertyForms.map((form) => (
                    <li>{form}</li>
                  ))}
                  <li>
                    Si no los recibiéramos esto puderia afectar su prima, descuentos, límites de
                    cobertura o derecho a la cobertura.
                  </li>
                </>
              ) : (
                <li>
                  Debemos recibir los formularios requeridos en un plazo de{' '}
                  <strong>{metadata.property}</strong> días. Si no los recibimos, puede verse
                  afectada la prima, los límites de la cobertura o el derecho a la misma.
                </li>
              )}
              <li>
                Recibirá una copia en papel de la póliza en un plazo de <strong>45</strong> días, a
                menos que opte por la opción “paperless” a través de nuestro servicio en línea.
                Nuestro método preferido para compartir los documentos de las pólizas es a través de
                nuestro portal de servicios en línea. Si necesita una copia de su póliza antes de
                esa fecha, en un plazo de <strong>24</strong> horas recibirá un correo electrónico
                con un enlace para crear una cuenta en línea, acceder a los documentos de la póliza,
                e imprimirlos usted mismo.
              </li>
            </ul>
          </ul>
        ),
      };

    case 'ho3MidvaleBundle':
      return {
        title,
        text: (
          <ul>
            {propertyEffectiveDate !== autoEffectiveDate ? (
              <>
                <li>
                  We are issuing your Auto policy effective <strong>12:01 AM</strong> on{' '}
                  <strong>{autoEffectiveDate}</strong>.
                </li>
                <li>
                  We are issuing your Home policy effective <strong>12:01 AM</strong> on{' '}
                  <strong>{propertyEffectiveDate}</strong>.
                </li>
              </>
            ) : (
              <li>
                We are issuing your Auto and Home policies effective <strong>12:01 AM</strong> on{' '}
                <strong>{autoEffectiveDate}</strong>.
              </li>
            )}
            <li>For your Auto Policy:</li>
            <ul>
              <li>
                You’ll have <strong>10</strong> days to complete any required actions to keep your
                discounts, or they will be removed from your policy and your premium will change.
                This includes enrolling in Autopay and Paperless through your online account and
                providing additional documentation for other selected discounts.
              </li>
              <li>
                A Welcome Email will be sent to the email address you provided outlining these
                instructions. It will contain a link to set up your online account and let you know
                if any further documentation is needed.
              </li>
            </ul>
            <li>For your Home policy:</li>
            <ul>
              <li>
                We may conduct an inspection of your home to ensure your property is adequately
                insured and acceptable. If any changes come out of this inspection, we will notify
                you.
              </li>
              {propertyForms ? (
                <>
                  <li>
                    We need the following forms from you within <strong>{metadata.property}</strong>{' '}
                    days:
                  </li>
                  {propertyForms.map((form) => (
                    <li>{form}</li>
                  ))}
                  <li>
                    If we do not receive these, it may affect your premium, discounts, coverage
                    limits, or eligibility for coverage.
                  </li>
                </>
              ) : (
                <li>
                  We must receive any required forms back within{' '}
                  <strong>{metadata.property}</strong> days. If we do not receive these, it may
                  affect your premium, coverage limits, or eligibility for coverage.
                </li>
              )}
              <li>
                You’ll receive a paper copy of the policy within <strong>45</strong> days, unless
                you opt in to paperless through our online servicing. Our preferred method for
                sharing policy documents is through our online servicing portal. If you need a copy
                of your policy prior to that time, within <strong>24</strong> hours will receive an
                email with a link to create an online account and view policy documents online, and
                print them yourself.
              </li>
            </ul>
          </ul>
        ),
        spanishText: (
          <ul>
            {propertyEffectiveDate !== autoEffectiveDate ? (
              <>
                <li>
                  Su póliza de auto comenzara el <strong>{autoEffectiveDate}</strong> a las{' '}
                  <strong>12:01 AM</strong>.
                </li>
                <li>
                  Su póliza de hogar comenzara el <strong>{propertyEffectiveDate}</strong> a las
                  <strong>12:01 AM</strong>.
                </li>
              </>
            ) : (
              <li>
                Su póliza comenzará el <strong>{autoEffectiveDate}</strong> a las{' '}
                <strong>12:01 AM</strong>.
              </li>
            )}
            <li>Para su póliza de auto:</li>
            <ul>
              {autoForms ? (
                <>
                  <li>
                    Necesitamos que nos envíe los siguientes documentos en un plazo de{' '}
                    <strong>{metadata.auto}</strong> días:
                  </li>
                  {autoForms.map((form) => (
                    <li>{form}</li>
                  ))}
                  <li>
                    Si no los recibiéramos esto puderia afectar su prima, descuentos, límites de
                    cobertura o derecho a la cobertura.
                  </li>
                </>
              ) : (
                <>
                  <li>
                    Dispondrá de <strong>10</strong> días para llevar a cabo las acciones necesarias
                    para mantener sus descuentos, o se eliminarán de su póliza y su prima cambiará.
                    Esto incluye inscribirse en Autopay y Paperless a través de su cuenta en línea y
                    proporcionar documentación adicional para otros descuentos seleccionados.
                  </li>
                  <li>
                    Se le enviará un correo electrónico de bienvenida a la dirección de correo
                    electrónico que nos haya facilitado con estas instrucciones. Contendrá un enlace
                    para configurar su cuenta en línea y le indicará si necesita más documentación.
                  </li>
                </>
              )}
            </ul>
            <li>Para su póliza de hogar:</li>
            <ul>
              <li>
                Es possible que llevemos a cabo una inspección de su casa para garantizar que su
                propiedad esté adecuadamente asegurada y este en condicion aceptable. Si de esta
                inspección se genera algún cambio en su póliza, se lo notificaremos.
              </li>
              {propertyForms ? (
                <>
                  <li>
                    Necesitamos que nos envíe los siguientes documentos en un plazo de{' '}
                    <strong>{metadata.property}</strong> días:
                  </li>
                  {propertyForms.map((form) => (
                    <li>{form}</li>
                  ))}
                  <li>
                    Si no los recibiéramos esto puderia afectar su prima, descuentos, límites de
                    cobertura o derecho a la cobertura.
                  </li>
                </>
              ) : (
                <li>
                  Debemos recibir los formularios requeridos en un plazo de{' '}
                  <strong>{metadata.property}</strong> días. Si no los recibimos, puede verse
                  afectada la prima, los límites de la cobertura o el derecho a la misma.
                </li>
              )}
              <li>
                Recibirá una copia en papel de la póliza en un plazo de <strong>45</strong> días, a
                menos que opte por la opción “paperless” a través de nuestro servicio en línea.
                Nuestro método preferido para compartir los documentos de las pólizas es a través de
                nuestro portal de servicios en línea. Si necesita una copia de su póliza antes de
                esa fecha, en un plazo de <strong>24</strong> horas recibirá un correo electrónico
                con un enlace para crear una cuenta en línea, acceder a los documentos de la póliza,
                e imprimirlos usted mismo.
              </li>
            </ul>
          </ul>
        ),
      };
    case 'ho4Bundle':
      return {
        title,
        text: (
          <ul>
            {autoEffectiveDate !== propertyEffectiveDate ? (
              <>
                <li>
                  We are issuing your Auto policy effective <strong>12:01 AM</strong> on{' '}
                  <strong>{autoEffectiveDate}</strong>.
                </li>
                <li>
                  We are issuing your Renters policy effective <strong>12:01 AM</strong> on{' '}
                  <strong>{propertyEffectiveDate}</strong>.
                </li>
              </>
            ) : (
              <li>
                We are issuing your Auto and Renters policies effective <strong>12:01 AM</strong> on{' '}
                <strong>{autoEffectiveDate}</strong>.
              </li>
            )}
            <li>For your Auto Policy:</li>
            <ul>
              {autoForms ? (
                <>
                  <li>
                    We need the following forms from you within <strong>{metadata.auto}</strong>{' '}
                    days:
                  </li>
                  {autoForms.map((form) => (
                    <li>{form}</li>
                  ))}
                  <li>
                    If we do not receive these, it may affect your premium, discounts, coverage
                    limits, or eligibility for coverage.
                  </li>
                </>
              ) : (
                <li>
                  We must receive any required forms back within <strong>{metadata.auto}</strong>{' '}
                  days. If we do not receive these, it may affect your premium, coverage limits, or
                  eligibility for coverage.
                </li>
              )}
            </ul>
            <li>For your Renters policy:</li>
            <ul>
              {propertyForms ? (
                <>
                  <li>
                    We need the following forms from you within <strong>{metadata.property}</strong>{' '}
                    days:
                  </li>
                  {propertyForms.map((form) => (
                    <li>{form}</li>
                  ))}
                  <li>
                    If we do not receive these, it may affect your premium, discounts, coverage
                    limits, or eligibility for coverage.{' '}
                  </li>
                </>
              ) : (
                <li>
                  We must receive any required forms back within{' '}
                  <strong>{metadata.property}</strong> days. If we do not receive these, it may
                  affect your premium, coverage limits, or eligibility for coverage.
                </li>
              )}
              <li>
                Within <strong>24</strong> hours, you will receive an email with a link to create an
                online account and view policy documents online.{' '}
              </li>

              <li>
                You’ll receive a paper copy of the policy within <strong>45</strong> days unless you
                opt-in to go paperless.{' '}
              </li>

              <li>
                Review the terms and conditions of the policy for any applicable exclusions,
                non-refundable policy fees or cancellation fees.{' '}
              </li>
            </ul>
          </ul>
        ),
        spanishText: (
          <ul>
            {propertyEffectiveDate !== autoEffectiveDate ? (
              <>
                <li>
                  Su póliza de auto comenzara el <strong>{autoEffectiveDate}</strong> a las{' '}
                  <strong>12:01 AM</strong>.
                </li>
                <li>
                  Su póliza de hogar comenzara el <strong>{propertyEffectiveDate}</strong> a las
                  <strong>12:01 AM</strong>.
                </li>
              </>
            ) : (
              <li>
                Su póliza comenzará el <strong>{autoEffectiveDate}</strong> a las{' '}
                <strong>12:01 AM</strong>.
              </li>
            )}
            <li>Para su póliza de auto:</li>
            <ul>
              {autoForms ? (
                <>
                  <li>
                    Necesitamos que nos envíe los siguientes documentos en un plazo de{' '}
                    <strong>{metadata.auto}</strong> días:
                  </li>
                  {autoForms.map((form) => (
                    <li>{form}</li>
                  ))}
                  <li>
                    Si no los recibiéramos esto puderia afectar su prima, descuentos, límites de
                    cobertura o derecho a la cobertura.
                  </li>
                </>
              ) : (
                <li>
                  Debemos recibir los formularios requeridos en un plazo de{' '}
                  <strong>{metadata.auto}</strong> días. Si no los recibimos, puede verse afectada
                  la prima, los límites de la cobertura o el derecho a la misma.
                </li>
              )}
            </ul>
            <li>Para su póliza de hogar:</li>
            <ul>
              <li>
                Es possible que llevemos a cabo una inspección de su casa para garantizar que su
                propiedad esté adecuadamente asegurada y este en condicion aceptable. Si de esta
                inspección se genera algún cambio en su póliza, se lo notificaremos.
              </li>
              <li>For your Renters policy:</li>
              {propertyForms ? (
                <>
                  <li>
                    Necesitamos que nos envíe los siguientes documentos en un plazo de{' '}
                    <strong>{metadata.property}</strong> días:
                  </li>
                  {propertyForms.map((form) => (
                    <li>{form}</li>
                  ))}
                  <li>
                    Si no los recibiéramos esto puderia afectar su prima, descuentos, límites de
                    cobertura o derecho a la cobertura.
                  </li>
                </>
              ) : (
                <li>
                  Debemos recibir los formularios requeridos en un plazo de{' '}
                  <strong>{metadata.property}</strong> días. Si no los recibimos, puede verse
                  afectada la prima, los límites de la cobertura o el derecho a la misma.
                </li>
              )}
              <li>
                En un plazo de <strong>24</strong> horas recibirá un correo electrónico con un
                enlace para crear una cuenta en línea y acceder a los documentos de la póliza.
              </li>
              <li>
                Tambien recibirá una copia impresa de la póliza en un plazo de <strong>45</strong>{' '}
                días, a menos que seleccione la opcion “paperless” en su cuenta en linea.
              </li>
              <li>
                Por favor, revise los términos y condiciones de la póliza para conocer las
                exclusiones aplicables, o si hubiera algun gasto de póliza que sea no reembolsable o
                por cancelación.
              </li>
            </ul>
          </ul>
        ),
      };
    case 'ho4MidvaleBundle':
      return {
        title,
        text: (
          <ul>
            {autoEffectiveDate !== propertyEffectiveDate ? (
              <>
                <li>
                  We are issuing your Auto policy effective <strong>12:01 AM</strong> on{' '}
                  <strong>{autoEffectiveDate}</strong>.
                </li>
                <li>
                  We are issuing your Renters policy effective <strong>12:01 AM</strong> on{' '}
                  <strong>{propertyEffectiveDate}</strong>.
                </li>
              </>
            ) : (
              <li>
                We are issuing your Auto and Renters policies effective <strong>12:01 AM</strong> on{' '}
                <strong>{autoEffectiveDate}</strong>.
              </li>
            )}
            <li>For your Auto Policy:</li>
            <ul>
              <li>
                You’ll have <strong>10</strong> days to complete any required actions to keep your
                discounts, or they will be removed from your policy and your premium will change.
                This includes enrolling in Autopay and Paperless through your online account and
                providing additional documentation for other selected discounts.
              </li>
              <li>
                A Welcome Email will be sent to the email address you provided outlining these /
                instructions. It will contain a link to set up your online account and let you know
                if any further documentation is needed.
              </li>
            </ul>
            <li>For your Home policy:</li>
            <ul>
              <li>
                We may conduct an inspection of your home to ensure your property is adequately
                insured and acceptable. If any changes come out of this inspection, we will notify
                you.
              </li>
            </ul>
            <li>For your Renters policy:</li>
            <ul>
              {propertyForms ? (
                <>
                  <li>
                    We need the following forms from you within <strong>{metadata.property}</strong>{' '}
                    days:
                  </li>
                  {propertyForms.map((form) => (
                    <li>{form}</li>
                  ))}
                  <li>
                    If we do not receive these, it may affect your premium, discounts, coverage
                    limits, or eligibility for coverage.{' '}
                  </li>
                </>
              ) : (
                <li>
                  We must receive any required forms back within{' '}
                  <strong>{metadata.property}</strong> days. If we do not receive these, it may
                  affect your premium, coverage limits, or eligibility for coverage.
                </li>
              )}
              <li>
                Within <strong>24</strong> hours, you will receive an email with a link to create an
                online account and view policy documents online.{' '}
              </li>

              <li>
                You’ll receive a paper copy of the policy within <strong>45</strong> days unless you
                opt-in to go paperless.{' '}
              </li>

              <li>
                Review the terms and conditions of the policy for any applicable exclusions,
                non-refundable policy fees or cancellation fees.{' '}
              </li>
            </ul>
          </ul>
        ),
        spanishText: (
          <ul>
            {propertyEffectiveDate !== autoEffectiveDate ? (
              <>
                <li>
                  Su póliza de auto comenzara el <strong>{autoEffectiveDate}</strong> a las{' '}
                  <strong>12:01 AM</strong>.
                </li>
                <li>
                  Su póliza de hogar comenzara el <strong>{propertyEffectiveDate}</strong> a las
                  <strong>12:01 AM</strong>.
                </li>
              </>
            ) : (
              <li>
                Su póliza comenzará el <strong>{autoEffectiveDate}</strong> a las{' '}
                <strong>12:01 AM</strong>.
              </li>
            )}
            <li>Para su póliza de auto:</li>
            <ul>
              {autoForms ? (
                <>
                  <li>
                    Necesitamos que nos envíe los siguientes documentos en un plazo de{' '}
                    <strong>{metadata.auto}</strong> días:
                  </li>
                  {autoForms.map((form) => (
                    <li>{form}</li>
                  ))}
                  <li>
                    Si no los recibiéramos esto puderia afectar su prima, descuentos, límites de
                    cobertura o derecho a la cobertura.
                  </li>
                </>
              ) : (
                <>
                  <li>
                    Dispondrá de <strong>10</strong> días para llevar a cabo las acciones necesarias
                    para mantener sus descuentos, o se eliminarán de su póliza y su prima cambiará.
                    Esto incluye inscribirse en Autopay y Paperless a través de su cuenta en línea y
                    proporcionar documentación adicional para otros descuentos seleccionados.
                  </li>
                  <li>
                    Se le enviará un correo electrónico de bienvenida a la dirección de correo
                    electrónico que nos haya facilitado con estas instrucciones. Contendrá un enlace
                    para configurar su cuenta en línea y le indicará si necesita más documentación.
                  </li>
                </>
              )}
            </ul>
            <li>Para su póliza de hogar:</li>
            <ul>
              <li>
                Es possible que llevemos a cabo una inspección de su casa para garantizar que su
                propiedad esté adecuadamente asegurada y este en condicion aceptable. Si de esta
                inspección se genera algún cambio en su póliza, se lo notificaremos.
              </li>
              <li>For your Renters policy:</li>
              {propertyForms ? (
                <>
                  <li>
                    Necesitamos que nos envíe los siguientes documentos en un plazo de{' '}
                    <strong>{metadata.property}</strong> días:
                  </li>
                  {propertyForms.map((form) => (
                    <li>{form}</li>
                  ))}
                  <li>
                    Si no los recibiéramos esto puderia afectar su prima, descuentos, límites de
                    cobertura o derecho a la cobertura.
                  </li>
                </>
              ) : (
                <li>
                  Debemos recibir los formularios requeridos en un plazo de{' '}
                  <strong>{metadata.property}</strong> días. Si no los recibimos, puede verse
                  afectada la prima, los límites de la cobertura o el derecho a la misma.
                </li>
              )}
              <li>
                En un plazo de <strong>24</strong> horas recibirá un correo electrónico con un
                enlace para crear una cuenta en línea y acceder a los documentos de la póliza.
              </li>
              <li>
                Tambien recibirá una copia impresa de la póliza en un plazo de <strong>45</strong>{' '}
                días, a menos que seleccione la opcion “paperless” en su cuenta en linea.
              </li>
              <li>
                Por favor, revise los términos y condiciones de la póliza para conocer las
                exclusiones aplicables, o si hubiera algun gasto de póliza que sea no reembolsable o
                por cancelación.
              </li>
            </ul>
          </ul>
        ),
      };
    default:
      return {};
  }
};
