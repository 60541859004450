import { sessionStorage } from '@ecp/utils/storage';

import { OfferStatusCode } from '@ecp/features/sales/shared/constants';
import {
  getDalSessionId,
  getLineOfBusiness,
  getOffers,
  getOffersForSelectedLob,
  getPrimaryInsuredStateCode,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { isProductHome, LineOfBusiness } from '@ecp/features/shared/product';

import { HO2_SESSION_STORAGE_KEY } from '../constants';
import { QuotesHO2Prompt } from './QuotesHO2Prompt';

// TODO Consider making it a standalone page vs a guard wrapper
// TODO This component body could be simplified as H02 vs H03 derivation looks unnecessarily complex
// TODO sessionStorage.getItem(HO2_SESSION_STORAGE_KEY) and setItem isn't the best way to handle displaying the prompt
export const QuotesHO2PromptGuard: React.FC<React.PropsWithChildren> = (props) => {
  const lineOfBusiness = useSelector(getLineOfBusiness);
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const dalSessionId = useSelector(getDalSessionId);

  const offers = useSelector(getOffers);
  const offersForSelectedLob = useSelector(getOffersForSelectedLob);
  const homeProduct = Object.keys(offers).find(isProductHome);
  const homeOffer = homeProduct && offers[homeProduct];

  const shouldShowPrompt =
    stateCode === 'MI' &&
    lineOfBusiness === LineOfBusiness.BUNDLE &&
    sessionStorage.getItem(`${dalSessionId} ${HO2_SESSION_STORAGE_KEY}`) !== true &&
    offersForSelectedLob?.auto &&
    !offersForSelectedLob?.home &&
    homeOffer &&
    homeOffer.eligibility.statusCode === OfferStatusCode.CNQ &&
    homeOffer.eligibility.reason === 'HO2 Quote';

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!shouldShowPrompt) return <>{props.children || null}</>;

  return <QuotesHO2Prompt />;
};
