import { Route } from '@ecp/utils/routing';

import { PagePath } from '@ecp/features/sales/shared/routing';
import {
  ParticipatingCarriersPage,
  PrivacyPolicyPage,
  UseOfCreditDisclosurePage,
} from '@ecp/features/sales/shell';

export const staticPaths = [
  PagePath.PARTICIPATING_INSURERS,
  PagePath.PRIVACY_POLICY,
  PagePath.USE_OF_CREDIT_DISCLOSURE,
];

export const staticRoutes = [
  // Static pages
  <Route path={PagePath.PARTICIPATING_INSURERS} component={ParticipatingCarriersPage} />,
  <Route path={PagePath.PRIVACY_POLICY} component={PrivacyPolicyPage} />,
  <Route path={PagePath.USE_OF_CREDIT_DISCLOSURE} component={UseOfCreditDisclosurePage} />,
];
