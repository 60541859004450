import { VehicleYearVinMakeModelQuestion, VehicleYearVinQuestion } from '../../components';

interface Props {
  vehicleRef: string;
  setMaskRequiredFieldError: (value: boolean) => void;
  disableVehicleServiceCallFlag?: boolean;
}

export const VehicleProfileFormQuestions: React.FC<Props> = (props) => {
  const { disableVehicleServiceCallFlag } = props;

  return !disableVehicleServiceCallFlag ? (
    <VehicleYearVinQuestion {...props} />
  ) : (
    <VehicleYearVinMakeModelQuestion {...props} />
  );
};
