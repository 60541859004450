import type { Metadata } from './metadata.js';
import baseMetadata from './metadata.js';

const metadata: Metadata = {
  ...baseMetadata,
  hideAdditionalDetails: true,
  hidePolicyHeader: true,
  translatePolicyDuration: true,
};

export default metadata;
