import { merge } from '@ecp/utils/common';

import type { Product } from '@ecp/features/shared/product';
import type { OptionsMetadata } from '@ecp/types';

import { CoveragePolicyLevelDiscountsMetadata as BaseCoveragePolicyLevelDiscountsMetadata } from './CoveragePolicyLevelDiscounts.metadata.js';

export const CoveragePolicyLevelDiscountsMetadata: Record<Product, OptionsMetadata> = merge(
  {},
  BaseCoveragePolicyLevelDiscountsMetadata,
  {
    'amfam.auto': {
      midvaleHomePolicy: {
        title: 'GMI home policy',
      },
      partnerDiscount: {
        title: 'Partner discount',
      },
      advancedSafety: {
        title: 'Advanced Safety',
      },
      connectedEnrollment: {
        title: 'Connected Enrollment',
      },
    },
  },
);
