import type { VehicleWithImage } from '@ecp/features/sales/shared/types';

import { VehicleOwnershipDateQuestion } from '../../components/Question/Vehicle';

interface Props {
  vehicleItemList: VehicleWithImage[];
}
export const VehicleOwnershipDateFormQuestions: React.FC<Props> = ({ vehicleItemList }) => {
  return (
    <>
      {vehicleItemList.map((vehicleItem) => {
        return (
          <VehicleOwnershipDateQuestion key={vehicleItem.vehicle.ref} vehicleItem={vehicleItem} />
        );
      })}
    </>
  );
};
