import type { ReactNode } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { Events, FeatureFlags, flagValues, trackEvent } from '@ecp/utils/flags';
import type { GeoAddress } from '@ecp/utils/geo';
import { SmartyStreetsEnums, validateAndCombineAddress } from '@ecp/utils/geo';
import { useEvent } from '@ecp/utils/react';
import { navigate } from '@ecp/utils/routing';

import { env } from '@ecp/env';
import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { useVehicleKeptAddressRef } from '@ecp/features/sales/quotes/auto';
import { AgreementContent, Page, ReviewPeriodDialog } from '@ecp/features/sales/shared/components';
import {
  AUTO_LOCKING_PERIOD_DAYS,
  NavStatus,
  STATE_CODE_PREFIX,
} from '@ecp/features/sales/shared/constants';
import {
  PagePath,
  useNavigateToNextPage,
  useNavigateToPage,
} from '@ecp/features/sales/shared/routing';
import {
  createAddressForUpdate,
  getBridgeType,
  getLineOfBusiness,
  makeSurePrefillFlowInStore,
  submitPolicyType,
  updateAnswers,
  updatePageStatus,
  useField,
  useFieldWithPrefix,
  usePrimaryAddress,
  usePrimaryAddressRef,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { isLineOfBusinessAuto } from '@ecp/features/shared/product';
import type { Field, Fields } from '@ecp/types';

import { PersonForm } from '../../forms/PersonForm';
import { useStyles } from './PersonPage.styles';

export interface AddressFields extends Fields {
  vehicleKeptAddress: {
    vehicleKeptAddressLine1: Field;
    vehicleKeptAddressLine2: Field;
    vehicleKeptAddressCity: Field;
    vehicleKeptAddressState: Field;
    vehicleKeptAddressZipcode: Field;
  };
  primaryAddress: {
    primaryLine1: Field;
    primaryLine2: Field;
    primaryCity: Field;
    primaryState: Field;
    primaryZipcode: Field;
  };
}

const useGetFields = (): AddressFields => {
  const primaryAddressRef = usePrimaryAddressRef();
  const vehicleKeptAddressRef = useVehicleKeptAddressRef();
  const useVKAAddressField = useFieldWithPrefix(vehicleKeptAddressRef, 'address.<id>');
  const usePrimaryAddressFields = useFieldWithPrefix(primaryAddressRef, 'address.<id>');

  return {
    vehicleKeptAddress: {
      vehicleKeptAddressCity: useVKAAddressField('city'),
      vehicleKeptAddressState: useVKAAddressField('state'),
      vehicleKeptAddressLine1: useVKAAddressField('line1'),
      vehicleKeptAddressLine2: useVKAAddressField('line2'),
      vehicleKeptAddressZipcode: useVKAAddressField('zipcode'),
    },
    primaryAddress: {
      primaryCity: usePrimaryAddressFields('city'),
      primaryState: usePrimaryAddressFields('state'),
      primaryLine1: usePrimaryAddressFields('line1'),
      primaryLine2: usePrimaryAddressFields('line2'),
      primaryZipcode: usePrimaryAddressFields('zipcode'),
    },
  };
};

interface PersonPageProps {
  disclosureScripts?: ReactNode;
}

export const PersonPage: React.FC<PersonPageProps> = (props) => {
  const { classes } = useStyles();
  const { disclosureScripts } = props;
  const dispatch = useDispatch();
  const primaryAddressRef = usePrimaryAddressRef();
  const lineOfBusiness = useSelector(getLineOfBusiness);
  const [geoAddressSuggestions, setGeoAddressSuggestions] = useState<GeoAddress[]>([]);
  const navigateToNextPage = useNavigateToNextPage();
  const navigateToNextAddressPage = useNavigateToPage(PagePath.PERSON_ADDRESS);
  const vehicleKeptAddressRef = useVehicleKeptAddressRef();
  const fields = useGetFields();
  const address = useField('static.address');
  const primaryAddress = usePrimaryAddress();
  const bridgeType = useSelector(getBridgeType);
  const autoLockingPeriodDays = useField(AUTO_LOCKING_PERIOD_DAYS);
  const enableCapiReviewPeriod = flagValues[FeatureFlags.CAPI_10_DAY_REVIEW];
  const showCapiReviewPeriodMessage =
    enableCapiReviewPeriod &&
    autoLockingPeriodDays.value &&
    isLineOfBusinessAuto(lineOfBusiness) &&
    !env.static.isAgent;
  const [capiReviewDialogOpen, setCapiReviewDialogOpen] = useState(showCapiReviewPeriodMessage);

  const [vehicleKeptAddressSuggestions, setVehicleKeptAddressSuggestions] = useState<GeoAddress[]>(
    [],
  );

  const handleCapiDialogClose = useCallback((): void => {
    setCapiReviewDialogOpen(false);
  }, [setCapiReviewDialogOpen]);

  // TODO: We might need a better way to handle this. A/B test metric tracking function for personal info page view
  useEffect(() => {
    trackEvent(Events.PERSONAL_PAGE);
  }, []);

  const navigateToPrivacyPolicyPage = useNavigateToPage(PagePath.PRIVACY_POLICY);
  const navigateToParticipatingCarriersPage = useNavigateToPage(PagePath.PARTICIPATING_INSURERS);
  const navigateToUseOfCreditDisclosurePage = useNavigateToPage(PagePath.USE_OF_CREDIT_DISCLOSURE);

  const navigateToPrivacyPolicy = useEvent(() => {
    trackClick({ action: 'BodyTextPrivacyPolicyLink', label: 'PrivacyPolicy' });
    env.static.isAgent
      ? navigateToPrivacyPolicyPage()
      : navigate(PagePath.PRIVACY_POLICY, { external: true });
  });
  const navigateToParticipatingCarriers = useEvent(() => {
    trackClick({ action: 'ParticipatingInsurersLink', label: 'ParticipatingInsurers' });
    env.static.isAgent
      ? navigateToParticipatingCarriersPage()
      : navigate(PagePath.PARTICIPATING_INSURERS, { external: true });
  });
  const navigateToUseOfCreditDisclosure = useEvent(() => {
    trackClick({ action: 'HowWeUseInsuranceScoresLink', label: 'HowWeUseInsuranceScores' });
    env.static.isAgent
      ? navigateToUseOfCreditDisclosurePage()
      : navigate(PagePath.USE_OF_CREDIT_DISCLOSURE, { external: true });
  });

  // If a user doesn't select a suggested address and chooses to enter the address manually all fields need to be combined and validated when a user submits the form.
  const handleNext = useCallback(async () => {
    if (vehicleKeptAddressRef) {
      const {
        vehicleKeptAddress: {
          vehicleKeptAddressCity,
          vehicleKeptAddressState,
          vehicleKeptAddressLine1,
          vehicleKeptAddressLine2,
        },
      } = fields;
      const vehicleKeptAddressStateWithoutPrefix = vehicleKeptAddressState.value as string;
      const VKAInputAddress = {
        street: `${vehicleKeptAddressLine1.value} ${
          vehicleKeptAddressLine2.value ? (vehicleKeptAddressLine2.value as string) : ''
        }`,
        state: vehicleKeptAddressStateWithoutPrefix
          ? vehicleKeptAddressStateWithoutPrefix.replace(STATE_CODE_PREFIX, '')
          : '',
        city: vehicleKeptAddressCity.value as string,
      };
      const newVehicleKeptAddress = await validateAndCombineAddress(
        VKAInputAddress,
        vehicleKeptAddressRef,
      );
      if (newVehicleKeptAddress) {
        const vehicleKeptAddressToBeSaved = createAddressForUpdate(newVehicleKeptAddress);
        await dispatch(updateAnswers({ answers: { ...vehicleKeptAddressToBeSaved } }));
      }
    }
    const {
      primaryAddress: { primaryCity, primaryState, primaryLine1, primaryLine2 },
    } = fields;
    const primaryStateWithoutPrefix = primaryState.value as string;
    const primaryInputAddress = {
      street: `${primaryLine1.value} ${primaryLine2.value ? (primaryLine2.value as string) : ''}`,
      state: primaryStateWithoutPrefix
        ? primaryStateWithoutPrefix.replace(STATE_CODE_PREFIX, '')
        : '',
      city: primaryCity.value as string,
    };
    const parsedAddress = await validateAndCombineAddress(primaryInputAddress, primaryAddressRef);

    await dispatch(submitPolicyType());

    if (
      parsedAddress &&
      parsedAddress.matchType === SmartyStreetsEnums.MATCH_TYPE.EXACT &&
      parsedAddress.line1 === primaryLine1.value
    ) {
      const addressToBeSaved = createAddressForUpdate(parsedAddress);

      address.props.actionOnComplete(primaryAddress);

      await dispatch(updateAnswers({ answers: { ...addressToBeSaved } }));

      dispatch(updatePageStatus(NavStatus.VALID, PagePath.PERSON_ADDRESS));

      await dispatch(makeSurePrefillFlowInStore());

      await navigateToNextPage();

      return;
    }
    // If the address is not parsed then do not prefill.
    // Prefill will be handled by PersonAddressPage when there is a valid address available.
    await navigateToNextAddressPage();
  }, [
    address.props,
    fields,
    vehicleKeptAddressRef,
    dispatch,
    navigateToNextPage,
    navigateToNextAddressPage,
    primaryAddress,
    primaryAddressRef,
  ]);

  return (
    <div>
      {capiReviewDialogOpen && <ReviewPeriodDialog onClose={handleCapiDialogClose} />}
      <Page
        title="Let's get to know you better"
        analyticsElement='choice.personPage.page'
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.PERSON} />,
        }}
      >
        {bridgeType === 'customerPrefill' && <p>Confirm that your information is correct</p>}
        <PersonForm
          onNext={handleNext}
          geoAddressSuggestions={geoAddressSuggestions}
          setGeoAddressSuggestions={setGeoAddressSuggestions}
          lineOfBusiness={lineOfBusiness}
          disclosureScripts={disclosureScripts}
          vehicleKeptAddressSuggestions={vehicleKeptAddressSuggestions}
          setVehicleKeptAddressSuggestions={setVehicleKeptAddressSuggestions}
        />
        {!env.static.isAgent && (
          <Grid item xs={12}>
            <AgreementContent
              className={classes.agreementContent}
              onNavigateToPrivacyPolicy={navigateToPrivacyPolicy}
              onNavigateToParticipatingCarriers={navigateToParticipatingCarriers}
              onNavigateToUseOfCreditDisclosure={navigateToUseOfCreditDisclosure}
            />
          </Grid>
        )}
      </Page>
    </div>
  );
};
