export interface Metadata {
  helperText?: string;
  labelStart?: string;
  partnerDisplayNameOverride?: string;
}

const metadata: Metadata = {
  labelStart: 'Do you currently have a home insurance policy with ',
};

export default metadata;
