export const getCookie = (name: string): string | null => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i += 1) {
    const [key, value] = cookies[i].split('=');
    if (name === key.trim()) {
      return value;
    }
  }

  return null;
};

/**
 * @param name
 * @param value
 * @param expires milliseconds
 */
export interface CookieOptions {
  /**
   * A path that must exist in the requested URL or the browser will not send the Cookie header.
   */
  path?: string;
  /**
   * Strict: The browser sends the cookie only for same-site requests.
   * Lax: The cookie is withheld on cross-site subrequests, but is sent when a user navigates to the URL from an external site, such as by following a link.
   * None: The browser sends the cookie with both cross-site and same-site requests
   */
  sameSite?: 'lax' | 'strict' | 'none';
}

export const setCookie = (
  name: string,
  value: string,
  expires: number,
  options: CookieOptions = {},
): void => {
  let expiresStr = '';
  const date = new Date();
  date.setTime(date.getTime() + expires);
  expiresStr = `expires=${date.toUTCString()}`;

  let mappedOptions = '';
  if (!options['path']) {
    mappedOptions += ' path=/;';
  }

  for (const [key, value] of Object.entries(options)) mappedOptions += ` ${key}=${value};`;

  document.cookie = `${name}=${value}; ${expiresStr};${mappedOptions}`;
};

export const removeCookie = (name: string): void => {
  setCookie(name, '', -1);
};
