import { useCallback, useEffect, useRef, useState } from 'react';

import {
  GoogleAnalyticsLabels,
  setDimension,
  TrackingDimensions,
} from '@ecp/utils/analytics/tracking';

import { useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import { Button, Form, NextPageInstructions } from '@ecp/features/sales/shared/components';
import {
  getAnswer,
  getPersonRefForDriver,
  setFormErrorsChangedByField,
  useFieldWithPrefix,
  useForm,
} from '@ecp/features/sales/shared/store';
import type {
  RootStore,
  ValidateFormParams,
  ValidateFormResult,
} from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Incident } from '@ecp/features/sales/shared/types';

import { DriverFormQuestions } from '../../formBody';
import type { DriverFields, IncidentFields } from '../../state';
import { getAllDriverFormConditions, useIsSniValue } from '../../state';
import { useDeepRemoveDriver } from '../../state/modelUtil';
import { useStyles } from './DriverForm.styles';

interface Props {
  isPni: boolean;
  driverRef: string;
  driverFields: DriverFields;
  incidentFields: IncidentFields;
  incidentList: Incident[];
  incidentRequired: boolean;
  setIncidentRequired: (value: boolean) => void;
  validateFormAndIncidents: (
    validateForm: (params?: ValidateFormParams) => ValidateFormResult,
    inDialog: boolean,
  ) => boolean;
  onNext?: () => void;
  needActions: boolean;
  isEdit: boolean;
}

export const DriverForm: React.FC<Props> = (props) => {
  const {
    driverFields,
    driverFields: {
      driver: { firstName },
    },
    incidentRequired,
    setIncidentRequired,
    validateFormAndIncidents,
    onNext,
    isPni,
    driverRef,
    needActions,
    isEdit,
  } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const personRef = useSelector((state: RootStore) =>
    getPersonRefForDriver(state, { driverRef }),
  ) as string;

  const {
    props: { value: name = '' },
  } = firstName;

  const possessiveName = name ? `${name}'s` : `the driver's`;

  const isSniValue = useIsSniValue(personRef);

  const [isRemoving, setRemoving] = useState<boolean>(false);
  const priorRefsKey = `${driverRef}.priorInsurance.ref`;
  const priorRefsValue = useSelector((state: RootStore) =>
    getAnswer(state, priorRefsKey),
  ) as Array<string>;
  const priorRef = priorRefsValue?.[0] || '';

  const userPriorInsuranceField = useFieldWithPrefix(priorRef, 'priorInsurance.<id>');
  const insuranceYears = userPriorInsuranceField('years');
  const carrierName = userPriorInsuranceField('carrierName');
  const deepRemoveDriver = useDeepRemoveDriver();

  const getFields = useGetFields();
  const getInitValues = useGetInitValues();
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues: useRef(getInitValues()),
    fields: getFields(),
    formKey: driverRef,
    conditions: getAllDriverFormConditions(driverFields, isPni, isSniValue),
  });

  const nameOrCapYou = name || 'You';

  useEffect(() => {
    // for some reason, Mui Select (possibly others), on first render doesn't set the input refs properly, so it ends up creating console error and not focussing the field properly
    if (isEdit) setTimeout(validateForm, 0);
    setDimension(TrackingDimensions.DRIVER_REF, driverRef);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverRef]);

  const handleSubmit = useCallback(async () => {
    if (
      !validateFormAndIncidents(validateForm, false) ||
      (isPni &&
        insuranceYears.exists &&
        !insuranceYears.value &&
        carrierName.value !== 'CARRIER.NONE')
    ) {
      // Check for insurance years question for validation
      // sapi v4 can't, currently, handle the required logic for prior insurance questions.
      if (!insuranceYears.value && insuranceYears.exists && carrierName.value !== 'CARRIER.NONE') {
        dispatch(
          setFormErrorsChangedByField({
            key: insuranceYears.key,
            errors: ['Required field'],
          }),
        );
      }

      return;
    }
    await patchFormValues();
    await onNext?.();
  }, [
    validateFormAndIncidents,
    validateForm,
    isPni,
    insuranceYears.exists,
    insuranceYears.value,
    insuranceYears.key,
    patchFormValues,
    onNext,
    carrierName.value,
    dispatch,
  ]);

  const handleRemoveDriver = useCallback(async (): Promise<void> => {
    setRemoving(true);
    await deepRemoveDriver(driverRef);
    await onNext?.();
    setRemoving(false);
  }, [deepRemoveDriver, onNext, driverRef]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        {!isPni && <p className={classes.sniTitle}>Adding another driver</p>}
        {isPni && <p className={classes.about}>{`About ${nameOrCapYou}`}</p>}
        <DriverFormQuestions
          driverRef={driverRef}
          personRef={personRef}
          isPni={isPni}
          incidentRequired={incidentRequired}
          setIncidentRequired={setIncidentRequired}
        />
        {needActions && (
          <>
            <NextPageInstructions divider>
              {`This finishes ${possessiveName} profile. What's next?`}
            </NextPageInstructions>
            <div className={classes.buttonSection}>
              <div className={classes.addButton}>
                <Button
                  variant='primary'
                  onClick={handleSubmit}
                  className={classes.next}
                  data-testid='driverFormContinue'
                  isProcessing={isPatchFormInProgress}
                  trackingName={GoogleAnalyticsLabels.CONTINUE}
                  trackingLabel='driver_details_continue'
                  analyticsElement='choice.driverDetailsPage.continueButton'
                >
                  Continue
                </Button>
              </div>
              {!isPni && (
                <div className={classes.cancelButton}>
                  <Button
                    variant='iconTextMedium'
                    onClick={handleRemoveDriver}
                    className={classes.next}
                    data-testid='driverFormRemove'
                    isProcessing={isRemoving}
                    trackingName='remove_driver'
                    trackingLabel={`remove_driver_${driverRef}`}
                    analyticsElement='choice.reviewVehicleAndDriverPage.DeleteDriverButton'
                    analyticsEventDetail={driverRef}
                  >
                    Remove driver
                  </Button>
                </div>
              )}
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
