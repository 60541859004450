import { PagePath } from './constants';

export const nextPage = (
  hasAutoDeltaQuestions: boolean,
  isShortFlow: boolean,
  indicative: boolean,
): PagePath => {
  let path: PagePath | undefined;
  if (hasAutoDeltaQuestions) {
    path = isShortFlow ? PagePath.AUTO_DELTA : undefined;
  }

  if (path === undefined) {
    path = indicative ? PagePath.AUTO_DELTA : PagePath.COVERAGES;
  }

  return path;
};
