import { useAddFields } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';

import metadata from './metadata';
import { useStyles } from './PaperlessBillingQuestion.styles';

export const PaperlessBillingQuestion: React.FC<QuestionProps> = (props) => {
  const {
    label = metadata.label,
    trackingName = 'paperless_billing_selection',
    helperText = metadata.helperText,
  } = props;
  const { classes } = useStyles();
  const paperless = useField('discount.auto.paperless');
  useAddFields({ paperless });

  if (!paperless.exists) return null;

  return (
    <div className={classes.helperText}>
      <RadioGroupWithOptions
        {...paperless.props}
        label={label}
        helperText={helperText}
        id='Paperless'
        variant='yesNoButton'
        trackingName={trackingName}
      />
    </div>
  );
};
