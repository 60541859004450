import { useCallback, useMemo } from 'react';

import type { SelectProps } from '@ecp/components';
import { GridItem } from '@ecp/components';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import type { AnswerValue } from '@ecp/types';

import type { VehicleOptions } from '../../../../../forms';
import { useVehicleInfo } from '../../../../../forms';
import { checkAndClearFields } from '../../../../../state';
import { useVehicleFields } from '../../../../../state/modelUtil/vehicleModelUtil';
import { useStyles } from '../VehicleInfoQuestions.styles';

type Props = QuestionProps &
  Pick<VehicleOptions, 'modelOptions' | 'isModelRequestInProgress'> & {
    vehicleRef: string;
    setMaskRequiredFieldError: (value: boolean) => void;
    maskRequiredFieldError: boolean;
  };

export const VehicleModelQuestion: React.FC<Props> = (props) => {
  const {
    isModelRequestInProgress,
    modelOptions,
    maskRequiredFieldError,
    vehicleRef,
    setMaskRequiredFieldError,
  } = props;
  const { classes } = useStyles();
  const { make, model, series, vin, stubVin, vehicleDetailId } = useVehicleFields(vehicleRef);

  const { setInfoError } = useVehicleInfo(make, model, series, vin, stubVin, vehicleDetailId);

  const smartError = (error: string | undefined, value: AnswerValue): string | undefined => {
    if (!error || !maskRequiredFieldError) return error;

    return maskRequiredFieldError && !value ? undefined : error;
  };

  const handleModelChange: NonNullable<SelectProps['actionOnChange']> = useCallback(
    (value) => {
      // Because we combine both car and truck models under a single 'Make' (example Ford) we need
      // to make sure that the truck specific make (example Ford Truck) is selected if the model is
      // a truck(example F150) in order to get a successful API call
      const selectedModel = modelOptions.find((item) => item.value === value);
      if (selectedModel && selectedModel?.make && make?.value !== selectedModel?.make) {
        make.props.actionOnChange(selectedModel.make);
      }
      setMaskRequiredFieldError(true);
      setInfoError('');
      checkAndClearFields([series, vin, stubVin, vehicleDetailId]);
      model.props.actionOnChange(value);
    },
    [
      modelOptions,
      make?.value,
      make.props,
      setMaskRequiredFieldError,
      setInfoError,
      series,
      vin,
      stubVin,
      vehicleDetailId,
      model.props,
    ],
  );

  const { error: modelFieldError, ...modelProps } = model.props;
  const modelFieldDisabled = useMemo((): boolean => {
    return isModelRequestInProgress || modelOptions.length === 0;
  }, [isModelRequestInProgress, modelOptions.length]);
  if (!model.exists) return null;

  return (
    <GridItem topSpacing='sm' xs={12} className={classes.vehicleInfoSelect}>
      <Select
        error={smartError(modelFieldError, model.value)}
        {...modelProps}
        disabled={modelFieldDisabled}
        id='VehicleModel'
        label='Vehicle model'
        actionOnChange={handleModelChange}
        options={modelOptions}
        data-testid='model'
        trackingName='VehicleModel'
      />
    </GridItem>
  );
};
