import { makeStyles } from '@ecp/themes/base';

import type { AutoDiscountsQuestionsProps } from './AutoDiscountsQuestions';

export const useStyles = makeStyles<AutoDiscountsQuestionsProps>({
  name: 'AutoDiscountsQuestions',
})((...[, { header }]) => ({
  root: {
    marginTop: header ? 15 : 0,
  },
}));
