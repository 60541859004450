export interface Metadata {
  hideDobEmailQuestionSection: boolean;
  showMiddleAndSuffixSection: boolean;
}

export const metadata: Metadata = {
  hideDobEmailQuestionSection: false,
  showMiddleAndSuffixSection: false,
};

export default metadata;
