export const partner = {
  shared: {
    partnerDisplayName: 'Homesite DIRECT',
    salesEmailQuotesAddress: 'homesales@homesite.com',
    salesPhoneHours: 'Monday - Friday: 8:00am - 11:00pm ET, Saturday - Sunday: 9:00am - 6:00pm ET',
    servicePhoneHours:
      'Monday - Friday: 8:00am - 11:00pm ET, Saturday: 9:30am - 8:00pm ET, Sunday: closed',
    qnbPhoneNumber: '1-800-947-0713',
    dnqPhoneNumber: '1-800-947-0713',
    salesPhoneNumber: '1-800-947-0713',
    servicePhoneNumber: '1-800-466-3748',
    agentFromEmailAddress: 'donotreply@email.homesite.com',
    agentFromEmailName: 'Homesite Insurance',
  },
  lobDetail: { AUTO: { dnqNumbers: {}, discount: {} } },
} as const;
