export interface Metadata {
  showHearAboutUsReferenceNumberQuestions: boolean;
  showAdvisorNameSearchQuestions?: boolean;
}

const metadata: Metadata = {
  showHearAboutUsReferenceNumberQuestions: false,
};

export default metadata;
