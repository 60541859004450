import { PaymentPlan, SELECTED_PAYMENT_PLAN } from '@ecp/features/sales/shared/constants';

import { STATIC_QUESTIONS as STATIC_QUESTIONS_BASE } from './structure.js';

export * from './structure.js';

export const STATIC_QUESTIONS: typeof STATIC_QUESTIONS_BASE = {
  ...STATIC_QUESTIONS_BASE,
  [SELECTED_PAYMENT_PLAN]: {
    ...STATIC_QUESTIONS_BASE[SELECTED_PAYMENT_PLAN],
    defaultValue: PaymentPlan.FULL_PREMIUM,
  },
};
