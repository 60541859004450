import { datadogLog } from '@ecp/utils/logger';
import { sessionStorage } from '@ecp/utils/storage';

import { env } from '@ecp/env';
import { PARTNER_EXPERIENCE_ID } from '@ecp/features/sales/shared/constants';
import type { ExperienceId } from '@ecp/partners';

import { SAPI_AUTH_TOKEN } from './constants';
import { getSapiAuthToken } from './sapiAuthToken';
import type { TokenStorage } from './types';

type AgentTokenStorage = {
  [T in ExperienceId]+?: TokenStorage;
};

const getToken = (expId: ExperienceId) => (): TokenStorage | undefined =>
  (sessionStorage.getItem(SAPI_AUTH_TOKEN) as AgentTokenStorage)?.[expId];

const setToken =
  (expId: ExperienceId) =>
  (token: TokenStorage): void => {
    const existingItem = sessionStorage.getItem(SAPI_AUTH_TOKEN) as AgentTokenStorage;

    sessionStorage.setItem(SAPI_AUTH_TOKEN, {
      ...existingItem,
      [expId]: token,
    });
  };

const getAuthToken = (expId: ExperienceId): (() => Promise<string>) => {
  const sapiCredentials = env.sapiClientCredentials[expId];
  if (!sapiCredentials) {
    datadogLog({
      logType: 'error',
      message: `Missing sapi id and secret for ${expId}`,
      context: {
        logOrigin:
          'libs/features/sales/shared/utils/network/src/sapiAuthToken/multiPartnerSapiAuthToken.ts',
        functionOrigin: 'getAuthToken',
      },
    });

    throw new Error(`Missing sapi id and secret for ${expId}`);
  }

  return getSapiAuthToken({
    getToken: getToken(expId),
    setToken: setToken(expId),
    audience: env.audience,
    sapiClientId: sapiCredentials.sapiClientId,
    sapiClientSecret: sapiCredentials.sapiClientSecret,
  });
};

export const getMultiPartnerSapiAuthToken = (): (() => Promise<string>) => {
  const tokens: { [T in ExperienceId]+?: () => Promise<string> } = {};

  return async (): Promise<string> => {
    // Need to use session storage, since expId in store could be wrong
    // or not exist if we are using this token to create an inquiry
    const expId =
      (sessionStorage.getItem(PARTNER_EXPERIENCE_ID) as ExperienceId | undefined) ??
      env.static.expId;

    let returnToken = tokens[expId];

    if (!returnToken) {
      returnToken = tokens[expId] = getAuthToken(expId);
    }

    return returnToken();
  };
};
