import type { AgentScripts } from './types';

export const creditDisclaimersMetadata = (): AgentScripts => {
  return {
    title: 'Consumer reports disclosure',
    text: 'To provide you the most accurate quote, we will use information provided by consumer reporting agencies. You are consenting to this by continuing with the quote. Your rights are described in the Fair Credit Report Act and our privacy policies, including how you may access and correct your information.',
    spanishText:
      'Para proveer le la cotización más precisa utilizaremos información proporcionada por agencias de informes del consumidor. Usted da su consentimiento a esto al continuar con la cotización.Sus derechos se describen en la Ley de Informe Imparcial de Crédito y nuestra Póliza de Privacidad, incluyendo como puede obtener acceso y corregir su información.',
  };
};
