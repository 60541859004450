import { useCallback, useRef, useState } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { useGetConditionValues, useGetFields, useGetInitValues } from '@ecp/features/sales/form';
import { Button, Form } from '@ecp/features/sales/shared/components';
import { useForm } from '@ecp/features/sales/shared/store';
import type { Field } from '@ecp/types';

import { AutoShortDiscountsFormQuestions } from '../../formBody';
import { useStyles } from './AutoShortDiscountsForm.styles';

export type AutoDiscounts = { [ref: string]: Field };

export interface AutoShortDiscountsFormProps {
  onNext: () => Promise<void>;
  subHeader?: string;
  subTitle?: string;
}

export const AutoShortDiscountsForm: React.FC<AutoShortDiscountsFormProps> = (props) => {
  const { onNext, subHeader = '', subTitle = '' } = props;
  const { classes } = useStyles();
  const initValues = useRef(useGetInitValues()());
  const getConditions = useGetConditionValues();
  const getFields = useGetFields();
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues,
    fields: getFields(),
    conditions: getConditions(),
  });

  const [isContinueClicked, setContinueClicked] = useState(false);

  const updateAnswers = useCallback(async () => {
    setContinueClicked(true);
    const { isValid } = validateForm();
    if (isValid) {
      await patchFormValues();
      await onNext();
      setContinueClicked(false);
    }

    return isValid;
  }, [patchFormValues, validateForm, onNext]);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.subTitle}>
            {subHeader}
          </Grid>
          <AutoShortDiscountsFormQuestions
            isContinueClicked={isContinueClicked}
            subTitle={subTitle}
          />
        </Grid>
        <Button
          variant='primary'
          onClick={updateAnswers}
          isProcessing={isPatchFormInProgress}
          data-testid='continue'
          className={classes.next}
          trackingLabel='auto_discounts_continue'
          trackingName={GoogleAnalyticsLabels.CONTINUE}
          analyticsElement='choice.discountPage.continueButton'
        >
          Continue
        </Button>
      </Form>
    </div>
  );
};
