export interface Metadata {
  subTextContent: string;
  subTextContentError: string;
  allowOneMarriedDriver: boolean;
  showSpecialCircumstancesLink: boolean;
  ignoreLicenseStatus?: boolean;
  ignoreLapseQuestion?: boolean;
  priorInsurancePrefix: string;
  defaultNewDriverSNI?: boolean;
}

export const metadata: Metadata = {
  subTextContent:
    'If anyone on the policy is legally married, in a domestic partnership, or in a civil union, their significant other must also be listed on the policy.',
  subTextContentError:
    'Your quote can’t have one married driver. Change a single driver to married, or a married driver to single to continue.',
  allowOneMarriedDriver: false,
  showSpecialCircumstancesLink: true,
  ignoreLicenseStatus: false,
  priorInsurancePrefix: 'insurance.prior',
  defaultNewDriverSNI: false,
};

export default metadata;
