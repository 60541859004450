export const partner = {
  shared: {
    partnerDisplayName: 'CoverMyStuff Insurance Program',
    salesEmailQuotesAddress: 'contactus@midvaleinsurance.com',
    salesPhoneHours: 'Monday - Friday: 8:00am - 11:00pm ET, Saturday - Sunday: 9:00am – 6:00pm ET',
    servicePhoneHours:
      'Monday - Friday: 8:00am - 11:00pm ET, Saturday: 9:30am – 8:00pm ET, Sunday: closed',
    qnbPhoneNumber: '1-844-375-2495',
    dnqPhoneNumber: '1-844-375-2495',
    salesPhoneNumber: '1-844-375-2495',
    servicePhoneNumber: '1-844-379-3731',
    agentFromEmailAddress: 'contactus@midvaleinsurance.com',
    agentFromEmailName: 'CoverMyStuff Insurance Program',
  },
  lobDetail: {
    AUTO: {
      partnerDisplayNameShort: 'CoverMyStuff',
      salesPhoneNumber: '1-844-375-2495',
      servicePhoneNumber: '1-844-375-2495',
      dnqNumbers: { default: '1-844-819-7543' },
      discount: {},
    },
  },
} as const;
