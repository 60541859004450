import { useEffect } from 'react';

import { Events, FeatureFlags, flagValues, trackEvent } from '@ecp/utils/flags';

import { env } from '@ecp/env';
import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page, ProductQuoteNumber } from '@ecp/features/sales/shared/components';
import { USER_SELECTION } from '@ecp/features/sales/shared/constants';
import { PagePath, useNavigateToNextPage } from '@ecp/features/sales/shared/routing';
import {
  getDrivers,
  getIsBundleForOfferProductsSelected,
  getOfferProductsSelectedByType,
  getSapiAnalyticsSelectedOfferEventDetail,
  getUserSelection,
  getVehicles,
  updateAnswers,
  usePniRef,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { UserSelection } from '@ecp/features/shared/product';

import { useDriverAutoDeltaMetadata, useVehicleAutoDeltaMetadata } from '../../state';
import { AutoDeltaForm } from './AutoDeltaForm';
import { useStyles } from './AutoDeltaPage.styles';
import { AutoDeltaTopBanner } from './AutoDeltaTopBanner';
import * as metadata from './metadata';

export const AutoDeltaPage: React.FC = () => {
  const { classes } = useStyles();
  const { auto: autoOfferProduct } = useSelector(getOfferProductsSelectedByType);
  const isBundle = useSelector(getIsBundleForOfferProductsSelected);

  const disableVehicleServiceCallFlag = flagValues[FeatureFlags.DISABLE_VEHICLE_SERVICE_CALL];

  // Vehicle Data
  const vehicles = useSelector(getVehicles);
  const vehicleMetadata = useVehicleAutoDeltaMetadata(vehicles);

  // Driver Data
  const pniRef = usePniRef();
  const drivers = useSelector(getDrivers);
  const driverMetadata = useDriverAutoDeltaMetadata(drivers, pniRef);
  const handleNext = useNavigateToNextPage();

  const sapiAnalyticsOfferEventDetail = useSelector(getSapiAnalyticsSelectedOfferEventDetail);

  // To handle forward and back navigation for product.userSelection
  const dispatch = useDispatch();
  const bundleTypeFromInquires = useSelector(getUserSelection);
  const bundleTypeOfSelectedProduct = isBundle ? UserSelection.BUNDLED : UserSelection.UNBUNDLED;

  // TODO: We might need a better way to handle this. A/B test metric tracking function for auto delta page view
  useEffect(() => {
    trackEvent(Events.AUTO_DELTA_PAGE);
  }, []);

  // The dependencies would make sure user selection gets properly updated.
  // Also, this would avoid any mismatch because of edge case back nav scenarios
  // TODO - Revisit this useEffect after SAPI resolves confusion regarding product.userSelection in answers
  useEffect(() => {
    // To always keep SAPI updated about the user product selection,
    // Quotes -> AutoDelta
    if (bundleTypeFromInquires === null || bundleTypeFromInquires !== bundleTypeOfSelectedProduct) {
      dispatch(updateAnswers({ answers: { [USER_SELECTION]: bundleTypeOfSelectedProduct } }));
    }
  }, [bundleTypeFromInquires, bundleTypeOfSelectedProduct, dispatch]);

  if (!autoOfferProduct) return null;

  return (
    <div className={classes.root}>
      <Page
        analyticsElement='choice.autoDeltaPage.Page'
        analyticsEventDetail={sapiAnalyticsOfferEventDetail}
        sidebarProps={{
          drawer: <NavbarDrawer pagePath={PagePath.AUTO_DELTA} />,
          forceSwipeable: !env.static.isAgent,
        }}
        title={<AutoDeltaTopBanner />}
        subTitle={<ProductQuoteNumber />}
        variant={metadata.autoDeltaPageHeaderVariant}
      >
        <AutoDeltaForm
          driverMetadata={driverMetadata}
          drivers={drivers}
          vehicleMetadata={vehicleMetadata}
          onNext={handleNext}
          autoOfferProduct={autoOfferProduct}
          allVehicles={vehicles}
          disableVehicleServiceCallFlag={disableVehicleServiceCallFlag}
        />
      </Page>
    </div>
  );
};
