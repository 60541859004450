import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'SpecialCircumstancesModal' })((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  specialCircumstancesList: {
    display: 'flex',
    flexDirection: 'column',
    '& ul': {
      marginTop: 0,
      paddingLeft: 20,
    },
  },
  dialogContainer: {
    marginBottom: 7,
    display: 'flex',
    flexDirection: 'row',
  },
}));
