import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'VehicleOwnershipDateQuestion' })((theme) => ({
  label: {
    ...theme.typography.body3Bold,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      margin: '10px 0px 5px',
    },
  },
  labelImage: {
    width: 80,
    height: 60,
    marginRight: 25,
    [theme.breakpoints.down('md')]: {
      width: 40,
      height: 30,
      marginRight: 10,
    },
  },
}));
