import { useCallback } from 'react';

import { FormLabel, Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { isMasked } from '@ecp/utils/common';

import type { SelectProps, TextFieldProps } from '@ecp/components';
import { GridItem } from '@ecp/components';
import { env } from '@ecp/env';
import { useAddFields } from '@ecp/features/sales/form';
import { Select, TextField } from '@ecp/features/sales/shared/components';
import { useField } from '@ecp/features/sales/shared/store';

import { VIN_REGEX_NEGATED_SYMBOL } from '../../../../constants';
import { useVehicleInfo, yearOptions } from '../../../../forms';
import { useStyles } from '../../../../forms/VehicleProfileForm/VehicleProfileForm.styles';
import {
  checkAndClearFields,
  useValidateVinNumber,
  useVehicleInfoOrVinValue,
  validateVinFormatRegex as validateVinFormat,
} from '../../../../state';

interface Props {
  vehicleRef: string;
  setMaskRequiredFieldError: (value: boolean) => void;
}

export const VehicleYearVinQuestion: React.FC<Props> = (props) => {
  const { vehicleRef, setMaskRequiredFieldError } = props;
  const { classes } = useStyles();

  const vehicleInfoOrVin = useField(`static.${vehicleRef}.vehicleInfoOrVin`);
  const make = useField(`${vehicleRef}.make`);
  const model = useField(`${vehicleRef}.model`);
  const series = useField(`${vehicleRef}.series`);
  const vin = useField(`${vehicleRef}.vin`);
  const year = useField(`${vehicleRef}.year`);
  const stubVin = useField(`${vehicleRef}.stubVin`);
  const vehicleDetailId = useField(`${vehicleRef}.vehicleDetailId`);

  useAddFields({ year, vin });

  const { fetchVehicleInfo, setInfoError } = useVehicleInfo(
    make,
    model,
    series,
    vin,
    stubVin,
    vehicleDetailId,
  );

  const vehicleInfoOrVinValue = useVehicleInfoOrVinValue(vehicleInfoOrVin, vin);

  // Set initial image url to empty string to show the generic car image

  // Following useEffect is commented as a part of epic ECP-13116 which expects getting rid of fuelApi
  // and update page layouts accordingly.
  // Reason behind commenting and not removing it or putting it behind feature flag is that this code
  // is needed soon for fuelApi replacement(EVOX). So avoiding code clutters and re-work by commenting it.
  // const vehicleImageUrl = useRef('');
  // useEffect(() => {
  //   const getVehicleImageUrl = async (): Promise<void> => {
  //     vehicleImageUrl.current = await dispatch(
  //       gettingVehicleImage({
  //         year: ensureStringOrNull(year.value),
  //         make: ensureStringOrNull(make.value),
  //         model: ensureStringOrNull(model.value),
  //       }),
  //     );
  //   };
  //   if (year.value && make.value && model.value) {
  //     getVehicleImageUrl();
  //   }
  // }, [year, dispatch, make.value, model.value]);

  //   const validateVehicleInfo = useValidateVehicleInfo(vehicleInfoOrVinValue, make, model, series);
  const validateVinNumber = useValidateVinNumber(vin);

  const handleYearChange: NonNullable<SelectProps['actionOnChange']> = useCallback(
    (value) => {
      setMaskRequiredFieldError(true);
      setInfoError('');
      checkAndClearFields([make, model, series, stubVin, vehicleDetailId]);
      if (isMasked(vin.value)) {
        checkAndClearFields([vin]);
      }
      year.props.actionOnChange(value);
      if (isMasked(vin.value)) {
        vin.props.actionOnChange(null);
        vin.props.actionOnComplete(null);
      }
    },
    [
      setMaskRequiredFieldError,
      setInfoError,
      series,
      model,
      make,
      year.props,
      vin,
      stubVin,
      vehicleDetailId,
    ],
  );

  const handleYearComplete: NonNullable<SelectProps['actionOnComplete']> = useCallback(
    async (value) => {
      year.props.actionOnComplete(value);
      const vinValue = vin.value as string;
      if (vehicleInfoOrVinValue === 'vin' && !validateVinFormat(vinValue) && !isMasked(vinValue)) {
        await fetchVehicleInfo(value, vinValue);
        validateVinNumber();
      }
    },
    [year.props, vin.value, vehicleInfoOrVinValue, fetchVehicleInfo, validateVinNumber],
  );

  const handleVinOnChange: NonNullable<TextFieldProps['actionOnChange']> = useCallback(
    (value) => {
      if (!value && vehicleInfoOrVin.value === undefined) {
        vehicleInfoOrVin.props.actionOnComplete('vin');
      }
      if (!isMasked(value)) {
        const vinString = value.toUpperCase().replace(VIN_REGEX_NEGATED_SYMBOL, '');
        setInfoError('');
        vin.props.actionOnChange(vinString);
        // reset fields on change
        if (!vinString) {
          setMaskRequiredFieldError(true);
          vin.validateUpdateAndPatch(null);
          checkAndClearFields([make, model, series, stubVin, vehicleDetailId]);
        }
      } else {
        vin.props.actionOnChange(null);
        vin.validateUpdateAndPatch(null);
        setMaskRequiredFieldError(true);
        checkAndClearFields([make, model, series, stubVin, vehicleDetailId]);
      }
    },
    [
      make,
      model,
      series,
      setInfoError,
      setMaskRequiredFieldError,
      vehicleInfoOrVin.props,
      vehicleInfoOrVin.value,
      vin,
      stubVin,
      vehicleDetailId,
    ],
  );

  const handleVinOnComplete: NonNullable<TextFieldProps['actionOnComplete']> = useCallback(
    async (value) => {
      if (isMasked(value)) return;
      if (!value) {
        setMaskRequiredFieldError(true);
        checkAndClearFields([make, model, series, stubVin, vehicleDetailId]);
      }
      if (year.value && typeof year.value === 'string' && !validateVinFormat(value)) {
        const vehicleInfoError = await fetchVehicleInfo(year.value, value);
        if (!vehicleInfoError) {
          validateVinNumber();
        }
      }
      if (env.static.isAgent) vin.props.actionOnComplete(value);
    },
    [
      year.value,
      validateVinNumber,
      setMaskRequiredFieldError,
      series,
      model,
      make,
      fetchVehicleInfo,
      stubVin,
      vehicleDetailId,
      vin,
    ],
  );

  return (
    <>
      <FormLabel>Enter vehicle identification number (VIN)</FormLabel>
      <Grid container>
        <Grid
          container
          columnSpacing={4}
          item
          xs={12}
          lg={12}
          className={classes.vehicleFieldsContainer}
        >
          {year.exists && (
            <GridItem topSpacing='sm' xs={12} md={6}>
              <Select
                {...year.props}
                id='VehicleYear'
                label='Vehicle year'
                actionOnComplete={handleYearComplete}
                actionOnChange={handleYearChange}
                options={yearOptions}
                data-testid='year'
                trackingName='vin_year_selection'
              />
            </GridItem>
          )}
          {vin.exists && (
            <GridItem topSpacing='sm' xs={12} md={6}>
              <TextField
                {...vin.props}
                error={vin.props.error || validateVinFormat(vin.value as string)}
                id='VIN'
                label='VIN'
                ariaLabel='VIN'
                actionOnChange={handleVinOnChange}
                actionOnComplete={handleVinOnComplete}
                trackingName='VIN'
                trackingLabel={GoogleAnalyticsLabels.REDACTED}
              />
            </GridItem>
          )}
        </Grid>
        {/* Commenting following grid temporarily, we'll be using it again */}
        {/* <Grid container item xs={12} lg={6} className={classes.columnRight}>
          <GridItem
            topSpacing={isMobile ? 'md' : 'sm'}
            className={cx(
              classes.imageContainer,
              vehicleInfoOrVinValue === 'vehicleInfo' && classes.vehicleInfoImageContainer,
            )}
          >
            <img
              className={classes.image}
              alt={`${year.value} ${make.value} ${model.value}`}
              src={vehicleImageUrl.current || GraphicGenericCarImageUrl}
            />
          </GridItem>
        </Grid> */}
      </Grid>
    </>
  );
};
