import { useCallback, useRef, useState } from 'react';

import { Divider } from '@mui/material';

import * as interactionId from '@ecp/utils/analytics/interaction-id';
import { ensureZipCode, isMasked } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';

import { LogoSpinner } from '@ecp/components';
import { Alert } from '@ecp/components';
import { useGetConditionValues } from '@ecp/features/sales/form';
import { Form, ReviewPeriodDialog } from '@ecp/features/sales/shared/components';
import {
  PARTNER_EXPERIENCE_ID,
  PRIMARY_INSURED_ADDRESS_REF,
  PRIMARY_INSURED_MAILING_ADDRESS_REF,
  PRIMARY_INSURED_PERSON_REF,
  STATE_CODE_PREFIX,
} from '@ecp/features/sales/shared/constants';
import {
  createInquiry,
  createRef,
  fetchCityAndStateSuggestionsThunkAction,
  getDalSessionId,
  getFieldErrors,
  getInquiryLoaded,
  getLineOfBusiness,
  getRecallErrorMessage,
  setFormErrorsChangedByField,
  setFormErrorsResetByField,
  setZipLookupBypassed,
  submitPolicyType,
  updateAnswers,
  updateLobOrder,
  useForm,
  useGetPartnerFields,
  useGetPersonFields,
  useGetProductFields,
  usePniRef,
  usePrimaryAddressRef,
} from '@ecp/features/sales/shared/store';
import type { ThunkAction, ValidateFormResult } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Answers, AnswerValue } from '@ecp/features/sales/shared/types';
import type { ExperienceId } from '@ecp/partners';
import { IconUIExclaimTriangle } from '@ecp/themes/base';
import type { Fields } from '@ecp/types';

import { CallRecordingModal } from '../../components';
import { CallToAction } from './CallToAction';
import { useStyles } from './LandingPageForm.styles';
import metadata from './metadata';
import { PartnerQuestions } from './PartnerQuestions';
import { PersonQuestions } from './PersonQuestions';
import type { ProductQuestionsProps } from './ProductQuestions';
import { ProductQuestions } from './ProductQuestions';
import { useReviewPeriodDialogProps } from './utils';

interface LandingPageFormProps {
  isRecallInProgress: boolean;
  onRetrieveSubmit: () => void;
  onStartNewQuote: () => Promise<void>;
}

interface SubmitParams extends Pick<ProductQuestionsProps, 'validateZipCodeAndGetStateCity'> {
  onStartNewQuote: LandingPageFormProps['onStartNewQuote'];
  patchFormValues: () => Promise<string>;
  productFields: ReturnType<typeof useGetProductFields>;
  setIsNewQuoteInProgress: (f: boolean) => void;
  validateForm: () => ValidateFormResult;
}

const getResetStateCity = (primaryAddressRef: string): Answers => ({
  [`${primaryAddressRef}.state`]: '',
  [`${primaryAddressRef}.city`]: '',
});

const startNewQuoteAction =
  ({
    onStartNewQuote,
    patchFormValues,
    productFields,
    setIsNewQuoteInProgress,
    validateForm,
    validateZipCodeAndGetStateCity,
  }: SubmitParams): ThunkAction<Promise<void>> =>
  (dispatch, getState) =>
    new Promise((resolve, reject) => {
      setIsNewQuoteInProgress(true);
      setTimeout(async () => {
        if (!validateForm().isValid) {
          setIsNewQuoteInProgress(false);

          return resolve();
        }
        const stateCity = await validateZipCodeAndGetStateCity(productFields.zipCode.value);
        await dispatch(
          updateAnswers({
            answers: {
              ...stateCity,
              [productFields.zipCode.key]: productFields.zipCode.value,
            },
            // If we already submitted zip/state/city (via field blur),
            // these values will be automatically excluded via preprocessAnswers in updateAnswers
            // and SAPI won't respond with "product not eligible" error,
            // as they only pass this error each time we submit state answer.
            // We want to force-include these answers.
            force: true,
          }),
        );
        await dispatch(submitPolicyType());
        let zipCodeFieldErrors = getFieldErrors(getState(), productFields.zipCode.key);
        if (zipCodeFieldErrors.length) {
          setIsNewQuoteInProgress(false);

          return resolve();
        }

        await patchFormValues();
        const lob = getLineOfBusiness(getState());
        await dispatch(updateLobOrder(lob));
        // Should validate the zip code after patchFormValues
        // here to keep form errors intact and accurate.
        // SAPI returns error only while trying to patch
        // unsupported state and city and not for zip.
        await validateZipCodeAndGetStateCity(productFields.zipCode.value);

        zipCodeFieldErrors = getFieldErrors(getState(), productFields.zipCode.key);

        if (zipCodeFieldErrors.length) {
          setIsNewQuoteInProgress(false);

          return resolve();
        }
        await onStartNewQuote();
        setIsNewQuoteInProgress(false);

        return resolve();
      }, 0);
    });

export const LandingPageForm: React.FC<LandingPageFormProps> = (props) => {
  const { isRecallInProgress, onRetrieveSubmit, onStartNewQuote } = props;
  const productFields = useGetProductFields();
  const personFields = useGetPersonFields();
  const partnerFields = useGetPartnerFields();
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const primaryAddressRef = usePrimaryAddressRef();
  const pniRef = usePniRef();
  const recallError = useSelector(getRecallErrorMessage);
  const inquiryLoaded = useSelector(getInquiryLoaded);
  const [previousDalSessionId, setPreviousDalSessionId] = useState<string | undefined>(undefined);
  const dalSessionId = useSelector(getDalSessionId);
  const [inquiryPreviouslyRequested, setInquiryPreviouslyRequested] = useState(!!dalSessionId);
  const [isNewQuoteInProgress, setIsNewQuoteInProgress] = useState(false);
  // Need to check if at least one inquiry has been requested, otherwise spinner will always show since undefined === undefined
  const showSpinner = inquiryPreviouslyRequested && previousDalSessionId === dalSessionId;
  const initValues = useRef({});
  const getCondition = useGetConditionValues();
  const { handleClose, show: showReviewPeriodDialog } = useReviewPeriodDialogProps();

  const { validateForm, patchFormValues } = useForm({
    initValues,
    fields: { ...personFields, ...partnerFields, ...(productFields as unknown as Fields) },
    conditions: getCondition(),
  });

  const validateZipCodeAndGetStateCity = useCallback(
    async (value: AnswerValue): Promise<Answers | null> => {
      const zipcode = ensureZipCode(value);
      if (!zipcode) return getResetStateCity(primaryAddressRef);

      // Prefill city, state and zipcode for Person Page form
      const cityAndStateSuggestionResult = await dispatch(
        fetchCityAndStateSuggestionsThunkAction(zipcode),
      );
      const cityAndStateSuggestion = cityAndStateSuggestionResult?.response;
      dispatch(setZipLookupBypassed(!!cityAndStateSuggestion?.bypass));
      if (cityAndStateSuggestion?.bypass) return getResetStateCity(primaryAddressRef);

      if (!cityAndStateSuggestion?.state) {
        dispatch(
          setFormErrorsChangedByField({
            key: productFields.zipCode.key,
            errors: ['Invalid Zip Code'],
          }),
        );
        datadogLog({
          logType: 'warn',
          message: 'Invalid Zip Code',
          context: {
            logOrigin: 'apps/sales/agent/src/forms/LandingPageForm/LandingPageForm.tsx',
            functionOrigin: 'validateZipCodeAndGetStateCity',
          },
        });

        return getResetStateCity(primaryAddressRef);
      }

      dispatch(
        setFormErrorsResetByField({
          key: productFields.zipCode.key,
        }),
      );

      return {
        [`${primaryAddressRef}.state`]: `${STATE_CODE_PREFIX}${cityAndStateSuggestion.state}`,
        [`${primaryAddressRef}.city`]: cityAndStateSuggestion.city,
      };
    },
    [dispatch, primaryAddressRef, productFields.zipCode.key],
  );

  const handleStartNewQuoteSubmit = useCallback(async () => {
    await dispatch(
      startNewQuoteAction({
        onStartNewQuote,
        patchFormValues,
        productFields,
        setIsNewQuoteInProgress,
        validateForm,
        validateZipCodeAndGetStateCity,
      }),
    );
  }, [
    dispatch,
    validateForm,
    patchFormValues,
    validateZipCodeAndGetStateCity,
    productFields,
    onStartNewQuote,
  ]);

  const handleRetrieveSubmit = useCallback(async () => {
    if (validateForm().isValid) {
      await patchFormValues();
      onRetrieveSubmit();
    }
  }, [validateForm, patchFormValues, onRetrieveSubmit]);

  const handleExperienceIdChanged = useCallback(
    async (value: ExperienceId): Promise<void> => {
      const personRef = inquiryPreviouslyRequested ? pniRef : dispatch(createRef('person'));
      const addressRef = inquiryPreviouslyRequested
        ? primaryAddressRef
        : dispatch(createRef('address'));
      const existingFormAnswers = inquiryPreviouslyRequested
        ? {
            [personFields.person.firstName.key]: personFields.person.firstName.value,
            [personFields.person.lastName.key]: personFields.person.lastName.value,
            [personFields.person.dateOfBirth.key]:
              personFields.person.dateOfBirth.validate(personFields.person.dateOfBirth.value)
                .length > 0 || isMasked(personFields.person.dateOfBirth.value)
                ? null
                : personFields.person.dateOfBirth.value,
            [personFields.person.email.key]: personFields.person.email.value,
            [productFields.zipCode.key]: productFields.zipCode.value,
          }
        : {};
      // Reset product selection on exp Id change
      productFields.product.reset();
      setPreviousDalSessionId(dalSessionId);
      setInquiryPreviouslyRequested(true);

      interactionId.reset();
      await dispatch(
        createInquiry({
          answers: {
            ...existingFormAnswers,
            [PRIMARY_INSURED_PERSON_REF]: personRef,
            [PRIMARY_INSURED_ADDRESS_REF]: addressRef,
            [PRIMARY_INSURED_MAILING_ADDRESS_REF]: addressRef,
            [PARTNER_EXPERIENCE_ID]: value,
            // TODO: 'analytics.variants': stuff
          },
        }),
      );
    },
    [
      inquiryPreviouslyRequested,
      pniRef,
      dispatch,
      primaryAddressRef,
      personFields.person.firstName.key,
      personFields.person.firstName.value,
      personFields.person.lastName.key,
      personFields.person.lastName.value,
      personFields.person.dateOfBirth,
      personFields.person.email.key,
      personFields.person.email.value,
      productFields.zipCode.key,
      productFields.zipCode.value,
      productFields.product,
      dalSessionId,
    ],
  );

  const inquirySpinner = (
    <div className={classes.inquiryRoot}>
      <LogoSpinner preparingText='session' />
    </div>
  );

  return (
    <Form className={classes.root}>
      {showReviewPeriodDialog && <ReviewPeriodDialog onClose={handleClose} />}
      <PartnerQuestions onExperienceIdChange={handleExperienceIdChanged} />
      {showSpinner ? (
        inquirySpinner
      ) : (
        <>
          <PersonQuestions disabled={!inquiryLoaded} />
          <Divider className={classes.divider} />
          <ProductQuestions
            validateZipCodeAndGetStateCity={validateZipCodeAndGetStateCity}
            disabled={!inquiryLoaded}
          />
          {recallError && (
            <Alert
              type='error'
              className={classes.alert}
              withIcon
              icon={<IconUIExclaimTriangle className={classes.alertIcon} />}
            >
              {recallError}
            </Alert>
          )}
          <Divider className={classes.divider} />
          <CallToAction
            isNewQuoteInProgress={isNewQuoteInProgress}
            isRecallInProgress={isRecallInProgress}
            onRecallQuoteClick={!metadata.hideRecallQuote ? handleRetrieveSubmit : undefined}
            onStartNewQuoteClick={handleStartNewQuoteSubmit}
          />
        </>
      )}
      {inquiryLoaded && <CallRecordingModal />}
    </Form>
  );
};
