import { Dialog } from '../Dialog';

interface Props {
  onClose(): void;
}

export const ReviewPeriodDialog: React.FC<Props> = (props) => {
  const { onClose } = props;

  return (
    <Dialog
      actionButtonLabel='I understand'
      titleText='New auto policies require a 14-day review period'
      open
      onClose={onClose}
      actionButtonOnClick={onClose}
      trackingDimension='AutoReviewPeriodUnderstand'
      trackingNameCloseIcon='auto_review_period_x_button'
      trackingLabelCloseIcon='x_continue'
      trackingNameButton='auto_review_period_understand_button'
      trackingLabelButton='understand_continue'
    >
      <p>
        A review period of 14 days is required before a CONNECT auto policy can be purchased. By
        continuing with a quote, you acknowledge a policy can't be purchased until after this review
        period.
      </p>
    </Dialog>
  );
};
