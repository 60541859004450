import { setApiComplete, setApiStart } from '../../api/actions';
import { wrapThunkActionWithErrHandler } from '../../util/wrapThunkActionWithErrHandler';
import { setAnswersApiGetSuccess } from '../actions';
import { deleteInquiry } from '../api';
import { getDalSessionId } from '../selectors';

type DeleteInquiryRef = { refType: string; refId: string };

/**
 * This will bulk delete any ref anywhere it is used.
 * Basically you can pass into it 'driver' 'abc123'
 * and anywhere that ref is used it will be removed.
 *
 * This works with any ref (i.e. vehicle, address, person, etc.)
 *
 * @param {string} refType - This is the type of reference i.e. vehicle, address, person, etc.
 * @param {string} refId - This is the id of that reference
 */
export const deleteInquiryRef = wrapThunkActionWithErrHandler<DeleteInquiryRef>(
  ({ refType, refId }) =>
    async (dispatch, getState) => {
      const dalSessionId = getDalSessionId(getState());

      if (!dalSessionId) {
        return;
      }

      dispatch(setApiStart(refId));
      const response = await deleteInquiry({ dalSessionId, refType, refId });
      dispatch(setAnswersApiGetSuccess(response.payload));

      dispatch(setApiComplete(refId));
    },
  'deleteInquiryRef',
);
