import { Grid } from '@mui/material';

import type { GeoAddress } from '@ecp/utils/geo';

import { GridItem } from '@ecp/components';
import { env } from '@ecp/env';
import { AdvisorNameSearch } from '@ecp/features/sales/shared/ameriprise-advisor';
import { PRIMARY_INSURED_PERSON_LOCK } from '@ecp/features/sales/shared/constants';
import {
  CallLexisNexisQuestion,
  DateOfBirthQuestion,
  HearAboutUsReferenceNumberQuestions,
  HelpYouQuestion,
  RecentLifeEventQuestion,
  VehicleKeptAddressQuestion,
} from '@ecp/features/sales/shared/questions';
import { getBridgeType, useField, usePniRef } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { LineOfBusiness } from '@ecp/features/shared/product';

import metadata from './metadata';
import { useStyles } from './PersonLivingForm.styles';

interface Props {
  setVehicleKeptAddressSuggestions(value: GeoAddress[]): void;
  vehicleKeptAddressSuggestions: GeoAddress[];
  lineOfBusiness: LineOfBusiness;
}

export const PersonLivingFormQuestions: React.FC<Props> = (props) => {
  const { classes } = useStyles();
  const { lineOfBusiness, setVehicleKeptAddressSuggestions, vehicleKeptAddressSuggestions } = props;
  const primaryInsuredPersonLock = useField(PRIMARY_INSURED_PERSON_LOCK);
  const pniRef = usePniRef();
  const bridgeType = useSelector(getBridgeType);

  return (
    <>
      <Grid container className={classes.container}>
        <GridItem xs={12}>
          <p className={classes.subheader}>When were you born?</p>
        </GridItem>
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
          <DateOfBirthQuestion
            id='DOB'
            personRef={pniRef}
            disabled={!!primaryInsuredPersonLock.props.value}
          />
        </GridItem>
      </Grid>
      {metadata.showHearAboutUsReferenceNumberQuestions && <HearAboutUsReferenceNumberQuestions />}
      {metadata.showAdvisorNameSearchQuestions && (
        <AdvisorNameSearch disabled={primaryInsuredPersonLock.props.value} />
      )}
      <GridItem topSpacing='lg' xs={12}>
        <RecentLifeEventQuestion disabled={!!primaryInsuredPersonLock.props.value} />
      </GridItem>
      {lineOfBusiness === LineOfBusiness.BUNDLE && (
        <GridItem topSpacing='lg'>
          <VehicleKeptAddressQuestion
            disabled={!!primaryInsuredPersonLock.props.value}
            setVehicleKeptAddressSuggestions={setVehicleKeptAddressSuggestions}
            vehicleKeptAddressSuggestions={vehicleKeptAddressSuggestions}
          />
        </GridItem>
      )}
      <GridItem topSpacing='lg' md={6} xs={12} className={classes.columnLeft}>
        <HelpYouQuestion disabled={!!primaryInsuredPersonLock.props.value} />
      </GridItem>
      {bridgeType !== 'customerPrefill' && (
        <GridItem topSpacing='lg' xs={12}>
          {/* Clean this up after this logic comes from SAPI part of SAPIv4  */}
          {(lineOfBusiness === LineOfBusiness.AUTO ||
            lineOfBusiness === LineOfBusiness.BUNDLE ||
            lineOfBusiness === LineOfBusiness.BUNDLE_AUTO_RENTERS) &&
            !env.static.isAgent && (
              <CallLexisNexisQuestion disabled={!!primaryInsuredPersonLock.props.value} />
            )}
        </GridItem>
      )}
    </>
  );
};
