import { Fragment, useMemo } from 'react';

import type { TypographyProps } from '@mui/material';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

import { GridItem } from '@ecp/components';
import { getCnqDndProducts } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { useStyles } from './AgentDashboardTable.styles';

interface Column {
  id: string;
  label: string | React.ReactElement;
  minWidth?: number;
  align?: TypographyProps['align'];
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'product', label: 'Product', minWidth: 110, align: 'center' },
  { id: 'quoteDetails', label: 'Quote details', minWidth: 260 },
];

export const AgentDashboardUnavailableTable: React.FC = () => {
  const { classes, cx } = useStyles();

  const cnqDndProducts = useSelector(getCnqDndProducts);

  const rows = useMemo(
    () =>
      cnqDndProducts.map((product) => (
        <TableRow key={`${product.displayName}  `} className={cx(classes.tableRow)} tabIndex={-1}>
          <TableCell
            className={classes.tableCellNoLeftBorder}
            data-testid={`${product.displayName}Label`}
            rowSpan={1}
          >
            <p>{product.displayName}</p>
          </TableCell>

          <TableCell>
            {product.quoteDetails.map((detail, idx) => (
              <Fragment key={`${idx}-${detail.value}`}>
                <span className={idx === 0 ? classes.detailsDnqCnq : classes.detailsDescription}>
                  {detail.description}{' '}
                </span>
                <span className={cx(idx === 0 && classes.detailsDnqCnq)}>{detail.value} </span>
                <br />
              </Fragment>
            ))}
          </TableCell>
        </TableRow>
      )),
    [
      classes.detailsDescription,
      classes.tableCellNoLeftBorder,
      classes.detailsDnqCnq,
      classes.tableRow,
      cnqDndProducts,
      cx,
    ],
  );

  if (!cnqDndProducts || cnqDndProducts.length === 0) return null;

  return (
    <>
      <GridItem topSpacing='lg' xs={12}>
        <h2> Unavailable quotes</h2>
      </GridItem>
      <GridItem topSpacing='sm' xs={12}>
        <div data-testid='agentDashboardTable' className={classes.root}>
          <Grid item xs={12} className={classes.tableWrapper}>
            <Table className={classes.tableCnqDnq} stickyHeader>
              <TableHead className={cx(classes.tableHeader, classes.tableHeaderUnavailable)}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>{rows}</TableBody>
            </Table>
          </Grid>
        </div>
      </GridItem>
    </>
  );
};
