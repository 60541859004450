import { merge } from '@ecp/utils/common';

import type { Product } from '@ecp/features/shared/product';
import type { OptionsMetadata } from '@ecp/types';

import { CoveragePolicyLevelDiscountsMetadata as BaseCoveragePolicyLevelDiscountsMetadata } from './CoveragePolicyLevelDiscounts.metadata.js';

export const CoveragePolicyLevelDiscountsMetadata: Record<Product, OptionsMetadata> = merge(
  {},
  {
    'connect.auto': {
      Costco: {
        title: 'Costco member',
      },
    },
    'homesite.home': {
      costcoMemberValue: {
        title: 'Costco member',
      },
      /** CPUI-4449 */
      costcoDiscount: {
        title: 'Costco discount',
      },
    },
    'homesite.renters': {
      costcoMemberValue: {
        title: 'Costco member',
      },
      costcoDiscount: {
        title: 'Costco discount',
      },
    },
    'amfam.auto': {
      costcoMemberValue: {
        title: 'Costco member',
      },
    },
  },
  BaseCoveragePolicyLevelDiscountsMetadata,
);
