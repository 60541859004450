export interface Metadata {
  helperText?: string | React.ReactElement;
  label?: string | React.ReactElement;
}

const metadata: Metadata = {
  helperText:
    'This discount is only available when you pay through a bank account. It does not apply to credit card payments.',
  label:
    'Do you plan to sign up for recurring payments of your auto premium through automatic withdrawal from a checking or savings account? (optional)',
};

export default metadata;
