import { Grid, Typography } from '@mui/material';

import { Page } from '@ecp/features/sales/shared/components';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import {
  getCurrentPage,
  getPreviousPage,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { LogoMoonshotWhite } from '@ecp/themes/base';

import type { AgentLoginFields } from './AgentLoginForm';
import { AgentLoginForm } from './AgentLoginForm';
import { useStyles } from './AgentLoginPage.styles';

const useGetFields = (): AgentLoginFields => {
  const useField = useFieldWithPrefix('', '');

  return {
    username: useField('static.username'),
    password: useField('static.password'),
  };
};

export const AgentLoginPage: React.FC = () => {
  const { classes } = useStyles();
  const fields = useGetFields();
  let currentPage = useSelector(getCurrentPage);
  if (currentPage === '/') currentPage = PagePath.LANDING;

  // PreviousPage is useful when user clicks on Log Out button from
  // a regular page (which needs a session). In this case, currentPage = '/agent/login'
  // Refer to the logic in AgentHeader
  let previousPage = useSelector(getPreviousPage);
  if (previousPage === '/') previousPage = PagePath.LANDING;

  const pageToNavigate =
    (currentPage !== PagePath.AGENT_LOGIN && currentPage) || previousPage || PagePath.LANDING;

  // Replace history.
  // Don't save previous page here.
  const handleNext = useNavigateToPage(pageToNavigate, {
    replace: true,
    removeQuery: false,
    replaceProfileAdd: false,
  });

  return (
    <Page className={classes.pageBackground}>
      <div className={classes.root}>
        <Grid container justifyContent='center' className={classes.container}>
          <Grid
            item
            xs={0}
            md={6}
            container
            justifyContent='center'
            alignItems='center'
            className={classes.logoContainer}
          >
            <LogoMoonshotWhite className={classes.appLogo} />
          </Grid>

          <Grid item xs={12} md={6} className={classes.form}>
            <Grid container justifyContent='center'>
              <Typography variant='h1' align='center'>
                Agent login
              </Typography>
            </Grid>
            <AgentLoginForm fields={fields} onNext={handleNext} />
          </Grid>
        </Grid>
      </div>
    </Page>
  );
};
