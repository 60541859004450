import { useEffect } from 'react';

import { agentAuth } from '@ecp/utils/auth';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { callRecording } from '@ecp/features/sales/shared/components';

interface CallComponentProps {
  handleAgentCallRecording?: (record: boolean, trackingName: string) => void;
}

interface CallRecordingOnRenderWrapperProps {
  component: React.FC<CallComponentProps>;
  trackingName: string;
}

export const CallRecordingOnRenderWrapper: React.FC<CallRecordingOnRenderWrapperProps> = ({
  component: Component,
  trackingName,
}) => {
  const callRecordingFeature = flagValues[FeatureFlags.AGENT_CALL_RECORDING];
  const agentGenesysEmail = callRecordingFeature ? agentAuth.getAgentEmail() : null;

  useEffect(() => {
    if (callRecordingFeature && agentGenesysEmail)
      callRecording('pause', agentGenesysEmail, trackingName);

    // Resume call recording on unmount
    return () => {
      if (callRecordingFeature && agentGenesysEmail)
        callRecording('resume', agentGenesysEmail, trackingName);
    };
  }, [callRecordingFeature, agentGenesysEmail, trackingName]);

  return <Component />;
};
