import type { AgentScripts } from './types';

export const ProtectedPersonDisclaimerQuestion = (): AgentScripts => {
  return {
    title: 'Do you identify as a Protected Person?',
    text: 'A protected person is someone who is a victim of domestic abuse, or who provides shelter, advocacy, or counseling to a domestic abuse victim may identify him/herself as a protected person so that we may keep their address and any confidential abuse information secure. Would you like to know more about the protected person status or do you identify as a protected person?',
    spanishText:
      'Una persona protegida es alguien que es víctima de abuso doméstico, o que proporciona refugio, defensa o asesoramiento a una víctima de abuso doméstico puede identificarse como una persona protegida para que podamos mantener su dirección y cualquier información confidencial de abuso seguro. ¿Desea saber más sobre el estatuto de persona protegida o se identifica como persona protegida?',
  };
};

export const ProtectedPersonDisclaimerMetadata = (partnerName: string): AgentScripts => {
  return {
    text: (
      <>
        <br />
        <br />
        <p>
          {partnerName} and Homesite Group Incorporated uses information from many sources. This
          assists the company to fairly determine eligibility for our programs and ensure accurate
          rates for all policies.
        </p>
        <br />
        <p>
          We may collect information from sources other than you or someone else named in your
          application. This information may include "confidential abuse information" - that is,
          confidential information regarding acts of domestic abuse or abuse status, the work, home,
          school, or other address or telephone number of a victim of domestic abuse or the status
          of an applicant or insured as a family member, employer or associate of a victim of
          domestic abuse or a person with whom an applicant is known to have a direct, close
          personal, family, or abuse-related counseling relationship. The information may be
          received from persons other than a victim of domestic abuse. Sources include, but are not
          limited to, medical records, police reports, interviews and insurance support agencies.
        </p>
        <br />
        <p>
          We will not use confidential abuse status to deny, refuse to issue, renew or reissue
          coverage, or to cancel or otherwise terminate a policy, or restrict or exclude policy
          coverage or benefits, or to charge a higher premium.
        </p>
      </>
    ),
    spanishText: (
      <>
        <p>
          {partnerName} y Homesite Group Incorporated utiliza información de muchas fuentes. Esto
          ayuda a la empresa a determinar con equidad la elegibilidad para nuestros programas y
          garantizar tarifas exactas para todas las pólizas.
        </p>
        <br />
        <p>
          Podemos recopilar información de fuentes distintas a usted o a otra persona nombrada en su
          solicitud. Esta información puede incluir información confidencial sobre abuso; es decir,
          información confidencial sobre actos de abuso doméstico; el trabajo, casa, escuela u otra
          dirección o número de teléfono de una víctima de abuso doméstico; el estado de un
          solicitante o asegurado como víctima de abuso doméstico; o su condición de miembro de la
          familia, empleador o asociado de una víctima de abuso doméstico o una persona con la que
          se sabe que el solicitante tiene una relación de asesoramiento directa, cercana, familiar
          o relacionada con el abuso. Las fuentes incluyen, entre otras, historiales médicos,
          informes policiales, entrevistas y organismos de apoyo a los seguros.
        </p>
        <br />
        <p>
          No utilizaremos la condición de abuso confidencial para denegar, negarnos a emitir,
          renovar o reemitir la cobertura, ni para cancelar o rescindir de otro modo una póliza, ni
          para restringir o excluir la cobertura o las prestaciones de la póliza, ni para cobrar una
          prima más alta.
        </p>
      </>
    ),
  };
};
