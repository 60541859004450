/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect } from 'react';

import { Events, trackEvent } from '@ecp/utils/flags';

import { NavbarDrawer } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import { PagePath, useNavigateToNextPage } from '@ecp/features/sales/shared/routing';
import { getAutoProduct, getPrimaryInsuredStateCode } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { AutoShortDiscountsForm } from '../../forms';
import { autoHeaderMetadata } from './metadata';

export const AutoShortDiscountsPage: React.FC = () => {
  const autoProduct = useSelector(getAutoProduct)!;
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const handleNext = useNavigateToNextPage();
  const title = 'Let’s see what discounts you might qualify for!';
  const subHeader =
    autoHeaderMetadata[autoProduct].stateOptions?.[stateCode]?.subHeader ||
    autoHeaderMetadata[autoProduct].subHeader;
  const subTitle =
    autoHeaderMetadata[autoProduct].stateOptions?.[stateCode]?.subTitle ||
    autoHeaderMetadata[autoProduct].subTitle;

  // TODO: We might need a better way to handle this.
  useEffect(() => {
    trackEvent(Events.AUTO_DISCOUNTS_PAGE);
  }, []);

  return (
    <div>
      <Page
        sidebarProps={{ drawer: <NavbarDrawer pagePath={PagePath.AUTO_DISCOUNTS_SHORT} /> }}
        title={title}
        analyticsElement='choice.discountPage.page'
      >
        <AutoShortDiscountsForm onNext={handleNext} subHeader={subHeader} subTitle={subTitle} />
      </Page>
    </div>
  );
};
