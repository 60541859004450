import type { StateCompanyName } from '@ecp/features/sales/shared/types';

import { NachaAuthorizationAgreement } from './GeneralCheckoutDisclaimers';
import {
  GeneralCreditAuthDisclaimer,
  GeneralPaymentConfirmationDisclaimer,
} from './GeneralCheckoutDisclaimers';
import {
  getRecurringPaymentMetadataForPropertyCC,
  RecurringPaymentMetadataForPropertyEFT,
} from './RecurringPaymentMetadataForProperty';
import type { GeneralPaymentDisclaimerProps } from './types';

export const RecurringPaymentCompanyName: { [productKey: string]: StateCompanyName } = {
  'connect.auto': {
    name: 'American Family Connect Property and Casualty Insurance Company',
    stateOptions: {
      GA: {
        name: 'American Family Connect Insurance Company',
      },
      NY: {
        name: 'American Family Connect Insurance Company',
      },
    },
  },
};

const GeneralAgreementDiclosure: React.FC<GeneralPaymentDisclaimerProps> = ({
  name,
}): JSX.Element => (
  <>
    <p>
      By selecting pre authorized checking withdrawal, you authorize {name} to charge your account
      at the financial institution selected to pay your auto premium installments. You authorize the
      financial institution selected to honor these charges for premium as if they were signed by
      you. You certify this agreement will remain in effect until you notify {name}, allowing a
      reasonable time to act on the cancellation. You may stop payment by notifying {name} at least
      three (3) banking days before the charge is made. {name} will notify you if any payment
      differs from the previous payment. In the event of an unauthorized transfer to {name}, or if
      you cancel this agreement at any time, please contact our office.
    </p>
    <p>
      The payment of your policy will be taken on the effective date of your policy. Late and Return
      Payment Fees may be assessed.
    </p>
  </>
);

export const RecurringPaymentMetadata: Record<
  string,
  Record<
    string,
    | ((name: string, state: string) => React.ReactElement)
    | ((name?: string, state?: string) => React.ReactElement)
  >
> = {
  EFT: {
    'connect.auto': (name: string): React.ReactElement => (
      <NachaAuthorizationAgreement
        product='auto'
        defaultComponent={<GeneralAgreementDiclosure name={name} />}
      />
    ),
    'homesite.home': (state: string): React.ReactElement => (
      <NachaAuthorizationAgreement
        product='home'
        defaultComponent={<RecurringPaymentMetadataForPropertyEFT state={state} />}
      />
    ),
    'homesite.renters': (state: string): React.ReactElement => (
      <NachaAuthorizationAgreement
        product='renters'
        defaultComponent={<RecurringPaymentMetadataForPropertyEFT state={state} />}
      />
    ),
    'amfam.auto': (): React.ReactElement => (
      <NachaAuthorizationAgreement
        product='auto'
        defaultComponent={<GeneralPaymentConfirmationDisclaimer />}
      />
    ),
    'amfam-adv.auto': (): React.ReactElement => (
      <NachaAuthorizationAgreement
        product='auto'
        defaultComponent={<GeneralPaymentConfirmationDisclaimer />}
      />
    ),
    'amfam-adv.home': (): React.ReactElement => (
      <NachaAuthorizationAgreement
        product='home'
        defaultComponent={<GeneralPaymentConfirmationDisclaimer />}
      />
    ),
    'amfam-adv.renters': (): React.ReactElement => (
      <NachaAuthorizationAgreement
        product='renters'
        defaultComponent={<GeneralPaymentConfirmationDisclaimer />}
      />
    ),
  },
  CreditCard: {
    'connect.auto': (name: string): React.ReactElement => (
      <GeneralCreditAuthDisclaimer name={name} product='auto' />
    ),
    'homesite.home': getRecurringPaymentMetadataForPropertyCC,
    'homesite.renters': getRecurringPaymentMetadataForPropertyCC,
    'amfam.auto': (): React.ReactElement => <GeneralPaymentConfirmationDisclaimer />,
    'amfam-adv.auto': (): React.ReactElement => <GeneralPaymentConfirmationDisclaimer />,
    'amfam-adv.home': (): React.ReactElement => <GeneralPaymentConfirmationDisclaimer />,
    'amfam-adv.renters': (): React.ReactElement => <GeneralPaymentConfirmationDisclaimer />,
  },
  CostcoVisa: {
    'connect.auto': (name: string): React.ReactElement => (
      <GeneralCreditAuthDisclaimer name={name} product='auto' />
    ),
    'homesite.home': getRecurringPaymentMetadataForPropertyCC,
    'homesite.renters': getRecurringPaymentMetadataForPropertyCC,
    'amfam.auto': (): React.ReactElement => <GeneralPaymentConfirmationDisclaimer />,
  },
};
