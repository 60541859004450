import { OutageBanner } from '@ecp/features/sales/shared/components';

import { AppFooter } from '../AppFooter';
import { AppHeader } from '../AppHeader';
import { useStyles } from './PageLayout.styles';

export interface PageLayoutProps {
  children?: React.ReactElement;
  hideFooter: boolean;
  hideHeader: boolean;
}

export const PageLayout: React.FC<PageLayoutProps> = ({ children, hideFooter, hideHeader }) => {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      {!hideHeader && <AppHeader />}
      <OutageBanner />
      <main className={classes.main}>{children}</main>
      {!hideFooter && <AppFooter />}
    </div>
  );
};
