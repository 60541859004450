import { useCallback, useMemo, useRef, useState } from 'react';

import { Grid } from '@mui/material';

import { castToBoolean, ensureStringOrNull } from '@ecp/utils/common';

import { GridItem, TooltipWithIcon } from '@ecp/components';
import {
  useAddFields,
  useGetConditionValues,
  useGetFields,
  useGetInitValues,
} from '@ecp/features/sales/form';
import {
  AutoDiscountsQuestions,
  discountsDefaultValue,
  useGetDriverDiscountMetadata,
  useGetVehicleItemsWithImage,
} from '@ecp/features/sales/quotes/auto';
import {
  CheckboxGroup,
  DriverIcon,
  Form,
  NextPageInstructions,
} from '@ecp/features/sales/shared/components';
import { DiscountsSubQuestions } from '@ecp/features/sales/shared/questions';
import {
  getAutoProduct,
  getPrimaryInsuredStateCode,
  questionExists,
  useForm,
  usePniRef,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type {
  AutoSelectDiscountsFields,
  Driver,
  DriverDiscountsValues,
} from '@ecp/features/sales/shared/types';
import { IconCardAuto } from '@ecp/themes/base';
import type { CardOption } from '@ecp/types';

import { DiscountsFormButton } from '../../../Discounts/DiscountsForm/DiscountsFormButton';
import { autoHeaderMetadata } from '../metadata';
import { useStyles } from './AutoSelectDiscountsForm.styles';

const KEPT_IN_GARAGE_HELP_TEXT = 'Is vehicle regularly parked in a garage when not at work?';

interface Props {
  fields: AutoSelectDiscountsFields;
  drivers: Driver[];
  onNext: () => Promise<void>;
  onAlternateNext: () => Promise<void>;
  subHeader?: string;
  nextPageInstructions?: string;
}

export const AutoSelectDiscountsForm: React.FC<Props> = (props) => {
  const { fields, onNext, onAlternateNext, drivers, nextPageInstructions = '' } = props;
  const { classes } = useStyles();
  const { vehicleDiscounts, driverDiscounts } = fields;
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const autoProduct = useSelector(getAutoProduct);
  const subTitle =
    autoProduct &&
    (autoHeaderMetadata[autoProduct].stateOptions?.[stateCode]?.subTitle ||
      autoHeaderMetadata[autoProduct].subTitle);
  const driverMetadata = useGetDriverDiscountMetadata(drivers, stateCode);
  const getConditions = useGetConditionValues();
  const getFields = useGetFields();
  const initValues = useRef(useGetInitValues()());
  const { validateForm, patchFormValues, isPatchFormInProgress } = useForm({
    initValues,
    fields: getFields(),
    conditions: getConditions(),
  });
  const vehicleDiscountKeys = useMemo(() => {
    return Object.keys(vehicleDiscounts);
  }, [vehicleDiscounts]);
  useAddFields({ ...fields.vehicleDiscounts, ...fields.driverDiscounts });
  const [isContinueClicked, setContinueClicked] = useState(false);

  const vehicleItemList = useGetVehicleItemsWithImage();
  const vehiclesOptions: CardOption[] = useMemo(() => {
    return vehicleItemList.map((v) => {
      // const { vehicle, imageUrl } = v;
      const { vehicle } = v;
      const { ref, description } = vehicle;
      const parsedRef = ref || '';

      return {
        value: parsedRef,
        label: description,
        icon: <IconCardAuto />,
        // ECP-13138 We'll be reusing this agaib with the appropriate image at a later time
        // image: (
        //   <img src={imageUrl} className={classes.vehicleImage} alt='' data-testid='vehicleImage' />
        // ),
      };
    });
  }, [vehicleItemList]);
  // }, [vehicleItemList, classes]);

  const keptGaragedDiscountExists = useSelector((state: RootStore) =>
    questionExists('vehicle.<id>.discount.keptInGarage')(state),
  );

  const keptInGarageValues = useMemo(() => {
    return vehicleDiscountKeys.reduce((values, key) => {
      const field = vehicleDiscounts[key].keptInGarage;
      const { value } = field;
      if (castToBoolean(value)) {
        return [...values, key];
      }

      return values;
    }, [] as Array<keyof typeof vehicleDiscounts & string>);
  }, [vehicleDiscountKeys, vehicleDiscounts]);

  const handleKeptInGarageChange = useCallback(
    (checkedRefs: string[]) => {
      checkedRefs.forEach((vehicleRef) => {
        const field = vehicleDiscounts[vehicleRef].keptInGarage;
        if (!castToBoolean(field.value)) {
          field.props.actionOnComplete(true);
        }
      });
      vehicleDiscountKeys.forEach((key) => {
        if (!checkedRefs.includes(key)) {
          const field = vehicleDiscounts[key].keptInGarage;
          if (castToBoolean(field.value)) {
            field.props.actionOnComplete(false);
          }
        }
      });
    },
    [vehicleDiscountKeys, vehicleDiscounts],
  );

  const driverDiscountsValues: DriverDiscountsValues = useMemo((): DriverDiscountsValues => {
    return Object.keys(driverDiscounts).reduce(
      (discountsValues: DriverDiscountsValues, driverRef): DriverDiscountsValues => {
        const discountsFields = driverDiscounts[driverRef];
        const discountValues = Object.keys(discountsFields).reduce((values, key) => {
          const field = discountsFields[key];
          if (castToBoolean(field.value) || ensureStringOrNull(field.value)) {
            values.push(key);
          }

          return values;
        }, [] as Array<keyof typeof discountsFields & string>);
        discountsValues[driverRef] = discountValues;

        return discountsValues;
      },
      {},
    );
  }, [driverDiscounts]);

  const handleDriverDiscountChange = useCallback(
    (driverRef: string, values: string[]) => {
      const discountsFields = driverDiscounts[driverRef];
      values.forEach((key) => {
        const field = discountsFields[key];
        if (!field.exists) return;
        const answerType = field.question.answerType;
        if (answerType === 'Boolean' && !castToBoolean(field.value))
          field.props.actionOnComplete(true);
        if (answerType === 'String' && !ensureStringOrNull(field.value))
          field.props.actionOnComplete(
            discountsDefaultValue[key as keyof typeof discountsDefaultValue],
          );
      });
      Object.keys(discountsFields).forEach((key) => {
        if (!values.includes(key)) {
          const field = discountsFields[key];
          if (!field.exists) return;
          const asnwerType = field.question.answerType;
          if (asnwerType === 'Boolean' && castToBoolean(field.value)) {
            field.props.actionOnComplete(false);
          }
          if (asnwerType === 'String' && ensureStringOrNull(field.value))
            field.props.actionOnComplete('');
        }
      });
      validateForm({ goToError: false });
    },
    [driverDiscounts, validateForm],
  );

  const handleIsFormValid = useCallback(async () => {
    const { isValid } = validateForm();
    if (isValid) {
      await patchFormValues();
    }

    return isValid;
  }, [patchFormValues, validateForm]);
  const handleSubmit = useCallback(async () => {
    setContinueClicked(true);
    await onNext();
    setContinueClicked(false);
  }, [onNext]);

  const handleSecondarySubmit = useCallback(async () => {
    setContinueClicked(true);
    await onAlternateNext();
    setContinueClicked(false);
  }, [onAlternateNext]);

  const pniRef = usePniRef();
  // This helps filtering the drivers without any discounts beforehand
  const driverDiscountsData = driverMetadata.filter((driver) => driver.cardOptions.length > 0);

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container>
          {vehicleDiscountKeys.length > 0 && keptGaragedDiscountExists && (
            <GridItem topSpacing='lg' xs={12}>
              <CheckboxGroup
                name='VehiclesInGarage'
                cardSize='medium'
                options={vehiclesOptions}
                label={
                  <>
                    Which vehicles are kept in a garage?{' '}
                    <span className={classes.textTertiary}> (optional)</span>
                    <TooltipWithIcon title={KEPT_IN_GARAGE_HELP_TEXT} />
                  </>
                }
                actionOnComplete={handleKeptInGarageChange}
                values={keptInGarageValues}
                trackingName='vehicles_in_garage_mutliselect'
                dataTestIdPrefix='VehiclesInGarage'
              />
            </GridItem>
          )}
          {driverDiscountsData.map((driver, index) => {
            const { ref: driverRef, personRef, cardOptions } = driver;
            const driverDiscountFields = getFields()?.[driverRef];
            const driverDiscountErrors =
              driverDiscountFields &&
              Object.values(driverDiscountFields)
                .reduce((acc, field) => {
                  if (field.errors.length) {
                    return acc.concat(field.errors);
                  }

                  return acc;
                }, [])
                .join(', ');

            return (
              <GridItem topSpacing='lg' xs={12} key={driverRef}>
                <CheckboxGroup
                  name={driverRef}
                  error={driverDiscountErrors}
                  cardSize='medium'
                  options={cardOptions}
                  data-testid={driverRef}
                  dataTestIdPrefix={`discount${index}`}
                  label={
                    <>
                      <DriverIcon index={index} className={classes.driverIcon} />
                      &nbsp;
                      {`Discounts for ${driver.firstName} ${personRef === pniRef ? '(PNI)' : ''}`}
                      <span className={classes.textTertiary}> (optional)</span>
                    </>
                  }
                  // eslint-disable-next-line react/jsx-no-bind
                  actionOnComplete={(value) => handleDriverDiscountChange(driverRef, value)}
                  values={driverDiscountsValues[driverRef]}
                  trackingName='driver_discounts'
                />
                <DiscountsSubQuestions
                  driverRef={driverRef}
                  isContinueClicked={isContinueClicked}
                />
              </GridItem>
            );
          })}
          <AutoDiscountsQuestions
            header={
              vehicleDiscountKeys.length > 0 || driverDiscountsData.length > 0 ? (
                <h3>{subTitle}</h3>
              ) : undefined
            }
          />
        </Grid>
        <NextPageInstructions divider>{nextPageInstructions}</NextPageInstructions>
        <DiscountsFormButton
          variant='primary'
          isFormValid={handleIsFormValid}
          onClick={handleSubmit}
          onSecondaryClick={handleSecondarySubmit}
          isProcessing={isPatchFormInProgress}
          data-testid='continue'
          className={classes.next}
          trackingLabel='auto_discounts_continue'
          analyticsElement='choice.discountPage.continueButton'
          setContinueClicked={setContinueClicked}
        >
          Continue
        </DiscountsFormButton>
      </Form>
    </div>
  );
};
