import { useCallback } from 'react';

import { isMasked } from '@ecp/utils/common';

import type { SelectProps } from '@ecp/components';
import { GridItem } from '@ecp/components';
import { Select } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';
import type { AnswerValue } from '@ecp/types';

import { useVehicleInfo } from '../../../../../forms';
import { checkAndClearFields, useValidateVinNumber, validateVinFormat } from '../../../../../state';
import { useVehicleFields } from '../../../../../state/modelUtil/vehicleModelUtil';
import { useStyles } from '../VehicleInfoQuestions.styles';
import metadata from './metadata';

interface Props extends QuestionProps {
  setMaskRequiredFieldError: (value: boolean) => void;
  vehicleRef: string;
  actionOnChange?: (value: string) => Promise<void>;
}

export const VehicleYearQuestion: React.FC<Props> = (props) => {
  const { vehicleRef, setMaskRequiredFieldError, actionOnChange } = props;
  const { classes } = useStyles();
  const { make, model, series, year, vin, stubVin, vehicleDetailId, vehicleInfoOrVin } =
    useVehicleFields(vehicleRef);

  const { setInfoError, fetchVehicleInfo } = useVehicleInfo(
    make,
    model,
    series,
    vin,
    stubVin,
    vehicleDetailId,
  );
  const { error: yearFieldError, ...yearProps } = year.props;

  const handleYearChange: NonNullable<SelectProps['actionOnChange']> = useCallback(
    (value) => {
      setMaskRequiredFieldError(true);
      setInfoError('');
      checkAndClearFields([make, model, series, stubVin, vehicleDetailId]);
      year.props.actionOnChange(value);
      if (isMasked(vin.value)) {
        checkAndClearFields([vin]);
      }
    },
    [
      setMaskRequiredFieldError,
      setInfoError,
      vehicleDetailId,
      make,
      model,
      series,
      year.props,
      vin,
      stubVin,
    ],
  );
  const validateVinNumber = useValidateVinNumber(vin);

  const handleYearComplete = useCallback(
    async (value: AnswerValue): Promise<void> => {
      year.props.actionOnComplete(value);
      if (vehicleInfoOrVin.value !== 'vin') return;

      const vinValue = vin.value as string;

      const vinFormatValidationError = validateVinFormat(vinValue);
      if (vinFormatValidationError || isMasked(vinValue)) return;

      await fetchVehicleInfo(value as string, vinValue);
      validateVinNumber();
    },
    [year.props, vin.value, vehicleInfoOrVin.value, fetchVehicleInfo, validateVinNumber],
  );

  if (!year.exists) return null;

  return (
    <GridItem topSpacing='sm' xs={12} className={classes.vehicleInfoSelect}>
      <Select
        error={yearFieldError}
        {...yearProps}
        id='VehicleYear'
        label='Vehicle year'
        actionOnChange={actionOnChange || handleYearChange}
        actionOnComplete={handleYearComplete}
        options={metadata.yearOptions}
        data-testid='year'
        trackingName={
          vehicleInfoOrVin.value === 'vehicleInfo' ? 'vehicle_year_selection' : 'vin_year_selection'
        }
      />
    </GridItem>
  );
};
