import { lowerFirst, upperFirst } from '@ecp/utils/common';

import { env } from '@ecp/env';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

type Event = 'resume' | 'pause';

export const callRecording = (event: Event, agentUserName: string, componentName: string): void => {
  const gws = new WebSocket(env.callRecordingServiceUrl);

  const data = {
    operation: event,
    origin: env.static.applicationName,
    email: agentUserName,
  };

  trackSapiAnalyticsEvent({
    element: `${lowerFirst(componentName)}.genysysCallRecording${upperFirst(event)}`,
    event: 'update',
    eventDetail: JSON.stringify(data),
  });

  gws.onopen = () => {
    gws.send(JSON.stringify(data));
  };
};
