import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import { Button } from '@ecp/features/sales/shared/components';
import { useIsMobile } from '@ecp/themes/base';

import { useStyles } from '../LandingPageForm.styles';
import metadata from '../metadata/metadata';

interface CallToActionProps {
  isNewQuoteInProgress: boolean;
  isRecallInProgress: boolean;
  onRecallQuoteClick?: () => void;
  onStartNewQuoteClick: () => void;
}

export const CallToAction: React.FC<CallToActionProps> = (props) => {
  const { classes, cx } = useStyles();
  const { isNewQuoteInProgress, isRecallInProgress, onRecallQuoteClick, onStartNewQuoteClick } =
    props;
  const isMobile = useIsMobile();

  return (
    <>
      {onRecallQuoteClick && <h2 className={classes.subtitle}>Select one to continue</h2>}
      <Grid container>
        {onRecallQuoteClick && (
          <Grid item xs={isMobile ? 12 : 0}>
            <Button
              className={cx(classes.next, classes.recallButton)}
              disabled={isNewQuoteInProgress}
              variant='outlinePrimary'
              onClick={onRecallQuoteClick}
              type='button'
              data-testid='recallQuotesContinueButton'
              isProcessing={isRecallInProgress}
            >
              Recall quote
            </Button>
          </Grid>
        )}
        <GridItem xs={isMobile ? 12 : 0} topSpacing={metadata.topSpacing}>
          <Button
            className={classes.next}
            disabled={isRecallInProgress}
            variant='primary'
            onClick={onStartNewQuoteClick}
            data-testid='continue'
            trackingName='ContinueButton'
            analyticsElement='choice.landingPage.continueButton'
            type='submit'
            isProcessing={isNewQuoteInProgress}
          >
            Start a new quote
          </Button>
        </GridItem>
      </Grid>
    </>
  );
};
