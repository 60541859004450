import type { Metadata } from './metadata.js';
import baseMetadata from './metadata.js';

export const metadata: Metadata = {
  ...baseMetadata,
  headerBody: 'Our licensed representatives can assist.',
  hideHelpCard: true,
};

export default metadata;
