import { Grid } from '@mui/material';

import { useTrackHover } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import { useFieldWithPrefix } from '@ecp/features/sales/shared/store';

import {
  FinancialResponsibilityFormQuestion,
  HouseholdPriorLossQuestion,
  IncidentQuestions,
  InsuranceQuestions,
} from '../../components';
import { useAllowAddingSNI } from '../../state';
import { DriverProfileFormQuestions } from './DriverProfileFormQuestions';

export interface DriverFormQuestionsProps {
  isPni: boolean;
  driverRef: string;
  personRef: string;
  incidentRequired: boolean;
  setIncidentRequired: (value: boolean) => void;
}

export const DriverFormQuestions: React.FC<DriverFormQuestionsProps> = (props) => {
  const { isPni, driverRef, personRef, incidentRequired, setIncidentRequired } = props;
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const firstName = usePersonField('firstName');
  const {
    props: { value: name = '' },
  } = firstName;

  const nameOrYou = name ? `Does ${name}` : 'Do you';
  const wasNameOrWereYou = name ? `was ${name}` : 'were you';
  const possessiveName = name ? `${name}'s` : 'your';
  const willNameOrYou = `will ${name ? name : 'you'}`;
  const ifNameOrYou = name ? `If ${name}` : 'If you';

  return (
    <Grid container>
      <DriverProfileFormQuestions
        isPni={isPni}
        driverRef={driverRef}
        personRef={personRef}
        possessiveName={possessiveName}
        ifNameOrYou={ifNameOrYou}
        wasNameOrWereYou={wasNameOrWereYou}
        willNameOrYou={willNameOrYou}
      >
        <GridItem topSpacing='lg' xs={12}>
          <HouseholdPriorLossQuestion isPni={isPni} />
        </GridItem>
        <GridItem topSpacing='lg' xs={12} {...useTrackHover('financial_responsibility_hover')}>
          <FinancialResponsibilityFormQuestion driverRef={driverRef} nameOrYou={nameOrYou} />
        </GridItem>
        <IncidentQuestions
          driverRef={driverRef}
          personRef={personRef}
          incidentRequired={incidentRequired}
          setIncidentRequired={setIncidentRequired}
        />
        <InsuranceQuestions
          driverRef={driverRef}
          personRef={personRef}
          isPni={isPni}
          allowAddSNI={useAllowAddingSNI(driverRef)}
        />
      </DriverProfileFormQuestions>
    </Grid>
  );
};
