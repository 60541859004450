import { Grid } from '@mui/material';

import { GridItem } from '@ecp/components';
import {
  HasExistingHomeProductQuestion,
  LengthOfResidenceQuestion,
  ResidencyTypeQuestion,
} from '@ecp/features/sales/shared/questions';
import { useField } from '@ecp/features/sales/shared/store';
import { LineOfBusiness } from '@ecp/features/shared/product';

import { PaperlessBillingQuestion, RecurringPaymentQuestion } from '../../components';
import { useStyles } from './AutoDiscountsQuestions.styles';

export interface AutoDiscountsQuestionsProps {
  header?: React.ReactElement;
}

export const AutoDiscountsQuestions: React.FC<AutoDiscountsQuestionsProps> = (props) => {
  const { header } = props;
  const { classes } = useStyles(props);
  const residencyType = useField('residencyType');
  const paperless = useField('discount.auto.paperless');
  const autoPay = useField('discount.auto.autoPay');
  const showHeader = paperless.exists || autoPay.exists || residencyType.exists;

  return (
    <GridItem className={classes.root}>
      <Grid container rowGap='40px'>
        {showHeader && header && (
          <GridItem topSpacing='lg' xs={12}>
            {header}
          </GridItem>
        )}
        <GridItem xs={12}>
          <PaperlessBillingQuestion />
        </GridItem>
        <GridItem xs={12}>
          <RecurringPaymentQuestion />
        </GridItem>
        <GridItem xs={12}>
          <LengthOfResidenceQuestion forLineOfBusiness={LineOfBusiness.AUTO} />
        </GridItem>
        <GridItem xs={12}>
          <ResidencyTypeQuestion />
        </GridItem>
        <GridItem xs={12}>
          <HasExistingHomeProductQuestion />
        </GridItem>
      </Grid>
    </GridItem>
  );
};
