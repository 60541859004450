import { useCallback, useState } from 'react';

import { FormHelperText, Grid } from '@mui/material';

import { trackClick, useTrackHover } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { Button, RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import { getPrimaryInsuredStateCode, useFieldWithPrefix } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, Incident } from '@ecp/features/sales/shared/types';
import { IconUICheckCircleSolid, IconUIDelete, IconUIEdit, IconUIPlus } from '@ecp/themes/base';

import { useAddIncident, useIncidents, useRemoveIncident } from '../../../../state';
import { useStyles } from './IncidentQuestions.styles';
import { IncidentQuestionsSection } from './IncidentQuestionsSection';
import { getIncidentFieldLabel } from './util';

export interface IncidentQuestionsProps {
  driverRef: string;
  personRef: string;
  incidentRequired: boolean;
  setIncidentRequired: (value: boolean) => void;
}

export const IncidentQuestions: React.FC<IncidentQuestionsProps> = (props) => {
  const { driverRef, personRef, incidentRequired, setIncidentRequired } = props;
  const { cx, classes } = useStyles();

  const useDriverField = useFieldWithPrefix(driverRef, 'driver.<id>');
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const {
    props: { value: name = '' },
  } = usePersonField('firstName');
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const hasIncidents = useDriverField('hasIncidents');
  const incidentList = useIncidents(driverRef || '');
  const addIncident = useAddIncident(driverRef);
  const removeIncident = useRemoveIncident(driverRef || '');
  const removeCurrentIncident = useRemoveIncident('');
  const [expanded, setExpanded] = useState('');
  const [addIncidentRef, setAddIncidentRef] = useState('');

  const ADD_NEW_INCIDENT = 'ADDNEW';
  const nameOrYou = name || 'you';
  const incidentsFieldLabel = getIncidentFieldLabel(nameOrYou, stateCode);
  const [isRemoving, setIsRemoving] = useState(false);

  useAddFields({ hasIncidents });

  const removeAllSavedIncidentsFromDriver = useCallback(() => {
    incidentList.forEach((incident) => removeIncident(incident));
  }, [incidentList, removeIncident]);

  const handleHasIncidentsChange = useCallback(
    (value: AnswerValue) => {
      hasIncidents.validateUpdateAndPatch(value);
      if (value) {
        setExpanded(ADD_NEW_INCIDENT);
        const incident = addIncident();
        setAddIncidentRef(incident.incidentRef);
      } else {
        // removeAllSavedIncidentsFromDriver will remove all the
        // incidents that are part of driver.<ref>.incident.ref
        removeAllSavedIncidentsFromDriver();

        setIncidentRequired(false);
      }
    },
    [
      addIncident,
      hasIncidents,
      removeAllSavedIncidentsFromDriver,
      setAddIncidentRef,
      setExpanded,
      setIncidentRequired,
    ],
  );

  const handleExpand = useCallback(
    (ref: string) => () => {
      setExpanded(ref);
      if (ref === ADD_NEW_INCIDENT) {
        const incident = addIncident();
        setAddIncidentRef(incident.incidentRef);
      }
    },
    [addIncident, setAddIncidentRef, setExpanded],
  );

  const handleDeleteIncident = useCallback(
    (incident?: Incident) => async () => {
      if (incident) {
        setIsRemoving(true);
        trackClick({ action: 'RemoveIncidentLink', label: 'RemoveIncident' });
        await removeIncident(incident);
        setIsRemoving(false);
      }
    },
    [removeIncident],
  );

  const handleCancelIncident = useCallback(() => {
    setAddIncidentRef('');
    setIncidentRequired(false);
    setExpanded('');
  }, [setAddIncidentRef, setExpanded, setIncidentRequired]);

  const renderIncidents = (
    <Grid item xs={12}>
      {incidentList.map((incident) => (
        <div key={incident.ref}>
          {expanded !== incident.ref && (
            <Grid item container xs={12} className={cx(classes.incidentCard, classes.incidentItem)}>
              <Grid item className={cx(classes.incidentText, classes.incidentItemText)}>
                {incident.claimDescription || incident.violationDescription}
              </Grid>
              <Grid className={classes.checkGrid}>
                <IconUICheckCircleSolid className={classes.checkIcon} />
              </Grid>
              <Grid item className={classes.editDeleteButton}>
                <Button
                  variant='iconTextMedium'
                  className={classes.incidentButton}
                  trackingName='edit_incident_button'
                  trackingLabel='edit_incident'
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={handleExpand(incident.ref)}
                >
                  <IconUIEdit />
                  Edit
                </Button>
                <Button
                  variant='iconTextMedium'
                  className={classes.incidentButton}
                  trackingName='delete_incident_button'
                  trackingLabel='delete_incident'
                  onClick={handleDeleteIncident(incident)}
                  disabled={isRemoving}
                >
                  <IconUIDelete />
                  Delete
                </Button>
              </Grid>
            </Grid>
          )}
          {/* Edit incident */}
          {expanded === incident.ref && (
            <IncidentQuestionsSection
              driverRef={driverRef}
              incidentCancel={handleCancelIncident}
              currentIncidentRef={incident.ref}
              addOrEditIncident='editIncident'
              removeCurrentIncident={removeCurrentIncident}
            />
          )}
        </div>
      ))}
      {/* Add incident */}
      <>
        {expanded !== ADD_NEW_INCIDENT && (
          <Button
            className={cx(classes.incidentCard, classes.addIncidentItem)}
            fullWidth
            trackingName='add_new_incident_button'
            trackingLabel='add_new_incident'
            onClick={handleExpand(ADD_NEW_INCIDENT)}
          >
            <div className={cx(classes.incidentText, classes.addIncidentItemText)}>
              Add another incident
            </div>
            <IconUIPlus className={classes.plusIcon} />
          </Button>
        )}
        {expanded === ADD_NEW_INCIDENT && (
          <IncidentQuestionsSection
            driverRef={driverRef}
            incidentCancel={handleCancelIncident}
            currentIncidentRef={addIncidentRef}
            addOrEditIncident='addIncident'
            removeCurrentIncident={removeCurrentIncident}
          />
        )}
      </>
    </Grid>
  );

  return (
    <>
      {hasIncidents.exists && (
        <>
          {/* eslint-disable-next-line react-hooks/rules-of-hooks */}
          <GridItem topSpacing='lg' xs={12} {...useTrackHover('accidents_or_claims_hover')}>
            <RadioGroupWithOptions
              {...hasIncidents.props}
              actionOnComplete={handleHasIncidentsChange}
              label={incidentsFieldLabel}
              id='HasIncidents'
              variant='yesNoButton'
              trackingName='accidents_or_claims_question'
            />
          </GridItem>
          <GridItem xs={12}>
            <FormHelperText focused={false} data-offset='220'>
              {incidentRequired && (
                <FormHelperText error>Please add at least 1 incident type</FormHelperText>
              )}
            </FormHelperText>
          </GridItem>
        </>
      )}
      {hasIncidents.value && (
        <Grid item xs={12}>
          {renderIncidents}
        </Grid>
      )}
    </>
  );
};
